import React, { useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import DropDown from '../../DropDown';
import HoveredIcon from '../../HoveredIconButton';
import HoveredIconConfirm from '../../HoveredIconConfirmButton';
import DataFieldCard from '../DataFieldCard';
import { KnockoutScreenTemplate } from '../../../../../shared/types/Knockout';
import {
  DataFieldExtendedWithSingleLocationAndFollowups,
  DataFieldLocationType,
} from '../../../../../shared/types/DataField';
import { useDeleteKnockoutScreenTemplateMutation } from '../../../../services/knockoutSlice';
import { AddDataFieldModal } from '../../AddDataFieldModal';
import ModalWidget from '../../../ModalWidget/ModalWidget';
import AddDataField from '../../DataFields/AddDataField';

interface KnockoutScreenCardProps {
  knockoutScreen: KnockoutScreenTemplate;
  onEditTriggered: (toEdit: KnockoutScreenTemplate) => void;
}

const calculateTotalProps = (
  dataField: DataFieldExtendedWithSingleLocationAndFollowups[]
) => {
  const dataFieldNum = dataField.length;
  let followUpNum = 0;
  dataField.forEach((e) => (followUpNum += e.knockoutFollowups.length));
  return `${dataFieldNum} Data Fields | ${followUpNum} Followups`;
};

const KnockoutScreenCard = ({
  knockoutScreen,
  onEditTriggered,
}: KnockoutScreenCardProps) => {
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [handle, setHandle] = useState<string>('');
  const [originDataFieldEdit, setOriginDataFieldEdit] = useState<
    DataFieldExtendedWithSingleLocationAndFollowups | undefined
  >(undefined);
  const [deleteKnockoutScreenTemplate] =
    useDeleteKnockoutScreenTemplateMutation();

  const handleDelete = () => {
    deleteKnockoutScreenTemplate(knockoutScreen.id);
  };

  const orderedKnockoutScreenFields = [
    ...knockoutScreen.knockoutScreenDataFields,
  ].sort((a, b) => a.dataFieldLocation.order - b.dataFieldLocation.order);
  return (
    <>
      {handle.length ? (
        <AddDataFieldModal
          handle={handle}
          setHandle={setHandle}
          locationID={knockoutScreen.id}
          locationType={DataFieldLocationType.KnockoutScreen}
        />
      ) : null}
      <ModalWidget
        isOpen={!!originDataFieldEdit}
        title="Edit Data Field"
        children={
          <AddDataField
            isClose={originDataFieldEdit === undefined}
            dataField={originDataFieldEdit}
            toggleEdit={() => null}
            onClose={() => setOriginDataFieldEdit(undefined)}
          />
        }
        onClose={() => setOriginDataFieldEdit(undefined)}
      />
      <Box
        sx={{
          borderLeft: 'solid 5px #2372B9',
          padding: '30px 40px',
          width: '100%',
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box alignItems="center">
            <Typography
              component="span"
              sx={{
                fontSize: '18px',
                fontWeight: '700',
              }}
            >
              {knockoutScreen.name}
            </Typography>
            &nbsp;&nbsp;
            {!!orderedKnockoutScreenFields.length && (
              <i
                className={`fa-sharp fa-solid fa-chevron-${
                  showDetail ? 'up' : 'down'
                }`}
                onClick={() => setShowDetail(!showDetail)}
              ></i>
            )}
          </Box>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              width: '105px',
            }}
          >
            {/* Delete Button for KnockoutScreen... */}
            <HoveredIconConfirm
              icon="fa-regular fa-trash"
              hoverIcon="fa-solid fa-trash"
              onClick={() => handleDelete()}
            />
            <HoveredIcon
              icon="fa-regular fa-pencil"
              hoverIcon="fa-solid fa-pencil"
              onClick={() => onEditTriggered(knockoutScreen)}
            />
            <DropDown
              icon="fa-regular fa-square-plus"
              hoverIcon="fa-solid fa-square-plus"
              items={['Apply Data Field(s)', 'Add Data Field']}
              onSelect={(e) => setHandle(e)}
            />
          </Stack>
        </Stack>
        <Typography
          component="div"
          sx={{
            fontSize: '11px',
            fontWeight: '700',
          }}
        >
          {knockoutScreen.starter && 'Starter Screen   '}
          <Typography
            component="span"
            sx={{ fontSize: '11px', color: '#646669' }}
          >
            {calculateTotalProps(orderedKnockoutScreenFields)}
          </Typography>
        </Typography>
        <Typography
          component="div"
          sx={{
            paddingTop: '15px',
            width: 'fit-content',
            maxWidth: '1200px',
            overflowWrap: 'break-word',
          }}
          dangerouslySetInnerHTML={{ __html: knockoutScreen.description ?? '' }}
        ></Typography>
      </Box>
      <Divider />
      {showDetail &&
        orderedKnockoutScreenFields.map((dataField, index) => (
          <DataFieldCard
            key={index}
            type={knockoutScreen.starter ? 'Starter' : 'Followup'}
            dataField={dataField}
            datafields={orderedKnockoutScreenFields}
            locationName={knockoutScreen.name}
            locationDescription={knockoutScreen.description ?? ''}
            onEdit={(data) => setOriginDataFieldEdit(data)}
          />
        ))}
    </>
  );
};

export default KnockoutScreenCard;
