import React from 'react';
import { Box, Stack, Typography, Divider } from '@mui/material';
import SelectOptionForSEPApplication from './SEPApplication/SelectOptionForSEPApplication';
import HoveredIcon from './HoveredIconButton';

const AdminHeader = ({
  title,
  editDepartmentOrder,
  departmentNames,
  onEditTriggered
}: // isButtonVisible,
// onCancel,
// onSave,
{
  title: string;
  editDepartmentOrder?: boolean;
  departmentNames?: any;
  onEditTriggered?: any
  // isButtonVisible: boolean;
  // onCancel: () => void;
  // onSave: () => void;
}) => {
  return (
    <>
      <Box sx={{ padding: '40px 40px 30px 40px' }}>
        <Stack
          direction="row"
          spacing={2}
          display="flex"
          justifyContent="space-between"
        >
          <Typography
            variant="h2"
            component="div"
            sx={{ fontSize: '25px', fontWeight: '600' }}
          >
            Admin
          </Typography>
          {/* {isButtonVisible && (
            <Stack
              direction="row"
              display="flex"
              justifyContent="right"
              spacing={2}
            >
              <Button
                sx={{ color: '#2372B9', textTransform: 'none' }}
                variant="text"
                onClick={onCancel}
              >
                Cancel
              </Button>
              <Button
                sx={{ backgroundColor: '#2372B9', textTransform: 'none' }}
                variant="contained"
                onClick={onSave}
              >
                Save
              </Button>
            </Stack>
          )} */}
        </Stack>
        <div style={{ display: 'flex', justifyContent: 'space-between'}}>
        <Typography
          variant="h2"
          component="div"
          sx={{ fontSize: '18px', color: '#7E8083', paddingTop: '10px' }}
        >
          Manage {title}
        </Typography>
        {editDepartmentOrder &&
        <HoveredIcon 
        icon="fa-regular fa-gear"
        hoverIcon="fa-solid fa-gear"
         onClick={ () => onEditTriggered(departmentNames)}
        />
        }
        </div>
      </Box>
      {title === 'SEP Application' ? (
        <Box sx={{ padding: '0px 40px 15px 40px' }}>
          <SelectOptionForSEPApplication />
        </Box>
      ) : (
        <Typography
          variant="h2"
          component="div"
          sx={{
            fontSize: '11px',
            color: '#7E8083',
            padding: '0px 40px 15px 40px',
          }}
        >
          <i className="fa-solid fa-circle-info"></i>
          &nbsp;&nbsp;This does not affect any previously created SEPs
        </Typography>
      )}
      <Divider />
    </>
  );
};

export default AdminHeader;
