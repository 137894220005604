import React, { useState } from 'react';
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ReactComponent as Circle } from '../../assets/svg/alerts.svg';
import { ReactComponent as StartCircle } from '../../assets/svg/alerts-grey.svg';
import StyledLinearProgress from '../../components/LinearProgress';
import { TaskExtended } from '../../../shared/types/Task';
import CloseIcon from '@mui/icons-material/Close';

type ProgressProps = {
  title: string;
  sepPhase?: TaskExtended[] | null;
  completedTasks?: TaskExtended[] | null;
  sepFinish?: boolean;
  sepPhaseLen?: number;
  completedLen?: number;
  phaseTitle?: string;
  onClick?: () => void;
};

const PhaseProgress: React.FC<ProgressProps> = ({
  title,
  sepPhase,
  completedTasks,
  sepFinish,
  sepPhaseLen,
  completedLen,
  phaseTitle,
  onClick,
}: ProgressProps) => {
  const theme = useTheme();
  const [isTaskDetailsModalOpen, setIsTaskDetailsModalOpen] = useState(false);
  const [selectedPhaseTasks, setSelectedPhaseTasks] = useState<TaskExtended[]>([]);
  const openTaskDetailsModal = (phaseTasks: TaskExtended[] | null | undefined) => {

    const tasksArray = phaseTasks ? (Array.isArray(phaseTasks) ? phaseTasks : [phaseTasks]) : [];
    setSelectedPhaseTasks(tasksArray);
    setIsTaskDetailsModalOpen(true);
  };
  
  
  const closeTaskDetailsModal = () => {
    setIsTaskDetailsModalOpen(false);
    setSelectedPhaseTasks([]);
  };
  const completed =
    completedLen !== undefined ? completedLen : completedTasks?.length;
  const sepPhaseLength =
    sepPhaseLen !== undefined ? sepPhaseLen : sepPhase?.length;
  const status: boolean =
    completed === sepPhaseLength && sepPhase !== null ? true : false;
  const unStarted: boolean =
    (completed === 0 && completedTasks !== null) || completed === undefined;
  const progressBarValue =
    completed !== undefined &&
    sepPhaseLength !== undefined &&
    sepPhaseLength !== 0
      ? (completed / sepPhaseLength) * 100
      : (completed === null && 100) || 0;

      return (
        <>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
              height: '16px',
              marginBottom: '5px',
              width: '97.8%',
            }}
          
          >
            <IconButton onClick={() => openTaskDetailsModal(sepPhase)} aria-label={title} style={{ fontSize: '24px', color: 'blue' }}>

              {sepPhaseLen === undefined ? (
                <ProgressIconToggle status={status} unStarted={unStarted}  />
              ) : (
                <Circle aria-label="phase-icon" style={{ fill: 'blue' }} />
              )}
            </IconButton>
            &nbsp;
            <StyledLinearProgress
              variant="determinate"
              complete={status}
              theme={theme}
              value={progressBarValue}
              sx={{ m: -0.4 }}
              onClick={() => openTaskDetailsModal(sepPhase)}
            />
            {sepPhaseLen === undefined && (
              <FinishSepIconToggle title={title} sepFinish={sepFinish!} />
            )}
 <Dialog open={isTaskDetailsModalOpen} onClose={closeTaskDetailsModal}>
  <DialogTitle>
    {selectedPhaseTasks.length > 0 ? `${title} Tasks` : 'No Tasks'}
    <IconButton
      edge="end"
      color="primary"
      size="small"  
      onClick={closeTaskDetailsModal}
      aria-label="close"
      sx={{ position: 'absolute', top: 8, right: 8 }}
    >
      <CloseIcon />
    </IconButton>
  </DialogTitle>
  <DialogContent>
  <Table>
      <TableHead>
      <TableRow>
          <TableCell sx={{ width: '40%', textAlign: 'left' }} align="left">Task</TableCell>
          <TableCell sx={{ width: '30%', textAlign: 'left' }} align="left">Department</TableCell>
          <TableCell sx={{ width: '30%', textAlign: 'left' }} align="left">Status</TableCell>
          <TableCell sx={{ width: '30%', textAlign: 'left' }} align="left">Assigned to</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {selectedPhaseTasks.map((task) => (
          <TableRow key={task.id}>
            <TableCell align="left">{task.name}</TableCell>
            <TableCell align="left">{task.department.name}</TableCell>
            <TableCell align="left">{task.status}</TableCell>
            <TableCell align="left">{task.assignee.displayName}</TableCell>
          </TableRow>

        ))}
      </TableBody>
    </Table>
  </DialogContent>
</Dialog>

</Box>
      <p className="sep-progress-label">
        {sepPhaseLen === undefined ? title : ''}
      </p>
      {sepPhaseLength && sepPhaseLength > 0 ? (
        <p className="sep-progress-num"onClick={() => openTaskDetailsModal(sepPhase)}>
        {completed ?? 0}/{sepPhaseLength}{' '}
          <span className="task">{sepPhaseLen ? title : 'Task Complete'}</span>
        </p>
      ) : (
        <p className="sep-progress-num">
          <span className="task">No Tasks Enabled</span>
        </p>
      )}
    </>
  );
};

export default PhaseProgress;

const ProgressIconToggle = ({
  status,
  unStarted,
}: {
  status: boolean;
  unStarted: boolean;
}) => {
  if (status) {
    return <i className="fa-solid fa-badge-check" style={{ color: 'green' }} />;
  } else {
    return unStarted ? (
      <StartCircle aria-label="unstarted-circle" />
    ) : (
      <Circle aria-label="started-circle" />
    );
  }
};

const FinishSepIconToggle = ({
  title,
  sepFinish,
}: {
  title: string;
  sepFinish: boolean;
}) => {
  if (title === 'Implement Phase') {
    return sepFinish ? (
      <>
        &nbsp;
        <i
          className="fa-solid fa-badge-check"
          style={{ color: 'green' }}
          aria-label="finish-progress"
        />
      </>
    ) : (
      <>
        &nbsp;
        <StartCircle aria-label="unfinish-circle" />
      </>
    );
  } else {
    return <></>;
  }
};