import * as React from 'react';
import Popover from '@mui/material/Popover';
import { styled } from '@mui/material';

const StyledPopOver = styled(Popover)(() => ({
  '.MuiPopover-paper': {
    borderRadius: '16px',
    marginTop: '23px',
    overflowX: 'Visible',
    overflowY: 'visible',
    display: 'inline-block',
    zIndex: 1000,
    '&::after': {
      content: '""',
      position: 'absolute',
      top: '0px',
      left: '50%',
      marginLeft: '22px',
      width: '0px',
      height: '0px',
      boxSizing: 'border-box',
      border: '16px solid black',
      borderColor: 'transparent transparent white white',
      transformOrigin: '0 0',
      transform: 'rotate(135deg)',
      boxShadow: '-5px 5px 10px -2px rgb(0 0 0 / 20%)',
    },
  },
}));

export default function PopOver({ component }: { component: JSX.Element }) {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div>
      <i
        aria-label="showPopup"
        style={{ color: open ? '#2372B9' : '#7E8083', cursor: 'pointer' }}
        className="fa-solid fa-circle-info"
        onClick={handleClick}
      ></i>
      <StyledPopOver
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        {component}
      </StyledPopOver>
    </div>
  );
}
