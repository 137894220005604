import React from 'react';
import { Box, Typography } from '@mui/material';
import { TaskStatus } from '../../../shared/types/Task';

const CellItem = ({
  icon,
  text,
  color,
}: {
  icon: React.ReactNode;
  text: string;
  color: string;
}) => (
  <Box
    display="flex"
    alignItems="center"
    px="16px"
    py="1px"
    sx={{
      backgroundColor: color,
      borderRadius: '100px',
      maxWidth: 'max-content',
      minWidth: '100px',
    }}
  >
    {icon}
    <Typography fontSize="16px" fontWeight="400" color={'#222222'} ml="8px">
      {text}
    </Typography>
  </Box>
);

const StatusCell = ({ status }: { status: TaskStatus }) => {
  if (status === TaskStatus.inReview) {
    return (
      <CellItem
        icon={
          <i
            className="fa-regular fa-hand-wave"
            style={{ color: '#F47B27' }}
          ></i>
        }
        color="#FFFBF6"
        text="Needs Review"
      />
    );
  }
  if (status === TaskStatus.changesRequested) {
    return (
      <CellItem
        icon={
          <i
            className="fa-regular fa-hand-wave"
            style={{ color: '#F47B27' }}
          ></i>
        }
        color="#FFFBF6"
        text="Changes Requested"
      />
    );
  }
  if (status === TaskStatus.complete) {
    return (
      <CellItem
        icon={
          <i className="fa-solid fa-check" style={{ color: '#6BA543' }}></i>
        }
        color="#F3F8F0"
        text="Complete"
      />
    );
  }
  if (status === TaskStatus.todo) {
    return (
      <CellItem
        icon={
          <i
            className="fa-solid fa-note-sticky"
            style={{ color: '#2372B9' }}
          ></i>
        }
        color="#EDF4FA"
        text="To-Do"
      />
    );
  }
  if (status === TaskStatus.pending) {
    return (
      <CellItem
        icon={
          <i
            className="fa-regular fa-ellipsis"
            style={{ color: '#7e8083' }}
          ></i>
        }
        color="#F2F2F2"
        text="Pending"
      />
    );
  }
  return <Box>{status}</Box>;
};

export default StatusCell;
