import { sepAPI } from '../API';
import { setSnackbarForEndpoint } from '../../utils/snackbar';
import {
  SearchParams,
  ActivitySearchResult,
  Activity
} from '../../../shared/types/Activity';

export const activitySlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    getActivities: builder.query<ActivitySearchResult, SearchParams>({
      query: (arg) => {
        const {
          limit,
          offset,
          sortBy,
          sortAsc,
          trackableType,
          trackableID,
          search,
          sepID,
        } = arg;
        return {
          url: `activities`,
          params: {
            sepID,
            limit,
            offset,
            sortBy,
            sortAsc,
            trackableType,
            trackableID,
            search,
          },
          providesTags: ['Activities'],
        };
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage:
            'There was a problem retrieving the list of activities.',
        });
      },
    }),
    getLatestActivity:builder.query<any[], number>({
      query: (id) => {
        return {
          url: `sep/${id}/latest-activity`,
        };
      },
      providesTags: ['Activities'],
    }),
  }),
});

export const { useGetActivitiesQuery,useGetLatestActivityQuery } = activitySlice;
