import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Box,
  Button,
  CircularProgress
} from "@mui/material";
import { useParams } from "react-router-dom";
import { LoadingContainer } from '../../containers/LoadingContainer';
import { useGetSepQuery } from "../../services/sepSlice";
const UnSubscribeModal = ({
  subscribedSeps,
  handleSave,
  isSepLoading
}: {
  subscribedSeps: any;
  handleSave: any;
  isSepLoading?: any
}) => {
  const { sepId } = useParams<{ sepId: string }>();
  const id: any = sepId ? Number(sepId) : undefined;
  const { data: sepData, error, isLoading } = useGetSepQuery(id);
  const getSubScribedSEP = subscribedSeps?.find((list: any) => list.id === id);
  if (isSepLoading || isLoading || !getSubScribedSEP) {
    return (
      <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center"
    >
      <CircularProgress size={50} />
    </Box>
    );
  } 
  return (
    <Box sx={{ p: 3, maxHeight: "500px", overflow: "auto" }}>
      {getSubScribedSEP ? (
        <Table
          sx={{ width: "100%" }}
          aria-labelledby="tableTitle"
          size="medium"
        >
          <TableHead>
            <TableRow>
              <TableCell>SEP ID</TableCell>
              <TableCell>SEP Name</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableCell>{sepId}</TableCell>
            <TableCell>{sepData?.name}</TableCell>
            <TableCell>
              <Button onClick={() => handleSave(sepId, sepData?.subscribers)}>
                Unsubscribe
              </Button>
            </TableCell>
          </TableBody>
        </Table>
      ) : (
        <div>This SEP is not subscribed yet</div>
      )}
    </Box>
  );
};
export default UnSubscribeModal;
