import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography, Button } from '@mui/material';
import SearchBar from '../../components/SearchBar/SearchBar';
import { useGetUserQuery } from '../../services/usersSlice';
import { useFindSepsQuery, useGetSubscribedSepsQuery, useUpdateSepMutation,useUpdateSepSubscriptionsMutation } from '../../services/sepSlice';
import { SEPStatus } from '../../../shared/types/SEP';
import SepTableHeader from '../../components/SepTable/SepTableHeader';
import SepTableBody from '../../components/SepTable/SepTableBody';
import PageNavigation from '../../components/PageNavigation/PageNavigation';
import ModalWidget from '../../components/ModalWidget/ModalWidget';
import EditColumnModal from '../../components/SepTable/EditColumnModal';
import SepSubscriptionsModal from '../../components/SepTable/SepSubscriptionsModal';
import { HeadCell, headCells } from '../../components/SepTable/SepTableBody';
import SepFilterBar from '../../components/SepFilterBar/SepFilterBar';
import { SEPSearchRow } from '../../../shared/types/SEP';
import { skipToken } from '@reduxjs/toolkit/query';


const AllSEPs = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('id');
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const [statusChecked, setStatusChecked] = useState([]);
  const [myToDosChecked, setMyToDosChecked] = useState<boolean>(false);
  const [showTodo, setShowTodo] = useState<boolean>(true);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [selected, setSelected] = useState<SEPSearchRow[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<HeadCell[]>([
    ...headCells,
  ]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [workFlowTypeRequest, setWorkFlowTypeRequest] = useState<string[]>(['SEP Design Review', 'Solution Architect Consultation'])
  const [isSubModalOpen, setIsSubModalOpen] = useState<boolean>(false);
  const [refreshSubscription,setRefreshSubscription]= useState<boolean>(false);
  const [updateSepSubs] = useUpdateSepSubscriptionsMutation();
  

  const updateStatuses = (statuses: any) => {
    setStatusChecked(statuses);
    // Changing any of the status filters should reset pagination.
    setPage(0);
  };

  const handleUnsubscribe = async (sepID : number,subscribers: string) => {
    const userEmail = ""+loggedInUser?.email
    const multipleEntry = subscribers.includes(","+userEmail)
    const updatedSubscriberList = multipleEntry? subscribers.replace(","+userEmail,"") :  subscribers.replace(userEmail,"")
    console.log("subs",updatedSubscriberList)
    if (sepID > 0) {
      
     await updateSepSubs({
            id: sepID,
            body: { subscribers: updatedSubscriberList },
          });
    }
    refetch()
  };
  const {
    data: loggedInUser,
    error: getUserError,
    isLoading: getUserIsLoading,
  } = useGetUserQuery('me');

  const statusArray = Object.values(SEPStatus)
    .map((status) => status.toString())
    .join(',');

  const filterStatus = statusChecked
    .map((phase: any) => {
      // if phase equals knockout return active status
      if (
        phase === 'knockout' ||
        phase === 'design' ||
        phase === 'implement' ||
        phase === 'initiate'
      ) {
        return 'active';
      }
      // if phase equals complete return approved status
      else if (phase === 'complete') {
        return 'approved';
      }
      // return phase if it's included in statusArray; otherwise, return null
      return statusArray.includes(phase) ? phase : null;
    })
    .filter((status) => status !== null);


  const { data, isLoading, isError } = useFindSepsQuery({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    sortBy,
    sortAsc,
    search: searchFilter,
    mine: true,
    myToDos: myToDosChecked,
    status: filterStatus.map((status: any) => status.toString()).join(','),
    workFlowType: workFlowTypeRequest.join(','),
  });

  const { data: subscribedSeps, isError:subError, isLoading:subLoading, error, refetch } = useGetSubscribedSepsQuery(
    loggedInUser?.email || skipToken
  );
  const refreshSubs = () =>{
    refetch();
    setRefreshSubscription(true)
    setSelected([])

  }
  useEffect(() => {

    refetch();

  }, [refetch, loggedInUser, refreshSubscription ]);
  useEffect(() => {
    // Debounce our search function here so we can wait 500ms for the user to stop typing
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length >= 3) {
        setSearchFilter(searchText);
      } else {
        setSearchFilter('');
      }
      // Changing searchFilter should reset pagination.
      setPage(0);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText, setSearchFilter]);

  useEffect(()=> {
    setWorkFlowTypeRequest(["SEP Design Review", "Solution Architect Consultation"])
  }, [])

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} pt="24px">
      <ModalWidget
        isOpen={isOpen}
        title="Edit Columns"
        children={
          <EditColumnModal
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        }
        onClose={() => setIsOpen(false)}
      />
      <ModalWidget
        isOpen={isSubModalOpen}
        title="SEP Subscriptions"
        children={
          <SepSubscriptionsModal
            subscribedSeps={subscribedSeps} handleSave={handleUnsubscribe}
          />
        }
        onClose={() => setIsSubModalOpen(false)}
      />
      <SearchBar
        title="My SEPs"
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <SepFilterBar
        statusChecked={statusChecked}
        setStatusChecked={updateStatuses}
        showTodo={showTodo}
        setShowTodo={setShowTodo}
        myToDosChecked={myToDosChecked}
        setMyToDosChecked={setMyToDosChecked}
        data={data}
        filterPage= {'mysep'}
        workFlowTypeRequest = {workFlowTypeRequest}
        setWorkFlowTypeRequest = {setWorkFlowTypeRequest}
      />
      <Button onClick={()=>{setIsSubModalOpen(true)}}>SEP Subscriptions</Button>
      {isLoading || getUserIsLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          py="24px"
        >
          <CircularProgress />
        </Box>
      ) : isError || getUserError ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          py="24px"
        >
          <Typography color="error.main" fontSize="24px">
            Error
          </Typography>
        </Box>
      ) : (
        <>
          <SepTableHeader
            count={data?.count ?? 0}
            sortBy={sortBy}
            sortAsc={sortAsc}
            status={statusChecked
              .map((phase: any) => phase.toString())
              .join(',')}
            searchFilter={searchFilter}
            resultNumber={data?.count ?? 0}
            assigneeId={loggedInUser?.id}
            onModalOpen={() => setIsOpen(true)}
            hideExportButton={true}
            selected={selected}
            refreshSubs={refreshSubs}
          />
          <SepTableBody
            rows={data?.seps || []}
            count={data?.count ?? 0}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortAsc={sortAsc}
            setSortAsc={setSortAsc}
            headers={visibleColumns}
            selected={selected}
            setSelected={setSelected}
            loggedInUser={loggedInUser?.id}
          />
          <PageNavigation
            count={data?.count ?? 0}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default AllSEPs;
