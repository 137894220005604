import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { setupStore } from './frontend/app/store';
import reportWebVitals from './frontend/reportWebVitals';
import './frontend/app/index.css';
import { RouterProvider } from 'react-router-dom';
import ThemeProvider from './frontend/theme/ThemeProvider';
import { MsalProvider } from '@azure/msal-react';
import pca from './frontend/app/msal';

import './frontend/app/index.css';

import { routes } from './frontend/utils/routes';

if (process.env.NODE_ENV === 'test') {
  const { setupWorker } = require('msw');
  const handlers = require('../testing/mocks/handlers');
  const worker = setupWorker(...handlers);
  worker.start();
}

const container = document.getElementById('root')!;

const root = createRoot(container);
const store = setupStore();

root.render(
  <React.StrictMode>
    <Provider store={store}>
      <MsalProvider instance={pca}>
        <ThemeProvider>
          <>
            <RouterProvider router={routes} />
          </>
        </ThemeProvider>
      </MsalProvider>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
