import React, { useEffect, useRef } from 'react';
import ResponsiveAppBar from '../components/Appbar';
import Snackbar from '@mui/material/Snackbar';
import { Box } from '@mui/material';
import { selectSnackbar, setSnackbar } from '../services/appSlice/appSlice';
import { useAppDispatch, useAppSelector } from '../app/hooks';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Outlet } from 'react-router';
import Footer from '../components/Footer';
import { useLocation } from 'react-router';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return (
    <MuiAlert
      elevation={6}
      ref={ref}
      variant="filled"
      sx={{ cursor: 'pointer' }}
      {...props}
    />
  );
});

const AppContainer = () => {
  const ref = useRef<HTMLElement>(null);
  const scrollRef = useRef<HTMLElement>(null);
  const snackbar = useAppSelector(selectSnackbar);
  const dispatch = useAppDispatch();
  // Check if print media query is true
  const printMatches = window.matchMedia('print').matches;
  const location = useLocation();
  useEffect(() => {
    if (ref.current && scrollRef.current) {
      const navHeight = ref.current.clientHeight;
      if (!location.pathname.includes('/task/')) {
      scrollRef.current.style.height = `calc(100vh - ${navHeight}px)`;
      scrollRef.current.style.overflow = 'auto';
      scrollRef.current.style.marginTop=`0%`
      } else {
        // Added height styling for TaskOverview component
        scrollRef.current.style.height = `auto`; 
        // scrollRef.current.style.overflow = ''; 
        scrollRef.current.style.marginTop=`5%`
      }
    }
  }, [location]);

  return (
    <>
      <Box ref={ref} sx={{ p: 0, m: 0 }}>
        <ResponsiveAppBar />
      </Box>
      <Box
        ref={scrollRef}
        sx={{ p: 0, m: 0 }}
        className={printMatches ? 'section-to-print' : ''}
      >
        <Outlet />
        <Footer />
      </Box>
      <Snackbar
        key={snackbar?.text}
        open={!!snackbar}
        onClick={() => dispatch(setSnackbar(null))}
      >
        <Alert severity={snackbar?.type}>{snackbar?.text}</Alert>
      </Snackbar>
    </>
  );
};

export default AppContainer;
