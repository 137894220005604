import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Divider,
  Stack,
  Typography,
  FormControl,
  TextField,
  useTheme,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {
  useUploadNewAttachmentMutation,
  useUploadNewUrlAttachmentMutation,
} from '../../services/attachmentSlice/attachmentSlice';
import LinearProgress, {
  linearProgressClasses,
} from '@mui/material/LinearProgress';
import NavButton from '../NavButton';
import { Attachment } from '../../../shared/types/Attachment';
import Button from '../Button';

const style = {
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  p: 2,
  width: '100%',
};

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#1a90ff' : '#308fe8',
  },
}));

function isValidHttpUrl(string: string) {
  let url;
  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === 'http:' || url.protocol === 'https:';
}

interface AddAttachmentFileProps {
  sepID: number;
  taskID?: number;
  onClose: () => void;
  onMessage: (
    type: 'error' | 'info' | 'success' | 'warning',
    msg: string
  ) => void;
  type: 'url' | 'file' | null;
  setType: (t: 'file' | 'url' | null) => void;
  onNewAttachment: (newAttachment: Attachment) => void;
}

const AddAttachmentFile = ({
  onClose,
  sepID,
  taskID,
  onNewAttachment,
  onMessage,
  type,
  setType,
}: AddAttachmentFileProps) => {
  const theme = useTheme();
  const [url, setUrl] = useState('');
  const [urlName, setUrlName] = useState('');
  const [start, setStart] = useState(0);
  const [fileName, setFileName] = useState('');
  const [uploadAttachmentMutation] = useUploadNewAttachmentMutation();
  const [uploadNewAttachmentMutation] = useUploadNewUrlAttachmentMutation();
  const fileRef = useRef<HTMLInputElement>(null);
  const allowedFileType = ['jpg', 'jpeg', 'gif', 'png','pdf','txt', 'docx', 'xlsx', 'pptx']

  const uploadAttachment = async () => {
    setStart(1);
    if (fileRef.current?.files && fileRef.current?.files.length > 0) {
      const file = fileRef.current?.files[0];
      const isFileSizeValid = file.size <= 20971520; // This is exactly 20MB. (1KB == 1024B and 1MB == 1024 KB)
      const fileType  = fileName
      .substring(fileName.lastIndexOf('.') + 1)
      .toLowerCase()
      const isFileTypeValid = allowedFileType.includes(fileType)
      if (isFileSizeValid && isFileTypeValid) {
        const data = new FormData();
        data.append('file', file);
        const { data: resData } = (await uploadAttachmentMutation({
          sepID,
          taskID,
          data,
        })) as { data: Attachment };
        if (resData) {
          onNewAttachment(resData);
          setStart(2);
          onMessage('success', 'File uploaded.');
          handleClose();
        }
      } else {
        if (!isFileSizeValid) onMessage('error', 'Failed!, File size is bigger than 20MB.');
        if (!isFileTypeValid) onMessage('error', 'Failed!, File type is invalid. Valid file extensions: .jpg, .jpeg, .gif, .png, .pdf, .txt, .docx, .xlsx, .pptx');
        setUrl('');
        setUrlName('');
        setFileName('');
        setStart(0);
        return;
      }
    } else {
      const { data } = (await uploadNewAttachmentMutation({
        sepID: sepID,
        taskID: taskID,
        data: { name: urlName, url: url },
      })) as { data: Attachment };
      if (data) {
        onNewAttachment(data);
        setStart(2);
        onMessage('success', 'URL uploaded.');
        handleClose();
      }
    }
  };

  const handleClose = () => {
    setUrl('');
    setUrlName('');
    setFileName('');
    setStart(0);
    onClose();
  };

  return (
    <FormControl aria-label="UploadForm" sx={{ width: '100%' }}>
      <Box sx={style} flexDirection="column">
        <Box
          display="flex"
          color={theme.palette.gray.solidGray}
          sx={{ alignItems: 'baseline' }}
        >
          &nbsp;&nbsp; <i className="fa-solid fa-circle-info fa-lg"></i>
          &nbsp;&nbsp;
          <Typography
            color={theme.palette.gray.solidGray}
            align="left"
            sx={{ font: 'normal 14px/22px Open Sans;' }}
          >
            Do not attach anything to the SEP that contains NERC or Export
            Control information.
          </Typography>
        </Box>
        <br />
        <Box>
          <input
            ref={fileRef}
            formEncType="multipart/form-data"
            name="attachment"
            style={{ display: 'none' }}
            onChange={(e) => {
              setFileName(
                e.target.value.substring(e.target.value.lastIndexOf('\\') + 1)
              );
            }}
            id="contained-button-file"
            type="file"
            accept=".jpg,.jpeg,.gif,.pdf,.png,.txt,.docx,.xlsx,.pptx"
            disabled={urlName !== '' && url !== ''}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              color="primary"
              component="span"
              disabled={urlName !== '' && url !== ''}
            >
              Choose a File from your Computer
            </Button>
          </label>
        </Box>
      </Box>
      {fileName !== '' && (
        <>
          <Box sx={style}>
            <Box>
              <i className="fa-solid fa-circle-check fa-xl"></i>&nbsp;
              {fileName === '' ? 'Attachment name' : fileName}
            </Box>
          </Box>
          <CustomProgressBar
            start={start}
            onFinish={() => {
              setStart(2);
              setTimeout(() => handleClose(), 500);
            }}
          />
        </>
      )}
      <Divider />
      <Box sx={{ display: 'flex', justifyContent: 'space-between', p: 2 }}>
        <Typography sx={{ font: 'normal normal 600 18px/24px Open Sans;' }}>
          Add A New URL &nbsp;
        </Typography>
        <Typography>
          {type === 'url' ? (
            <Button onClick={() => setType('file')} disabled={fileName !== ''}>
              <i className="fa-regular fa-angle-up"></i>
            </Button>
          ) : (
            <Button onClick={() => setType('url')} disabled={fileName !== ''}>
              <i className="fa-regular fa-angle-down"></i>
            </Button>
          )}
        </Typography>
      </Box>
      {type === 'url' && (
        <Box sx={{ ...style, backgroundColor: '#F3F8FC' }}>
          <Box>
            <FormControl sx={{ width: '300px' }}>
              <TextField
                id="outlined-basic"
                label="Name"
                variant="outlined"
                onChange={(e) => setUrlName(e.target.value)}
                sx={{ m: 2, backgroundColor: 'white' }}
                disabled={fileName !== ''}
              />
              <TextField
                id="outlined-basic"
                label="URL"
                variant="outlined"
                type="url"
                onChange={(e) => setUrl(e.target.value)}
                sx={{ m: 2, backgroundColor: 'white' }}
                disabled={fileName !== ''}
              />
            </FormControl>
          </Box>
        </Box>
      )}
      <Divider />
      <Typography component="div" sx={{ p: 2 }}>
        <Stack
          direction="row"
          spacing={2}
          display="flex"
          justifyContent="right"
        >
          <NavButton
            sx={{ color: '#2372B9', fontSize: '16px', p: 1 }}
            onClick={() => {
              handleClose();
            }}
          >
            Cancel
          </NavButton>
          <Button
            aria-label="AddButton"
            variant="contained"
            startIcon={<i className="fa fa-plus"></i>}
            sx={{
              p: 1,
              width: '200px',
              backgroundColor: 'solidBlue.main',
              '&:hover': {
                backgroundColor: 'solidBlue.main',
              },
            }}
            disabled={
              fileName === '' &&
              (urlName === '' ||
                url === '' ||
                (url.length > 0 && !isValidHttpUrl(url)))
            }
            onClick={() => uploadAttachment()}
            type="submit"
          >
            Add Attachment
          </Button>
        </Stack>
      </Typography>
    </FormControl>
  );
};

const CustomProgressBar = ({
  start,
  onFinish,
}: {
  start: number;
  onFinish: () => void;
}) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    const timer = setInterval(() => {
      if (start === 0) {
        setProgress(0);
        return;
      }
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          onFinish();
          return 100;
        }
        if (start === 2) return 100;
        const diff = Math.ceil(Math.random() * 10);
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  });
  return (
    <div>
      <Box sx={{ ...style, p: 0 }}>
        <Box sx={{ width: '250px' }}>
          <BorderLinearProgress variant="determinate" value={progress} />
        </Box>
      </Box>
      <Box sx={{ ...style, p: 1 }}>
        <Typography>{progress}% completed</Typography>
      </Box>
    </div>
  );
};

export default AddAttachmentFile;
