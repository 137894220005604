import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';

import './style.css';

const AnimationedButton = ({
  sort,
  checked,
  onChecked,
}: {
  sort: string;
  checked: boolean;
  onChecked: () => void;
}) => {
  return (
    <Button
      className="icon-button"
      size="small"
      variant="text"
      onClick={() => {
        onChecked();
      }}
      sx={
        checked
          ? { backgroundColor: '#EDF4F9', m: 0, textTransform: 'none' }
          : { m: 0, textTransform: 'none' }
      }
    >
      {sort === 'asc' ? (
        <i className="fas fa-sort-amount-up-alt"></i>
      ) : (
        <i className="fas fa-sort-amount-up"></i>
      )}
      <p className={sort === 'asc' ? 'asc' : 'desc'}>
        {sort === 'asc' ? 'Oldest Activity' : 'Newest Activity'}
      </p>
    </Button>
  );
};

const OrderToolBar = ({
  asc,
  orderChanged,
}: {
  asc: boolean;
  orderChanged: (asc: boolean) => void;
}) => {
  const [isAsc, setIsAsc] = useState<boolean>(asc);
  return (
    <Stack direction="row" alignItems="center" spacing={2} sx={{ p: 1 }}>
      <AnimationedButton
        sort="desc"
        checked={!isAsc}
        onChecked={() => {
          setIsAsc(false);
          orderChanged(false);
        }}
      />
      <AnimationedButton
        sort="asc"
        checked={isAsc}
        onChecked={() => {
          setIsAsc(true);
          orderChanged(true);
        }}
      />
    </Stack>
  );
};

export default OrderToolBar;
