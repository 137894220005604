import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import HoveredIconConfirm from '../../HoveredIconConfirmButton';
import HoveredIcon from '../../HoveredIconButton';
import { KnockoutFollowupExtended } from '../../../../../shared/types/Knockout';
import { useDeleteFollowupTemplateMutation } from '../../../../services/knockoutSlice';

interface FollowUpProps {
  followUp: KnockoutFollowupExtended;
  onEdit: () => void;
}

const FollowUp = ({ followUp, onEdit }: FollowUpProps) => {
  const [deleteFollowUp] = useDeleteFollowupTemplateMutation();
  const handleDelete = () => {
    deleteFollowUp(followUp.id);
  };

  return (
    <>
      <Box sx={{ paddingLeft: '30px', paddingTop: '16px' }}>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              flex: 1,
            }}
          >
            <Box sx={{ paddingBottom: '15px' }}>
              <Typography
                component="div"
                sx={{ color: '#646669', fontSize: '12px', minWidth: '200px' }}
              >
                Answer
              </Typography>
              <Typography
                component="div"
                sx={{ color: '#222222', fontSize: '14px' }}
              >
                {followUp.value}
              </Typography>
            </Box>
            <Box
              sx={{
                flex: '0 1 auto',
                whiteSpace: 'nowrap',
                width: '100%',
                paddingBottom: '15px',
              }}
            >
              <Typography
                component="div"
                sx={{ color: '#646669', fontSize: '12px' }}
              >
                Followup Type
              </Typography>
              <Typography
                component="div"
                sx={{ color: '#222222', fontSize: '14px' }}
              >
                <Typography
                  component="span"
                  sx={{ fontSize: '14px', fontWeight: '700' }}
                >
                  {followUp.followupType === 'KnockoutScreen'
                    ? 'Knockout Screen'
                    : followUp.followupType === 'DefaultStakeholder'
                    ? 'Default Stakeholder'
                    : followUp.followupType}
                  :{` `}
                </Typography>
                {followUp.followupType === 'Task'
                  ? followUp.followupTask?.name
                  : followUp.followupType === 'KnockoutScreen'
                  ? followUp.followupScreen?.name
                  : followUp.followupStakeholder?.displayName}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ mx: 2 }}>
            <HoveredIconConfirm
              icon="fa-regular fa-trash"
              hoverIcon="fa-solid fa-trash"
              onClick={() => handleDelete()}
            />
          </Box>
          <HoveredIcon
            icon="fa-regular fa-pencil"
            hoverIcon="fa-solid fa-pencil"
            onClick={() => onEdit()}
          />
        </Stack>
        <Divider />
      </Box>
    </>
  );
};

export default FollowUp;
