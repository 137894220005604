import React, { useContext, useMemo, useRef, useState, useEffect } from 'react';
import {
  Box,
  Stack,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  InputAdornment,
  Typography,
  Autocomplete,
} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useAccount } from '@azure/msal-react';
import { TaskExtended, TaskPhase } from '../../../shared/types/Task';
import { useGetDepartmentsQuery } from '../../services/departmentSlice/departmentSlice';
import { useGetTasksWithSEPIDQuery } from '../../services/tasksSlice/tasksSlice';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Department } from '../../../shared/types/Department';
import { TaskContext } from './CreateTask';
import AutoComplete from '../AutoComplete/AutoComplete';
import { User } from '../../../shared/types/User';
import { debounce } from 'lodash';
import { useSearchUsersQuery } from '../../services/usersSlice';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#646669',
    color: '#FBFCFC',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const StyledTextField = styled(TextField)({
  '& input': {
    fontSize: '14px',
    cursor: 'pointer',
  },
  '& .MuiInputBase-root': {
    paddingRight: '8px',
    cursor: 'pointer',
  },
  '& .Mui-disabled': {
    color: 'rgba(0,0,0,1)',
  },
});

const Placeholder = ({ children }: { children: string }) => {
  return <div style={{ color: '#222222', fontSize: '14px' }}>{children}</div>;
};

interface OnChangedTaskProp {
  name: string;
  id: number;
}

const SecondStep = ({ sepID, displayName }: { sepID: number, displayName:any }) => {
  const { task, setTask } = useContext(TaskContext);
  const inputRef = useRef(null);
  const [showDropDown, setShowDropDown] = useState<boolean>(false);
  const [dependTask, setDependTask] = useState<OnChangedTaskProp>({
    name: '',
    id: 0,
  });
  const { data } = useGetDepartmentsQuery();
  const { data: sepTasks } = useGetTasksWithSEPIDQuery(sepID);


  const [searchTerm, setSearchTerm] = useState<string>('');
  const [userOptions, setUserOptions] = useState<User[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [defaultAssigneeRadio, setDefaultAssigneeRadio]= useState<string>('');
  const { data: searchResults = [], isLoading, isError, error } = useSearchUsersQuery(
    {
      search: searchTerm,
    },
    { skip: searchTerm.trim() === '' }
  ); 

  const tasks = useMemo(() => {
    if (sepTasks && task.departmentID) {
      return sepTasks.filter(
        (e) => e.departmentID === Number.parseInt(task.departmentID)
      );
    }
    return [];
  }, [sepTasks, task]);

  const debouncedSearch = useRef<ReturnType<typeof debounce>>(debounce((term: string) => setSearchTerm(term), 100));
  useEffect(() => {
    debouncedSearch.current(searchTerm);
    return () => debouncedSearch.current.cancel();
  }, [searchTerm]);
  useEffect(() => {
    if (!isLoading && !isError) {
      setUserOptions((prevOptions) => {

        return JSON.stringify(prevOptions) !== JSON.stringify(searchResults) ? searchResults : prevOptions;
      });
    }
  }, [searchResults, isLoading, isError]);

  useEffect(()=>{
    setDefaultAssigneeRadio(task.defaultAssignee.length>12 ? 'Other': task.defaultAssignee)
    
  },[task])

  const account = useAccount();
  const roleList: any = useMemo(() => {
    return account?.idTokenClaims?.roles;
  }, [account]);

  const departments: Department[] = [];

  if (roleList && roleList.length && data) {
    const superUser = roleList?.includes('AuthSuperUser');
    data.forEach((item: Department) => {
      const adRole = roleList?.find((role: any) => role === item.adAppRole);
      if (adRole || superUser) {
        departments.push(item);
      }
    });
  }

  return (
    <Stack
      display="flex"
      direction="column"
      alignItems="left"
      spacing={4}
      sx={{ p: 4 }}
    >
      <FormControlLabel
        control={
          <Checkbox
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setTask({ ...task, review: e.target.checked })
            }
            checked={task.review}
          />
        }
        label="This task requires a review"
        sx={{ color: '#222222' }}
      />
      <Box>
        <FormLabel
          id="demo-controlled-radio-buttons-group"
          sx={{ color: '#222222' }}
        >
          Default Assignee &nbsp;
          <LightTooltip title="The person the task will be assigned to by default. Tasks can reassigned from the task details screen.">
            <i className="fa-solid fa-circle-info"></i>
          </LightTooltip>
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="controlled-radio-buttons-group"
          value={defaultAssigneeRadio}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setDefaultAssigneeRadio(e.target.value)
            setTask({ ...task, defaultAssignee: e.target.value })
          } }>
          <FormControlLabel
            value="requestor"
            control={<Radio />}
            label="Requestor"
          />
          <FormControlLabel
            value="stakeholder"
            control={<Radio />}
            label="Stakeholder"
          />
          <FormControlLabel
            value="Other"
            control={<Radio />}
            label="Other"
          />
        </RadioGroup>
        {defaultAssigneeRadio && defaultAssigneeRadio=== 'Other' && (
          <React.Fragment>
            <Autocomplete
              options={userOptions}
              getOptionLabel={(user) => user.displayName || user.email || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => {
                if(value?.id){
                  setTask({ ...task, 'defaultAssignee':value.id, 'departtmetID':3})
                }
                if (value?.email) {
                  setErrorMessage('');
                } else {
                  setErrorMessage('Enter a valid user ID');
                }
              }}
              inputValue={searchTerm}
              onInputChange={(_, newInputValue) => debouncedSearch.current(newInputValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label= {displayName ? displayName :   "Search and Select Users"} 
                  sx={{
                    borderColor: task.defaultAssignee === 'Other' && searchTerm.trim() === '' ? 'red' : 'default',
                    '& input': {
                      fontSize: '14px',
                      cursor: 'pointer',
                      color: task.defaultAssignee === 'Other' && searchTerm.trim() === '' ? 'red' : 'default',
                    },
                  }}
                />
              )}
              />
              {!displayName  && searchTerm.trim() === '' && (
                <Typography sx={{ color: 'red', fontSize: '12px', mt: 1 }}>
                  Enter a valid user ID
                </Typography>
              )}
            </React.Fragment>
          )}
      </Box>
      <Box>
        <InputLabel sx={{ color: '#222222' }}>Department</InputLabel>
        <Select
          size="small"
          id="demo-simple-select-helper"
          value={task.departmentID}
          displayEmpty={true}
          onChange={(event: SelectChangeEvent) =>
            setTask({ ...task, departmentID: event.target.value })
          }
          sx={{ width: '330px' }}
        >
          <MenuItem value={'0'}>
            <Placeholder>Choose a department from the dropdown</Placeholder>
          </MenuItem>
          {departments?.map((e, i) => (
            <MenuItem key={i} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel sx={{ color: '#222222' }}>Phase</InputLabel>
        <Select
          size="small"
          id="demo-simple-select-helper"
          value={task.phase}
          displayEmpty={true}
          onChange={(event: SelectChangeEvent) =>
            setTask({ ...task, phase: event.target.value })
          }
          sx={{ width: '330px', textTransform: 'capitalize' }}
        >
          <MenuItem value="">
            <Placeholder>Choose a phase from the dropdown</Placeholder>
          </MenuItem>
          {Object.values(TaskPhase).map((e, i) => (
            <MenuItem key={i} value={e} sx={{ textTransform: 'capitalize' }}>
              {e}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel sx={{ color: '#222222' }}>
          Related Task
          <Typography
            component="span"
            sx={{ ml: 1, font: 'italic normal normal 14px/22px Open Sans' }}
          >
            Optional
          </Typography>
        </InputLabel>
        <StyledTextField
          ref={inputRef}
          size="small"
          value={
            dependTask.name ||
            'Choose the task that should wait for this new task to complete'
          }
          sx={{ width: '580px' }}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <InputAdornment position="end">
                <ArrowDropDownIcon />
              </InputAdornment>
            ),
          }}
          onClick={() => setShowDropDown(!showDropDown)}
        ></StyledTextField>
        <AutoComplete
          data={tasks.map((e: TaskExtended) => ({ name: e?.name, id: e?.id }))}
          type="dependentTask"
          show={showDropDown}
          anchor={inputRef.current}
          onClose={() => setShowDropDown(false)}
          onChange={(e) => {
            setDependTask(e);
            setTask({ ...task, dependentTaskID: e.id });
            setShowDropDown(false);
          }}
          showConfirmButton={false}
        />
        <Box
          sx={{
            font: 'normal normal normal 11px/22px Open Sans',
            letterSpacing: '0px',
            color: '#646669',
          }}
        >
          This chosen task will remain in Pending status until the new task you
          are creating is complete
        </Box>
      </Box>
    </Stack>
  );
};

export default SecondStep;
