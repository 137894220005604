import React, { useState } from 'react';
import {
  useCreateDataFieldOptionTemplateMutation,
  useCreateDataFieldTemplateMutation,
  useGetDataFieldTemplatesQuery,
} from '../../../services/dataFieldsSlice';
import { Box } from '@mui/material';

import AdminHeader from '../../../components/Admin/AdminHeader';
import AddButton from '../../../components/Admin/AddButton';
import ModalWidget from '../../../components/ModalWidget/ModalWidget';
import DataFieldScreenCard from '../../../components/Admin/DataFields/DataFieldScreenCard';
import AddDataField from '../../../components/Admin/DataFields/AddDataField';
import { DepartmentExtended } from '../../../../shared/types/Department';

import { CircularProgress } from '@mui/material';
import { DataField } from '../../../../shared/types/DataField';

const DataFields = () => {
  const { data, isLoading } = useGetDataFieldTemplatesQuery();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<DepartmentExtended | undefined>(undefined);
  const [createDataField] = useCreateDataFieldTemplateMutation();
  const [createDataFieldOptions] = useCreateDataFieldOptionTemplateMutation();

  const handleEditTriggered = (e: DepartmentExtended) => {
    setEdit(e);
    setIsOpen(true);
  };

  return (
    <>
      {isLoading ? (
        <Box pt={10} sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress data-testid="loading-spinner" size={100} />
        </Box>
      ) : (
        <Box sx={{ minHeight: '500px' }}>
          <AdminHeader title="Data Fields" />
          <MappedListComponent
            data={data}
            onEditTriggered={handleEditTriggered}
          />
          <ModalWidget
            title={edit === undefined ? 'Add Data Field' : 'Edit Data Field'}
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
              setEdit(undefined);
            }}
            children={
              <AddDataField
                isClose={!isOpen}
                onAdd={(df, op) => {
                  createDataField(df).then((res: any) => {
                    if (op.length > 0 && 'data' in res) {
                      op.forEach((e) => {
                        createDataFieldOptions({
                          ...e,
                          dataFieldID: res.data.id,
                        });
                      });
                    }
                  });
                  setIsOpen(false);
                }}
                onClose={() => setIsOpen(false)}
              />
            }
          />
          <AddButton
            tab="Data Field"
            onAdd={(tab: string) => {
              setIsOpen(true);
            }}
          />
        </Box>
      )}
    </>
  );
};

export default DataFields;

const MappedListComponent = React.memo(
  ({
    data,
    onEditTriggered,
  }: {
    data?: DataField[];
    onEditTriggered: (e: DepartmentExtended) => void;
  }) => {
    return (
      <>
        {data?.map((datafield, index) => {
          return (
            <DataFieldScreenCard
              key={index}
              datafield={datafield}
              onEditTriggered={(e: any) => {
                onEditTriggered(e);
              }}
            />
          );
        })}
      </>
    );
  }
);
