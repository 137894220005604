import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import {
  CreateDataFieldOptionTemplateBody,
  CreateDataFieldTemplateBody,
  DataFieldLocationType,
  DataFieldType,
  UpdateDataFieldOptionTemplateBody,
  UpdateDataFieldTemplateBody,
} from '../../../../../shared/types/DataField';
import {
  useCreateDataFieldOptionTemplateMutation,
  useDeleteDataFieldOptionTemplateMutation,
  useUpdateDataFieldOptionsTemplateMutation,
  useUpdateDataFieldTemplateMutation,
} from '../../../../services/dataFieldsSlice';
import StepperDots from '../../../StepperDots/StepperDots';
import { FirstStep } from './AddDepartmentDataFieldFirstStep';
import { SecondStep } from './AddDepartmentDataFieldSecondStep';

export const prettierValue = (val: string) => {
  if (val === '') return '';
  if (val === 'multiSelect') {
    return 'Multi-Select';
  }
  if (val === 'tileMultiSelect') return 'Tile Multi-Select';
  if (val === 'tileSelect') return 'Tile Select';
  if (val === 'yesNo') return 'Yes/No';
  return val[0].toUpperCase() + val.slice(1);
};

export const getOriginValue = (val: string) => {
  if (val === '') return '';
  if (val === 'Multi-Select') return 'multiSelect';
  if (val === 'Tile Multi-Select') return 'tileMultiSelect';
  if (val === 'Tile Select') return 'tileSelect';
  if (val === 'Yes/No') return 'yesNo';
  return val[0].toLowerCase() + val.slice(1);
};

export const textFieldSx = {
  color: '#222222',
  '& > p': {
    margin: '5px 0px',
    color: '#646669',
    fontSize: '11px',
  },
};
export type AddDepartmentDataFieldState = {
  name: string | '';
  description: string | '';
  type: DataFieldType | '';
  options: any[];
  locationType:
    | DataFieldLocationType.None
    | DataFieldLocationType.Department
    | DataFieldLocationType.DepartmentReview;
};
export type AddDepartmentDataFieldHandler = (
  df: CreateDataFieldTemplateBody,
  locationType:
    | DataFieldLocationType.Department
    | DataFieldLocationType.DepartmentReview
) => void;
interface AddDepartmentDataFieldProps {
  onClose?: () => void;
  onAdd?: AddDepartmentDataFieldHandler;
  onUpdate?: (
    df: UpdateDataFieldTemplateBody,
    op: UpdateDataFieldOptionTemplateBody[]
  ) => void;
  dataField?: any;
  toggleEdit?: () => void;
}
export default function AddDepartmentDataField({
  onClose,
  onAdd,
  dataField,
  toggleEdit,
}: AddDepartmentDataFieldProps) {
  const [createDataFieldOptions] = useCreateDataFieldOptionTemplateMutation();
  const [updateOptions] = useUpdateDataFieldOptionsTemplateMutation();
  const [updateDataFieldTemplate] = useUpdateDataFieldTemplateMutation();
  const [deleteDataFieldOptionTemplate] =
    useDeleteDataFieldOptionTemplateMutation();
  const [indexOfCurrentScreen, setIndexOfCurrentScreen] = useState<number>(0);
  const [createDataFieldTemplateBody, setCreateDataFieldTemplateBody] =
    useState<AddDepartmentDataFieldState>({
      name: '',
      description: '',
      type: '',
      options: [] as any[],
      locationType: DataFieldLocationType.None,
    });

  useEffect(() => {
    setCreateDataFieldTemplateBody({
      name: dataField?.name ?? '',
      description: dataField?.description ?? '',
      type: dataField?.type ?? '',
      options: dataField?.dataFieldOptions || [],
      locationType:
        dataField?.dataFieldLocation?.locationType ||
        DataFieldLocationType.None,
    });
  }, [dataField]);

  const handleDataFieldNameChange = (value: string) => {
    setCreateDataFieldTemplateBody((prev) => ({ ...prev, name: value }));
  };

  const handleDataFieldDescriptionChange = (value: string) => {
    setCreateDataFieldTemplateBody((prev) => ({ ...prev, description: value }));
  };

  const handleAddDataField = () => {
    if (createDataFieldTemplateBody.locationType === DataFieldLocationType.None)
      return;

    if (onAdd) {
      onAdd(
        {
          name: createDataFieldTemplateBody.name,
          type: createDataFieldTemplateBody.type as DataFieldType,
          description: createDataFieldTemplateBody.description,
          dataFieldOptions: createDataFieldTemplateBody.options,
        },
        createDataFieldTemplateBody.locationType
      );
      setCreateDataFieldTemplateBody({
        name: '',
        description: '',
        type: '',
        options: [],
        locationType: DataFieldLocationType.None,
      });
    }
  };

  const singleValueDataFields = [
    'input',
    'textarea',
    'number',
    'email',
    'date',
    'dateTime',
    'yesNo',
    'toggle',
    'checkbox',
    'textbox'
  ];

  const handleUpdateDataField = () => {
    const options = [...createDataFieldTemplateBody.options];

    const deleteDataFieldOptions = singleValueDataFields.includes(
      createDataFieldTemplateBody.type
    );

    updateDataFieldTemplate({
      id: dataField.id,
      body: createDataFieldTemplateBody as UpdateDataFieldTemplateBody,
    }).then((res: any) => {
      if (options.length > 0 && 'data' in res) {
        for (const option of options) {
          if (!deleteDataFieldOptions) {
            if ('id' in option) {
              const { id, ...properties } = option;
              updateOptions({ id: id as number, ...properties });
            } else {
              const newOption = { ...option };
              newOption.dataFieldID = dataField.id;
              createDataFieldOptions(newOption);
            }
          } else {
            deleteDataFieldOptionTemplate(option.id);
          }
        }
      }
      toggleEdit && toggleEdit();
      onClose && onClose();
    });
  };

  const handleDataFieldTypeChanged = (value: DataFieldType) => {
    setCreateDataFieldTemplateBody((prev) => ({ ...prev, type: value }));
  };

  const handleDataFieldLocationTypeChanged = (
    type:
      | DataFieldLocationType.Department
      | DataFieldLocationType.DepartmentReview
  ) => {
    setCreateDataFieldTemplateBody((prev) => ({ ...prev, locationType: type }));
  };

  const handleNewOptionAdded = () => {
    setCreateDataFieldTemplateBody((prev) => ({
      ...prev,
      options: [
        ...prev.options,
        { value: '', description: '', dataFieldID: 0, order: 0 },
      ],
    }));
  };

  const handleOptionValueChanged = (value: string, index: number) => {
    setCreateDataFieldTemplateBody((prev) => {
      return {
        ...prev,
        options: prev.options.map((option, i) => {
          if (i === index) return { ...option, value };
          return option;
        }),
      };
    });
  };

  const handleOptionIconChanged = (value: string, index: number) => {
    setCreateDataFieldTemplateBody((prev) => {
      return {
        ...prev,
        options: prev.options.map((option, i) => {
          if (i === index) return { ...option, icon: value };
          return option;
        }),
      };
    });
  };

  const handleOptionDeleted = (index: number) => {
    setCreateDataFieldTemplateBody((prev) => ({
      ...prev,
      options: prev.options.filter((_, i) => i !== index),
    }));
  };

  const validateOptionsData = (
    isTileSelect: boolean,
    data: CreateDataFieldOptionTemplateBody[]
  ) => {
    if (data.length === 0) return false;
    return data.every((option) =>
      isTileSelect ? !!option.value && !!option.icon : !!option.value
    );
  };

  const isFirstStepEnable =
    createDataFieldTemplateBody.name !== '' &&
    createDataFieldTemplateBody.description !== '' &&
    createDataFieldTemplateBody.locationType !== DataFieldLocationType.None;
  const isTileSelectOption =
    createDataFieldTemplateBody.type === 'tileSelect' ||
    createDataFieldTemplateBody.type === 'tileMultiSelect';
  const hasOption =
    createDataFieldTemplateBody.type === 'select' ||
    createDataFieldTemplateBody.type === 'multiSelect' ||
    isTileSelectOption;
  const isSecondStepEnable = hasOption
    ? !!createDataFieldTemplateBody.type &&
      validateOptionsData(
        isTileSelectOption,
        createDataFieldTemplateBody.options
      )
    : !!createDataFieldTemplateBody.type;

  return (
    <Box>
      <Box
        sx={{
          diplay: 'flex',
          minWidth: '100%',
          maxHeight: '600px',
          overflow: 'auto',
        }}
      >
        {indexOfCurrentScreen === 0 && (
          <FirstStep
            value={createDataFieldTemplateBody}
            onNameChange={handleDataFieldNameChange}
            onDescriptionChange={handleDataFieldDescriptionChange}
            onDataFieldLocationTypeChanged={handleDataFieldLocationTypeChanged}
          />
        )}
        {indexOfCurrentScreen === 1 && (
          <SecondStep
            options={createDataFieldTemplateBody.options}
            type={createDataFieldTemplateBody.type as DataFieldType}
            onNewOptionAdded={handleNewOptionAdded}
            onDataFieldTypeChanged={handleDataFieldTypeChanged}
            onOptionDeleted={handleOptionDeleted}
            onOptionValueChanged={handleOptionValueChanged}
            onOptionIconChanged={handleOptionIconChanged}
          />
        )}
        <StepperDots steps={2} activeStep={indexOfCurrentScreen} />
      </Box>
      <Divider sx={{ backgroundColor: '#7E8083' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          p: 2,
          '& > button': {
            m: 1,
          },
        }}
      >
        {indexOfCurrentScreen !== 0 && (
          <Button
            variant="text"
            disabled={false}
            onClick={() => setIndexOfCurrentScreen(0)}
            sx={{ textTransform: 'none' }}
          >
            <Typography
              sx={{
                font: 'normal normal 600 16px/22px Open Sans',
                color: '#2372B9',
              }}
            >
              <i className="fa-regular fa-angle-left"></i> &nbsp;Back
            </Typography>
          </Button>
        )}

        <Button
          variant="contained"
          onClick={() => {
            indexOfCurrentScreen === 0
              ? setIndexOfCurrentScreen(1)
              : dataField
              ? handleUpdateDataField()
              : handleAddDataField();
          }}
          disabled={
            indexOfCurrentScreen === 0
              ? !isFirstStepEnable
              : !isSecondStepEnable
          }
          sx={{
            textTransform: 'none',
            backgroundColor: '#2372B9',
            font: 'normal normal 600 16px/22px Open Sans',
          }}
        >
          {indexOfCurrentScreen === 0
            ? 'Continue'
            : (dataField ? 'Edit' : 'Add') + ' Data Field'}
        </Button>
      </Box>
    </Box>
  );
}
