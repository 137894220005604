import React from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Divider from '@mui/material/Divider';
import OrderToolBar from './OrderToolBar';
import SearchBar from '../SearchBar/SearchBar';
import { TrackableType } from '../../../shared/types/Activity';
import { Typography } from '@mui/material';

const ActivityHeader = ({
  sort,
  search,
  select,
  onSort,
  onSearch,
  onSelect,
}: {
  sort: boolean;
  search: string;
  select: string;
  onSort: (sortAsc: boolean) => void;
  onSearch: (search: string) => void;
  onSelect: (type: string) => void;
}) => {
  return (
    <Box
      component="form"
      sx={{
        '& > :not(style)': { m: 1 },
      }}
      noValidate
      autoComplete="off"
    >
      <Stack direction="row" justifyContent="space-between">
        <SearchBar
          title=""
          searchText={search}
          placeHolder="Search"
          setSearchText={(text) => onSearch(text)}
        />
        <Box sx={{ display: 'flex', width: '300px' }}>
          <Typography sx={{ padding: '5px' }} component="span">
            Event&nbsp;
          </Typography>
          <Select
            aria-label="Select"
            size="small"
            sx={{
              width: '100%',
              maxWidth: '200px',
              height: '33px',
              padding: '0px',
              '& .MuiOutlinedInput-input': {
                padding: '0px',
                paddingLeft: '8px',
                '& input': {
                  padding: '5px 14px 5px 0',
                  '&::placeholder': {
                    fontSize: '14px',
                    opacity: 1,
                    color: (theme) => theme.palette.gray.main,
                  },
                },
                '& fieldset': {
                  borderColor: (theme) => theme.palette.gray.main,
                },
                '&:hover fieldset': {
                  borderColor: (theme) => theme.palette.solidBlue.main,
                },
                '&.Mui-focused fieldset': {
                  borderColor: (theme) => theme.palette.solidBlue.main,
                },
              },
            }}
            value={select}
            onChange={(e: SelectChangeEvent) => onSelect(e.target.value)}
          >
            <MenuItem key={'default'} value="All">
              All
            </MenuItem>
            {Object.values(TrackableType)
              .filter((v) => v !== 'DataFieldOption')
              .map((value: string, i: number) => (
                <MenuItem key={i} value={value}>
                  {value === 'DataField' ? 'Data Field' : value}
                </MenuItem>
              ))}
          </Select>
        </Box>
      </Stack>
      <Divider />
      <OrderToolBar
        asc={sort}
        orderChanged={(isAsc) => {
          onSort(isAsc);
        }}
      />
      <Divider />
    </Box>
  );
};

export default ActivityHeader;
