import React, { useState, useRef, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
} from '@mui/material';
import StatusCell from '../SepTable/StatusCell';
import UserSearchBar from './AutoComplete';
import { ReadableTaskPhase, TaskExtended } from '../../../shared/types/Task';
import UserDisplay from '../UserDisplay/UserDisplay';
import { useGetSepQuery } from '../../services/sepSlice';
import { format, formatDistance } from 'date-fns';
import { User, UserShort } from '../../../shared/types/User';
import { useAccount } from '@azure/msal-react';
import { useGetDepartmentsQuery } from '../../services/departmentSlice/departmentSlice';
import { Department } from '../../../shared/types/Department';
import { env } from '../../../shared/env';

export const IconCell = ({
  icon,
  iconHover,
  onClick,
}: {
  icon: string;
  iconHover: string;
  onClick: () => void;
}) => {
  const [hover, setHover] = useState(false);

  return (
    <Button
      sx={{
        minWidth: '20px',
        height: '20px',
        padding: '5px',
      }}
      onClick={onClick}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <i
        className={hover ? iconHover : icon}
        style={{
          fontSize: '10px',
          color: hover ? '#2372B9' : '#7E8083',
        }}
      ></i>
    </Button>
  );
};

const StyledTableCell = styled(TableCell)({
  borderBottom: 'none',
  padding: '2px 30px 2px 8px',
});

interface TaskHeaderProps {
  task: TaskExtended;
  onChange: (changedTask: TaskExtended) => void;
}

const TaskHeader = ({ task, onChange }: TaskHeaderProps) => {
  const { data: sep } = useGetSepQuery(Number(task.sepID));
  const [isAssignerEdit, setIsAssignerEdit] = useState(false);
  const [isReviewerEdit, setIsReviewerEdit] = useState(false);
  const assigneeRef = useRef(null);
  const reviewerRef = useRef(null);
  const timeZoneAbbrev = new Date()
    .toLocaleTimeString('en-us', { timeZoneName: 'short' })
    .split(' ')[2];

  const headers = ['Assigned to', 'Owed to', 'Department', 'Phase'];
  if (task.startedAt) {
    headers.push('Age');
  }

  const handleChangeUser = (type: string, user: User) => {
    if (type === 'assignee') {
      onChange({
        ...task,
        assignedUserID: user.id,
        assignee: user as UserShort,
      });
      setIsAssignerEdit(false);
    } else {
      onChange({
        ...task,
        defaultReviewerID: user.id,
        defaultReviewer: user as UserShort,
      });
      setIsReviewerEdit(false);
    }
  };
  const account = useAccount();
  const { data: departments } = useGetDepartmentsQuery();

  const isDeptStakeholder = useMemo(() => {
    const deptADAppRole = departments?.find(
      (d: Department) => d.id === task.departmentID
    )?.adAppRole;
    if (env.REACT_APP_LOCAL_DEV_ADMIN === 'true') {
      return ['AuthSuperUser'];
    }
    return (
      account?.idTokenClaims?.roles?.includes('AuthSuperUser') ||
      (deptADAppRole && account?.idTokenClaims?.roles?.includes(deptADAppRole))
    );
  }, [account, departments, task.departmentID]);

  return (
    <Box p="30px">
      <Box display="flex" justifyContent="space-between" mb="4px">
      <Typography
  aria-label="SEP ID"
  fontSize="18px"
>
  <a href={`/sep/${task.sepID}`}>
    SEP #{task.sepID}:
  </a>
  <span>{` ${sep?.name || ''}`}</span>
</Typography>
        <Typography
          aria-label="task last updated"
          fontSize="10px"
          color="gray.darkGray1"
        >
          Last edited: {format(new Date(task.updatedAt), 'M/d/yy h:mma')}{' '}
          {timeZoneAbbrev}
        </Typography>
      </Box>
      <Typography
        aria-label="title"
        fontSize="24px"
        fontWeight="600"
        color="gray.main"
        mb="16px"
      >
        {task.name}
      </Typography>
      <StatusCell status={task.status} />
      <Box mt="32px" sx={{ overflowX: 'auto', overflowY: 'hidden' }}>
        <Table size="small" sx={{ width: 'max-content' }}>
          <TableHead>
            <TableRow>
              {headers.map((header) => (
                <StyledTableCell key={header}>
                  <Typography fontSize="12px" color="gray.darkGray1">
                    {header} &nbsp; &nbsp;
                    {header === 'Assigned to' && isDeptStakeholder && (
                      <IconCell
                        icon="fa-regular fa-pencil"
                        iconHover="fa-solid fa-pencil"
                        onClick={() => setIsAssignerEdit(true)}
                      />
                    )}
                    {header === 'Owed to' && isDeptStakeholder && (
                      <IconCell
                        icon="fa-regular fa-pencil"
                        iconHover="fa-solid fa-pencil"
                        onClick={() => setIsReviewerEdit(true)}
                      />
                    )}
                  </Typography>
                </StyledTableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <StyledTableCell>
                <Box ref={assigneeRef} display="flex" alignItems="center">
                  <UserDisplay
                    userID={task.assignedUserID}
                    user={task.assignee}
                    showPicture
                    showName
                    title={
                      task.assignee?.id === sep?.createdBy
                        ? 'Requestor'
                        : task.department.name
                    }
                  />
                </Box>
                <UserSearchBar
                  type="assigner"
                  show={isAssignerEdit}
                  anchor={assigneeRef.current}
                  onClose={() => {
                    setIsAssignerEdit(false);
                  }}
                  onChange={(user: User) => handleChangeUser('assignee', user)}
                />
              </StyledTableCell>
              <StyledTableCell>
                <Box ref={reviewerRef} display="flex" alignItems="center">
                  <UserDisplay
                    userID={task.defaultReviewerID}
                    user={
                      task.defaultReviewer ? task.defaultReviewer : undefined
                    }
                    showPicture
                    showName
                    title={task.department.name}
                  />
                </Box>
                <UserSearchBar
                  type="reviewer"
                  show={isReviewerEdit}
                  anchor={reviewerRef.current}
                  adAppRoleName={task.department.adAppRole}
                  onClose={() => {
                    setIsReviewerEdit(false);
                  }}
                  onChange={(user: User) => handleChangeUser('reviewer', user)}
                />
              </StyledTableCell>
              <StyledTableCell>{task.department.name}</StyledTableCell>
              <StyledTableCell>{ReadableTaskPhase[task.phase]}</StyledTableCell>
              {task.startedAt && (
                <StyledTableCell>
                  {formatDistance(
                    new Date(task.startedAt),
                    task.completedAt ? new Date(task.completedAt) : new Date()
                  )}
                </StyledTableCell>
              )}
            </TableRow>
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default TaskHeader;
