import * as React from 'react';
import { Box, Typography } from '@mui/material';
const pjson = require('../../../../package.json');

const buildNumber = pjson.com_constellation.Build.BuildNumber;
const buildUrl = [
  pjson.com_constellation.Build.Repository.Uri.replace('ConstellationADO@', ''),
  'commit',
  pjson.com_constellation.Build.SourceVersion,
].join('/');

function Footer(props: any) {
  return (
    <>
      <Box
        component="footer"
        sx={{
          marginLeft: 'auto',
          marginRight: 'auto',
          display: 'flex',
          alignItems: 'center',
          color: '#7e8083',
          backgroundColor: '#FBFCFC',
          flexDirection: 'column',
          p: 3,
          width: '100%',
          fontSize: '18px',
        }}
      >
        <Typography>
          Version:{' '}
          <a
            href={buildUrl}
            target="_blank"
            rel="noopener"
            style={{ color: '#7e8083' }}
          >
            {buildNumber}
          </a>
        </Typography>
      </Box>
    </>
  );
}

export default Footer;
