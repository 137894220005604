import React from 'react';
import { DataFieldLocationType } from '../../../../shared/types/DataField';
import {
  useCreateDataFieldLocationTemplateMutation,
  useCreateDataFieldOptionTemplateMutation,
  useCreateDataFieldTemplateMutation,
} from '../../../services/dataFieldsSlice';
import ModalWidget from '../../ModalWidget/ModalWidget';
import AddDataField from '../DataFields/AddDataField';
import ApplyDataField from '../DataFields/ApplyDataField';

export interface ModalProps {
  handle: string;
  setHandle: React.Dispatch<React.SetStateAction<string>>;
  locationID: number;
  locationType: DataFieldLocationType;
  isDepartment?: boolean;
}

export const AddDataFieldModal = ({
  handle,
  setHandle,
  locationID,
  locationType,
  isDepartment,
}: ModalProps) => {
  const [createDataField] = useCreateDataFieldTemplateMutation();
  const [createDataFieldOptions] = useCreateDataFieldOptionTemplateMutation();
  const [createDataFieldLocation] =
    useCreateDataFieldLocationTemplateMutation();

  return (
    <ModalWidget
      title={handle}
      isOpen={handle === 'Add Data Field' || handle === 'Apply Data Field(s)'}
      onClose={() => setHandle('')}
      children={
        handle === 'Add Data Field' ? (
          <AddDataField
            isClose={
              !(handle === 'Add Data Field' || handle === 'Apply Data Field(s)')
            }
            onAdd={(df, op) => {
              createDataField(df).then((res: any) => {
                if (op.length > 0 && 'data' in res) {
                  op.forEach((e) => {
                    createDataFieldOptions({
                      ...e,
                      dataFieldID: res.data.id,
                    });
                  });
                }
                if ('data' in res) {
                  createDataFieldLocation({
                    dataFieldID: res.data.id,
                    locationID: locationID,
                    locationType: locationType,
                    required: false,
                    readOnly: false,
                    order: 0,
                  });
                }
              });
              setHandle('');
            }}
          />
        ) : (
          <ApplyDataField
            onClose={() => setHandle('')}
            isDepartment={isDepartment}
            onApply={(e) => {
              e.forEach((data) => {
                createDataFieldLocation({
                  dataFieldID: data.id,
                  locationID: locationID,
                  locationType: locationType,
                  required: false,
                  readOnly: false,
                  order: 0,
                });
              });
              setHandle('');
            }}
            closed={handle === 'Apply Data Field(s)'}
          />
        )
      }
    />
  );
};
