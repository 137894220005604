const {
  REACT_APP_API_BASE_URL,
  REACT_APP_CLIENT_ID,
  REACT_APP_TENANT_ID,
  REACT_APP_REDIRECT_URI,
  REACT_APP_SHOW_ADMIN_FEATURES,
  REACT_APP_LOCAL_DEV_ADMIN,
  REACT_APP_DO_I_NEED_APM_ID_URL,
  REACT_APP_CHEAT_SHEET_URL,
  REACT_APP_IT_PORTFOLIO_OWNER_URL
} =
  /* istanbul ignore next */
  process.env.NODE_ENV === 'test' || process.env.NODE_ENV === 'development'
    ? process.env
    : (window as any).__env__;

export const env: Environment = {
  REACT_APP_API_BASE_URL: REACT_APP_API_BASE_URL,
  REACT_APP_CLIENT_ID: REACT_APP_CLIENT_ID,
  REACT_APP_TENANT_ID: REACT_APP_TENANT_ID,
  REACT_APP_REDIRECT_URI: REACT_APP_REDIRECT_URI,
  REACT_APP_SHOW_ADMIN_FEATURES: REACT_APP_SHOW_ADMIN_FEATURES,
  REACT_APP_LOCAL_DEV_ADMIN: REACT_APP_LOCAL_DEV_ADMIN,
  REACT_APP_DO_I_NEED_APM_ID_URL: REACT_APP_DO_I_NEED_APM_ID_URL,
  REACT_APP_CHEAT_SHEET_URL: REACT_APP_CHEAT_SHEET_URL,
  REACT_APP_IT_PORTFOLIO_OWNER_URL: REACT_APP_IT_PORTFOLIO_OWNER_URL,

};

type Environment = {
  REACT_APP_API_BASE_URL: string;
  REACT_APP_CLIENT_ID: string;
  REACT_APP_TENANT_ID: string;
  REACT_APP_REDIRECT_URI: string;
  REACT_APP_SHOW_ADMIN_FEATURES: string;
  REACT_APP_LOCAL_DEV_ADMIN: string;
  REACT_APP_DO_I_NEED_APM_ID_URL: string;
  REACT_APP_CHEAT_SHEET_URL:string;
  REACT_APP_IT_PORTFOLIO_OWNER_URL:string;
};
