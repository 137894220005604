import {
  DataFieldUpdate,
  DataField,
  CreateDataFieldTemplateBody,
  DataFieldWithOptionsAndLocations,
  DataFieldOption,
  CreateDataFieldLocationTemplateBody,
  DataFieldLocation,
  CreateDataFieldOptionTemplateBody,
  UpdateDataFieldLocationTemplateBody,
  UpdateDataFieldTemplateBody,
  UpdateDataFieldOptionTemplateBody,
  UpdateDataFieldLocationOrderTemplateBody,
} from '../../../shared/types/DataField';
import { setSnackbarForEndpoint } from '../../utils/snackbar';
import { sepAPI } from '../API/sepAPI';

export const dataFieldsSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    updateDataFields: builder.mutation<
      DataFieldWithOptionsAndLocations,
      { sepId: number; dataFields: DataFieldUpdate[] }
    >({
      query: ({ sepId, dataFields }) => {
        return {
          url: `data-fields/${sepId}`,
          method: 'PATCH',
          body: dataFields,
        };
      },
      invalidatesTags: ['SEPs', 'TasksForSEP'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Data updated successfully!',
          errorMessage: 'There was a problem creating your SEP.',
        });
      },
    }),
    getDataFieldsBySEPID: builder.query<
      DataFieldWithOptionsAndLocations[],
      number
    >({
      query: (sepID) => `data-fields/${sepID}`,
      providesTags: ['DataFields'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage:
            'There was a problem getting the data fields for this SEP.',
        });
      },
    }),
    getDataFieldTemplates: builder.query<
      DataFieldWithOptionsAndLocations[],
      void
    >({
      query: () => `data-field-template`,
      providesTags: ['DataFields'],
    }),
    deleteDataFieldTemplate: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `data-field-template/${id}`,
          method: 'DELETE',
          params: { id: id },
        };
      },
      invalidatesTags: [
        'Knockout-Templates',
        'DataFields',
        'TaskTemplate',
        'Departments',
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Data Field deleted successfully!',
          errorMessage: 'There was a problem deleting your Data Field.',
        });
      },
    }),
    deleteDataFieldLocationTemplate: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `data-field-location-template/${id}`,
          method: 'DELETE',
          params: { id: id },
        };
      },
      invalidatesTags: [
        'Knockout-Templates',
        'DataFields',
        'TaskTemplate',
        'Departments',
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Data Field deleted successfully!',
          errorMessage: 'There was a problem deleting your Data Field.',
        });
      },
    }),
    createDataFieldTemplate: builder.mutation<
      DataField,
      CreateDataFieldTemplateBody
    >({
      query: (data) => {
        return {
          url: `data-field-template`,
          method: `POST`,
          body: { ...data },
        };
      },
      invalidatesTags: [
        'Knockout-Templates',
        'DataFields',
        'Departments',
        'TaskTemplate',
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Data Field created successfully!',
          errorMessage: 'There was a problem creating your Data Field.',
        });
      },
    }),
    createDataFieldOptionTemplate: builder.mutation<
      DataFieldOption,
      CreateDataFieldOptionTemplateBody
    >({
      query: (data) => {
        return {
          url: `data-field-option-template`,
          method: `POST`,
          body: { ...data },
        };
      },
      invalidatesTags: [
        'Knockout-Templates',
        'Departments',
        'DataFields',
        'TaskTemplate',
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Data Field Option created successfully!',
          errorMessage: 'There was a problem creating your Data Field Option.',
        });
      },
    }),
    updateDataFieldTemplate: builder.mutation<
      DataField,
      { id: number; body: UpdateDataFieldTemplateBody }
    >({
      query: ({ id, body }) => ({
        url: `data-field-template/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: [
        'Knockout-Templates',
        'DataFields',
        'TaskTemplate',
        'Departments',
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Data Field updated successfully!',
          errorMessage: 'There was a problem updating your Data Field.',
        });
      },
    }),
    updateDataFieldOptionsTemplate: builder.mutation<
      DataFieldOption,
      { id: number; body: UpdateDataFieldOptionTemplateBody }
    >({
      query: (option) => {
        const { id, ...body } = option;
        return {
          url: `data-field-option-template/${id}`,
          method: 'PATCH',
          body: body,
        };
      },
      invalidatesTags: [
        'Knockout-Templates',
        'DataFields',
        'TaskTemplate',
        'Departments',
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Data Field Option updated successfully!',
          errorMessage: 'There was a problem updating your Data Field Option.',
        });
      },
    }),
    deleteDataFieldOptionTemplate: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `data-field-option-template/${id}`,
          method: 'DELETE',
          params: { id: id },
        };
      },
      invalidatesTags: ['Knockout-Templates', 'DataFields', 'TaskTemplate'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Data Field Option deleted successfully!',
          errorMessage: 'There was a problem deleting your Data Field Option.',
        });
      },
    }),
    createDataFieldLocationTemplate: builder.mutation<
      DataFieldLocation,
      CreateDataFieldLocationTemplateBody
    >({
      query: (data) => {
        return {
          url: `data-field-location-template`,
          method: `POST`,
          body: { ...data },
        };
      },
      invalidatesTags: [
        'Knockout-Templates',
        'DataFields',
        'Departments',
        'TaskTemplate',
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Data Field Location Template created successfully!',
          errorMessage:
            'There was a problem creating your Data Field Location Template.',
        });
      },
    }),
    updateDataFieldLocationTemplate: builder.mutation<
      DataFieldLocation,
      { id: number; body: UpdateDataFieldLocationTemplateBody }
    >({
      query: ({ id, body }) => ({
        url: `data-field-location-template/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: [
        'Knockout-Templates',
        'DataFields',
        'TaskTemplate',
        'Departments',
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Data Field Location Template updated successfully!',
          errorMessage:
            'There was a problem updating your Data Field Location Template.',
        });
      },
    }),
    updateDataFieldOrderLocationTemplate: builder.mutation<
      void,
      UpdateDataFieldLocationOrderTemplateBody
    >({
      query: ({ payload }) => ({
        url: 'data-field-order-template',
        method: 'PATCH',
        body: payload,
      }),
      invalidatesTags: (result, error, { locationType }) => {
        switch (locationType) {
          case 'KnockoutScreen':
            return ['DataFields', 'Knockout-Templates'];
          case 'Department':
            return ['DataFields', 'Departments'];
          default:
            return ['DataFields', 'Knockout-Templates'];
        }
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage:
            'Data Field Location Order Template updated successfully!',
          errorMessage:
            'There was a problem updating your Data Field Location Order Template.',
        });
      },
    }),
  }),
});

export const {
  useUpdateDataFieldsMutation,
  useGetDataFieldsBySEPIDQuery,
  useGetDataFieldTemplatesQuery,
  useCreateDataFieldTemplateMutation,
  useCreateDataFieldOptionTemplateMutation,
  useUpdateDataFieldTemplateMutation,
  useUpdateDataFieldOptionsTemplateMutation,
  useCreateDataFieldLocationTemplateMutation,
  useDeleteDataFieldTemplateMutation,
  useDeleteDataFieldOptionTemplateMutation,
  useDeleteDataFieldLocationTemplateMutation,
  useUpdateDataFieldLocationTemplateMutation,
  useUpdateDataFieldOrderLocationTemplateMutation,
} = dataFieldsSlice;
