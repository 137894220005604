import React from 'react';
import Box from '@mui/material/Box';
import { APM } from '../../../shared/types/APM';

export const DataField = ({
  field,
  value,
}: {
  field: string;
  value: string;
}) => {
  return (
    <Box sx={{ p: 2, pt: 0 }}>
      <Box
        aria-label="field"
        sx={{
          font: 'normal normal normal 12px/22px Open Sans',
          color: '#7E8083',
          pt: 1,
        }}
      >
        {field}
      </Box>
      <Box
        aria-label="value"
        sx={{ font: 'normal normal normal 14px/22px Open Sans', pt: 1 }}
      >
        {value}
      </Box>
    </Box>
  );
};

export default function DataClassificationFields({
  data,
}: {
  data: APM | undefined;
}) {
  return (
    <Box sx={{ p: 2, minWidth: '300px' }}>
      <Box sx={{ fontSize: '16px', fontWeight: '700', p: 2 }}>
        Data Classification Fields
      </Box>
      <DataField
        field={'Data Classification'}
        value={data?.data_classification || ''}
      />
      <DataField
        field={'Compliance Flag'}
        value={data?.u_compliance_flag || ''}
      />
      <DataField field={'PII'} value={data?.u_pii || ''} />
      <DataField field={'PI'} value={data?.u_pi || ''} />
      <DataField
        field={'Additional Requlatory Requirement'}
        value={data?.u_additional_regulatory_requirement || ''}
      />
    </Box>
  );
}
