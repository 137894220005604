import React from 'react';

import { Select, MenuItem } from '@mui/material';

const SelectOptionForSEPApplication = () => {
  return (
    <Select
      labelId="demo-simple-select-label"
      id="demo-simple-select"
      value={'Quick Help Menu'}
      size="small"
      onChange={(e) => null}
      sx={{ maxWidth: '250px' }}
    >
      <MenuItem value={'Quick Help Menu'}>Quick Help Menu</MenuItem>
    </Select>
  );
};

export default SelectOptionForSEPApplication;
