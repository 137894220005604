import React, { ReactNode } from 'react';
import { styled } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import MuiSelect from '@mui/material/Select';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import { DataFieldOption } from '../../../shared/types/DataField';
import { Checkbox } from '@mui/material';

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    width: 280,
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #ced4da',
    fontSize: 16,
    padding: '6px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    // Use the system font instead of the default Roboto font.
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:focus': {
      borderRadius: 4,
      borderColor: '#80bdff',
      boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
    },
  },
}));

interface Props {
  dataFieldOptions: DataFieldOption[];
  onChange: any;
  multiple?: boolean;
  //selectedValue: number
  value: any;
  readOnly?: boolean;
  renderValue?: ((value: any) => ReactNode) | undefined;
}

const Select = ({
  dataFieldOptions,
  onChange,
  value,
  readOnly,
  multiple,
  renderValue,
}: Props) => {
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 250,
        width: 270,
      },
    },
  };

  return (
    <Box>
      <FormControl sx={{ m: 1 }} variant="standard">
        <MuiSelect
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          onChange={onChange}
          input={<BootstrapInput />}
          multiple={multiple || false}
          value={value || (multiple ? [] : 0)}
          MenuProps={MenuProps}
          disabled={readOnly || undefined}
          renderValue={renderValue || undefined}
        >
          <MenuItem value={0}>
            <em>Choose {multiple ? 'any that apply' : 'one'}</em>
          </MenuItem>

          {dataFieldOptions.map((option: any) => {
            return (
              <MenuItem key={option.id} value={option.id}>
                {multiple && (
                  <Checkbox checked={value.indexOf(option.id) > -1} />
                )}
                {option.value}
              </MenuItem>
            );
          })}
        </MuiSelect>
      </FormControl>
    </Box>
  );
};

export default Select;
