import React, { useEffect } from 'react';
import { Box, Button, Container, CssBaseline } from '@mui/material';
import { Outlet, useNavigate, useLocation } from 'react-router';

const Login = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const locArr = location.pathname.split('/');
  const sepOverview = locArr[1] === 'sep' && typeof +locArr[2] === 'number';
  const sepDetail = locArr[1] === 'details' && typeof +locArr[2] === 'number';

  useEffect(() => {
    document.body.style.backgroundColor = '#FBFCFC';
  }, []);

  return (
    <>
      <CssBaseline />
      <Box component="main" sx={{ mx: '120px' }}>
        <Container maxWidth={false}>
          <Box
            className="no-print"
            style={{
              background: '#FBFCFC',
              position: 'fixed',
              zIndex: 100,
              top: 60,
              width: '100vw',
              height: '70px',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            {(sepOverview || sepDetail) && (
              <Button
                variant="text"
                className="no-print"
                onClick={() => navigate(-1)}
                sx={{ padding: 0, height: 40 }}
              >
                <i className="fa-solid fa-angle-left"></i> &nbsp; Back
              </Button>
            )}
          </Box>
          <Box mt={8} />
          <Container
            className="section-to-print"
            maxWidth={false}
            sx={{
              bgcolor: '#FFFFFF',
              minHeight: '85vh',
              borderRadius: '10px',
              boxShadow: '0px 1px 5px #0000001A;',
              padding: '0px !important',
            }}
          >
            <Outlet />
          </Container>
        </Container>
      </Box>
    </>
  );
};

export default Login;
