import React from 'react';
import Avatar from '@mui/material/Avatar';
import { useGetUserQuery } from '../../services/usersSlice';
import { useGetUserPhotoQuery } from '../../services/microsoftSlice';
import { stringAvatar, generateColorHsl } from '../../utils/avatarGenerator';
import { Grid, Typography } from '@mui/material';
import { User, UserShort } from '../../../shared/types/User';

interface UserDisplayProps {
  /** A user ID from Azure Active Directory OR "me" */
  userID?: string | undefined | null;
  /** The user model info, if available. If not given or undefined, the user data will be requested from Azure Active Directory  */
  user?: UserShort | User | undefined;
  /** Whether to show the user's picture */
  showPicture?: boolean;
  /** Whether to show the user's name */
  showName?: boolean;
  /** Whether to show the user's email */
  showEmail?: boolean;
  /** If given, will show the given title below the user's name. Could be "requestor" or the relevant stakeholder role of the user (such as "Legal" or "Security"). */
  title?: string | undefined;
}

const UserDisplay = ({
  userID,
  showName,
  showEmail,
  showPicture,
  title,
  user,
}: UserDisplayProps) => {
  const { data: userPhoto } = useGetUserPhotoQuery(
    userID as string | undefined,
    { skip: !userID }
  );
  const { data: userADInfo } = useGetUserQuery(userID as string | undefined, {
    skip: !!user || !userID,
  }); // Do not request info from AD if we already have the user data or there is no given userID
  const userInfo = user || userADInfo;
  const userName: string =
    userInfo?.givenName && userInfo?.surname
      ? `${userInfo?.givenName} ${userInfo?.surname}`
      : userInfo?.displayName || '';
  const userEmail: string = userInfo?.email || '';
  return userID ? (
    <Grid
      container
      wrap="nowrap"
      alignItems="center"
      title={user?.displayName + ' | ' + userEmail}
    >
      {showPicture && (
        <Grid
          item
          sx={{
            marginRight: showName || showEmail || title ? 1 : 0,
          }}
        >
          {userPhoto ? (
            <Avatar
              aria-label="Profile picture"
              alt="Profile picture"
              src={userPhoto}
            />
          ) : (
            <Avatar
              sx={{ bgcolor: generateColorHsl(userName) }}
              {...stringAvatar(userName)}
              aria-label="Generated profile picture"
              alt="Generated profile picture"
            />
          )}
        </Grid>
      )}
      <Grid item>
        {showName && (
          <Typography color="gray.main" fontSize="14px">
            {userName}
          </Typography>
        )}
        {showEmail && (
          <Typography color="gray.darkGray1" fontSize="12px">
            {userEmail}
          </Typography>
        )}
        {title && (
          <Typography color="gray.darkGray1" fontSize="12px">
            {title}
          </Typography>
        )}
      </Grid>
    </Grid>
  ) : (
    <Typography color="gray.darkGray1" fontSize="12px">
      None
    </Typography>
  );
};

export default UserDisplay;
