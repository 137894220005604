import React from 'react';
import { styled } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';

interface CustomButtonProps extends ButtonProps {
  component?: string;
}

const MuiButton = styled(Button)(({ size, variant, theme }) => ({
  textTransform: 'none',
  borderRadius: '6px',
  font: 'normal normal 600 16px/22px Open Sans;',
  ...(variant === 'contained' && {
    backgroundColor: theme.palette.solidBlue.main,
    '&:hover': {
      backgroundColor: theme.palette.blue.blue1,
    },
    '&:active': {
      backgroundColor: theme.palette.blue.blue2,
    },
    '&:focus': {
      backgroundColor: theme.palette.solidBlue.main,
      boxShadow: `0 0 0 3px ${theme.palette.blue.focus}`,
    },
    '&:disabled': {
      color: theme.palette.gray.darkGray1,
    },
  }),
  ...(variant === 'outlined' && {
    color: theme.palette.solidBlue.main,
    border: '2px solid',
    borderColor: theme.palette.solidBlue.main,
    font: 'normal normal 600 16px/22px Open Sans;',
    '&:hover': {
      backgroundColor: theme.palette.blue.lightBlue1,
      border: '2px solid',
    },
    '&:active': {
      backgroundColor: theme.palette.blue.lightBlue2,
      border: '2px solid',
    },
    '&:focus': {
      boxShadow: `0 0 0 3px ${theme.palette.blue.focus}`,
    },
    '&:disabled': {
      color: theme.palette.gray.darkGray1,
      background: ' #F2F2F2 0% 0% no-repeat padding-box;',
      border: '2px solid #7E8083;',
      borderRadius: '6px',
      opacity: 1,
    },
  }),
  ...(size === 'large' && {
    font: 'normal normal 600 18px/24px Open Sans;',
  }),
  ...(size === 'medium' && {
    font: 'normal normal 600 16px/22px Open Sans;',
  }),
  ...(size === 'small' && {
    font: 'normal normal 600 12px/17px Open Sans;',
  }),
}));

const StyledButton = (props: CustomButtonProps) => (
  <MuiButton disableFocusRipple disableTouchRipple {...props} />
);

export default StyledButton;
