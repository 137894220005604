import React, { useState } from 'react';
import { IconButton } from '@mui/material';

const HoveredIcon = ({
  icon,
  hoverIcon,
  onClick,
}: {
  icon: string;
  hoverIcon: string;
  onClick: () => void;
}) => {
  //Todo: Should implement onClick event.
  const [hover, setHover] = useState<boolean>(false);
  return (
    <IconButton
      sx={{
        width: '25px',
        height: '25px',
        borderRadius: '3px',
        fontSize: '15px',
        color: hover ? '#2372B9' : '#646669',
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      onClick={() => onClick()}
    >
      <i className={hover ? hoverIcon : icon}></i>
    </IconButton>
  );
};

export default HoveredIcon;
