import React, { useState, useEffect, useCallback } from 'react';
import Modal from '@mui/material/Modal';
import { Box, Divider, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';

import NavButton from '../NavButton';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 650,
  bgcolor: 'background.paper',
  border: '1px solid #eee',
  borderRadius: '10px',
  boxShadow: 24,
  maxWidth: 'calc(60vw)',
};

export interface ModalWidgetProps {
  isOpen: boolean;
  title: string;
  children: JSX.Element;
  onClose: () => void;
  expandModal?: boolean;
}

interface IconProps {
  icon: string;
  iconHover: string;
  value: string;
}

export const IconCell = ({ icon, iconHover, value }: IconProps) => {
  const [className, setClassName] = useState(icon);
  return (
    <div
      onMouseOver={() => setClassName(iconHover)}
      onMouseLeave={() => setClassName(icon)}
    >
      <i className={className}></i>
      &nbsp;&nbsp;
      {value}
    </div>
  );
};

const ModalWidget = ({
  isOpen,
  title,
  children,
  onClose,
  expandModal,
}: ModalWidgetProps) => {
  const handleEscKey = useCallback(
    (event: any) => {
      if (event.keyCode === 27) {
        onClose();
      }
    },
    [onClose]
  );

  useEffect(() => {
    window.addEventListener('keydown', handleEscKey);

    // Clean up the event listener
    return () => {
      window.removeEventListener('keydown', handleEscKey);
    };
  }, [handleEscKey]);

  return (
    <Modal
      keepMounted
      disableAutoFocus
      open={isOpen}
      onClose={() => onClose()}
      aria-labelledby="keep-mounted-modal-title"
      aria-describedby="keep-mounted-modal-description"
    >
      <Box sx={expandModal ? { ...style, width: 'fit-content' } : style}>
        <Box id="keep-mounted-modal-title" component="div">
          <Stack
            direction="row"
            spacing={2}
            display="flex"
            justifyContent="space-between"
          >
            <Typography
              sx={{ p: 2, font: 'normal normal 600 18px/24px Open Sans' }}
            >
              {title}
            </Typography>
            <NavButton
              sx={{ color: '#2372B9', fontSize: '16px' }}
              onClick={() => onClose()}
            >
              <i className="fa-solid fa-circle-xmark"></i>&nbsp;&nbsp;Close
            </NavButton>
          </Stack>
        </Box>
        <Divider sx={{ backgroundColor: '#7E8083' }} />
        {children}
      </Box>
    </Modal>
  );
};

export default ModalWidget;
