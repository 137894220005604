import React, { useState, useEffect } from 'react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { ThemeProvider } from '@emotion/react';
import {
  useTheme,
  createTheme,
  Paper,
  Stack,
  Box,
  Typography,
} from '@mui/material';
//import './style.css';
import { StrictModeDroppable as Droppable } from '../../../utils/StrictModeDroppable';
import HoveredIconConfirm from '../HoveredIconConfirmButton';
import HoveredIcon from '../HoveredIconButton';
import AddButton from '../AddButton';
import {
  useDeleteSettingMutation,
  useReOrderSettingMutation,
} from '../../../services/appSlice/appSlice';
import { Setting } from '../../../../shared/types/Settings';
import ModalWidget from '../../ModalWidget/ModalWidget';
import UpsertMenuItem from './UpsertMenuItem';

const style = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#F3F8FC',
          },
        },
      },
    },
  },
});

const getRenderItem =
  (items: any, handleEdit: (e: any) => void, handleDelete: (e: any) => void) =>
  (provided: any, snapshot: any, rubric: any) => {
    const theme = useTheme();
    const [hoverIndex, setHoverIndex] = useState(-1);
    if (items[rubric.source.index] === undefined) return <></>;
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <ThemeProvider theme={style}>
          <Paper
            className={snapshot.isDragging ? 'dragging' : ''}
            onMouseOver={() => setHoverIndex(rubric.source.index)}
            onMouseLeave={() => setHoverIndex(-1)}
            square={true}
            elevation={hoverIndex === rubric.source.index ? 3 : 0}
            sx={{
              border: '1px solid #222',
              borderRadius: '4px',
              margin: '16px 0px',
            }}
          >
            <Stack
              direction="row"
              spacing={2}
              p={2}
              sx={{
                alignItems: 'center',
              }}
            >
              {/** import svg  */}
              <Box
                px={2}
                style={{
                  color:
                    hoverIndex === rubric.source.index || snapshot.isDragging
                      ? theme.palette.blue.blue1
                      : '',
                }}
              >
                <i className="fa-solid fa-grip-dots-vertical"></i>
              </Box>
              <Stack direction="column">
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal normal 16px/22px Open Sans',
                    letterSpacing: '0px',
                    color: '#222222',
                    opacity: 1,
                  }}
                >
                  {items[rubric.source.index].name}
                </Typography>
              </Stack>
              <Box sx={{ marginLeft: 'auto !important' }}>
                <HoveredIconConfirm
                  icon="fa-regular fa-trash"
                  hoverIcon="fa-solid fa-trash"
                  title="Delete Menu Item"
                  content={`Are you sure you want to delete [${
                    items[rubric.source.index].name
                  }]?`}
                  onClick={() => handleDelete(items[rubric.source.index])}
                />
              </Box>
              <Box>
                <HoveredIcon
                  icon="fa-regular fa-pencil"
                  hoverIcon="fa-solid fa-pencil"
                  onClick={() => handleEdit(items[rubric.source.index])}
                />
              </Box>
            </Stack>
          </Paper>
        </ThemeProvider>
      </div>
    );
  };

const ReviewerTools = ({ items }: { items: Array<any> }) => {
  const [reorderArray, setReorderArray] = useState<Array<any>>([]);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState<Setting | undefined>(undefined);
  const [deleteSetting] = useDeleteSettingMutation();
  const [reOrderSetting] = useReOrderSettingMutation();

  useEffect(() => {
    setReorderArray(items);
  }, [items]);

  const onDragEnd = (result: any) => {
    if (!result || result.destination === null) return;
    const tempArray = [...reorderArray];
    const [reorderedItem] = tempArray.splice(result.source.index, 1) || [];
    tempArray.splice(result.destination.index, 0, reorderedItem);
    setReorderArray(tempArray.map((e, order) => ({ ...e, order: order })));
    tempArray.map((e, order) =>
      reOrderSetting({
        id: e.id,
        body: {
          name: e.name,
          value: e.value,
          section: e.section,
          order: order,
        },
      })
    );
  };

  const handleEdit = (item: any) => {
    setEdit(item as Setting);
    setTimeout(() => setOpen(true), 0);
  };
  const handleDelete = (item: any) => {
    deleteSetting(item.id);
  };
  const renderItem = getRenderItem(reorderArray, handleEdit, handleDelete);

  return (
    <>
      <ModalWidget
        isOpen={open}
        title={edit ? 'Edit Menu Item' : 'Add Menu Item'}
        children={
          <UpsertMenuItem
            section="ReviewerTools"
            item={edit}
            onClose={() => setOpen(false)}
            maxOrder={items.length}
          />
        }
        onClose={() => {
          setOpen(false);
          setEdit(undefined);
        }}
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="datafield" renderClone={renderItem}>
          {(provided) => (
            <section {...provided.droppableProps} ref={provided.innerRef}>
              {/* {reorderArray.sort((a,b) => a.dataFieldLocation.order - b.dataFieldLocation.order)} */}
              {items.map((item, index) => {
                return (
                  <Draggable
                    key={item.id}
                    draggableId={item.id.toString()}
                    index={index}
                  >
                    {renderItem}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </section>
          )}
        </Droppable>
      </DragDropContext>
      <AddButton
        tab="Menu Item"
        onAdd={(tab: string) => {
          setOpen(true);
        }}
      />
    </>
  );
};

export default ReviewerTools;
