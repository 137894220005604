import React from 'react';
import { Box, Divider } from '@mui/material';
import { TaskExtended } from '../../../shared/types/Task';
import { useGetSepTasksQuery } from '../../services/sepSlice';
import { useGetCommentsQuery } from '../../services/commentSlice/commentSlice';
import { useGetDepartmentsExtendedQuery } from '../../services/departmentSlice/departmentSlice';
import CommentList from './CommentList';
import { DepartmentExtended } from '../../../shared/types/Department';
import { useGetDataFieldsBySEPIDQuery } from '../../services/dataFieldsSlice';

export interface SepCommentsProps {
  sepID: number;
  roleList?: any;
}

const SepComments = ({ sepID, roleList }: SepCommentsProps) => {
  const { data: tasks } = useGetSepTasksQuery(sepID);
  const { data: comments } = useGetCommentsQuery(sepID);
  const { data: dataFields } = useGetDataFieldsBySEPIDQuery(sepID);
  const { data: departments } = useGetDepartmentsExtendedQuery();
  const order: Array<string> = [
    'Solution Architecture',
    'Portfolio Owner',
    'Legal',
    'Third Party Security',
    'Security',
    'EA',
    'Supply',
    'Nuclear Cyber Security',
  ];
  const groupedObjects: { [departmentID: number]: TaskExtended[] } = (
    tasks ? tasks : []
  ).reduce<{ [departmentID: number]: TaskExtended[] }>(
    (accumulator, currentValue) => {
      (accumulator[currentValue.departmentID] =
        accumulator[currentValue.departmentID] || []).push(currentValue);
      return accumulator;
    },
    {}
  );

  const tasksPerDepartments: TaskExtended[][] = Object.values(
    groupedObjects
  ).map((group) => {
    if (group.length > 1) {
      return group;
    } else {
      return [group[0]];
    }
  });
  const sepDepartments = tasksPerDepartments.map((e) => e[0].department);
  const sepDepartmentsExtended = departments?.filter(
    (e) => sepDepartments?.filter((sepdp) => sepdp?.id === e?.id).length > 0
  );

  const sortDepartments = (departments: DepartmentExtended[]) => {
    return departments.sort((a, b) => a.orderId - b.orderId);
  };

  return (
    <Box>
      <CommentList
        comments={(comments ? comments : []).filter(
          (comment) =>
            (comment.departmentID === null ||
              comment.departmentID === undefined) &&
            (comment.taskID === null || comment.taskID === undefined)
        )}
      />
      <Divider
        sx={{
          backgroundColor: '#F8F9FA',
          height: '8px',
          border: 'none',
        }}
      />

      {sortDepartments(sepDepartmentsExtended || []).map(
        (department, index) => {
          const depTasks = tasksPerDepartments?.filter(
            (tasksPerDeparment: TaskExtended[]) =>
              tasksPerDeparment[0].departmentID === department.id
          )[0];
          const depDataFields = dataFields?.filter(
            (dataField) =>
              dataField.dataFieldLocations.filter(
                (lo) =>
                  lo.locationType === 'DepartmentReview' &&
                  lo.locationID === department.id
              ).length > 0
          );
          const depComments = (comments ? comments : []).filter(
            (comment) =>
              comment.departmentID === department.id ||
              depTasks.filter((e) => e.id === comment.taskID).length > 0
          );
          const availableTasks = depTasks?.filter((e) => e.enabled);
          if (availableTasks?.length === 0)
            return <React.Fragment key={index}></React.Fragment>;
          return (
            <React.Fragment key={index}>
              <CommentList
                department={department}
                dataFields={depDataFields}
                tasks={availableTasks}
                comments={depComments}
                sepID={sepID}
              />
              <Divider
                sx={{
                  backgroundColor: '#F8F9FA',
                  height: '8px',
                  border: 'none',
                }}
              />
            </React.Fragment>
          );
        }
      )}
    </Box>
  );
};

export default SepComments;
