import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { Typography, Stack } from '@mui/material';
import { Activity, icons } from '../../../shared/types/Activity';
import { format, formatDistance } from 'date-fns';
import { styled } from '@mui/material/styles';

export const IconCell = ({
  icon,
  iconHover,
}: {
  icon: string;
  iconHover: string;
}) => {
  const [hover, setHover] = useState(false);
  return (
    <i
      className={hover ? iconHover : icon}
      style={{
        fontSize: '14px',
        marginTop: '7px',
        color: hover ? '#2372B9' : '#7E8083',
      }}
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    ></i>
  );
};

const VerticalDivider = () => {
  return (
    <Typography
      component="span"
      sx={{
        width: '2px',
        minHeight: '16px',
        border: 'solid 0px #7E8083',
        backgroundColor: '#7E8083',
        marginLeft: '21px',
        flex: 1,
        alignSelf: 'stretch',
      }}
    ></Typography>
  );
};

interface TimerDotProps {
  isFirst?: boolean;
  isLast?: boolean;
  data: Activity;
}

const TimerDot = ({ isFirst, isLast, data }: TimerDotProps) => {
  return (
    <Stack
      display="flex"
      flexDirection="column"
      justifyContent="center"
      sx={{
        alignItems: 'left',
        paddingLeft: '20px',
        paddingRight: '20px',
      }}
    >
      <Stack
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="top"
      >
        <Stack
          display="flex"
          flexDirection="row"
          alignItems="top"
          justifyContent="center"
        >
          <Box
            alignItems="center"
            sx={{
              position: 'relative',
              alignSelf: 'stretch',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Typography
              component="div"
              sx={{
                width: '32px',
                height: '32px',
                border: 'solid 2px #7E8083',
                borderRadius: '17px',
                textAlign: 'center',
                margin: '5px',
              }}
            >
              <IconCell
                icon={icons[data.action + data.trackableType]}
                iconHover="fa-solid fa-circle-check hovered-icon"
              />
            </Typography>
            {isLast ? <></> : <VerticalDivider />}
          </Box>

          <Typography
            component="div"
            sx={{
              fontSize: '16px',
              paddingLeft: '30px',
              color: '#646669',
              maxWidth: '900px',
              height: '100%',
              textAlign: 'left',
              paddingTop: '8px',
              wordWrap: 'break-word',
            }}
          >
            {data.title}
          </Typography>
        </Stack>
        <Typography
          sx={{
            fontSize: '14px',
            minWidth: '180px',
            whiteSpace: 'nowrap',
            textAlign: 'center',
            paddingTop: '8px',
          }}
        >
          {format(new Date(data.createdAt), 'MM/dd/yyyy | hh:mm a')}
        </Typography>
      </Stack>
    </Stack>
  );
};

const TimeLine = ({ activities }: { activities: Activity[] }) => {
  const groupedActivities = React.useMemo(() => {
    const groups: [string, Activity[]][] = [];
    activities.forEach((a) => {
      const header = formatDistance(new Date(a.createdAt), new Date(), {
        addSuffix: true,
      });
      const foundGroup = groups.find((g) => g[0] === header);
      if (!foundGroup) {
        groups.push([header, []]);
      }
      groups[groups.length - 1][1].push(a);
    });
    return groups;
  }, [activities]);
  const StyledHeader = styled(Typography)(({ theme }) => ({
    fontSize: 14,
    color: theme.palette.gray.darkGray1,
    fontWeight: 600,
    textTransform: 'capitalize',
    borderBottom: 'solid 1px #E5E9EA',
    marginLeft: 90,
    paddingBottom: 5,
    marginTop: 20,
    width: 'calc(100% - 100px)',
  }));

  return (
    <Box aria-label="ActivityData">
      {groupedActivities.map((group, idx) => (
        <div key={idx}>
          <StyledHeader>{group[0]}</StyledHeader>
          {group[1].map((activity, i) => (
            <TimerDot
              key={i}
              data={activity}
              isLast={i === group[1].length - 1}
            />
          ))}
        </div>
      ))}
    </Box>
  );
};

export default TimeLine;
