import React from 'react';
import { Box } from '@mui/material';

const SortIcons = ({
  sort,
  property,
  isVisible,
}: {
  sort: boolean;
  property: boolean;
  isVisible: boolean;
}) => {
  if (!isVisible) {
    return <></>;
  }
  return (
    <Box sx={{ p: '5px' }}>
      {!property ? (
        <i className="fa-solid fa-sort" style={{ color: '#7E8083' }}></i>
      ) : sort ? (
        <i className="fa-solid fa-sort-up" style={{ color: '#7E8083' }}></i>
      ) : (
        <i className="fa-solid fa-sort-down" style={{ color: '#7E8083' }}></i>
      )}
    </Box>
  );
};

export default SortIcons;
