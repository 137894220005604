import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { SEPPhase, SEPStatus, WORKFLOWTYPE, NewWorkflowType } from '../../../shared/types/SEP';

interface StatusInterface {
  id: any;
  label: string;
  icon: string;
  iconColor: string;
}

const statusLists: StatusInterface[] = [
  {
    id: [
      SEPPhase.knockout,
      SEPPhase.design,
      SEPPhase.initiate,
      SEPPhase.implement,
    ],
    label: 'In Progress',
    icon: 'fa-regular fa-ellipsis',
    iconColor: '#7e8083',
  },
  {
    id: [SEPPhase.complete],
    label: 'Approved',
    icon: 'fa-solid fa-note-sticky',
    iconColor: '#2372B9',
  },

  {
    id: [SEPStatus.cancelled],
    label: 'Cancelled',
    icon: 'fa-solid fa-note-sticky',
    iconColor: '#2372B9',
  },
  {
    id: [SEPStatus.rejected],
    label: 'Rejected',
    icon: 'fa-solid fa-note-sticky',
    iconColor: '#2372B9',
  },
  {
    id: [SEPStatus.expired],
    label: 'Expired',
    icon: 'fa-solid fa-note-sticky',
    iconColor: '#2372B9',
  },
];

const workFlowList: StatusInterface[] = [
  {
    id: [ WORKFLOWTYPE.designReview],
    label: 'SEP Design review',
    icon: 'fa-solid fa-note-sticky',
    iconColor: '#2372B9',
  },
  // {
  //   id: [WORKFLOWTYPE.iotLabs],
  //   label: 'IoT Lab Approval',
  //   icon: 'fa-solid fa-note-sticky',
  //   iconColor: '#2372B9',
  // },
  {
    id: [WORKFLOWTYPE.saConsultation],
    label: NewWorkflowType?.solutionArchitectConsultation,
    icon: 'fa-solid fa-note-sticky',
    iconColor: '#2372B9',
  },
];

export const StyledCheckBox = ({
  indeterminate = false,
  checked,
  onChange,
  ariaLabel,
  label,
}: {
  indeterminate: boolean;
  checked: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  ariaLabel: string;
  label: JSX.Element;
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          indeterminate={indeterminate}
          sx={{ p: 0.5 }}
          aria-label={ariaLabel}
          checked={checked}
          onChange={onChange}
        />
      }
      label={label}
      sx={{ mr: 0 }}
    />
  );
};

const MyToDosCheckbox = ({
  myToDosChecked,
  setMyToDosChecked,
  data,
}: {
  myToDosChecked: boolean;
  setMyToDosChecked: (value: boolean) => void;
  data: any;
}) => {
  const handleToDosCheckbox = () => {
    setMyToDosChecked(!myToDosChecked);
  };

  return (
    <Box>
      <FormControlLabel
        control={
          <Checkbox
            indeterminate={false}
            sx={{ p: 0.5 }}
            aria-label="My To-Dos"
            checked={myToDosChecked}
            onChange={handleToDosCheckbox}
          />
        }
        label={
          <Box>
            <Typography
              fontSize="12px"
              color="gray.main"
              sx={{
                '&:hover': {
                  color: 'solidBlue.main',
                },
              }}
            >
              My To-Dos
            </Typography>
          </Box>
        }
        sx={{ mr: 0 }}
      />
    </Box>
  );
};

const SEPFilterBar = ({
  statusChecked,
  setStatusChecked,
  showTodo,
  setShowTodo,
  myToDosChecked,
  setMyToDosChecked,
  data,
  filterPage,
  workFlowTypeRequest,
  setWorkFlowTypeRequest
}: {
  statusChecked: any;
  setStatusChecked: (value: any) => void;
  showTodo: boolean;
  setShowTodo: (value: boolean) => void;
  myToDosChecked: boolean;
  setMyToDosChecked: (value: boolean) => void;
  data: any;
  filterPage: string;
  workFlowTypeRequest?: string[];
  setWorkFlowTypeRequest?: any
}) => {
 
  const inProgressIds = statusLists.filter((status)=> status.label === "In Progress")[0].id.flat()

  useEffect(() => {
    if(filterPage === 'mysep')
    setStatusChecked(inProgressIds)
  },[]);

  const handleCheckBoxChange = (event: any) => {
    const {name, checked} = event.target;
    if (name === "all") {
      setWorkFlowTypeRequest(checked? [NewWorkflowType?.sepDesignReview, NewWorkflowType?.solutionArchitectConsultation] : [])
    }
    setWorkFlowTypeRequest((prev: any) => {
      const newState = checked ? [...prev, name] : prev.filter((type: any) => type !== name)
      // if(name.length === 2) return ['SEP Design Review', 'Solution Architect Consultation']
      if(newState.includes(NewWorkflowType.sepDesignReview) && newState.includes(NewWorkflowType.solutionArchitectConsultation)) {
        return [NewWorkflowType?.sepDesignReview, NewWorkflowType?.solutionArchitectConsultation]
      }
      return newState
    });
  }
 
  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    id: SEPPhase[]
  ) => {
    if (id.length === 0) {
      if (event.target.checked)
        setStatusChecked(statusLists.map((list) => list.id).flat());
      else setStatusChecked([]);
      return;
    }

    if (event.target.checked) {
      setStatusChecked([
        ...statusChecked.filter((st: any) => id.indexOf(st) === -1),
        ...id,
      ]);
    } else {
      setStatusChecked([
        ...statusChecked.filter((st: any) => id.indexOf(st) === -1),
      ]);
    }
  };


  const isSelected = (ids: any) =>
    ids.every((id: any) => statusChecked.indexOf(id) !== -1);

  const isallSelected = workFlowTypeRequest?.length === 2;
  const isIndeterminate = workFlowTypeRequest && workFlowTypeRequest?.length > 0 && workFlowTypeRequest?.length < 2;

  return (
    <>
    <Box
      display="flex"
      flexWrap="wrap"
      justifyContent="center"
      alignItems="center"
      gap="16px"
      px="16px"
      mb="16px"
    >
      <Typography fontSize="12px" color="gray.main">
        <b>View by Status:</b>
      </Typography>
      <StyledCheckBox
        indeterminate={
          statusChecked.length > 0 &&
          statusChecked.length <
            statusLists.map((list) => list.id).flat().length
        }
        checked={
          statusLists.length > 0 &&
          statusChecked.length ===
            statusLists.map((list) => list.id).flat().length
        }
        onChange={(event) => handleChange(event, [])}
        ariaLabel="Select All Statues"
        label={
          <Box>
            <Typography
              fontSize="12px"
              color="gray.main"
              sx={{
                '&:hover': {
                  color: 'solidBlue.main',
                },
              }}
            >
              All Statuses
            </Typography>
          </Box>
        }
      />
      {statusLists.map((list: StatusInterface, index: number) => {
        return (
          <StyledCheckBox
            key={list.label}
            indeterminate={false}
            ariaLabel={`Status Checkbox ${index}`}
            checked={isSelected(list.id)}
            onChange={(event) => handleChange(event, list.id)}
            label={
              <Box
                display="flex"
                alignItems="center" 
                gap="4px"
                sx={{
                  '& i': {
                    color: list.iconColor,
                  },
                  '&:hover': {
                    '& i': {
                      color: 'solidBlue.main',
                    },
                    '& p': {
                      color: 'solidBlue.main',
                    },
                  },
                }}
              >
                <Typography fontSize="12px" color="gray.main">
                  {list.label}
                </Typography>
              </Box>
            }
          />
        );
      })}

      {/* {
        Object.values(SEPStatus).map((status: SEPStatus) => {
          return (
            <>
              {status !== SEPStatus.active && <StyledCheckBox
                key={status}
                indeterminate={false}
                ariaLabel={`Status Checkbox ${status}`}
                checked={false}
                onChange={() => handleStatusChange(status)}
                label={
                  <Box
                    display="flex"
                    alignItems="center"
                    gap="4px"
                    sx={{
                      '& i': {
                        color: '#7e8083',
                      },
                      '&:hover': {
                        '& i': {
                          color: 'solidBlue.main',
                        },
                        '& p': {
                          color: 'solidBlue.main',
                        },
                      },
                    }}
                  >
                    <Typography fontSize="12px" color="gray.main" style={{textTransform:'capitalize'}}>
                      {status}
                    </Typography>
                  </Box>
                }
              />}
            </>
          );
        })
      } */}
      {showTodo && (
        <MyToDosCheckbox
          myToDosChecked={myToDosChecked}
          setMyToDosChecked={setMyToDosChecked}
          data={data}
        />
      )}
    </Box>
    <Box
    display="flex"
    flexWrap="wrap"
    justifyContent="center"
    alignItems="center"
    gap="16px"
    px="16px"
    mb="16px"
  >
    <Typography fontSize="12px" color="gray.main">
        <b>View by WorkFlow Type:</b>
      </Typography>
      <FormControlLabel
      control = {
        <Checkbox
        checked= {isallSelected}
        indeterminate= {isIndeterminate}
        onChange={handleCheckBoxChange}
        name= "all"
        />
      }
      label= {
      <Typography sx = {{fontSize:"12px", color:"gray.main"}} >
        All Workflow Types
      </Typography>
      }
      sx = {{ marginRight: '0px'}}
      />
      <FormControlLabel 
      control = {
        <Checkbox
        checked= {workFlowTypeRequest && workFlowTypeRequest?.includes(NewWorkflowType?.sepDesignReview)}
        onChange={handleCheckBoxChange}
        name= "SEP Design Review"
        />
      }
      label= {
        <Typography sx = {{fontSize:"12px", color:"gray.main"}} >
          SEP Design Review
        </Typography>
        }
        sx = {{ marginRight: '0px'}}
      />
      <FormControlLabel 
      control = {
        <Checkbox
        checked= {workFlowTypeRequest && workFlowTypeRequest?.includes(NewWorkflowType.solutionArchitectConsultation)}
        onChange={handleCheckBoxChange}
        name= "Solution Architect Consultation"
        />
      }
      label= {
        <Typography sx = {{fontSize:"12px", color:"gray.main"}} >
          Solution Architect Consultation
        </Typography>
        }
        sx = {{ marginRight: '0px'}}
      />
    </Box>
    </>
  );
};

export default SEPFilterBar;
