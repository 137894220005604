import React, { useState } from 'react';
import {
  Box,
  TextField,
  Button,
  Stack,
  InputLabel,
  MenuItem,
  Typography,
  Divider,
  OutlinedInput,
} from '@mui/material';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  DataFieldType,
  CreateDataFieldTemplateBody,
  CreateDataFieldOptionTemplateBody,
  UpdateDataFieldTemplateBody,
  UpdateDataFieldOptionTemplateBody,
} from '../../../../../shared/types/DataField';
import HoveredIcon from '../../HoveredIconButton';
import StepperDots from '../../../StepperDots/StepperDots';
import {
  useCreateDataFieldOptionTemplateMutation,
  useDeleteDataFieldOptionTemplateMutation,
  useUpdateDataFieldOptionsTemplateMutation,
  useUpdateDataFieldTemplateMutation,
} from '../../../../services/dataFieldsSlice';

export const prettierValue = (val: string) => {
  if (val === '') return '';
  if (val === 'multiSelect') {
    return 'Multi-Select';
  }
  if (val === 'tileMultiSelect') return 'Tile Multi-Select';
  if (val === 'tileSelect') return 'Tile Select';
  if (val === 'yesNo') return 'Yes/No';
  if (val === 'emailLookup') return 'Email Lookup';
  if (val === 'apmLookup') return 'APM Lookup';
  return val[0].toUpperCase() + val.slice(1);
};

export const getOriginValue = (val: string) => {
  if (val === '') return '';
  if (val === 'Multi-Select') return 'multiSelect';
  if (val === 'Tile Multi-Select') return 'tileMultiSelect';
  if (val === 'Tile Select') return 'tileSelect';
  if (val === 'Yes/No') return 'yesNo';
  if (val === 'Email Lookup') return 'emailLookup';
  if (val === 'APM Lookup') return 'apmLookup';
  return val[0].toLowerCase() + val.slice(1);
};

const textFieldSx = {
  color: '#222222',
  '& > p': {
    margin: '5px 0px',
    color: '#646669',
    fontSize: '11px',
  },
};

interface FirstStepProps {
  value: CreateDataFieldTemplateBody;
  onChange: (value: { name: string; desc: string }) => void;
}

function FirstStep({ onChange, value }: FirstStepProps) {
  return (
    <Box>
      <Stack
        display="flex"
        direction="column"
        alignItems="left"
        spacing={4}
        sx={{ p: 4 }}
      >
        <Box>
          <InputLabel sx={{ color: '#222222' }} htmlFor="task-name">
            Data Field Name
          </InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="name"
            name="name"
            fullWidth
            value={value.name}
            placeholder="Ex. Does this request involve Nuclear?"
            helperText="This will be the question or statement needing to be answered"
            onChange={(e) => {
              onChange({ name: e.target.value, desc: value.description || '' });
            }}
          />
        </Box>
        <Box>
          <InputLabel sx={{ color: '#222222' }} htmlFor="task-assignment-name">
            Data Field Description
          </InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="description"
            name="description"
            fullWidth
            value={value.description}
            multiline
            rows={4}
            placeholder="Describe the reason for this data field here…"
            onChange={(e) => {
              onChange({ name: value.name, desc: e.target.value });
            }}
          />
        </Box>
      </Stack>
    </Box>
  );
}

interface SecondStepProps {
  options: CreateDataFieldOptionTemplateBody[];
  type: string;
  onChange: (
    options: CreateDataFieldOptionTemplateBody[],
    type: DataFieldType
  ) => void;
}

function SecondStep({ onChange, options, type }: SecondStepProps) {
  const isTileSelect = type === 'tileSelect' || type === 'tileMultiSelect';
  const hasOption = type === 'select' || type === 'multiSelect' || isTileSelect;
  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ width: '338px', mb: '30px' }}>
        <InputLabel id="demo-simple-select-label">Data Field Type</InputLabel>
        <Select
          id="data-field-select"
          value={prettierValue(type)}
          onChange={(e: SelectChangeEvent) => {
            onChange(options, getOriginValue(e.target.value) as DataFieldType);
          }}
          size="small"
          displayEmpty
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Typography sx={{ color: '#7E8083' }}>
                  Choose a data field type from the dropdown
                </Typography>
              );
            }
            return selected;
          }}
        >
          <MenuItem disabled value="">
            <Typography sx={{ color: '#7E8083' }}>
              Choose a data field type from the dropdown
            </Typography>
          </MenuItem>
          {Object.keys(DataFieldType).map((key, index) => {
            const indexOfKey = Object.keys(DataFieldType).indexOf(key);
            const value = Object.values(DataFieldType)[indexOfKey];
            return (
              <MenuItem key={index} value={key}>
                {prettierValue(value)}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      {hasOption && (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ color: '#222222', width: '216px' }}>
              Data Field Options
            </Box>

            {isTileSelect && (
              <Box sx={{ color: '#222222', width: '216px' }}>
                Data Field Option Icon
              </Box>
            )}
            {hasOption && <Box sx={{ width: '25px' }}></Box>}
          </Box>
          {options.map((option, i) => (
            <Box
              key={i}
              sx={{ display: 'flex', justifyContent: 'space-between' }}
            >
              <Box sx={{ mb: '5px' }}>
                <TextField
                  sx={textFieldSx}
                  size="small"
                  id="datafieldOption"
                  name="datafieldOption"
                  fullWidth
                  rows={4}
                  value={option.value}
                  placeholder="Type an option here."
                  onChange={(e) => {
                    onChange(
                      options.map((item, index) => {
                        if (index === i)
                          return { ...item, value: e.target.value };
                        return item;
                      }),
                      type as DataFieldType
                    );
                  }}
                />
              </Box>
              {isTileSelect && (
                <Box>
                  <TextField
                    sx={textFieldSx}
                    size="small"
                    id="datafieldOption"
                    name="datafieldOption"
                    fullWidth
                    rows={4}
                    value={option.icon || ''}
                    placeholder="Paste a Font Awesome icon name."
                    onChange={(e) => {
                      onChange(
                        options.map((item, index) => {
                          if (index === i)
                            return { ...item, icon: e.target.value };
                          return item;
                        }),
                        type as DataFieldType
                      );
                    }}
                  />
                </Box>
              )}
              <Box sx={{ mt: '7px' }}>
                <HoveredIcon
                  icon="fa-regular fa-trash"
                  hoverIcon="fa-solid fa-trash"
                  onClick={() => {
                    onChange(
                      options.filter((item, index) => index !== i),
                      type as DataFieldType
                    );
                  }}
                />
              </Box>
            </Box>
          ))}
        </Box>
      )}
      {hasOption && (
        <Button
          sx={{ textTransform: 'none', mt: '5px' }}
          variant="text"
          onClick={() =>
            onChange(
              [
                ...options,
                { value: '', description: '', dataFieldID: 0, order: 0 },
              ],
              type as DataFieldType
            )
          }
        >
          <i className="fa-solid fa-plus"></i> &nbsp; Add option
        </Button>
      )}
    </Box>
  );
}

export default function AddDataField({
  isClose,
  onAdd,
  dataField,
  toggleEdit,
}: {
  isClose: boolean;
  onAdd?: (
    df: CreateDataFieldTemplateBody,
    op: CreateDataFieldOptionTemplateBody[]
  ) => void;
  onUpdate?: (
    df: UpdateDataFieldTemplateBody,
    op: UpdateDataFieldOptionTemplateBody[]
  ) => void;
  dataField?: any;
  toggleEdit?: () => void;
}) {
  const [createDataFieldOptions] = useCreateDataFieldOptionTemplateMutation();
  const [updateOptions] = useUpdateDataFieldOptionsTemplateMutation();
  const [updateDataFieldTemplate] = useUpdateDataFieldTemplateMutation();
  const [deleteDataFieldOptionTemplate] =
    useDeleteDataFieldOptionTemplateMutation();
  const [indexOfCurrentScreen, setIndexOfCurrentScreen] = useState<number>(0);
  const [createDataFieldTemplateBody, setCreateDataFieldTemplateBody] =
    useState({
      name: dataField?.name ?? '',
      description: dataField?.description ?? '',
      type: dataField?.type ?? '',
    });
  const [
    createDataFieldOptionTemplateBody,
    setCreateDataFieldOptionTemplateBody,
  ] = useState(dataField?.dataFieldOptions ?? []);

  const handleAddDataField = () => {
    if (onAdd) {
      onAdd(
        {
          ...createDataFieldTemplateBody,
          type: createDataFieldTemplateBody.type as DataFieldType,
        },
        createDataFieldOptionTemplateBody
      );
    }
  };

  const singleValueDataFields = [
    'input',
    'textarea',
    'number',
    'email',
    'date',
    'dateTime',
    'yesNo',
    'toggle',
    'checkbox',
    'textbox'
  ];

  const handleUpdateDataField = () => {
    const options = [...createDataFieldOptionTemplateBody];

    const deleteDataFieldOptions = singleValueDataFields.includes(
      createDataFieldTemplateBody.type
    );

    updateDataFieldTemplate({
      id: dataField.id,
      body: createDataFieldTemplateBody,
    }).then((res: any) => {
      if (options.length > 0 && 'data' in res) {
        for (const option of options) {
          if (!deleteDataFieldOptions) {
            if ('id' in option) {
              const { id, ...properties } = option;
              updateOptions({ id: id as number, ...properties });
            } else {
              const newOption = { ...option };
              newOption.dataFieldID = dataField.id;
              createDataFieldOptions(newOption);
            }
          } else {
            deleteDataFieldOptionTemplate(option.id);
          }
        }
      }
      toggleEdit && toggleEdit();
    });
  };

  const validateOptionsData = (
    isTileSelect: boolean,
    data: CreateDataFieldOptionTemplateBody[]
  ) => {
    if (data.length === 0) return false;
    return data.every((option) =>
      isTileSelect ? !!option.value && !!option.icon : !!option.value
    );
  };

  const isFirstStepEnable =
    createDataFieldTemplateBody.name !== '' &&
    createDataFieldTemplateBody.description !== '';
  const isTileSelectOption =
    createDataFieldTemplateBody.type === 'tileSelect' ||
    createDataFieldTemplateBody.type === 'tileMultiSelect';
  const hasOption =
    createDataFieldTemplateBody.type === 'select' ||
    createDataFieldTemplateBody.type === 'multiSelect' ||
    isTileSelectOption;
  const isSecondStepEnable = hasOption
    ? !!createDataFieldTemplateBody.type &&
      validateOptionsData(isTileSelectOption, createDataFieldOptionTemplateBody)
    : !!createDataFieldTemplateBody.type;

  return (
    <>
      <Box sx={{ diplay: 'flex', minWidth: '100%' }}>
        {indexOfCurrentScreen === 0 && (
          <FirstStep
            value={createDataFieldTemplateBody as CreateDataFieldTemplateBody}
            onChange={(e: { name: string; desc: string }) => {
              setCreateDataFieldTemplateBody({
                ...createDataFieldTemplateBody,
                name: e.name,
                description: e.desc,
              });
            }}
          />
        )}
        {indexOfCurrentScreen === 1 && (
          <SecondStep
            options={createDataFieldOptionTemplateBody}
            type={createDataFieldTemplateBody.type as DataFieldType}
            onChange={(options, type) => {
              setCreateDataFieldTemplateBody({
                ...createDataFieldTemplateBody,
                type: type,
              });
              setCreateDataFieldOptionTemplateBody(options);
            }}
          />
        )}
        <StepperDots steps={2} activeStep={indexOfCurrentScreen} />
      </Box>
      <Divider sx={{ backgroundColor: '#7E8083' }} />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          p: 2,
          '& > button': {
            m: 1,
          },
        }}
      >
        {indexOfCurrentScreen !== 0 && (
          <Button
            variant="text"
            disabled={false}
            onClick={() => setIndexOfCurrentScreen(0)}
            sx={{ textTransform: 'none' }}
          >
            <Typography
              sx={{
                font: 'normal normal 600 16px/22px Open Sans',
                color: '#2372B9',
              }}
            >
              <i className="fa-regular fa-angle-left"></i> &nbsp;Back
            </Typography>
          </Button>
        )}
        <Button
          variant="contained"
          onClick={() => {
            indexOfCurrentScreen === 0
              ? setIndexOfCurrentScreen(1)
              : dataField
              ? handleUpdateDataField()
              : handleAddDataField();
          }}
          disabled={
            indexOfCurrentScreen === 0
              ? !isFirstStepEnable
              : !isSecondStepEnable
          }
          sx={{
            textTransform: 'none',
            backgroundColor: '#2372B9',
            font: 'normal normal 600 16px/22px Open Sans',
          }}
        >
          {indexOfCurrentScreen === 0 ? 'Continue' : 'Save Data Fields'}
        </Button>
      </Box>
    </>
  );
}
