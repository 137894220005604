import { Button } from '@mui/material';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import MeetingRoom from '@mui/icons-material/MeetingRoom';
import { Visibility } from '@mui/icons-material';
import { TaskStatus } from '../../../shared/types/Task';

export interface StatusButtonProps {
  status: string;
  review: boolean;
  onClick: (status: TaskStatus | 'viewRequest') => void;
}

const StatusButton = ({ status, review, onClick }: StatusButtonProps) => {
  const completeButton = (
    <Button
      aria-label="Task Status"
      variant="contained"
      startIcon={<CheckIcon />}
      sx={{
        width: '270px',
        backgroundColor: 'solidBlue.main',
        '&:hover': {
          backgroundColor: 'solidBlue.main',
        },
      }}
      onClick={() => onClick(TaskStatus.complete)}
    >
      Complete
    </Button>
  );

  const requestReviewButton = (
    <Button
      aria-label="Task Status"
      variant="contained"
      startIcon={<Visibility />}
      sx={{
        width: '270px',
        backgroundColor: 'solidBlue.main',
        '&:hover': {
          backgroundColor: 'solidBlue.main',
        },
      }}
      onClick={() => onClick(TaskStatus.inReview)}
    >
      Complete
    </Button>
  );

  const requestChangeButton = (
    <Button
      aria-label="Task Status"
      variant="outlined"
      startIcon={<MeetingRoom />}
      sx={{
        width: '270px',
        marginRight: '10px',
      }}
      onClick={() => onClick(TaskStatus.changesRequested)}
    >
      Request Changes
    </Button>
  );


  const todoButton = (
    <Button
      aria-label="Task Status"
      variant="outlined"
      startIcon={<MeetingRoom />}
      sx={{
        width: '270px',
        marginRight: '10px',
      }}
      onClick={() => onClick(TaskStatus.todo)}
    >
      To-Do
    </Button>
  );

  interface componentType {
    [key: string]: JSX.Element;
  }

  const component: componentType = {
    todo: review ? <>{requestReviewButton}</> : <>{completeButton}</>,
    inReview: (
      <>
        {requestChangeButton}
        {completeButton}
      </>
    ),
    changesRequested: (
      <>
        
        {requestReviewButton}
      </>
    ),
    complete: review ? <>{requestReviewButton}</> : <>{todoButton}</>,
  };

  return <>{component[status]}</>;
};

export default StatusButton;
