import React from 'react';
import { TaskExtended } from '../../../shared/types/Task';
import { DepartmentExtended } from '../../../shared/types/Department';
import { CommentExtended } from '../../../shared/types/Comment';
import { format, parseISO } from 'date-fns';
import {
  Box,
  Divider,
  Checkbox,
  FormControlLabel,
  Typography,
  Grid,
} from '@mui/material';
import { useGetUserQuery } from '../../services/usersSlice/';
import CommentCard from '../../components/Comments/CommentCard';
import { DataFieldWithOptionsAndLocations } from '../../../shared/types/DataField';
import './styles.css';
import { useGetSepStakeholderQuery } from '../../services/defaultStakeholderSlice';
import { skipToken } from '@reduxjs/toolkit/query';

export interface CommentListProps {
  department?: DepartmentExtended;
  dataFields?: DataFieldWithOptionsAndLocations[];
  tasks?: TaskExtended[];
  comments: CommentExtended[];
  sepID?: number;
}

const CommentList = ({
  department,
  tasks,
  comments,
  dataFields,
  sepID,
}: CommentListProps) => {
  const { data: loggedInUser } = useGetUserQuery('me');
  const { data: stakeHolders } = useGetSepStakeholderQuery(sepID ?? skipToken);

  const stakeholder = stakeHolders?.find((stakeholder) => {
    return department && stakeholder.departmentID === department.id;
  });

  const title =
    department === undefined || tasks === undefined
      ? 'General Comments'
      : department.name;

  return (
    <Box>
      <Box
        sx={{
          font: 'normal normal 600 18px/24px Open Sans',
          color: '#222222',
          m: 2,
          px: 2,
          pt: 2,
        }}
        data-testid="title"
      >
        {title}
      </Box>
      {department !== undefined && tasks !== undefined && (
        <>
          <Box
            sx={{
              font: 'normal normal normal 12px/22px Open Sans',
              px: 4,
              pt: '4px',
              color: '#646669',
            }}
          >
            {`${title} contact`}
          </Box>
          <Box
            sx={{
              font: 'normal normal normal 14px/22px Open Sans',
              px: 4,
              pt: '4px',
              color: '#222222',
            }}
          >
            {stakeholder?.user?.surname && stakeholder?.user?.givenName
              ? `${stakeholder?.user?.givenName} ${stakeholder?.user?.surname}`
              : stakeholder?.user?.displayName}
          </Box>
          <Box
            sx={{
              font: 'normal normal 600 12px/22px Open Sans',
              color: '#222222',
              px: 4,
              pt: 2,
            }}
          >
            Review
          </Box>
          <Box sx={{ ml: 3, mr: 3 }}>
            <Grid
              container
              item
              sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}
              xs={12}
              md={12}
              lg={12}
            >
              {dataFields?.map((dataField, index) => (
                <Grid key={index} item md={4}>
                  <p className="review-label">{dataField.name}</p>
                  <p className="review-data">
                    {dataField.value ||
                      dataField.dataFieldOptions.filter(
                        (option) => option.selected
                      )[0]?.value}
                  </p>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Divider sx={{ ml: 4 }} />
          <Box
            sx={{
              font: 'normal normal 600 12px/22px Open Sans',
              color: '#222222',
              px: 4,
              pt: 2,
            }}
          >
            Tasks and Comments
          </Box>
          <Divider sx={{ ml: 4 }} />
        </>
      )}

      {tasks ? (
        tasks.map((task, index) => (
          <TaskCommentCard
            key={`TaskCommentCard${index}`}
            task={task}
            comments={comments.filter(
              (filterItem) =>
                filterItem.taskID === task.id &&
                (filterItem.departmentID === null ||
                  filterItem.departmentID === undefined)
            )}
            loggedInUser={loggedInUser}
          />
        ))
      ) : (
        <TaskCommentCard
          task={undefined}
          comments={comments}
          loggedInUser={loggedInUser}
        />
      )}
    </Box>
  );
};

export const TaskCommentCard = ({
  task,
  comments,
  loggedInUser,
}: {
  task: TaskExtended | undefined;
  comments: CommentExtended[];
  loggedInUser: any;
}) => {
  return (
    <Box sx={{ ml: 4, mb: 2, mt: 2, mr: 4 }}>
      {task && (
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <FormControlLabel
            control={
              <Checkbox
                disableRipple
                checked={true}
                sx={{
                  cursor: 'default',
                }}
              />
            }
            label={task.name}
            sx={{
              font: 'normal normal normal 14px/22px Open Sans',
              letterSpacing: '0px',
              color: '#222222',
              opacity: 1,
            }}
          />
          {task.completedAt && (
            <Typography
              sx={{
                float: 'right',
                font: 'normal normal normal 12px/22px Open Sans',
                color: '#646669',
              }}
            >
              {`Completion Date: ${format(
                parseISO(task.completedAt || ''),
                'P'
              )}`}
            </Typography>
          )}
        </Box>
      )}
      {comments.map((comment, inds) => (
        <CommentCard
          key={`CommentCard${inds}`}
          comment={comment}
          loggedInUser={loggedInUser}
          handleCommentModal={(a, b) => null}
          handleDeleteComment={() => null}
          detailView
        />
      ))}
    </Box>
  );
};

export default CommentList;
