import React, { useState, useRef, useMemo } from 'react';
import {
  Paper,
  Box,
  FormControlLabel,
  Divider,
  Checkbox,
  Typography,
  Select,
  MenuItem,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ReactQuill from 'react-quill';
import UserDisplay from '../UserDisplay/UserDisplay';
import {
  useCreateCommentMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
} from '../../services/commentSlice/commentSlice';
import { CommentExtended } from '../../../shared/types/Comment';
import { useGetUserQuery } from '../../services/usersSlice';
import CommentCard, { TargetCommentBar } from './CommentCard';
import ModalWidget from '../ModalWidget/ModalWidget';
import Button from '../Button';
import Switch from '../Switch';
import { ActionButtons } from '../CancelSave';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import { useGetDepartmentsQuery } from '../../services/departmentSlice/departmentSlice';
import { Department } from '../../../shared/types/Department';

interface CommentsProps {
  taskID?: number;
  sepID: number;
  comments: CommentExtended[];
  currentDepartmentID?: number | undefined;
  roleList?: any;
  showAddNewCommentButton?: boolean;
}

const initialComment: CommentExtended = {
  id: 0,
  createdAt: 'NONE',
  updatedAt: 'NONE',
  createdBy: 'NONE',
  changeRequest: false,
  sepID: 0,
  comment: '',
  replyCommentID: 0,
  departmentID: 0,
  task: null,
  taskID: 0,
  deletedAt: 'NONE',
  creator: {
    id: 'NONE',
    displayName: 'NONE',
    email: 'NONE',
    givenName: 'NONE',
    surname: 'NONE',
  },
};

const Comments = ({ taskID, sepID, comments, roleList, showAddNewCommentButton }: CommentsProps) => {
  const [createCommentMutation] = useCreateCommentMutation();
  const [deleteCommentMutation] = useDeleteCommentMutation();
  const [editCommentMutation] = useEditCommentMutation();
  const { data: loggedInUser } = useGetUserQuery('me');
  const { data: departments } = useGetDepartmentsQuery();

  const [commentType, setCommentType] = useState<string>('');
  const [showPrivateMessages, setShowPrivateMessages] =
    useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);
  const [value, setValue] = useState('');
  const [currentDepartmentID, setCurrentDepartmentID] = useState(0);
  const [targetComment, setTargetComment] =
    useState<CommentExtended>(initialComment);
  const [seeAll, setSeeAll] = useState(comments.length < 5);

  // return array with possible department access
  // if user superAdmin return all departments else return the filter permited departments

  const superUser = roleList?.includes('AuthSuperUser');
  const userDepartments = useMemo(() => {
    const departmentsArray: any[] = [];
    if (roleList?.length) {
      departments?.forEach((department: Department) => {
        !superUser
          ? roleList?.map(
              (departmentAccess: string) =>
                department.adAppRole === departmentAccess &&
                departmentsArray.push({
                  id: department.id,
                  label: department.name,
                })
            )
          : departmentsArray.push({
              id: department.id,
              label: department.name,
            });
      });
    }
    return departmentsArray;
  }, [departments, roleList, superUser]);

  const commentsToShow = React.useMemo(() => {
    const privateComments = showPrivateMessages
      ? comments
          .filter(
            (comment) =>
              comment.departmentID !== undefined &&
              comment.departmentID !== null
          )
          .sort((a, b) => b.id - a.id)
      : [...comments].sort((a, b) => b.id - a.id);

    return seeAll ? [...privateComments] : privateComments.slice(0, 5);
  }, [comments, seeAll, showPrivateMessages]);

  const handleSave = () => {
    // create new comment or reply/edit
    commentType === 'Edit'
      ? handleEditComment(targetComment)
      : handleAddNewComment(commentType, value);
    setIsOpen(false);
    setCurrentDepartmentID(0);
  };

  const handleAddNewComment = async (type: string, comment: string) => {
    const { data } = (await createCommentMutation({
      sepID,
      comment: comment,
      changeRequest: false,
      replyCommentID: type === 'Reply' ? targetComment.id : undefined,
      departmentID: currentDepartmentID || undefined,
      taskID,
    })) as { data: CommentExtended };
    if (data) {
      setCommentType('');
    }
  };

  const handleDeleteComment = async (e: CommentExtended) => {
    await deleteCommentMutation(e.id);
    setCommentType('');
  };

  const handleEditComment = async (e: CommentExtended) => {
    await editCommentMutation({
      id: e.id,
      comment: value,
    });
  };

  const handleCommentModal = (type: string, comment?: CommentExtended) => {
    type === 'Edit' ? comment && setValue(comment.comment) : setValue('');
    setCommentType(type);
    comment && setTargetComment(comment);
    setIsOpen(true);
    setCurrentDepartmentID(comment?.departmentID || 0);
  };

  const handleClose = () => {
    setCommentType('');
    setIsOpen(false);
  };

  const handleCancel = () => {
    setValue('');
    setIsOpen(false);
  };

  const handleDepartmentChange = (e: any) => {
    setCurrentDepartmentID(e.target.value);
  };

  const handlePrivateMessage = () => {
    setCurrentDepartmentID(userDepartments[0].id);
  };

  const togglePrivateMessges = () => {
    setShowPrivateMessages(!showPrivateMessages);
  };

  return (
    <Box>
      <CommentModal
        superUser={superUser}
        isOpen={isOpen}
        commentType={commentType}
        targetComment={targetComment}
        loggedInUser={loggedInUser}
        setCommentType={setCommentType}
        handleClose={handleClose}
        userDepartments={userDepartments}
        value={value}
        setValue={setValue}
        handleSave={handleSave}
        handleCancel={handleCancel}
        handleDepartmentChange={handleDepartmentChange}
        currentDepartmentID={currentDepartmentID}
        handlePrivateMessage={handlePrivateMessage}
      />
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            mt: 2,
            mr: 4,
            mb: 1,
            alignItems: 'flex-end',
          }}
        >
          {showAddNewCommentButton && (
            <Button
              aria-label="Add New Comment"
              variant="contained"
              startIcon={<AddIcon />}
              sx={{
                  position: "absolute",
                  right: "15%",
                  top: "57%",
              }}
              onClick={() => handleCommentModal("New")}
            >
              Add New Comment
            </Button>
          )}
            <Button
              aria-label="Add New Comment"
              variant="contained"
              startIcon={<AddIcon />}
              onClick={() => handleCommentModal("New")}
            >
              Add New Comment
            </Button>
          <Box mt={2} />
          <FormControlLabel
            control={
              <Box sx={{ pl: 1 }}>
                <Switch
                  value={showPrivateMessages}
                  onChange={togglePrivateMessges}
                />
              </Box>
            }
            label="Show Private Comments Only"
            labelPlacement="start"
            sx={{ mx: '0px !important' }}
          />
        </Box>
      </Box>

      {commentsToShow.map((e: CommentExtended, i: number) => {
        return (
          <CommentCard
            key={e.id}
            comment={e}
            loggedInUser={loggedInUser}
            handleCommentModal={handleCommentModal}
            handleDeleteComment={handleDeleteComment}
            handleEditComment={handleEditComment}
          />
        );
      })}
      {!seeAll && (
        <Box
          component="div"
          display="flex"
          alignItems="center"
          justifyContent="center"
          sx={{ mt: 2 }}
        >
          <Button
            variant="text"
            size="small"
            sx={{ fontWeight: 600, textTransform: 'none', fontSize: 14, mb: 2 }}
            onClick={() => {
              setSeeAll(true);
            }}
          >
            See More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default Comments;

interface CommentModalProps {
  //handleAddNewComment: any;
  superUser: boolean;
  isOpen: boolean;
  loggedInUser?: any;
  commentType: string;
  targetComment: any;
  setCommentType: React.Dispatch<React.SetStateAction<string>>;
  handleClose: () => void;
  value: string;
  setValue: React.Dispatch<React.SetStateAction<string>>;
  userDepartments: any[];
  handleSave: () => void;
  handleCancel: () => void;
  handleDepartmentChange: (e: any) => void;
  currentDepartmentID: number;
  handlePrivateMessage: () => void;
}

export const CommentModal = ({
  superUser,
  isOpen,
  loggedInUser,
  commentType,
  targetComment,
  handleClose,
  value,
  setValue,
  handleSave,
  handleCancel,
  userDepartments,
  handleDepartmentChange,
  currentDepartmentID,
  handlePrivateMessage,
}: CommentModalProps) => {
  const editorRef = useRef<ReactQuill | null>(null);

  const commentTypes = ['New', 'Reply', 'Edit', 'Complete'];

  const commentTypeValidator = commentTypes.includes(commentType);
  const [commentVal,setCommentVal]  = useState(false)
  React.useEffect(() => {
    const cleanText = value.replace(/<\/?[^>]+(>|$)/g, "");
    setCommentVal(cleanText.length === 0)
    const keyDownHandler = (event: any) => {
      // if enter key and shift
      if (event.key === 'Enter' && event.shiftKey) {
        event.preventDefault();

        // call submit function here
        handleSave();
      }
    };

    // keypress listener
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      // listener cleanup
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [commentTypeValidator, value, handleSave]);

  React.useEffect(() => {
    // on modal open focus on text area
    if (editorRef && editorRef.current && editorRef.current.editor) {
      editorRef.current.unprivilegedEditor?.getSelection(true);
    }
  }, [isOpen]);

  return (
    <>
      <ModalWidget
        title={`${commentType} Comment`}
        isOpen={isOpen}
        onClose={handleClose}
        children={
          <>
            <Paper
              elevation={0}
              sx={{
                p: 2,
                backgroundColor: '#F8F9FA',
              }}
            >
              {commentType === 'Reply' && (
                <TargetCommentBar comment={targetComment} />
              )}
              <Box
                sx={{
                  pl: 2,
                }}
              >
                <UserDisplay
                  userID={loggedInUser?.id || ''}
                  showName={true}
                  showPicture={true}
                  title="Requestor"
                />
              </Box>
              <Box
                sx={{
                  p: 2,
                  ml: 6,
                  mr: 2,
                  mt: 1,
                }}
              >
                <RichTextEditor
                  value={value}
                  setValue={setValue}
                  ref={editorRef}
                  style={{ height: '130px' }}
                />
              </Box>
            </Paper>
            <Divider />
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Box sx={{ pl: 2 }}>
                {!!userDepartments.length &&
                  (!superUser && userDepartments.length === 1 ? (
                    <FormControlLabel
                      label={
                        <Typography sx={{ fontSize: '14px' }}>
                          Private Reply (Only my team can view)
                        </Typography>
                      }
                      control={<Checkbox onChange={handlePrivateMessage} />}
                    />
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        color: 'grey',
                      }}
                    >
                      <i className="fa-solid fa-lock"></i>
                      &nbsp;
                      <Select
                        size="small"
                        defaultValue={0}
                        value={currentDepartmentID}
                        onChange={(e) => handleDepartmentChange(e)}
                      >
                        <MenuItem value={0}>
                          <em>Choose a department</em>
                        </MenuItem>
                        {userDepartments.map((department) => {
                          return (
                            <MenuItem key={department.id} value={department.id}>
                              {department.label}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </Box>
                  ))}
              </Box>
              <ActionButtons
                handleCancel={handleCancel}
                handleSave={handleSave}
                buttonValidation={commentVal}
                saveLabel={
                  commentType === 'Reply'
                    ? 'Post Reply'
                    : commentType === 'Edit'
                    ? 'Edit Comment'
                    : commentType === 'Complete'
                    ? 'Complete Task' :'Post Comment'
                }
                saveIcon={
                  commentType === 'Reply' ? (
                    <i className="fa-solid fa-thumbtack"></i>
                  ) : (
                    ''
                  )
                }
              />
            </Box>
          </>
        }
      />
    </>
  );
};
