import React, { useState, useEffect, forwardRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface RichTextEditorProps {
  value: string | null | undefined;
  setValue: (v: string) => void;
  placeholder?: string | undefined;
  style?: React.CSSProperties | undefined;
  disabled?: boolean;
  onChangeEvent?: boolean;
}
const RichTextEditor = forwardRef(
  (
    { value, setValue, placeholder, style, disabled, onChangeEvent }: RichTextEditorProps,
    ref: React.ForwardedRef<ReactQuill | null>
  ) => {
    /** editorValue is used to keep track of the user's active typing. `props.value` will be updated when the user stops typing. (performance) */
    const [editorValue, setEditorValue] = useState(
      value?.replaceAll(/style="[a-zA-Z0-9:;.\s()\-,]*"/gi, '') ?? ''
    );

    useEffect(() => {
      setEditorValue(
        value?.replaceAll(/style="[a-zA-Z0-9:;.\s()\-,]*"/gi, '') ?? ''
      );
    }, [value]);

    useEffect(() => {
      // Debounce our setValue functrion here so we can wait for the user to stop typing (helps with performance)
      if(!onChangeEvent) {
      const delayDebounceFn = setTimeout(() => {
        // remove styles from pasted html
        setValue(
          editorValue.replaceAll(/style="[a-zA-Z0-9:;.\s()\-,]*"/gi, '')
        );
      }, 300);
      return () => clearTimeout(delayDebounceFn);
    }
    }, [editorValue, setValue]);

    const handleTextChange = (newVal: string) => {
      setEditorValue(newVal);
      setValue(newVal)
    }
    
    const checkCharacterCount = (e: any) => {
      // Only allow up to 4000 characters
      if (editorValue.length > 4000 && e.key !== 'Backspace') {
        e.preventDefault();
      }
    };

    return (
      <ReactQuill
        ref={ref}
        placeholder={!!placeholder ? placeholder : 'Begin typing...'}
        onKeyDown={checkCharacterCount}
        modules={{
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            [{ list: 'ordered' }, { list: 'bullet' }],
            ['link'],
            ['clean'],
          ],
        }}
        theme="snow"
        value={editorValue}
        onChange={onChangeEvent ? handleTextChange : setEditorValue}
        style={style ? style : {}}
        readOnly={disabled}
      />
    );
  }
);
export default RichTextEditor;
