import React, { useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import HoveredIconConfirm from '../HoveredIconConfirmButton';
import DataFieldOptionCard from './DataFieldOptionCard';
import { DataFieldType } from '../../../../shared/types/DataField';
import { useDeleteDataFieldTemplateMutation } from '../../../services/dataFieldsSlice';
import ModalWidget from '../../ModalWidget/ModalWidget';
import AddDataField from './AddDataField';
import HoveredIcon from '../HoveredIconButton';

const type: Record<DataFieldType, string> = {
  input: 'Input',
  textarea: 'Text Area',
  number: 'Number',
  email: 'Email',
  emailLookup : 'Email Lookup',
  apmLookup:'APM Lookup',
  select: 'Select',
  multiSelect: 'Multi Select',
  date: 'Date',
  dateTime: 'Date Time',
  yesNo: 'Yes/No',
  checkbox: 'Checkbox',
  toggle: 'Toggle',
  tileSelect: 'Tile Select',
  tileMultiSelect: 'Tile Multi Select',
  textbox: 'Text box'
};

const calculateTotalProps = (datafield: {
  type: DataFieldType;
  dataFieldOptions: any[];
}) => {
  const dataFieldNum = datafield.dataFieldOptions.length;

  const selectedType = type[datafield.type] ?? datafield.type;
  return `${selectedType}${dataFieldNum ? ` | ${dataFieldNum} Options` : ''}`;
};

const DataFieldScreenCard = ({ datafield }: any) => {
  const [deleteDataField] = useDeleteDataFieldTemplateMutation();

  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<any>(undefined);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const handleDelete = () => {
    setShowDeleteModal(!showDeleteModal);
    deleteDataField(datafield.id);
  };

  return (
    <>
      <ModalWidget
        isOpen={!!editItem}
        title="Edit Data Field"
        children={
          <AddDataField
            isClose={editItem === undefined}
            dataField={editItem}
            toggleEdit={() => null}
            onClose={() => setEditItem(undefined)}
          />
        }
        onClose={() => setEditItem(undefined)}
      />
      <Box
        sx={{
          borderLeft: 'solid 5px #2372B9',
          padding: '30px 40px',
          width: '100%',
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box alignItems="center">
            <Typography
              component="span"
              sx={{
                fontSize: '18px',
                fontWeight: '700',
              }}
            >
              {datafield.name}
            </Typography>
            &nbsp;&nbsp;
            {!!datafield.dataFieldOptions.length && (
              <i
                className={`fa-sharp fa-solid fa-chevron-${
                  showDetail ? 'up' : 'down'
                }`}
                onClick={() => setShowDetail(!showDetail)}
              ></i>
            )}
          </Box>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              width: '60px',
            }}
          >
            {/* Delete Button for KnockoutScreen... */}
            <HoveredIconConfirm
              icon="fa-regular fa-trash"
              hoverIcon="fa-solid fa-trash"
              onClick={() => handleDelete()}
            />
            <HoveredIcon
              icon="fa-regular fa-pencil"
              hoverIcon="fa-solid fa-pencil"
              onClick={() => setEditItem(datafield)}
            />
          </Stack>
        </Stack>
        <Typography
          component="div"
          sx={{
            fontSize: '11px',
            fontWeight: '700',
          }}
        >
          {datafield.starter && 'Starter Screen   '}
          <Typography
            component="span"
            sx={{ fontSize: '11px', color: '#646669' }}
          >
            {calculateTotalProps(datafield)}
          </Typography>
        </Typography>
        <Box
          component="div"
          sx={{
            paddingTop: '15px',
            width: 'fit-content',
            maxWidth: '1200px',
            overflowWrap: 'break-word',
          }}
          dangerouslySetInnerHTML={{ __html: datafield.description ?? '' }}
        ></Box>
      </Box>
      <Divider />
      {showDetail &&
        datafield.dataFieldOptions.map((datafieldOption: any, index: any) => {
          return (
            <DataFieldOptionCard
              key={index}
              datafieldOption={datafieldOption}
              index={index}
            />
          );
        })}
    </>
  );
};

export default DataFieldScreenCard;
