import React, { useState, useEffect, useRef } from 'react';
import {
  Box,
  Grid,
  InputAdornment,
  Tooltip,
  useTheme,
  CircularProgress,
  Button,
  Stack,
  Divider,
  Autocomplete,
  TextField as TextFieldForLookup,
  FormLabel
} from '@mui/material';
import TextField from '../../components/TextField';
import InfoIcon from '@mui/icons-material/Info';
import RefreshIcon from '@mui/icons-material/Refresh';
import SearchIcon from '@mui/icons-material/Search';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetApmQuery } from '../../services/appSlice/appSlice';
import TextFieldComponent from '../../components/TextField';
import { useUpdateSepMutation } from '../../services/sepSlice';
import { useUpdateTaskMutation } from '../../services/tasksSlice/tasksSlice';
import { useGetSepTasksQuery } from '../../services/sepSlice'
import { useSearchUsersQuery } from '../../services/usersSlice';
import { User } from '../../../shared/types/User';
import { debounce } from 'lodash';
import { useUpdateDataFieldsMutation } from '../../services/dataFieldsSlice';


const EditSEP = ({ sep, dataFields, onClose }: { sep: any;dataFields:any; onClose: () => void }) => {
  const altContactDataField = dataFields?.find((item: any)=>{
    return item.name == 'Alternate Contact'
  })
  const theme = useTheme();
  const [updateDataFields] = useUpdateDataFieldsMutation();
  const [altContact,setAltContact]=useState(altContactDataField?.value);
  const [updateSep] = useUpdateSepMutation();
  const [sepName, setSepName] = useState<string>('');
  const [sepDesc, setSepDesc] = useState<string>('');
  const [apmID, setApmID] = useState<string>('');
  const [searchField, setSearchField] = useState<number | null | string>(null);
  const [activelyTyping, setActivelyTyping] = useState<number | null | string>(
    null
  );
  const [errorMsg, setErrorMsg] = useState('');
  const { data, isError, isLoading, error, refetch } = useGetApmQuery(
    searchField || skipToken
  );
  const { data: sepTasks } = useGetSepTasksQuery(sep.id);
  const [updateTaskMutation] = useUpdateTaskMutation();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [userOptions, setUserOptions] = useState<User[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { data: searchResults = []} = useSearchUsersQuery(
    {
      search: searchTerm,
      
    },
    { skip: searchTerm.trim() === '' }
  ); 

  const debouncedSearch = useRef<ReturnType<typeof debounce>>(debounce((term: string) => setSearchTerm(term), 100));
  useEffect(() => {
    debouncedSearch.current(searchTerm);
    return () => debouncedSearch.current.cancel();
  }, [searchTerm]);
  useEffect(() => {
    if (!isLoading && !isError) {
      setUserOptions((prevOptions) => {
        return JSON.stringify(prevOptions) !== JSON.stringify(searchResults) ? searchResults : prevOptions;
      });
    }
  }, [searchResults, isLoading, isError]);
  useEffect(()=>{
    const dataFieldAltContact = dataFields?.find((item: any)=>{
      return item.name == 'Alternate Contact'
    })
    setAltContact(dataFieldAltContact?.value)
  },[dataFields])

  const onEnterPress = (e: any) => e.key === 'Enter' && handleFind();

  const handleChange = (e: any) => {
    setActivelyTyping(e.target.value);
    setApmID('');
  };
  const checkbvDrMisalignment = (apmdata: any) => {
    
    const highTierValues = ['Tier 0', 'Tier 1', 'Tier 2']
    const mismatch = apmdata?.emergency_tier && !((highTierValues.includes(apmdata?.emergency_tier) && apmdata?.business_criticality && apmdata?.business_criticality.toLowerCase() == 'high') ||
      (apmdata?.emergency_tier && apmdata?.emergency_tier == 'Tier 3' && apmdata?.business_criticality && apmdata?.business_criticality.toLowerCase() == 'medium') ||
      (apmdata?.emergency_tier && apmdata?.emergency_tier == 'Tier 4' && apmdata?.business_criticality && apmdata?.business_criticality.toLowerCase() == 'low'))
    return mismatch;
  }
  const handleSave = () => {
    if(data){
      const isDataMisaligned = checkbvDrMisalignment(data)
      const bvDRTask = sepTasks && sepTasks.filter(
        (e: any) => e.taskTemplateID == 3040
      )[0];
     if (isDataMisaligned && bvDRTask) {
        updateTaskMutation({
          taskID: bvDRTask.id,
          task: {
            enabled: true,
            review: true,
          },
        });
      }
    }
    
    updateSep({
      id: sep?.id,
      body: {
        name: sepName,
        description: sepDesc,
        apmID: parseInt(apmID || ''),
        apmData:data,
      },
    });
    console.log("old alternate contact --",altContactDataField.value)
    console.log("new alt contact val",altContact)
    if(altContact !== altContactDataField.value){
      const data = [{
        id: altContactDataField?.id,
        value: altContact
      }]
      updateDataFields({ sepId: sep?.id, dataFields: data });
    }
    onClose();
  };

  const handleFind = () => {
    if (activelyTyping !== null && activelyTyping !== '') {
      if (activelyTyping === data?.number) {
        setApmID(activelyTyping);
        setErrorMsg('');
      } else {
        setSearchField(activelyTyping);
      }
    }
  };

  const handleSEPDescChange = (e: string) => {
    setSepDesc(e);
  };

  useEffect(() => {
    if (sep) {
      setSepName(sep.name || '');
      setSepDesc(sep.description || '');
      setApmID(sep.apmID || '');
      setActivelyTyping(sep.apmID || '');
      setSearchField(sep.apmID || '');
    }
  }, [sep]);

  useEffect(() => {
    if (data) {
      setApmID((e) => data.number || '');
      setErrorMsg('');
    }
    if (error && 'data' in error) {
      setApmID((e) => '');
      setErrorMsg(error.data as string);
    }
  }, [data, error]);
  return (
    <>
      <Box sx={{ px: 4, py: 3, width: '100%' }}>
        <Box>
          <TextFieldComponent
            name="name"
            onChange={(e: any) => setSepName(e.target.value)}
            label="Initiative Name"
            caption="Give your initiative request a name"
            fullWidth
            value={sepName}
          />
          <Box mt={2} />
          <TextFieldComponent
            multiline
            name="description"
            onChange={(e: any) => handleSEPDescChange(e)}
            label="Description"
            caption="Please describe this initiative in as much detail as possible"
            rows={10}
            fullWidth
            value={sepDesc}
            isDomString
          />
        </Box>
        <React.Fragment>
                  <div style={{width:'400px', paddingTop:'10px'}}>
                  <FormLabel>Alternate Contact</FormLabel>
                  <Autocomplete style={{paddingTop:'5px'}}
                    options={userOptions}
                    getOptionLabel={(user) => user.displayName || user.email || ''}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(e, value) => {
                      setAltContact(value?.email)
                      //handleChangeEmailLookup(value?.email, field.id)
                      if (value?.email) {
                        setErrorMessage('');
                      } else {
                        setErrorMessage('Enter a valid user ID');
                      }
                    }}
                    inputValue={searchTerm}
                    onInputChange={(_, newInputValue) => debouncedSearch.current(newInputValue)}
                    renderInput={(params) => (
                      <TextFieldForLookup
                        {...params}
                        label= {altContact?altContact :"Search and Select Users"}
                        value= {altContact}
                        sx={{
                          borderColor: searchTerm.trim() === '' ? 'red' : 'default',
                          '& input': {
                            fontSize: '14px',
                            cursor: 'pointer',
                            color: searchTerm.trim() === '' ? 'red' : 'default',
                          },
                        }}
                      />
                    )}
                  />
                 
                  </div>
                </React.Fragment>
        <Grid container sx={{ width: '100%' }}>
          <Grid item md={5}>
            {/* this is the error on console for being null */}
            <TextField
              label="APM ID"
              icon={
                <Tooltip placement="top" title="The APM ID from Service Now.">
                  <InfoIcon sx={{ color: theme.palette.gray.solidGray }} />
                </Tooltip>
              }
              caption="Click the search icon for details"
              type="number"
              error={isError || errorMsg !== ''}
              helperText={errorMsg}
              onKeyUp={(e: any) => onEnterPress(e)}
              endAdornment={
                <div onClick={() => handleFind()}>
                  <InputAdornment
                    sx={{ mr: 1, cursor: 'pointer' }}
                    position="end"
                  >
                    <SearchIcon />
                  </InputAdornment>
                </div>
              }
              onChange={(e: any) => handleChange(e)}
              value={activelyTyping || ''}
              sx={{ width: '100%', mr: 1 }}
            />
          </Grid>
          <Grid item md={7}>
            <TextField
              disabled
              label="APM Name"
              sx={{ width: '100%', ml: 1 }}
              value={(apmID !== '' ? data?.name : '') || ''}
              endAdornment={
                isLoading && (
                  <InputAdornment
                    sx={{ mr: 1, cursor: 'pointer' }}
                    position="end"
                  >
                    <CircularProgress
                      size="20px"
                      sx={{ color: theme.palette.gray.solidGray }}
                    />
                  </InputAdornment>
                )
              }
            />
          </Grid>
        </Grid>
        <Button startIcon={<RefreshIcon />} onClick={() =>{setSearchField(activelyTyping); refetch() }}>Refresh APM Details</Button>
      </Box>
      <Divider />
      <Box sx={{ px: 4, py: 2, width: '100%' }}>
        <Stack
          direction="row"
          display="flex"
          justifyContent="right"
          spacing={2}
        >
          <Button
            sx={{ color: '#2372B9', textTransform: 'none' }}
            variant="text"
            onClick={() => onClose()}
          >
            Cancel
          </Button>
          <Button
            type="submit"
            sx={{ backgroundColor: '#2372B9', textTransform: 'none' }}
            variant="contained"
            onClick={() => handleSave()}
            disabled={
              sepName === '' ||
              (errorMsg !== '' && activelyTyping !== '') ||
              (!!!apmID && activelyTyping !== '')
            }
          >
            Save Changes
          </Button>
        </Stack>
      </Box>
    </>
  );
};

export default EditSEP;
