import React, { useState } from 'react';
import {
  Box,
  Divider,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import HoveredIcon from '../../HoveredIconButton';
import DropDown from '../../DropDown';
import HoveredIconConfirm from '../../HoveredIconConfirmButton';
import FollowUp from './FollowUp';
import {
  DataFieldExtendedWithSingleLocationAndFollowups,
  DataFieldType,
  DataFieldWithOptionsAndSingleLocation,
} from '../../../../../shared/types/DataField';
import { KnockoutFollowupExtended } from '../../../../../shared/types/Knockout';
import {
  useDeleteDataFieldLocationTemplateMutation,
  useUpdateDataFieldLocationTemplateMutation,
} from '../../../../services/dataFieldsSlice';
import ModalWidget from '../../../../components/ModalWidget/ModalWidget';
import AddFollowup from './AddFollowup';
import ReorderDataFieldsModal from './ReorderDataFields';
import AddDataField from './AddDataField';

interface DataFieldCardProps {
  dataField: DataFieldExtendedWithSingleLocationAndFollowups;
  datafields: DataFieldWithOptionsAndSingleLocation[];
  onEdit: (dataField: DataFieldExtendedWithSingleLocationAndFollowups) => void;
  locationName: string;
  locationDescription: string;
  type: string;
}

const DataFieldCard = ({
  dataField,
  type,
  datafields,
  onEdit,
  locationName,
  locationDescription,
}: DataFieldCardProps) => {
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [isAddFollowup, setIsAddFollowup] = useState<string>('');
  const [isReorderDataFieldModalOpen, setIsReorderDataFieldModalOpen] =
    useState(false);
  const [pendingFollowUp, setPendingFollowUp] =
    useState<KnockoutFollowupExtended | null>(null);
  const [deleteDataFieldLocationTemplate] =
    useDeleteDataFieldLocationTemplateMutation();
  const [editDataField, setEditDataField] = useState<boolean>(false);
  const [updateDataFieldTemplateLocation] =
    useUpdateDataFieldLocationTemplateMutation();

  const toggleEdit = () => {
    setEditDataField(!editDataField);
  };

  const handleDataFieldLocation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    const property = { [name]: checked };
    updateDataFieldTemplateLocation({
      id: dataField.dataFieldLocation.id,
      body: property,
    });
  };

  // Deletes a data field, not a data field option
  const handleDelete = () => {
    deleteDataFieldLocationTemplate(dataField.dataFieldLocation.id);
  };
  const handleReorderDataFieldModalClose = () => {
    setIsReorderDataFieldModalOpen(!isReorderDataFieldModalOpen);
  };

  return (
    <>
      {isReorderDataFieldModalOpen && (
        <ReorderDataFieldsModal
          isOpen={isReorderDataFieldModalOpen}
          onClose={handleReorderDataFieldModalClose}
          datafields={datafields}
          locationName={locationName}
          locationDescription={locationDescription}
          type={type}
        />
      )}
      <ModalWidget
        isOpen={isAddFollowup === 'Add Followup' || pendingFollowUp !== null}
        title={
          isAddFollowup === 'Add Followup' ? isAddFollowup : 'Edit Followup'
        }
        children={
          <AddFollowup
            type={
              isAddFollowup === 'Add Followup' ? isAddFollowup : 'Edit Followup'
            }
            dataField={dataField}
            followup={pendingFollowUp}
            onClose={() => {
              setIsAddFollowup('');
              setPendingFollowUp(null);
            }}
          />
        }
        onClose={() => {
          setIsAddFollowup('');
          setPendingFollowUp(null);
        }}
      />
      {editDataField && (
        <ModalWidget
          isOpen={editDataField}
          title="Edit Data Field"
          onClose={toggleEdit}
          children={
            <AddDataField
              isClose={!editDataField}
              dataField={dataField}
              toggleEdit={toggleEdit}
            />
          }
        />
      )}
      <Box
        sx={{
          backgroundColor: '#F8F9FA',
          padding: '16px 40px 16px 45px',
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>
            <Stack display="flex" flexDirection="row" alignItems="center">
              <Typography
                component="span"
                sx={{
                  color: '#646669',
                  fontSize: '12px',
                  fontWeight: '700',
                }}
              >
                {dataField.name}
              </Typography>
              &nbsp;&nbsp;
              {!!dataField.knockoutFollowups.length && (
                <i
                  className={`fa-sharp fa-solid fa-chevron-${
                    showDetail ? 'up' : 'down'
                  }`}
                  onClick={() => setShowDetail(!showDetail)}
                ></i>
              )}
            </Stack>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              {dataField.description || ''}
            </Typography>
          </Box>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              width: '145px',
            }}
          >
            {type === 'department' && <Box sx={{ width: '25px' }}></Box>}
            <HoveredIconConfirm
              icon="fa-regular fa-trash"
              hoverIcon="fa-solid fa-trash"
              onClick={() => handleDelete()}
            />
            <HoveredIcon
              icon="fa-regular fa-pencil"
              hoverIcon="fa-solid fa-pencil"
              onClick={() =>
                type === 'department'
                  ? onEdit(dataField)
                  : setEditDataField(true)
              }
            />
            <HoveredIcon
              icon="fa-regular fa-arrow-up-arrow-down"
              hoverIcon="fa-solid fa-arrow-up-arrow-down"
              onClick={() => setIsReorderDataFieldModalOpen(true)}
            />
            {type !== 'department' && (
              <DropDown
                icon="fa-regular fa-square-plus"
                hoverIcon="fa-solid fa-square-plus"
                items={['Add Followup']}
                onSelect={(e: string) => setIsAddFollowup(e)}
              />
            )}
          </Stack>
        </Stack>
        <Box sx={{ paddingTop: '15px' }}>
          <FormControlLabel
            sx={{ '& > span': { fontSize: '14px' } }}
            control={
              <Checkbox
                name="required"
                onChange={handleDataFieldLocation}
                checked={dataField.dataFieldLocation.required}
              />
            }
            label="Required"
          />
          <FormControlLabel
            sx={{ '& > span': { fontSize: '14px' } }}
            control={
              <Checkbox
                name="readOnly"
                onChange={handleDataFieldLocation}
                checked={dataField.dataFieldLocation.readOnly}
              />
            }
            label="Read Only"
          />
        </Box>
        {showDetail && (
          <>
            <Divider sx={{ marginRight: '-40px' }} />
            {dataField.knockoutFollowups.map(
              (e: KnockoutFollowupExtended, index) => (
                <FollowUp
                  key={index}
                  followUp={
                    dataField.type === DataFieldType.yesNo
                      ? {
                          ...e,
                          value:
                            e.value[0].toUpperCase() + e.value.slice(1) ===
                            'Idk'
                              ? "I don't know"
                              : e.value[0].toUpperCase() + e.value.slice(1),
                        }
                      : e
                  }
                  onEdit={() => setPendingFollowUp(e)}
                />
              )
            )}
          </>
        )}
      </Box>
      <Divider />
    </>
  );
};

export default DataFieldCard;
