import React, { useEffect, useState, useCallback } from 'react';

import {
  DataFieldType,
  DataFieldUpdate,
  DataFieldWithOptionsAndLocations,
  DataFieldWithOptionsAndSingleLocation,
} from '../../shared/types/DataField';
interface MapDataProps {
  dataFields?:
    | DataFieldWithOptionsAndLocations[]
    | DataFieldWithOptionsAndSingleLocation[];
  dataFieldsForDepartment?:
    | DataFieldWithOptionsAndLocations[]
    | DataFieldWithOptionsAndSingleLocation[];
  setDataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  currentDepartmentID?: number;
  currentSubTab?: string;
  knockout?: boolean;
  sepDetails?: boolean;
  sepID?: number;
}

const useMapData = ({
  dataFields,
  dataFieldsForDepartment,
  knockout,
  currentDepartmentID,
  currentSubTab,
}: MapDataProps) => {
  const [data, setData] = useState<DataFieldUpdate[]>([]);
  const [requiredFields, setRequiredFields] = useState<number[]>([]);
  const [buttonValidation, setButtonValidation] = useState(true);
  const [dataChanged, setDataChanged] = useState(false);
  const fields = dataFields || dataFieldsForDepartment;

  function isValidEmail(email: DataFieldType.email) {
    return !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      email
    );
  }

  const checker = useCallback(() => {
    const isRequired = data.filter((item: any) =>
      requiredFields.includes(item.id)
    );

    const fieldValidator = (element: any, i: number) => {
      switch (element.type) {
        case 'yesNo':
        case 'input':
        case 'text':
        case 'textarea':
        case 'date':
        case 'number':
        case 'dateTime':
        case 'toggle':
        case 'checkbox':
        case 'textbox' :
          return element.value === '' || element.value === null;
        case 'email':
          return (
            element.value === '' ||
            element.value === null ||
            isValidEmail(element.value)
          );
        case 'select':
        case 'multiSelect':
        case 'tileSelect':
        case 'tileMultiSelect':
          return (
            (element.dataFieldOptions !== undefined &&
              element.dataFieldOptions === null) ||
            !element.dataFieldOptions.some((e: any) => e.selected)
          );

        default:
          break;
      }
    };

    const validator = isRequired.some(fieldValidator);
    setButtonValidation(validator);
  }, [data, requiredFields]);

  useEffect(() => {
    if (fields !== undefined) {
      const newData: any = [];
      const newRequiredFields: any = [];
      fields.forEach((field: any) => {
        // Finds if a field is required or readOnly depending on Department and access level
        const fieldProperties = knockout
          ? field.dataFieldLocation
          : field.dataFieldLocations?.find(
              (location: any) =>
                location.locationType === currentSubTab &&
                location.locationID === currentDepartmentID
            );

        if (fieldProperties?.required) {
          newRequiredFields.push(field.id);
        }
        switch (field.type) {
          case 'yesNo':
          case 'input':
          case 'text':
          case 'textarea':
          case 'email':
          case 'date':
          case 'number':
          case 'dateTime':
          case 'toggle':
          case 'checkbox':
          case 'textbox' :
            newData.push({
              id: field.id,
              value: field.value,
              type: field.type,
            });
            break;
          case 'select':
          case 'multiSelect':
          case 'tileSelect':
          case 'tileMultiSelect':
            const val = field.dataFieldOptions.find(
              (fieldOptions: any) => fieldOptions.selected === true
            );
            if (val) {
              newData.push({
                id: val.dataFieldID,
                dataFieldOptions: field.dataFieldOptions.map((val: any) => val),
                type: field.type,
              });
            } else {
              newData.push({
                id: field.id,
                dataFieldOptions: null,
                type: field.type,
              });
            }
            break;
          default:
        }
      });
      setData(newData);
      setRequiredFields(newRequiredFields);
    }
  }, [fields, currentSubTab, knockout, currentDepartmentID]);

  useEffect(() => {
    checker();
  }, [data, checker]);

  return {
    data,
    setData,
    buttonValidation,
    isValidEmail,
    dataChanged,
    setDataChanged,
  };
};

export default useMapData;
