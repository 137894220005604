import React, { useState } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import HoveredIcon from '../../../HoveredIconButton';
import HoveredIconConfirm from '../../../HoveredIconConfirmButton';

import {
  TaskTemplate,
  TaskWithTaskDependency,
} from '../../../../../../shared/types/Task';
import {
  useDeleteTaskDependencyMutation,
  useGetTaskTemplatesQuery,
} from '../../../../../services/tasksSlice/tasksSlice';
import ModalWidget from '../../../../ModalWidget/ModalWidget';
import ManageTaskDependency from '../../TaskDependencyModal';

interface TaskDependencyProps {
  taskDependency: TaskWithTaskDependency;
  taskTemplate: TaskTemplate;
  index: number;
  lastElement: number;
  filteredTasks: TaskTemplate[];
}

const TaskDependencyCard = ({
  taskDependency,
  taskTemplate,
  index,
  lastElement,
}: TaskDependencyProps) => {
  const { data: taskTemplates } = useGetTaskTemplatesQuery();
  const [deleteTaskDependency] = useDeleteTaskDependencyMutation();
  const [edit, setEdit] = useState(false);
  const [taskDependenciesArr, setTaskDepenciesArr] = useState<number[]>([]);
  const [selectedTaskDependency, setSelectedTaskDependency] =
    useState(taskDependency);

  const handleDelete = () => {
    deleteTaskDependency(taskDependency.taskDependency);
  };

  const dependentTasksIDs = taskTemplate.parentTasks.map(
    (dependency) => dependency.id
  );

  const Tasks =
    taskTemplates?.filter(
      (task: any) => !taskDependenciesArr.includes(task.id)
    ) || [];

  const handleTaskEdit = (taskDep: any) => {
    const includeCurrentTask = dependentTasksIDs.filter(
      (id) => id !== taskDep.id
    );
    setTaskDepenciesArr(includeCurrentTask);
    setSelectedTaskDependency(taskDep);
    setEdit(true);
  };

  return (
    <>
      {edit && (
        <TaskEditDependencyModal
          edit={edit}
          setEdit={setEdit}
          taskTemplate={taskTemplate}
          taskDependency={taskDependency}
          filteredTasks={Tasks}
          selectedTaskDependency={selectedTaskDependency}
        />
      )}
      <Box>
        <Box
          my={1}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingRight: '40px',
          }}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Stack display="flex">
              <Typography
                component="span"
                sx={{
                  color: '#646669',
                  font: 'normal normal normal 12px/28px Open Sans',
                }}
              >
                {taskDependency?.shortName}
              </Typography>

              <Typography
                component="span"
                sx={{
                  fontSize: '14px',
                  fontWeight: '700',
                }}
                dangerouslySetInnerHTML={{
                  __html: `<div 
              style="
              display: inline-block;
              width:480px;
              white-space: nowrap;
              overflow:hidden;
              line-height:2;
              text-overflow: ellipsis; "> ${
                !taskDependency.name
                  ? ''
                  : taskDependency.name.replace(/<[^>]+>/g, '')
              }</div>`,
                }}
              ></Typography>
            </Stack>
          </Box>
          <Box>
            <Stack
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
              sx={{ width: '180px' }}
            >
              <Typography
                component="span"
                sx={{
                  color: '#646669',
                  font: 'normal normal normal 12px/22px Open Sans',
                }}
              >
                Required Status
              </Typography>
              <Typography sx={{ textTransform: 'capitalize' }}>
                {taskDependency.taskDependency.status === 'inReview'
                  ? 'In Review'
                  : taskDependency.taskDependency.status}
              </Typography>
            </Stack>
          </Box>
          <Box>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              sx={{
                width: '64px',
              }}
            >
              <HoveredIconConfirm
                icon="fa-regular fa-trash"
                hoverIcon="fa-solid fa-trash"
                onClick={() => handleDelete()}
              />
              <HoveredIcon
                icon="fa-regular fa-pencil"
                hoverIcon="fa-solid fa-pencil"
                onClick={() => handleTaskEdit(taskDependency)}
              />
            </Stack>
          </Box>
        </Box>
        {lastElement !== index && <Divider />}
      </Box>
    </>
  );
};

export default TaskDependencyCard;

interface TaskEditDependencyModalProps {
  edit: boolean;
  taskDependency?: TaskWithTaskDependency;
  taskTemplate: TaskTemplate;
  filteredTasks: TaskTemplate[];
  setEdit: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTaskDependency?: TaskWithTaskDependency;
}

const TaskEditDependencyModal = ({
  edit,
  setEdit,
  taskTemplate,
  filteredTasks,
  selectedTaskDependency,
}: TaskEditDependencyModalProps) => {
  const handleEditCancel = () => {
    setEdit(false);
  };

  return (
    <ModalWidget
      expandModal
      isOpen={edit}
      title="Edit Task Dependency"
      onClose={handleEditCancel}
      children={
        <>
          <ManageTaskDependency
            taskTemplate={taskTemplate}
            options={filteredTasks}
            description="Choose a task from the dropdown"
            setEdit={setEdit}
            selectedTaskDependency={selectedTaskDependency}
          />
        </>
      }
    />
  );
};
