import { sepAPI } from '../API';
import {
  UpdateTaskStatusResult,
  UpdateTaskStatusMutationBody,
  TaskSearchResult,
  TaskStatus,
  UpdateMultipleTaskBody,
  UpdateTaskBody,
  Task,
  CreateTaskBody,
  TaskExtended,
  CreateTaskTemplateBody,
  UpdateTaskTemplateBody,
  TaskTemplate,
  TaskDependency,
  CreateTaskDependencyTemplateBody,
  UpdateTaskDependencyTemplateBody,
} from '../../../shared/types/Task';
import { SearchParams } from '../../../shared/types/SEP';
import { setSnackbarForEndpoint } from '../../utils/snackbar';

export const tasksSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    getTasks: builder.query<TaskSearchResult, SearchParams>({
      query: (arg) => {
        const { limit, offset, sortBy, sortAsc, status, assigneeId, search } =
          arg;
        return {
          url: 'tasks',
          params: {
            limit,
            offset,
            sortBy,
            sortAsc,
            status,
            'assignee.id': assigneeId,
            search,
          },
        };
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting the tasks.',
        });
      },
    }),
    getTasksWithSEPID: builder.query<TaskExtended[], number>({
      query: (id) => {
        return {
          url: `sep/${id}/tasks`,
        };
      },
      providesTags: ['Tasks'],
    }),
    getAllTasks: builder.query<TaskSearchResult, void>({
      query: () => {
        return {
          url: 'tasks',
        };
      },
    }),
    updateTaskStatus: builder.mutation<
      UpdateTaskStatusResult,
      UpdateTaskStatusMutationBody
    >({
      query: (arg) => {
        const { status, taskID } = arg;
        return {
          url: `task/${taskID}/status`,
          method: 'PATCH',
          body: {
            status,
          },
        };
      },
      invalidatesTags: ['Tasks', 'TasksForSEP', 'SEPs'],
      onQueryStarted: (arg, api) => {
        let successMessage;
        switch (arg.status) {
          case TaskStatus.todo:
            successMessage = 'Successfully set task status to To-do!';
            break;
          case TaskStatus.inReview:
            successMessage = 'Successfully requested review for this task!';
            break;
          case TaskStatus.changesRequested:
            successMessage = 'Successfully requested changes for this task!';
            break;
          case TaskStatus.complete:
            successMessage = 'Successfully completed this task!';
            break;
          default:
            successMessage = 'Successfully updated the task status!';
        }
        setSnackbarForEndpoint(api, {
          errorMessage: "There was a problem updating this tasks' status!",
          successMessage,
        });
      },
    }),
    updateTasks: builder.mutation<
      any,
      { sepId: number; tasks: UpdateMultipleTaskBody[] }
    >({
      query: ({ sepId, tasks }) => ({
        url: `sep/${sepId}/tasks`,
        method: 'PATCH',
        body: tasks,
      }),
      invalidatesTags: ['Tasks', 'TasksForSEP', 'SEPs'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Tasks assignment updated successfully!',
          errorMessage: 'There was a problem updating tasks assigment.',
        });
      },
    }),
    updateTask: builder.mutation<
      Task,
      { taskID: number; task: UpdateTaskBody }
    >({
      query: ({ taskID, task }) => ({
        url: `task/${taskID}`,
        method: 'PATCH',
        body: task,
      }),
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Task updated successfully!',
          errorMessage: 'There was a problem updating task.',
        });
      },
      invalidatesTags: ['TasksForSEP', 'Tasks'],
    }),
    createTask: builder.mutation<TaskExtended, CreateTaskBody>({
      query: (body) => ({
        url: `task`,
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['Tasks', 'TasksForSEP', 'SEPs'],
      onQueryStarted: (body, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Task created successfully!',
          errorMessage: 'There was a problem creating this task.',
        });
      },
    }),
    getTaskTemplates: builder.query<TaskTemplate[], void>({
      query: () => 'task-templates',
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({
                type: 'TaskTemplate' as const,
                id,
              })),
              'TaskTemplate',
            ]
          : ['TaskTemplate'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting this SEP.',
        });
      },
    }),
    createTaskTemplate: builder.mutation<Task, CreateTaskTemplateBody>({
      query: (body) => ({
        url: 'task-templates',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: ['TaskTemplate'],
      onQueryStarted: (body, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Task created successfully!',
          errorMessage: 'There was a problem creating this task.',
        });
      },
    }),
    deleteTaskTemplate: builder.mutation<void, number>({
      query: (id) => ({
        url: `task-template/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'TaskTemplate', id: arg },
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Task deleted successfully!',
          errorMessage: 'There was a problem deleting this task.',
        });
      },
    }),
    updateTaskTemplate: builder.mutation<
      Task,
      { id: number; body: UpdateTaskTemplateBody }
    >({
      query: ({ id, body }) => ({
        url: `task-template/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'TaskTemplate', id: arg.id },
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Task updated successfully!',
          errorMessage: 'There was a problem updating task.',
        });
      },
    }),
    addTaskDependency: builder.mutation<
      TaskDependency,
      CreateTaskDependencyTemplateBody
    >({
      query: (body) => ({
        url: 'task-dependency-templates',
        method: 'POST',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'TaskTemplate', id: arg.taskID },
      ],
      onQueryStarted: (body, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Task created successfully!',
          errorMessage: 'There was a problem creating this task.',
        });
      },
    }),
    updateTaskDependency: builder.mutation<
      TaskDependency,
      { id: number; body: UpdateTaskDependencyTemplateBody }
    >({
      query: ({ id, body }) => ({
        url: `task-dependency-template/${id}`,
        method: 'PATCH',
        body: body,
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'TaskTemplate', id: arg.body.taskID },
      ],
      onQueryStarted: (body, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Task edited successfully!',
          errorMessage: 'There was a problem editing this task.',
        });
      },
    }),
    deleteTaskDependency: builder.mutation<
      void,
      { taskID: number; dependentTaskID: number; id: number }
    >({
      query: ({ id, taskID }) => ({
        url: `task-dependency-template/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (result, error, arg) => [
        { type: 'TaskTemplate', id: arg.taskID },
      ],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Task dependency deleted successfully!',
          errorMessage: 'There was a problem deleting this task dependency.',
        });
      },
    }),
    exportTasks: builder.query<any, void>({
      query: () => ({
        url: 'tasks/export',
        cache: 'no-cache',
        headers: {
          'content-type': 'text/csv',
        },
        responseHandler: async (response: any) => {
          return window.URL.createObjectURL(await response.blob());
        },
      }),
      onQueryStarted: (body, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Task csv file generated successfully!',
          errorMessage: 'There was a problem generating tasks csv file.',
        });
      },
    }),
  }),
});

export const {
  useGetTasksQuery,
  useGetTasksWithSEPIDQuery,
  useUpdateTaskStatusMutation,
  useGetAllTasksQuery,
  useUpdateTasksMutation,
  useUpdateTaskMutation,
  useCreateTaskMutation,
  useGetTaskTemplatesQuery,
  useCreateTaskTemplateMutation,
  useDeleteTaskTemplateMutation,
  useAddTaskDependencyMutation,
  useDeleteTaskDependencyMutation,
  useUpdateTaskTemplateMutation,
  useUpdateTaskDependencyMutation,
  useLazyExportTasksQuery,
} = tasksSlice;
