import React from 'react';
import { styled } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import {
  ReadableTaskStatus,
  Task,
  TaskExtended,
} from '../../../shared/types/Task';

export interface StatusTextProps {
  task: TaskExtended;
}

interface componentType {
  [key: string]: JSX.Element;
}

const StatusText = ({ task }: StatusTextProps) => {
  const StyledLink = styled(Link)(({ theme }) => ({
    textDecoration: 'none',
    fontSize: 20,
    color: theme.palette.gray.main,
    '&:hover': {
      color: theme.palette.solidBlue.main,
    },
  }));
  const componentText: componentType = {
    todo: task.review ? (
      <>
        This task requires a review.
        <br />
        Have you uploaded an attachment that needs review? Click Request Review.
        <br />
        Otherwise you can come back later!
      </>
    ) : (
      <>
        Where are you at with this task?
        <br />
        If you’re finished with this task click Complete.
        <br />
        Otherwise you can come back later!
      </>
    ),
    inReview: (
      <>
        This task needs another set of eyes.
        <br />
        Do you need any changes made? Click Request Changes.
        <br />
        Everything look good? Click Complete.
        <br />
        Otherwise you can come back later!
      </>
    ),
    changesRequested: (
      <>
        This task needs a change made before it can be completed.
        <br />
        What's the change? Click View the Change Request.
        <br />
        Have all changes been made? Click Request Review.
        <br />
        Otherwise you can come back later!
      </>
    ),
    complete: (
      <>
        Congratulations! This task is complete!
        <br />
        {task.locked ? (
          <>
            The {task.phase} phase for this SEP has been completed! This task
            has been <b>locked</b>.
          </>
        ) : (
          <>
            Hit Complete by Accident? You can revert to a previous status below!
          </>
        )}
      </>
    ),
    pending: (
      <>
        {task.parentTasks.length > 0 ? (
          task.parentTasks
            .filter((t) => t.enabled)
            .map((parentTask: Task, key: number) => (
              <div key={key} className="normal-text">
                This task is dependent on{' '}
                <span className="blue-text">
                  <StyledLink
                    className="blue-text"
                    to={`/sep/${parentTask.sepID}/task/${parentTask.id}`}
                    sx={{ fontSize: 12 }}
                  >
                    {parentTask.shortName} &nbsp;
                  </StyledLink>
                </span>
                which is currently in{' '}
                <b>{ReadableTaskStatus[parentTask.status]}</b> status.
              </div>
            ))
        ) : (
          <></>
        )}
      </>
    ),
  };
  return <>{componentText[task.status]}</>;
};

export default StatusText;
