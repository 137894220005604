import React, { useState, useEffect } from 'react';
import {
  Box,
  InputLabel,
  TextField,
  Button,
  Divider,
  Typography,
} from '@mui/material';
import {
  CreateDepartmentBody,
  DepartmentExtended,
} from '../../../../../shared/types/Department';
import { UserSearch } from '../../KnockoutScreens/Followup/FollowupTypeDropDown';
import {
  useCreateDepartmentMutation,
  useEditDepartmentMutation,
} from '../../../../services/departmentSlice/departmentSlice';

const textFieldSx = {
  color: '#222222',
  '& > p': {
    margin: '5px 0px',
    color: '#646669',
    fontSize: '11px',
  },
};

const AddDepartment = ({
  onClose,
  value,
}: {
  onClose: () => void;
  value: DepartmentExtended | undefined;
}) => {
  const [originValue, setOriginValue] = useState<CreateDepartmentBody>({
    name: '',
    adAppRole: '',
    contactID: '',
  });

  useEffect(() => {
    setOriginValue({
      name: value ? value.name : '',
      adAppRole: value ? value.adAppRole : '',
      contactID: value
        ? value.contact
          ? value.contact.displayName || ''
          : ''
        : '',
    });
  }, [value]);

  const clearForm = () => {
    setOriginValue({
      name: '',
      adAppRole: '',
      contactID: '',
    });
  };

  const [createDepartment] = useCreateDepartmentMutation();
  const [editDepartment] = useEditDepartmentMutation();
  const onUserChange = (user: string) => {
    setOriginValue((prevState) => ({ ...prevState, contactID: user }));
  };

  const handleAddDepartment = () => {
    createDepartment(originValue).then((_) => clearForm());
    onClose();
  };

  const handleEditDepartment = () => {
    editDepartment({
      data: originValue,
      id: value?.id || 0,
    }).then((_) => clearForm());
    onClose();
  };

  const handleDepartmentNameChange: React.ChangeEventHandler<
    HTMLInputElement
  > = (e) => {
    setOriginValue((prevState) => ({ ...prevState, name: e.target.value }));
  };

  const handleAzureRoleChange: React.ChangeEventHandler<HTMLInputElement> = (
    e
  ) => {
    setOriginValue((prevState) => ({
      ...prevState,
      adAppRole: e.target.value,
    }));
  };

  const handleCancelClicked = () => {
    clearForm();
    onClose();
  };

  const isAddDepartment = value === undefined;
  const handleAcceptChangesClicked = isAddDepartment
    ? handleAddDepartment
    : handleEditDepartment;
  const isFormInvalid = originValue.name === '' || originValue.adAppRole === '';
  const disabled = isAddDepartment
    ? isFormInvalid
    : isFormInvalid ||
      (originValue.name === value.name &&
        originValue.adAppRole === value.adAppRole &&
        originValue.contactID === value.contactID);
  const acceptChangesText = isAddDepartment
    ? 'Add Department'
    : 'Edit Department';

  return (
    <>
      <Box sx={{ m: 3 }}>
        <Box sx={{ mb: 3 }}>
          <InputLabel sx={{ color: '#222222' }} htmlFor="department-name">
            Department Name
          </InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="department-name"
            name="name"
            fullWidth
            value={originValue.name}
            placeholder="Ex. Department Architecture"
            onChange={handleDepartmentNameChange}
          />
        </Box>
        <Box sx={{ mb: 3 }}>
          <InputLabel sx={{ color: '#222222' }} htmlFor="azure-ad-role">
            Azure AD Role
          </InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="azure-ad-role"
            name="name"
            fullWidth
            value={originValue.adAppRole}
            placeholder="Ex. AuthEnterpriseArchitecture"
            onChange={handleAzureRoleChange}
          />
        </Box>
        <Box>
          <Box sx={{ color: '#222222' }}>
            Default Stakeholder for Department
          </Box>
          <UserSearch
            adAppRole=""
            disabled={false}
            onChange={onUserChange}
            existDefault={true}
            user={value?.contact?.displayName}
          />
        </Box>
      </Box>
      <Divider sx={{ backgroundColor: '#7E8083' }} />

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          p: 2,
          '& > button': {
            m: 1,
          },
        }}
      >
        <Button
          variant="text"
          disabled={false}
          onClick={handleCancelClicked}
          sx={{ textTransform: 'none' }}
        >
          <Typography
            sx={{
              font: 'normal normal 600 16px/22px Open Sans',
              color: '#2372B9',
            }}
          >
            Cancel
          </Typography>
        </Button>
        <Button
          variant="contained"
          onClick={handleAcceptChangesClicked}
          sx={{
            textTransform: 'none',
            backgroundColor: '#2372B9',
            font: 'normal normal 600 16px/22px Open Sans',
          }}
          disabled={disabled}
        >
          {acceptChangesText}
        </Button>
      </Box>
    </>
  );
};

export default AddDepartment;
