import {
  Configuration,
  PublicClientApplication,
  SilentRequest,
} from '@azure/msal-browser';
import { env } from '../../shared/env';

const msalConfig: Configuration = {
  auth: {
    clientId: env.REACT_APP_CLIENT_ID as string,
    authority: `https://login.microsoftonline.com/${env.REACT_APP_TENANT_ID}`,
    redirectUri: env.REACT_APP_REDIRECT_URI as string,
  },
};
const pca = new PublicClientApplication(msalConfig);

/** This defines the scopes requested from Azure AD */
export const authRequest: SilentRequest = {
  scopes: [
    'openid',
    'profile',
    'User.Read',
    'User.ReadBasic.All',
    'Application.Read.All',
  ],
};

export default pca;
