import React, { useCallback } from 'react';
import CustomSelect from '../../components/Select';
import { Box, Typography, useTheme, Chip } from '@mui/material';
import { DataFieldOption } from '../../../shared/types/DataField';

const Select = ({
  field,
  data,
  setData,
  setDataChanged,
  DataFields,
  setDataFields,
  multiple,
  index,
  knockout,
  readOnly,
  required,
}: any) => {
  const theme = useTheme();

  const findLabel = useCallback((fields: any[], itemId: number) => {
    const label = fields.find(({ id, value }) => {
      return id === itemId;
    });
    return label.value;
  }, []);

  //filter object properties
  const filterFieldProperties = (datafield: DataFieldOption) => {
    const { id, value, selected } = datafield;
    return { id, value, selected };
  };

  const handleSelectData = useCallback(
    (fieldId: number, fieldOptionId: number, newArray: any) => {
      const newData: any = [...data];
      if (newData.length > 0) {
        const isPresent = newData.findIndex(
          (field: any) => field.id === fieldId
        );
        let newState = [];
        if (isPresent === -1) {
          newData.push({
            id: fieldId,
            dataFieldOptions: newArray,
          });
          setData(newData);
        } else {
          newState = data.map((dataFieldValue: any) => {
            return dataFieldValue.id === fieldId
              ? {
                  ...dataFieldValue,
                  dataFieldOptions: newArray,
                }
              : dataFieldValue;
          });
          setData(newState);
        }
      } else {
        newData.push({
          id: fieldId,
          dataFieldOptions: newArray,
        });
        setData(newData);
      }
    },
    [data, setData]
  );

  const selectValue = useCallback(
    (fieldId: number) => {
      const res = data.find((option: any) => option.id === fieldId);
      if (res?.dataFieldOptions) {
        const selectedOption = res.dataFieldOptions.find(
          (field: any) => field.selected === true
        );
        return selectedOption?.id;
      } else {
        return 0;
      }
    },
    [data]
  );

  const selectMultipleValue = useCallback(
    (fieldId: number) => {
      const res = data.find((option: any) => option.id === fieldId);
      return (
        res?.dataFieldOptions
          ?.filter((o: any) => o.selected)
          ?.map((o: any) => o.id) || []
      );
    },
    [data]
  );

  // Select CHANGE EVENT
  const handleSelect = useCallback(
    (e: any, index: number, fieldId: number) => {
      const { value } = e.target;
      if (setDataChanged !== undefined) setDataChanged(true);
      const newDataFields = [...DataFields];
      const newOptions = [...newDataFields[index].dataFieldOptions];
      const testDataField = newOptions.map(filterFieldProperties);
      const newData = testDataField.map((option) =>
        option.id === value
          ? { ...option, selected: true }
          : { ...option, selected: false }
      );
      const nDF = { ...newDataFields[index], dataFieldOptions: newData };
      newDataFields[index] = nDF;
      setDataFields(newDataFields);
      handleSelectData(fieldId, value, newData);
    },
    [DataFields, setDataFields, handleSelectData, setDataChanged]
  );

  const handleSelectMultiple = useCallback(
    (e: any, index: number, fieldId: number) => {
      const { value } = e.target;

      if (setDataChanged !== undefined) setDataChanged(true);
      const newDataFields: any[] = [...DataFields];
      const newOptions = [...newDataFields[index].dataFieldOptions];
      const testDataField = newOptions.map(filterFieldProperties);
      const newData = testDataField.map((option) =>
        value.includes(option.id)
          ? { ...option, selected: true }
          : { ...option, selected: false }
      );
      const nDF = { ...newDataFields[index], dataFieldOptions: newData };
      newDataFields[index] = nDF;
      setDataFields(newDataFields);
      handleSelectData(fieldId, value, newData);
    },
    [DataFields, setDataFields, handleSelectData, setDataChanged]
  );

  return (
    <Box key={field.id}>
      <Typography my={2} className={required ? 'required' : ''}>
        {field.name}
      </Typography>
      {multiple ? (
        <CustomSelect
          multiple
          value={selectMultipleValue(field.id)}
          renderValue={(selected: number[]) => {
            return (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((val: any) => {
                  return (
                    <Chip
                      sx={{
                        border: 1,
                        borderColor: theme.palette.grey[400],
                        borderRadius: 1,
                      }}
                      key={val}
                      label={findLabel(field.dataFieldOptions, val)}
                    />
                  );
                })}
              </Box>
            );
          }}
          onChange={(e: any) => handleSelectMultiple(e, index, field.id)}
          dataFieldOptions={field.dataFieldOptions}
          readOnly={readOnly || undefined}
        />
      ) : (
        <CustomSelect
          onChange={(e: any) => handleSelect(e, index, field.id)}
          value={selectValue(field.id)}
          dataFieldOptions={field.dataFieldOptions}
          readOnly={readOnly || undefined}
        />
      )}
    </Box>
  );
};

export default Select;
