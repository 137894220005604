//TODO:  include test

import React, { useState, useEffect, useCallback, useRef } from 'react';
import {
  Box,
  Checkbox,
  Chip,
  Divider,
  FormControlLabel,
  Switch,
  Typography,
  useTheme,
  Autocomplete,
  TextField as TextFieldForLookup,
  FormLabel,
  FormHelperText
} from '@mui/material';
import YesNo from '../../components/YesNo';
import {
  DataFieldType,
  DataFieldUpdate,
  DataFieldWithOptionsAndLocations,
  DataFieldWithOptionsAndSingleLocation,
} from '../../../shared/types/DataField';
import TextField from '../../components/TextField';
import { useSearchUsersQuery } from '../../services/usersSlice';
import { User } from '../../../shared/types/User';
import { debounce } from 'lodash';
import RichTextEditor from '../../components/RichTextEditor/RichTextEditor';

//Datafield Components
import { Select, TileSelect, ApmSearchComponent } from '../../components/DataFields';
interface DataFormProps {
  dataFields:
    | DataFieldWithOptionsAndSingleLocation[]
    | DataFieldWithOptionsAndLocations[];
  data: DataFieldUpdate[];
  setData: React.Dispatch<React.SetStateAction<DataFieldUpdate[]>>;
  locationID?: number;
  knockout?: boolean;
  setDataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  sepDetails?: boolean;
  currentDepartmentID?: number;
  currentSubTab?: string;
  sepID?:number;
  setApmId?:React.Dispatch<React.SetStateAction<number>>;
  currentScreen?: any;
}

function isValidEmail(email: DataFieldType.email) {
  return !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email
  );
}

const DataForm: React.FC<DataFormProps> = ({
  dataFields,
  data,
  setData,
  knockout,
  sepDetails,
  currentDepartmentID,
  currentSubTab,
  setDataChanged,
  sepID,
  setApmId,
  currentScreen
}) => {
  const [DataFields, setDataFields] = useState<any[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [userOptions, setUserOptions] = useState<User[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const { data: searchResults = [], isLoading, isError, error } = useSearchUsersQuery(
    {
      search: searchTerm,
      
    },
    { skip: searchTerm.trim() === '' }
  ); 

  const debouncedSearch = useRef<ReturnType<typeof debounce>>(debounce((term: string) => setSearchTerm(term), 100));
  useEffect(() => {
    debouncedSearch.current(searchTerm);
    return () => debouncedSearch.current.cancel();
  }, [searchTerm]);
  useEffect(() => {
    if (!isLoading && !isError) {
      setUserOptions((prevOptions) => {
        return JSON.stringify(prevOptions) !== JSON.stringify(searchResults) ? searchResults : prevOptions;
      });
    }
  }, [searchResults, isLoading, isError]);

  const [desVal, setDesVal] = useState('');
  
  //YesNo CHANGE EVENT
  const handleChange = useCallback(
    (e: any, fieldId: number) => {
      if (setDataChanged !== undefined) setDataChanged(true);
      const { value } = e.target;
      let newData: any = [...data];

      const index = newData.findIndex((object: any) => object.id === fieldId);

      if (newData.length > 0) {
        if (index === -1) {
          newData.push({ id: fieldId, value: value });
        } else {
          newData = data.map((dataFieldValue: any) =>
            dataFieldValue.id === fieldId
              ? { ...dataFieldValue, value: value }
              : dataFieldValue
          );
        }
        setData(newData);
      } else {
        newData.push({ id: fieldId, value: value });
        setData(newData);
      }
    },
    [data, setData, setDataChanged]
  );
  const handleChangeEmailLookup = useCallback(
    (value: any, fieldId: number) => {
      if (setDataChanged !== undefined) setDataChanged(true);
      
      let newData: any = [...data];

      const index = newData.findIndex((object: any) => object.id === fieldId);

      if (newData.length > 0) {
        if (index === -1) {
          newData.push({ id: fieldId, value: value });
        } else {
          newData = data.map((dataFieldValue: any) =>
            dataFieldValue.id === fieldId
              ? { ...dataFieldValue, value: value }
              : dataFieldValue
          );
        }
        setData(newData);
      } else {
        newData.push({ id: fieldId, value: value });
        setData(newData);
      }
    },
    [data, setData, setDataChanged]
  );
  const handleBoolValue = useCallback(
    (e: any, fieldId: number) => {
      if (setDataChanged !== undefined) setDataChanged(true);
      const { checked } = e.target;
      let newData: any = [...data];

      const index = newData.findIndex((object: any) => object.id === fieldId);

      if (newData.length > 0) {
        if (index === -1) {
          newData.push({ id: fieldId, value: checked });
        } else {
          newData = data.map((dataFieldValue: any) =>
            dataFieldValue.id === fieldId
              ? { ...dataFieldValue, value: checked }
              : dataFieldValue
          );
        }
        setData(newData);
      } else {
        newData.push({ id: fieldId, value: checked });
        setData(newData);
      }
    },
    [data, setData, setDataChanged]
  );

const handleRichTextChange = (val: any, fieldId: any) => {
  setDesVal(val)
  let newData: any = [...data];

      const index = newData.findIndex((object: any) => object.id === fieldId);

      if (newData.length > 0) {
        if (index === -1) {
          newData.push({ id: fieldId, value: val });
        } else {
          newData = data.map((dataFieldValue: any) =>
            dataFieldValue.id === fieldId
              ? { ...dataFieldValue, value: val }
              : dataFieldValue
          );
        }
        setData(newData);
      } else {
        newData.push({ id: fieldId, value: val });
        setData(newData);
      }
    
}
  //YesNo VALUE
  const handleValue = useCallback(
    (id: number) => {
      if (data.length > 0) {
        const item: any = data.find((val: any) => val.id === id);
        if (item && item.hasOwnProperty('value')) return item.value;
        return null;
      }
      return null;
    },
    [data]
  );

  const handleEmailLookupValue = useCallback(
    (id: number) => {
      if (DataFields?.length > 0) {
        const item: any = DataFields.find((val: any) => val.id === id);
        if (item && item.hasOwnProperty('value')) return item.value;
        return null;
      }
      return null;
    },
    [DataFields]
  );
  const handleApmIdChange = useCallback(
    (apm: number) => {
      
      if (setApmId){
        if (setDataChanged !== undefined) setDataChanged(true);
      const apmId  = apm? apm : 0
      setApmId(apmId)
      }
    },
    [setDataChanged]
  );

  useEffect(() => {
    setDataFields(dataFields);
  }, [data, dataFields]);

  return (
    <Box>
      <SepDetails sepDetails={sepDetails} dataFields={dataFields} />
      {/* {dataFields?.length === 0 && (<span></span>
        // <Typography>
        //   No data fields have been applied to this section.
        // </Typography>
      )} */}

      {!sepDetails &&
        dataFields?.map((field: any, index) => {
          // Finds if a field is required or readOnly depending on Department and access level
          const fieldProperties = knockout
            ? field.dataFieldLocation
            : field.dataFieldLocations?.find(
                (location: any) =>
                  location.locationType === currentSubTab &&
                  location.locationID === currentDepartmentID
              );

          switch (field.type) {
            case 'yesNo':
              return (
                <YesNo
                  field={field}
                  value={handleValue(field.id)}
                  onChange={(e: any) => handleChange(e, field.id)}
                  readOnly={fieldProperties?.readOnly}
                  required={fieldProperties?.required}
                  key={field.id}
                />
              );
            case 'emailLookup':
              return (
                <React.Fragment>
                  <div style={{width:'400px',paddingLeft:'10px'}}>
                  <FormLabel className={fieldProperties?.required ? 'required' : ''} >{field.name}</FormLabel>
                  <Autocomplete style={{paddingTop:'15px'}}
                    options={userOptions}
                    getOptionLabel={(user) => user.displayName || user.email || ''}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    onChange={(e, value) => {
                      
                      handleChangeEmailLookup(value?.email, field.id)
                      if (value?.email) {
                        setErrorMessage('');
                      } else {
                        setErrorMessage('Enter a valid user ID');
                      }
                    }}
                    inputValue={searchTerm}
                    onInputChange={(_, newInputValue) => debouncedSearch.current(newInputValue)}
                    renderInput={(params) => (
                      <TextFieldForLookup
                        {...params}
                        label= {handleEmailLookupValue(field.id)? handleEmailLookupValue(field.id) :"Search and Select Users"}
                        sx={{
                          borderColor: searchTerm.trim() === '' ? 'red' : 'default',
                          '& input': {
                            fontSize: '14px',
                            cursor: 'pointer',
                            color: searchTerm.trim() === '' ? 'red' : 'default',
                          },
                        }}
                      />
                    )}
                  />
                 
                  </div>
                </React.Fragment>
              );
            case 'apmLookup':
              return (
                <ApmSearchComponent sepID={sepID} handleApmIdChange={handleApmIdChange}/>
              );
            case 'input':
            case 'textarea':
            case 'date':
            case 'number':
            case 'dateTime':
              return (
                <TextField
                  key={field.id}
                  field={field}
                  placeholder={!currentScreen?.name?.includes('Name and Description') ? field.description || field.name : ''}
                  type={
                    field.type === 'input' || field.type === 'textarea'
                      ? 'text'
                      : field.type === 'dateTime'
                      ? 'datetime-local'
                      : field.type
                  }
                  multiline={field.type === 'textarea'}
                  onChange={(e: any) => handleChange(e, field.id)}
                  value={handleValue(field.id) || ''}
                  sx={{
                    marginLeft: 1,
                    width: { md: field.type === 'input' && currentScreen?.name?.includes('Name and Description')  ? 1200 : 300 },
                    mr: 1,
                  }}
                  required={fieldProperties?.required}
                  readOnly={fieldProperties?.readOnly}
                  caption ={field.type === 'input' && currentScreen?.name?.includes('Name and Description') ? field.description : ''}
                />
              );
              case 'textbox' :
                return(
                  <>
                  <Typography my={2}>
                  {field?.name}
                  </Typography>
                   <RichTextEditor 
                     value={desVal} 
                     setValue={(value: any) => handleRichTextChange(value, field?.id)} 
                     style={{ height: '250px', width: '1200px' }}
                     onChangeEvent={true}
                    />
                  <FormHelperText>{field?.description}</FormHelperText>
                 </>
                );
              
            case 'email':
              const value = handleValue(field.id);
              const validation =
                value !== '' && value !== null && isValidEmail(value); //&& isValidEmail(handleValue(field.id))
              return (
                <TextField
                  key={field.id}
                  field={field}
                  type="email"
                  error={validation}
                  placeholder={field.description || field.name}
                  onChange={(e: any) => handleChange(e, field.id)}
                  value={value || ''}
                  sx={{
                    marginLeft: 1,
                    width: 300,
                    mr: 1,
                  }}
                  required={fieldProperties?.required}
                  readOnly={fieldProperties?.readOnly}
                  helperText={validation && 'Not a valid email'}
                />
              );

            case 'select':
              return (
                <Select
                  field={field}
                  index={index}
                  key={field.id}
                  data={data}
                  DataFields={DataFields}
                  setDataFields={setDataFields}
                  setData={setData}
                  setDataChanged={setDataChanged}
                  readOnly={fieldProperties?.readOnly}
                  required={fieldProperties?.required}
                />
              );

            case 'multiSelect':
              return (
                <Select
                  field={field}
                  index={index}
                  DataFields={DataFields}
                  setDataChanged={setDataChanged}
                  setDataFields={setDataFields}
                  setData={setData}
                  multiple={true}
                  key={field.id}
                  data={data}
                  readOnly={fieldProperties?.readOnly}
                  required={fieldProperties?.required}
                />
              );
            case 'tileSelect':
              return knockout ? (
                <TileSelect
                  field={field}
                  index={index}
                  key={field.id}
                  data={data}
                  DataFields={DataFields}
                  setDataFields={setDataFields}
                  setData={setData}
                  setDataChanged={setDataChanged}
                />
              ) : (
                <Select
                  field={field}
                  index={index}
                  key={field.id}
                  data={data}
                  DataFields={DataFields}
                  setDataFields={setDataFields}
                  setData={setData}
                  setDataChanged={setDataChanged}
                  readOnly={fieldProperties?.readOnly}
                  required={fieldProperties?.required}
                />
              );
            case 'tileMultiSelect':
              return knockout ? (
                <TileSelect
                  field={field}
                  index={index}
                  key={field.id}
                  data={data}
                  DataFields={DataFields}
                  setDataFields={setDataFields}
                  setData={setData}
                  setDataChanged={setDataChanged}
                  multiple
                  readOnly={fieldProperties?.readOnly}
                  required={fieldProperties?.required}
                />
              ) : (
                <Select
                  field={field}
                  index={index}
                  DataFields={DataFields}
                  setDataChanged={setDataChanged}
                  setDataFields={setDataFields}
                  setData={setData}
                  multiple={true}
                  key={field.id}
                  data={data}
                  readOnly={fieldProperties?.readOnly}
                  required={fieldProperties?.required}
                />
              );
            case 'toggle':
              return (
                <Box my={2} key={field.id}>
                  <FormControlLabel
                    className={fieldProperties?.required ? 'required' : ''}
                    control={
                      <Switch
                        disabled={fieldProperties?.readOnly}
                        checked={handleValue(field.id) || false}
                        onChange={(e: any) => handleBoolValue(e, field.id)}
                      />
                    }
                    label={field.name}
                  />
                </Box>
              );
            case 'checkbox':
              return (
                <Box my={2} key={field.id}>
                  <FormControlLabel
                    className={fieldProperties?.required ? 'required' : ''}
                    control={
                      <Checkbox
                        disabled={fieldProperties?.readOnly}
                        checked={handleValue(field.id) || false}
                        onChange={(e: any) => handleBoolValue(e, field.id)}
                      />
                    }
                    label={field.name}
                  />
                </Box>
              );
            default:
              return <p>Under construction</p>;
          }
        })}
    </Box>
  );
};

export default DataForm;

const SepDetails = ({ sepDetails, dataFields }: any) => {
  const theme = useTheme();
  const selectValue = (array: any) => {
    const option = array.filter((o: any) => o.selected);

    return !option.length ? (
      <p className="sep-data">{'No current answer'}</p>
    ) : (
      option.map((e: any) => (
        <Chip
          key={e.id}
          sx={{
            border: 1,
            borderColor: theme.palette.grey[400],
            borderRadius: 1,
            marginLeft: 1,
          }}
          label={e.value}
        />
      ))
    );
  };

  return (
    <>
      {sepDetails && dataFields &&
        dataFields?.map((field: any) => {
          switch (field.type) {
            case 'yesNo':
            case 'textarea':
            case 'input':
            case 'date':
            case 'number':
            case 'email':
            case 'dateTime':
              return (
                <div key={field.id}>
                  <Box>
                    <p className="sep-label" style={{ marginTop: '10px' }}>
                      {field.name}
                    </p>
                    <p className="sep-data">
                      {field.value || 'No current answer'}
                    </p>
                  </Box>
                  <br />
                  <Divider />
                </div>
              );
            case 'select':
            case 'multiSelect':
            case 'tileSelect':
            case 'tileMultiSelect':
              return (
                <div key={field.id}>
                  <Box>
                    <p className="sep-label" style={{ marginTop: '10px' }}>
                      {field.name}
                    </p>
                    {selectValue(field.dataFieldOptions)}
                  </Box>
                  <br />
                  <Divider />
                </div>
              );
            case 'toggle':
              return (
                <Box my={2} key={field.id}>
                  <FormControlLabel
                    control={
                      <Switch disabled={true} checked={field.value || false} />
                    }
                    label={field.name}
                  />
                </Box>
              );
            case 'checkbox':
              return (
                <Box my={2} key={field.id}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        disabled={true}
                        checked={field.value || false}
                      />
                    }
                    label={field.name}
                  />
                </Box>
              );
            default:
              return <p>Under construction</p>;
          }
        })}
    </>
  );
};
