import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { SEPSearchRow } from '../../../shared/types/SEP';
import { useGetSepStakeholderQuery } from '../../services/defaultStakeholderSlice';
import { DefaultStakeholderExtended } from '../../../shared/types/DefaultStakeholder';
import { TaskExtended } from '../../../shared/types/Task';
const SepContacts = ({
  sep,
  tasks,
}: {
  sep: SEPSearchRow;
  tasks: TaskExtended[];
}) => {
  const { data } = useGetSepStakeholderQuery(sep.id);
  return (
    <>
      <Box sx={{ pl: '24px', pr: '32px', py: '8px' }}>
        <Typography sx={{ font: 'normal normal 600 18px/24px Open Sans;' }}>
          SEP Contacts:
        </Typography>
        <Grid container spacing={0}>
          <Grid
            container
            item
            sx={{ display: 'flex', flexWrap: 'wrap', width: '1300px' }}
          >
            {data &&
              data
                .filter(
                  (e) =>
                    e.user !== null &&
                    tasks.some(
                      (task) =>
                        task.enabled && task.departmentID === e.departmentID
                    )
                )
                .map(
                  (stakeHolder: DefaultStakeholderExtended, index: number) => (
                    <Grid
                      item
                      key={index}
                      sx={{ marginRight: 5, maxWidth: 300 }}
                    >
                      <p className="sep-label">{stakeHolder.department.name}</p>
                      <p className="sep-data">
                        {stakeHolder?.user?.givenName &&
                        stakeHolder?.user?.surname
                          ? `${stakeHolder?.user?.givenName} ${stakeHolder?.user?.surname}`
                          : stakeHolder?.user?.displayName || ''}
                      </p>
                    </Grid>
                  )
                )}
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SepContacts;
