import React, { useState } from 'react';
import {
  useDeleteAttachmentMutation,
  attachmentSlice,
} from '../../services/attachmentSlice/attachmentSlice';
import './styles.css';
import { Box, Divider, Stack, Typography, Snackbar } from '@mui/material';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import {
  Attachment,
  AttachmentExtended,
} from '../../../shared/types/Attachment';
import ModalWidget from '../ModalWidget/ModalWidget';
import ConfirmDialog from '../ConfirmDialog';
import AddAttachmentFile from './AddAttachmentFile';
import ErrorIcon from '@mui/icons-material/Error';
import { useAppDispatch } from '../../app/hooks';
import { format, parseISO } from 'date-fns';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    arrow
    classes={{ popper: className }}
    PopperProps={{
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, -12],
          },
        },
      ],
    }}
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: '#646669',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#646669',
    color: '#FBFCFC',
    boxShadow: theme.shadows[1],
    fontSize: 14,
    whiteSpace: 'pre-line',
  },
}));

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
interface AttachmentUploadProps {
  attachments: AttachmentExtended[];
  sepID: number;
  taskID?: number;
}

const thumbnails: { [key: string]: { type: string; className: string } } = {
  jpg: { type: 'IMAGE', className: 'fa-solid fa-image' },
  jpeg: { type: 'IMAGE', className: 'fa-solid fa-image' },
  gif: { type: 'IMAGE', className: 'fa-solid fa-image' },
  png: { type: 'IMAGE', className: 'fa-solid fa-image' },
  jfif: { type: 'IMAGE', className: 'fa-solid fa-image' },
  pdf: { type: 'PDF', className: 'fa-sharp fa-solid fa-file-pdf' },
  txt: { type: 'TEXT', className: 'fa fa-file-text' },
  docx: { type: 'WORD', className: 'fa-solid fa-file-word' },
  xlsx: { type: 'EXCEL', className: 'fa-solid fa-file-excel' },
  xls: { type: 'EXCEL', className: 'fa-solid fa-file-excel' },
  vsd: { type: 'VISIO', className: 'fa-file-powerpoint-o' },
  vsdx: { type: 'VISIO', className: 'fa-file-powerpoint-o' },
  pptx: { type: 'PRESENTATION', className: 'fa-file-powerpoint-o' },
  url: { type: 'URL', className: 'fas fa-globe' },
  default: { type: 'OTHER', className: 'fa-solid fa-file' },
};

interface AlertType {
  open: boolean;
  msgType: 'error' | 'info' | 'success' | 'warning';
  msg: string;
}

const initialAlertType: AlertType = { open: false, msgType: 'info', msg: '' };

const AttachmentUpload = ({
  attachments,
  sepID,
  taskID,
}: AttachmentUploadProps) => {
  const [deleteAttachmentMutation] = useDeleteAttachmentMutation();
  const [modal, setModal] = useState<'file' | 'url' | null>(null);
  const [alert, setAlert] = useState<AlertType>(initialAlertType);

  const handleAttachmentAdded = (item: Attachment) => {
    setModal('file');
  };

  const handleAddModal = (type: 'file' | 'url') => {
    setModal(type);
  };

  const handleDelete = async (item: AttachmentExtended) => {
    await deleteAttachmentMutation(item.id);
  };

  return (
    <>
      <Snackbar
        open={alert.open}
        autoHideDuration={4000}
        onClose={() => setAlert({ ...alert, open: false })}
      >
        <Alert
          onClose={() => setAlert({ ...alert, open: false })}
          severity={alert.msgType}
          sx={{ width: '100%' }}
        >
          {alert.msg}
        </Alert>
      </Snackbar>
      {modal && (
        <ModalWidget
          isOpen={!!modal}
          title="Add a New Attachment"
          children={
            <AddAttachmentFile
              sepID={sepID}
              taskID={taskID}
              type={modal}
              setType={setModal}
              onClose={() => setModal(null)}
              onNewAttachment={(item: Attachment) =>
                handleAttachmentAdded(item)
              }
              onMessage={(type, msg) =>
                setAlert({ open: true, msgType: type, msg: msg })
              }
            />
          }
          onClose={() => setModal(null)}
        />
      )}
      <Box sx={{ pl: 4, pr: 4 }}>
        <Stack
          direction="row"
          spacing={1}
          display="flex"
          justifyContent="left"
          color="#555"
          sx={{ p: 1 }}
        >
          <ErrorIcon />
          <Typography>
            File size up to 5MB | Valid file extensions: .jpg, .jpeg, .gif,
            .png, .pdf, .txt, .docx, .xlsx, .pptx
          </Typography>
        </Stack>
        <Typography component="h2">Diagram</Typography>
        <Divider />
        <Stack
          direction="row"
          spacing={4}
          display="flex"
          justifyContent="left"
          flexWrap="wrap"
          sx={{ p: 2 }}
        >
          {attachments
            .filter((e) => e.url === null)
            .map((e, i) => (
              <AttachmentItem
                key={i}
                attachment={e}
                onDelete={(item) => handleDelete(item)}
              />
            ))}
          <div className="container">
            <input
              type="button"
              onClick={() => handleAddModal('file')}
              name="attachment"
            />
            <div id="input-area">
              <i className="fa fa-plus"></i>
              <br />
              Add New Attachment
            </div>
          </div>
        </Stack>
        <Typography component="h2">Other</Typography>
        <Divider />
        <Stack
          direction="row"
          spacing={4}
          display="flex"
          justifyContent="left"
          sx={{ p: 2 }}
        >
          {attachments
            .filter((e) => e.url !== null)
            .map((e, i) => (
              <AttachmentItem
                key={i}
                attachment={e}
                onDelete={(item) => handleDelete(item)}
              />
            ))}
          <div className="container">
            <input
              type="button"
              onClick={() => handleAddModal('url')}
              name="attachment"
            />
            <div id="input-area">
              <i className="fa fa-plus"></i>
              <br />
              Add New Attachment
            </div>
          </div>
        </Stack>
      </Box>
    </>
  );
};

const ViewMoreModal = ({ attachment }: { attachment: AttachmentExtended }) => {
  return (
    <Box sx={{ p: 1, pb: 3 }}>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            font: 'normal normal normal 12px/22px Open Sans',
            color: '#646669',
          }}
        >
          Name
        </Box>
        <Box
          sx={{
            font: 'normal normal normal 14px/22px Open Sans',
            color: '#222222',
            whiteSpace: 'normal',
            width: '100%',
            wordWrap: 'break-word',
          }}
        >
          {attachment.url ? attachment.url : attachment.name}
        </Box>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            font: 'normal normal normal 12px/22px Open Sans',
            color: '#646669',
          }}
        >
          Uploaded By
        </Box>
        <Box
          sx={{
            font: 'normal normal normal 14px/22px Open Sans',
            color: '#222222',
          }}
        >
          {attachment.creator.displayName}
        </Box>
      </Box>
      <Box sx={{ p: 2 }}>
        <Box
          sx={{
            font: 'normal normal normal 12px/22px Open Sans',
            color: '#646669',
          }}
        >
          Type
        </Box>
        <Box
          sx={{
            font: 'normal normal normal 14px/22px Open Sans',
            color: '#222222',
          }}
        >
          {attachment.url ? 'URL' : attachment.mimeType}
        </Box>
      </Box>
    </Box>
  );
};

const AttachmentItem = ({
  attachment,
  onDelete,
}: {
  attachment: AttachmentExtended;
  onDelete: (id: AttachmentExtended) => void;
}) => {
  const dispatch = useAppDispatch();
  const [viewMore, setViewMore] = useState(false);
  const [modal, setModal] = useState<string>('');
  const [alert, setAlert] = useState<string>('');
  const tmb: string =
    attachment.url === null
      ? attachment.name
          .substring(attachment.name.lastIndexOf('.') + 1)
          .toLowerCase()
      : 'url';
  const { type, className } = (
    thumbnails[tmb] === undefined ? thumbnails['default'] : thumbnails[tmb]
  ) as { type: string; className: string };
  const downloadFile = async (
    attachment: AttachmentExtended,
    preview: boolean
  ) => {
    const { data: urls } = await dispatch(
      attachmentSlice.endpoints.downloadAttachmentFile.initiate({
        id: attachment.id,
        mimeType: attachment.mimeType as string,
      })
    );
    if (urls) {
      if (preview) {
        window.open(urls.fileUrl as string);
      } else {
        const link = document.createElement('a');
        link.download = attachment.name;
        link.href = urls.blobUrl as string;
        link.click();
      }
    }
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        setAlert('Successfully copied to clipboard');
      })
      .catch((error) => {
        setAlert('Error: Could not copy text: ');
      });
  };

  return (
    <Box>
      <Snackbar
        open={alert !== ''}
        autoHideDuration={4000}
        onClose={() => setAlert('')}
      >
        <Alert
          onClose={() => setAlert('')}
          severity={alert.startsWith('Error') ? 'error' : 'success'}
          sx={{ width: '100%' }}
        >
          {alert}
        </Alert>
      </Snackbar>
      {modal === 'Delete Attachment' ? (
        <ConfirmDialog
          open={modal === 'Delete Attachment'}
          title={modal}
          content={'Are you sure you want to delete this attachment?'}
          onConfirm={(isDelete) => {
            isDelete && onDelete(attachment);
            setModal('');
          }}
        />
      ) : (
        <ModalWidget
          isOpen={modal === 'File Info' || modal === 'URL Info'}
          title={modal}
          onClose={() => setModal('')}
          children={<ViewMoreModal attachment={attachment} />}
        />
      )}
      <div className="container" onClick={() => downloadFile(attachment, true)}>
        <>
          <div className="main-bar"></div>
          <div id="input-area" className="image">
            <i className={className + ' fa-5x'}></i>
            <br />
            <div className="file-type">{type}</div>
          </div>
          <div className="middle">
            <button
              className="text"
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.stopPropagation();
                setModal('Delete Attachment');
              }}
            >
              <i className="fa-solid fa-trash"></i>
            </button>
            <button
              className="text"
              onClick={(
                event: React.MouseEvent<HTMLButtonElement, MouseEvent>
              ) => {
                event.stopPropagation();
                setViewMore(true);
              }}
            >
              <i className="fa-solid fa-ellipsis"></i>
            </button>
          </div>
          {viewMore && (
            <>
              <div
                id="detail-area"
                onClick={(
                  event: React.MouseEvent<HTMLDivElement, MouseEvent>
                ) => {
                  event.stopPropagation();
                  setViewMore(false);
                }}
              >
                {attachment.url ? (
                  <>
                    <button
                      className="detail-text"
                      onClick={() => copyToClipboard(attachment.url || '')}
                    >
                      <i className="fa fa-download"></i>&nbsp;&nbsp; Copy URL
                    </button>
                    <button
                      className="detail-text"
                      onClick={() => setModal('URL Info')}
                    >
                      URL Info
                    </button>
                  </>
                ) : (
                  <>
                    <button
                      className="detail-text"
                      onClick={() => downloadFile(attachment, false)}
                    >
                      <i className="fa fa-download"></i>&nbsp;&nbsp; Download
                    </button>
                    <button
                      className="detail-text"
                      onClick={() => setModal('File Info')}
                    >
                      File Info
                    </button>
                  </>
                )}
              </div>
            </>
          )}
        </>
      </div>
      <LightTooltip sx={{ width: '200px' }} title={attachment.name}>
        <Box className="file-info-name">{attachment.name}</Box>
      </LightTooltip>
      <Box className="file-info-date">{`${format(
        parseISO(attachment.createdAt || ''),
        'P'
      )} | ${type}`}</Box>
    </Box>
  );
};

export default AttachmentUpload;
