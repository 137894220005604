import React from 'react';
import { Box, Grid, Typography } from '@mui/material';

const APMInfo = ({ apmData }: { apmData: string }) => {
  const apmJSONData = JSON.parse(apmData);
  return (
    <Box sx={{ padding: '30px 30px 10px 30px' }}>
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            font: ' normal normal 600 18px/24px Open Sans;',
          }}
        >
          APM Info
        </Typography>
      </Box>
      <Grid
        container
        item
        sx={{ display: 'flex', mb: 3 }}
        xs={12}
        md={12}
        lg={12}
      >
        {Object.keys(apmJSONData)
          .filter(
            (filterKey) =>
              apmJSONData[filterKey] !== '' &&
              apmJSONData[filterKey] !== null &&
              apmJSONData[filterKey] !== undefined
          )
          .map((key, index) => (
            <Grid key={`reviewerTeams${index}`} item md={4}>
              <p
                className="sep-label"
                style={{ textTransform: 'capitalize' }}
                data-testid={key}
              >
                {key.replaceAll('u_', '').replaceAll('_', ' ')}
              </p>
              <p className="sep-data" data-testid={apmJSONData[key]}>
                {apmJSONData[key]}
              </p>
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default APMInfo;
