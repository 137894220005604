import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Box,
  Stack,
  InputLabel,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Select,
  MenuItem,
  Autocomplete,
  TextField,
  Typography,
} from '@mui/material';
import { useAccount } from '@azure/msal-react';
import { TaskPhase } from '../../../../../shared/types/Task';
import { useGetDepartmentsQuery } from '../../../../services/departmentSlice/departmentSlice';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { Department } from '../../../../../shared/types/Department';
import { useSearchUsersQuery } from '../../../../services/usersSlice';
import { User } from '../../../../../shared/types/User';
import { debounce } from 'lodash';

const textFieldSx = {
  color: '#222222',
  '& > p': {
    margin: '5px 0px',
    color: '#646669',
    fontSize: '11px',
  },
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#646669',
    color: '#FBFCFC',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const Placeholder = ({ children }: { children: string }) => {
  return <div style={{ color: '#222222', fontSize: '14px' }}>{children}</div>;
};

const SecondStep = ({ task, handleChange, handleChangeOfDefaultAssignee, displayName }: any) => {
  const { data } = useGetDepartmentsQuery();
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [userOptions, setUserOptions] = useState<User[]>([]);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [defaultAssigneeRadio, setDefaultAssigneeRadio]= useState<string>('');
  const account = useAccount();
  const roleList: any = useMemo(() => {
    return account?.idTokenClaims?.roles;
  }, [account]);

  const departments: Department[] = [];
  const { data: searchResults = [], isLoading, isError, error } = useSearchUsersQuery(
    {
      search: searchTerm,
      
    },
    { skip: searchTerm.trim() === '' }
  ); 

  console.log("Default Assignee here ",displayName)
 
  
  


  if (roleList && roleList.length && data) {
    const superUser = roleList?.includes('AuthSuperUser');
    data.forEach((item: Department) => {
      const adRole = roleList?.find((role: any) => role === item.adAppRole);
      if (adRole || superUser) {
        departments.push(item);
      }
    });
  }

  const debouncedSearch = useRef<ReturnType<typeof debounce>>(debounce((term: string) => setSearchTerm(term), 100));
  useEffect(() => {
    debouncedSearch.current(searchTerm);
    return () => debouncedSearch.current.cancel();
  }, [searchTerm]);
  useEffect(() => {
    if (!isLoading && !isError) {
      setUserOptions((prevOptions) => {

        return JSON.stringify(prevOptions) !== JSON.stringify(searchResults) ? searchResults : prevOptions;
      });
    }
  }, [searchResults, isLoading, isError]);

  useEffect(()=>{
    setDefaultAssigneeRadio(task.defaultAssignee.length>12 ? 'Other': task.defaultAssignee)
    
  },[task])

  
  return (
    <Stack
      display="flex"
      direction="column"
      alignItems="left"
      spacing={4}
      sx={{ p: 4 }}
    >
      <FormControlLabel
        control={
          <Checkbox
            name="review"
            onChange={(e) => handleChange(e)}
            checked={task.review}
          />
        }
        label="This task requires a review"
        sx={{ color: '#222222' }}
      />
      <Box>
        <FormLabel
          id="demo-controlled-radio-buttons-group"
          sx={{ color: '#222222' }}
        >
          Default Assignee &nbsp;
          <LightTooltip title="The person the task will be assigned to by default. Tasks can reassigned from the task details screen.">
            <i className="fa-solid fa-circle-info"></i>
          </LightTooltip>
        </FormLabel>
        <RadioGroup
          aria-labelledby="demo-controlled-radio-buttons-group"
          name="defaultAssignee"
          value={defaultAssigneeRadio}
          onChange={(e) => {
              setDefaultAssigneeRadio(e.target.value)
              handleChange(e)
            }}
        >
          <FormControlLabel
            value="requestor"
            control={<Radio />}
            label="Requestor"
          />
          <FormControlLabel
            value="stakeholder"
            control={<Radio />}
            label="Stakeholder"
          />
          <FormControlLabel
            value="Other"
            control={<Radio />}
            label="Other"
          />
        </RadioGroup>
        {defaultAssigneeRadio && defaultAssigneeRadio=== 'Other' && (
          <React.Fragment>
            <Autocomplete
              options={userOptions}
              getOptionLabel={(user) => user.displayName || user.email || ''}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              onChange={(_, value) => {
                if(value?.id){
                 // setDefaultAssigneeValue(value.id) 
                  handleChangeOfDefaultAssignee(value.id)
                }
                if (value?.email) {
                  setErrorMessage('');
                } else {
                  setErrorMessage('Enter a valid user ID');
                }
              }}
              inputValue={searchTerm}
              onInputChange={(_, newInputValue) => debouncedSearch.current(newInputValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label= {displayName ? displayName :   "Search and Select Users"} 
                  sx={{
                    borderColor: task.defaultAssignee === 'Other' && searchTerm.trim() === '' ? 'red' : 'default',
                    '& input': {
                      fontSize: '14px',
                      cursor: 'pointer',
                      color: task.defaultAssignee === 'Other' && searchTerm.trim() === '' ? 'red' : 'default',
                    },
                  }}
                />
              )}
              />
              {!displayName  && searchTerm.trim() === '' && (
                <Typography sx={{ color: 'red', fontSize: '12px', mt: 1 }}>
                  Enter a valid user ID
                </Typography>
              )}
            </React.Fragment>
          )}
      </Box>
      <Box>
      <InputLabel sx={{ color: '#222222' }}>Task Summary for Requestor</InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="requestorSummary"
            name="requestorSummary"
            fullWidth
            //data-testid="task-name"
            value={task.requestorSummary || ''}
            placeholder=""
            helperText="This should be the status summary when this task is pending with the requestor"
            onChange={(e) => handleChange(e)}
          />
        </Box>
        <Box>
      <InputLabel sx={{ color: '#222222' }}>Task Summary for Stakeholder</InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="stakeholderSummary"
            name="stakeholderSummary"
            fullWidth
            //data-testid="task-name"
            value={task.stakeholderSummary || ''}
            placeholder=""
            helperText="This should be the status summary when this task is pending with the stakeholder"
            onChange={(e) => handleChange(e)}
          />
        </Box>
      <Box>
        
        <InputLabel sx={{ color: '#222222' }}>Department</InputLabel>
        <Select
          size="small"
          id="demo-simple-select-helper"
          value={task.departmentID}
          displayEmpty={true}
          name="departmentID"
          onChange={(e) => handleChange(e)}
          sx={{ width: '330px' }}
        >
          <MenuItem value={0}>
            <Placeholder>Choose a department from the dropdown</Placeholder>
          </MenuItem>
          {departments?.map((e, i) => (
            <MenuItem key={i} value={e.id}>
              {e.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      <Box>
        <InputLabel sx={{ color: '#222222' }}>Phase</InputLabel>
        <Select
          size="small"
          id="demo-simple-select-helper"
          name="phase"
          value={task.phase}
          displayEmpty={true}
          onChange={(e) => handleChange(e)}
          sx={{ width: '330px', textTransform: 'capitalize' }}
        >
          <MenuItem value="">
            <Placeholder>Choose a phase from the dropdown</Placeholder>
          </MenuItem>
          {Object.values(TaskPhase).map((e, i) => (
            <MenuItem key={i} value={e} sx={{ textTransform: 'capitalize' }}>
              {e}
            </MenuItem>
          ))}
        </Select>
      </Box>
    </Stack>
  );
};

export default SecondStep;
