import React from 'react';
import { Box, Stack, CircularProgress } from '@mui/material';
import Button from '../Button';

export interface ActionButtonProps {
  handleCancel: () => void;
  handleSave: any;
  loading?: boolean;
  buttonValidation?: boolean;
  saveIcon?: any;
  saveLabel?: string;
}

export const ActionButtons = ({
  handleCancel,
  handleSave,
  loading,
  buttonValidation,
  saveLabel,
  saveIcon,
}: ActionButtonProps) => {
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}
      m={3}
    >
      <Stack spacing={1} direction="row">
        <Button disabled={loading} variant="outlined" onClick={handleCancel}>
          Cancel
        </Button>
        <Button
          disabled={loading || buttonValidation}
          variant="contained"
          onClick={handleSave}
        >
          {saveIcon ?? ''}
          &nbsp;&nbsp;
          {saveLabel ?? 'Save'}
        </Button>
        {loading && <CircularProgress size={30} />}
      </Stack>
    </Box>
  );
};
