import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { APM } from '../../../shared/types/APM';
import {
  Setting,
  SettingCreateBody,
  SettingUpdateBody,
} from '../../../shared/types/Settings';
import { RootState } from '../../app/store';
import { setSnackbarForEndpoint } from '../../utils/snackbar';
import { sepAPI } from '../API';
export interface AppState {
  snackbar: SnackbarState | null;
}

const initialState: AppState = {
  snackbar: null,
};

export interface SnackbarState {
  text: string;
  type: 'success' | 'error';
}

/** A slice used for general application-level stored data */
export const appSlice = createSlice({
  name: 'app',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`
    setSnackbar: (state, action: PayloadAction<SnackbarState | null>) => {
      state.snackbar = action.payload;
    },
  },
});

export const { setSnackbar } = appSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.app.value)`
export const selectSnackbar = (state: RootState): SnackbarState | null =>
  state.app.snackbar;

export default appSlice.reducer;

// Get all departments in DB
export const utilsSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    getApm: builder.query<APM, number | string | null>({
      query: (id) => `apm/${id}`,
    }),
    getSettings: builder.query<Array<Setting>, void>({
      query: () => `settings`,
      providesTags: ['Settings'],
    }),
    insertSetting: builder.mutation<Setting, SettingCreateBody>({
      query: (body) => {
        return {
          url: `settings`,
          method: 'POST',
          body: body,
        };
      },
      invalidatesTags: ['Settings'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Setting is created successfully!',
          errorMessage: 'There was a problem creating your Setting.',
        });
      },
    }),
    updateSetting: builder.mutation<
      Setting,
      { id: number; body: SettingUpdateBody }
    >({
      query: (data) => {
        const { id, body } = data;
        return {
          url: `settings/${id}`,
          method: 'PATCH',
          body: body,
        };
      },
      invalidatesTags: ['Settings'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Setting is updated successfully!',
          errorMessage: 'There was a problem updating your Setting.',
        });
      },
    }),
    reOrderSetting: builder.mutation<
      Setting,
      { id: number; body: SettingUpdateBody }
    >({
      query: (data) => {
        const { id, body } = data;
        return {
          url: `settings/${id}`,
          method: 'PATCH',
          body: body,
        };
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Setting is updated successfully!',
          errorMessage: 'There was a problem updating your Setting.',
        });
      },
    }),
    deleteSetting: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `settings/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Settings'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Setting is deleted successfully!',
          errorMessage: 'There was a problem deleting your Setting.',
        });
      },
    }),
  }),
});

export const {
  useLazyGetApmQuery,
  useGetApmQuery,
  useGetSettingsQuery,
  useInsertSettingMutation,
  useUpdateSettingMutation,
  useDeleteSettingMutation,
  useReOrderSettingMutation,
} = utilsSlice;
