import React, { useState, useMemo } from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import TaskHeader from '../../components/TaskOverview/TaskHeader';
import NavButton from '../../components/NavButton';
import TrackTask from '../../components/TaskOverview/TrackTask';
import Comments from '../../components/Comments/Comments';
import Activities from '../../components/Activity/Activities';
import { useAccount } from '@azure/msal-react';
import AttachmentUpload from '../../components/AttachmentUpload/AttachmentUpload';
import { TaskExtended } from '../../../shared/types/Task';
import { CommentExtended } from '../../../shared/types/Comment';
import { useUpdateTaskMutation } from '../../services/tasksSlice/tasksSlice';
import { useGetCommentsQuery } from '../../services/commentSlice/commentSlice';
import { useGetAttachmentByIDQuery } from '../../services/attachmentSlice/attachmentSlice';
import { AttachmentExtended } from '../../../shared/types/Attachment';
import { useGetDataFieldsBySEPIDQuery } from '../../services/dataFieldsSlice';
import { DataFieldWithOptionsAndLocations } from '../../../shared/types/DataField';
import { env } from '../../../shared/env';

export interface AttachmentState {
  sepID: number;
  taskID: number;
}

const NavLinks = ['Track Task', 'Attachments', 'Activity'];
interface TaskOverviewProps {
  task: TaskExtended;
}
const TaskOverview = ({ task }: TaskOverviewProps) => {
  const [curNav, setCurNav] = useState<string>('Track Task');
  const [updateTaskMutation] = useUpdateTaskMutation();
  const handleNavSelected = (link: string) => {
    setCurNav(link);
  };
  const { data: comments } = useGetCommentsQuery(task.sepID);
  const { data: attachments } = useGetAttachmentByIDQuery(task.sepID);
  const { data: dataFields, refetch } = useGetDataFieldsBySEPIDQuery(task.sepID);

  const account = useAccount();
  const roleList: any = useMemo(() => {
    if (env.REACT_APP_LOCAL_DEV_ADMIN === 'true') {
      return ['AuthSuperUser'];
    } else {
      return account?.idTokenClaims?.roles || [];
    }
  }, [account]);

  const taskComments = React.useMemo(() => {
    return (
      comments?.filter(
        (comment: CommentExtended) => comment.taskID === task.id
      ) || []
    );
  }, [task, comments]);

  const taskAttachments = React.useMemo(() => {
    return (
      attachments?.filter(
        (attachments: AttachmentExtended) => attachments.taskID === task.id
      ) || []
    );
  }, [task, attachments]);

  const taskDatafields = React.useMemo(() => {
   
    const filteredTaskDataFields =  dataFields?.filter((datafield: DataFieldWithOptionsAndLocations) => {
          return datafield.dataFieldLocations.find(
            (location) =>
              location.locationID === task.id && location.locationType === 'Task'
          );
        }) || []
    const mapOnlyTaskDataFieldLocations = filteredTaskDataFields.map((dataField)=>{
      const taskLocation = dataField.dataFieldLocations.find(
        (location) =>
          location.locationID === task.id && location.locationType === 'Task'
      );
      return {...dataField,taskDataFieldLocation : taskLocation} 
    })
    return mapOnlyTaskDataFieldLocations || []
  }, [task, dataFields]);

  const handleChange = (changed: TaskExtended) => {
    updateTaskMutation({
      taskID: task.id,
      task: {
        enabled: true,
        review: true,
        name: changed.name,
        shortName: changed.shortName,
        assignedUserID: changed.assignedUserID,
        defaultReviewerID: changed.defaultReviewerID,
        phase: changed.phase,
      },
    });
  };

  return (
    <Box>
      <TaskHeader
        task={task}
        onChange={(changedTask) => handleChange(changedTask)}
      />
      <Divider />
      <Box p="32px">
        <Stack direction="row" spacing={2}>
          {NavLinks.map((link) => (
            <NavButton
              key={link}
              font="normal normal 600 18px/24px Open Sans;"
              onClick={() => handleNavSelected(link)}
              sx={
                link === curNav
                  ? {
                      color: '#0971f1',
                      backgroundColor: '#f3f8fb',
                    }
                  : {}
              }
            >
              {link}
            </NavButton>
          ))}
        </Stack>
        <Box p="32px 24px">
          {curNav === 'Track Task' && (
            <>
              <TrackTask
                task={task}
                setCurNav={setCurNav}
                dataFields={taskDatafields}
                refetchData = {refetch}
              />
              <Typography
                fontSize="18px"
                fontWeight="600"
                color="gray.main"
                mt="32px"
              >
                Task Comments
              </Typography>
              <Divider sx={{ mb: '32px' }} />
              <Comments
                taskID={task.id}
                sepID={task.sepID}
                comments={taskComments}
                roleList={roleList}
                showAddNewCommentButton = {true}
              />
            </>
          )}
          {curNav === 'Attachments' && taskAttachments && (
            <AttachmentUpload
              attachments={taskAttachments}
              sepID={task.sepID}
              taskID={task.id}
            />
          )}
          {curNav === 'Activity' && <Activities taskID={task.id} />}
        </Box>
      </Box>
    </Box>
  );
};

export default TaskOverview;
