import React, { useState, useEffect } from 'react';
import Button from '../../components/Button';
import TextFieldComponent from '../../components/TextField/TextField';
import { Box, Typography, Divider, Grid, Container, CircularProgress } from '@mui/material';
import { useTheme } from '@mui/material';
import { SEPSearchResult, CreateSEPBody } from '../../../shared/types/SEP';
import {
  useCreateSepMutation,
  useUpdateSepMutation,
} from '../../services/sepSlice';
import { useNavigate, useLocation } from 'react-router';
import { useGetDataFieldTemplatesQuery } from '../../services/dataFieldsSlice';
import { useGetWorkflowDataFieldTemplateQuery } from '../../../frontend/services/knockoutSlice';
import { TileSelect } from '../../components/DataFields';
import { DataFieldOption } from '../../../shared/types/DataField';
import Tile from '../../components/Tile'
import { LoadingContainer } from '../../containers/LoadingContainer';

export const CreateSEP = (SEPSearch: SEPSearchResult) => {
  const { count, seps } = SEPSearch;
  const navigate = useNavigate();
  const theme = useTheme();
  const [createSep, { isLoading: isCreating }] = useCreateSepMutation();
  const [updateSep, { isLoading: isUpdating }] = useUpdateSepMutation();
  const SEPStarted = count === 1;
  const location = useLocation();
  const [sep, setSep] = useState<CreateSEPBody>({
    name: '',
    description: '',
  });
  const { data: getTemplateScreen } = useGetWorkflowDataFieldTemplateQuery();
  const { data: getTemplateDataFieldOption } = useGetDataFieldTemplatesQuery();
  const [showLoader, setShowLoader] = useState(false)
  const getWorkFlowData = getTemplateDataFieldOption?.find((data: any) => data?.name === 'Workflow data field');
  const workFlowHeading = getTemplateScreen?.find((data: any) => data?.name.includes('Solution Enablement Process'));
  const [optionsList, setOptionsList] = useState(getWorkFlowData?.dataFieldOptions)

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { name, value } = e.target;
    setSep({ ...sep, [name]: value });
  };

  const handleCreate = async () => {
    const { name, description } = sep;
    const selectedOption = optionsList?.find((opt: any) => opt.selected)?.value;
    setShowLoader(true)
    try {
    if(selectedOption !== 'Do I need an SEP?') {
    const create = await createSep({ name:'', description:'', workFlowType: selectedOption });
    if ('data' in create)
      navigate(`/knockout/${create.data.id}`, { state: location.pathname });
   else {
    console.log("cannot create SEP")
  } } } catch (error) {
    console.error('error while creating SEP', error)
  } finally {
    setShowLoader(false)
  }
  };

  const handleChangeDescription = (e: string): void => {
    setSep({ ...sep, description: e });
  };

  const handleUpdate = async () => {
    const id = SEPSearch.seps[0].id;
    const selectedOption = optionsList?.find((opt: any) => opt.selected)?.value
    const updatedWorkflowType = {workFlowType: selectedOption}
    if(selectedOption !== 'Do I need an SEP?') {
    const update = await updateSep({ id, body: updatedWorkflowType }).unwrap();
    if ('id' in update)
      navigate(`/knockout/${update.id}`, { state: location.pathname });
  } else{
    console.log("cannot create SEP")
  }
  };

  const handleClick = () => {
    SEPStarted ? handleUpdate() : handleCreate();
  };

  useEffect(() => {
    if (!SEPStarted) return;
    if (SEPStarted) {
      // setSep({
      //   name: seps[0].name,
      //   description: seps[0].description,
      // });
      console.log("hello")
    }
    //eslint-disable-next-line
  }, [seps[0]?.name, seps[0]?.description, SEPStarted]);

  useEffect(()=>{
    setOptionsList(getWorkFlowData?.dataFieldOptions)
  }, [getWorkFlowData])

  const handleSelect = (index:any) => {
    const newOptions = optionsList?.map((opt, i) => 
    i === index ? {...opt, selected: true} : {...opt, selected: false}
    );
    setOptionsList(newOptions)
  }

  const anyOptionSelected = optionsList?.some((option: any) => option.selected)

  return (
    <>
    { showLoader ? (
    <LoadingContainer>
        <CircularProgress size={100} />
      </LoadingContainer>
      ) : (
        <>
      <Box mt={5} />
        <Typography sx={{ font: 'normal normal 600 28px/38px Open Sans;' }}>
          {workFlowHeading?.name}
        </Typography>
        <Typography
          mt={3}
          mb={4}
          sx={{
            font: 'normal normal normal 20px/27px Open Sans;',
            color: theme.palette.gray.solidGray,
            maxWidth: 600,
            wordBreak: 'break-word',
          }}
        >
          <span
            className="knockout-description"
            dangerouslySetInnerHTML={{
              __html: workFlowHeading?.description ?? '',
            }}
          />
        </Typography>
      <Container sx={{ mt: 3 }} maxWidth="md">
        <Grid container spacing={6} alignItems="center" justifyContent="center">
        {optionsList?.map((option: DataFieldOption, index: any) => {
          return (
            <Tile
              length={2}
              field={option}
              onClick={
                () => handleSelect(index)
              }
              value={option?.value}
              selected={
                 option?.selected
              }
              key={option.id}
            />
          );
        })} 
        
         </Grid>
      </Container> 

      {/** LOWER BUTTON CONTAINER */}
      <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ diplay: "flex", minWidth: "100%", px: 6 }}>
        <Divider sx={{ backgroundColor: "black" }} />
        <Box sx={{ display: "flex", mt: 3, justifyContent: "flex-end", px: 2 }}>
          <Button
            size="large"
            variant="contained"
            onClick={handleClick}
            disabled={!anyOptionSelected}
          >
            Continue
          </Button>
        </Box>
      </Box>
      </>
      )}
    </>
  );
};
