/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import {
  Box,
  Card,
  CardContent,
  Grid,
  InputAdornment,
  Skeleton,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import TextField from '../TextField';
import InfoIcon from '@mui/icons-material/Info';
import SearchIcon from '@mui/icons-material/Search';
import { skipToken } from '@reduxjs/toolkit/query';
import { useGetApmQuery } from '../../services/appSlice/appSlice';
import { APM } from '../../../shared/types/APM';

const nullAPM = {
  number: null,
  name: null,
  business_criticality: null,
  data_classification: null,
  u_compliance_flag: null,
  emergency_tier: null,
  install_status: null,
  support_vendor: null,
  u_functional_area: null,
  u_apm_portfolio: null,
  u_portfolio_owner: null,
  u_active_user_count: null,
  install_type: null,
  u_type: null,
  u_pii: null,
  u_pi: null,
  u_additional_regulatory_requirement: null,
  u_gap_between_dr_tier_designation_and_architected_dr: null,
};

const ApmLookup = ({ onClose }: { onClose: () => void }) => {
  const theme = useTheme();
  const [searchField, setSearchField] = useState<number | null | string>(null);
  const [activelyTyping, setActivelyTyping] = useState<number | null | string>(
    null
  );
  const [errorMsg, setErrorMsg] = useState('');
  const [apm, setApm] = useState<APM>(nullAPM);
  const { data, isError, error } = useGetApmQuery(searchField || skipToken);

  const onEnterPress = (e: any) => e.key === 'Enter' && handleFind();

  const handleChange = (e: any) => {
    setActivelyTyping(e.target.value);
  };

  const handleFind = () => {
    if (activelyTyping !== null && activelyTyping !== '') {
      setSearchField(activelyTyping);
    } else {
      setErrorMsg('Add a correct APM ID');
    }
  };

  useEffect(() => {
    if (data) {
      setApm(data);
      setErrorMsg('');
    }
    if (error && 'data' in error) {
      setApm(nullAPM);
      setErrorMsg(error.data as string);
    }
  }, [data, error]);
  return (
    <>
      <Box sx={{ px: 4, py: 3, width: '100%' }}>
        <Grid container>
          <Grid item>
            {/* this is the error on console for being null */}
            <TextField
              label="APM ID"
              icon={
                <Tooltip placement="top" title="The APM ID from Service Now.">
                  <InfoIcon sx={{ color: theme.palette.gray.solidGray }} />
                </Tooltip>
              }
              caption="Click the search icon for details"
              type="number"
              error={isError || errorMsg !== ''}
              helperText={errorMsg}
              onKeyUp={onEnterPress}
              endAdornment={
                <div onClick={handleFind}>
                  <InputAdornment
                    sx={{ mr: 1, cursor: 'pointer' }}
                    position="end"
                  >
                    <SearchIcon />
                  </InputAdornment>
                </div>
              }
              onChange={handleChange}
              value={activelyTyping || ''}
              sx={{ width: '100%', mr: 1 }}
            />
          </Grid>
        </Grid>
        <ApmCard apm={apm} />
        {/* <APMFields apm={data || nullAPM} /> */}
      </Box>
    </>
  );
};

interface APMLabelType {
  [key: string]: string;
}

const APMLabels: APMLabelType = {
  number: 'APM ID',
  name: 'Application Name',
  business_criticality: 'Business Value',
  data_classification: 'Data Classification',
  u_compliance_flag: 'Compliance Flag',
  emergency_tier: 'DR Tier Designation',
  install_status: 'Lifecycle Status',
  support_vendor: 'Support Vendor',
  u_functional_area: 'Functional Area',
  u_apm_portfolio: 'Portfolio',
  u_portfolio_owner: 'Portfolio Owner',
  u_active_user_count: 'User Count',
  install_type: 'Development Type',
  u_type: 'Type',
  u_pii: 'PII',
  u_pi: 'PI',
  u_additional_regulatory_requirement: 'Additional Regulatory Requirement',
  u_gap_between_dr_tier_designation_and_architected_dr: 'DR Gap',
};

const ApmCard = ({ apm }: { apm: APM }) => {
  return (
    <Card variant="outlined">
      <CardContent>
        <Typography variant="h5" component="h2" gutterBottom>
          {apm.number == null ? <Skeleton /> : `${apm.number} - ${apm.name}`}
        </Typography>
        <Grid container spacing={2}>
          {Object.entries(apm)
            .filter(([key, _value]) => key !== 'number' && key !== 'name')
            .map(([key, value]) => (
              <Grid item xs={12} sm={6} md={4} lg={3} key={key}>
                <Typography
                  variant="subtitle1"
                  gutterBottom
                  sx={{ color: '#7E8083' }}
                >
                  {APMLabels[key]}
                </Typography>
                <Typography variant="body2">
                  {value == null ? <Skeleton /> : value}
                </Typography>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

export default ApmLookup;
