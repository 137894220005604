import { Box, CircularProgress, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SearchBar from '../../components/SearchBar/SearchBar';
import PageNavigation from '../../components/PageNavigation/PageNavigation';
import SepTableHeader from '../../components/SepTable/SepTableHeader';
import SepTableBody from '../../components/SepTable/SepTableBody';
import { useFindSepsQuery } from '../../services/sepSlice';
import { HeadCell, headCells } from '../../components/SepTable/SepTableBody';
import ModalWidget from '../../components/ModalWidget/ModalWidget';
import EditColumnModal from '../../components/SepTable/EditColumnModal';
import SepFilterBar from '../../components/SepFilterBar/SepFilterBar';
import { SEPSearchRow, SEPStatus } from '../../../shared/types/SEP';

const AllSEPs = () => {
  const [searchText, setSearchText] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [sortBy, setSortBy] = useState<string>('id');
  const [sortAsc, setSortAsc] = useState<boolean>(false);
  const [statusChecked, setStatusChecked] = useState([]);
  const [myToDosChecked, setMyToDosChecked] = useState<boolean>(false);
  const [showTodo, setShowTodo] = useState<boolean>(false);
  const [page, setPage] = useState<number>(0);
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const [selected, setSelected] = useState<SEPSearchRow[]>([]);
  const [visibleColumns, setVisibleColumns] = useState<HeadCell[]>([
    ...headCells,
  ]);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [workFlowTypeRequest, setWorkFlowTypeRequest] = useState<string[]>([])

  const statusArray: any = Object.values(SEPStatus).map((status) => status);

  const filterStatus = statusChecked
    .map((phase: any) => {
      // if phase equals knockout return active status
      if (
        phase === 'knockout' ||
        phase === 'design' ||
        phase === 'implement' ||
        phase === 'initiate'
      ) {
        return 'active';
      }
      // if phase equals complete return approved status
      else if (phase === 'complete') {
        return 'approved';
      }
      // return phase if it's included in statusArray; otherwise, return null
      return statusArray.includes(phase) ? phase : null;
    })
    .filter((status) => status !== null);

  const { data, isLoading, isError } = useFindSepsQuery({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    sortBy,
    sortAsc,
    search: searchFilter,
    status: filterStatus.map((status: any) => status.toString()).join(','),
    workFlowType: workFlowTypeRequest.join(','),
  });

  const updateStatuses = (statuses: any) => {
    setStatusChecked(statuses);
    setPage(0);
  };

  useEffect(() => {
    // Debounce our search function here so we can wait 500ms for the user to stop typing
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length >= 3) {
        setSearchFilter(searchText);
      } else {
        setSearchFilter('');
      }
      // Changing searchFilter should reset pagination.
      setPage(0);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText, setSearchFilter]);
  const refreshSubs = () =>{
    console.log("Refresh")

  }
  return (
    <Box display="flex" flexDirection="column" flexGrow={1} pt="24px">
      <ModalWidget
        isOpen={isOpen}
        title="Edit Columns"
        children={
          <EditColumnModal
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
          />
        }
        onClose={() => setIsOpen(false)}
      />
      <SearchBar
        title="All SEPs"
        searchText={searchText}
        setSearchText={setSearchText}
      />
      <SepFilterBar
        statusChecked={statusChecked}
        setStatusChecked={updateStatuses}
        showTodo={showTodo}
        setShowTodo={setShowTodo}
        myToDosChecked={myToDosChecked}
        setMyToDosChecked={setMyToDosChecked}
        data={data}
        filterPage= {'allsep'}
        workFlowTypeRequest = {workFlowTypeRequest}
        setWorkFlowTypeRequest = {setWorkFlowTypeRequest}
      />
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          py="24px"
        >
          <CircularProgress />
        </Box>
      ) : isError ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexGrow={1}
          py="24px"
        >
          <Typography color="error.main" fontSize="24px">
            Error
          </Typography>
        </Box>
      ) : (
        <>
          <SepTableHeader
            count={data?.count ?? 0}
            sortBy={sortBy}
            sortAsc={sortAsc}
            status={statusChecked
              .map((phase: any) => phase.toString())
              .join(',')}
            searchFilter={searchFilter}
            resultNumber={data?.count ?? 0}
            onModalOpen={() => setIsOpen(true)}
            selected={selected}
            refreshSubs= {refreshSubs}
          />
          <SepTableBody
            rows={data?.seps || []}
            count={data?.count ?? 0}
            sortBy={sortBy}
            setSortBy={setSortBy}
            sortAsc={sortAsc}
            setSortAsc={setSortAsc}
            headers={visibleColumns}
            selected={selected}
            setSelected={setSelected}
            loggedInUser={''}
          />
          <PageNavigation
            count={data?.count ?? 0}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </>
      )}
    </Box>
  );
};

export default AllSEPs;
