import React from 'react';
import {
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
  Box,
} from '@mui/material';
import { StyledCheckBox } from '../SepFilterBar/SepFilterBar';
// import { SEPSearchRow } from '../../../shared/types/SEP';
// import SepTableRow from './SepTableRow';
import { HeadCell } from './SepTableBody';
import SortIcons from './SortIcon';

export interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
  onSelect: (selected: boolean) => void;
  selected: number;
  all: number;
  sortAsc: boolean;
  sortBy: string;
  headers: HeadCell[];
}

export default function EnhancedTableHead(props: EnhancedTableProps) {
  const { sortAsc, sortBy, onRequestSort, selected, all, onSelect } = props;
  const createSortHandler =
    (property: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead sx={{ zIndex: 3 }}>
      <TableRow sx={{ zIndex: 3 }}>
        <TableCell
          key={`row-num-showDetail`}
          align="center"
          padding="normal"
          sx={{
            zIndex: 900,
            left: 0,
            maxWidth: '44px',
            minWidth: '44px',
            maxHeight: '76px',
            minHeight: '76px',
          }}
        ></TableCell>
        <TableCell
          key={`row-num-checkBox`}
          align="center"
          padding="normal"
          sx={{ zIndex: 900, left: 44, maxWidth: '53px', minWidth: '53px' }}
        >
          <StyledCheckBox
            indeterminate={selected > 0 && selected !== all}
            checked={selected === all}
            onChange={(event) => onSelect(event.target.checked)}
            ariaLabel=""
            label={<Box sx={{ height: '44px' }}></Box>}
          />
        </TableCell>
        {props.headers
          .filter((e) => e.visible)
          .map((headCell) => (
            <TableCell
              key={headCell.id}
              align="left"
              padding="normal"
              sortDirection={sortBy === headCell.key && sortAsc ? 'asc' : false}
              sx={
                headCell.id === 'id'
                  ? {
                      zIndex: 900,
                      left: 96.5,
                      maxWidth: '93px',
                      minWidth: '93px',
                    }
                  : { zIndex: 880 }
              }
            >
              <TableSortLabel
                active={sortBy === headCell.key}
                direction={sortBy === headCell.key && sortAsc ? 'asc' : 'desc'}
                aria-label={`Table Sort Label ${headCell.key}`}
                onClick={createSortHandler(headCell.key)}
                IconComponent={() => (
                  <SortIcons
                    sort={sortAsc}
                    property={sortBy === headCell.key}
                    isVisible={headCell.key !== ''}
                  />
                )}
                disabled={headCell.key === ''}
              >
                {headCell.label}
              </TableSortLabel>
            </TableCell>
          ))}
      </TableRow>
    </TableHead>
  );
}
