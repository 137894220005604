import React, { useEffect, useState } from 'react';
import {
  Box,
  Divider,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import Answer from '../Followup/Answer';
import {
  DataFieldExtendedWithSingleLocationAndFollowups,
  DataFieldType,
} from '../../../../../shared/types/DataField';
import FollowupTypeDropDown from '../Followup/FollowupTypeDropDown';
import {
  KnockoutFollowupExtended,
  KnockoutFollowupType,
} from '../../../../../shared/types/Knockout';
import { useAddFollowupTemplateMutation } from '../../../../services/knockoutSlice';
import { useEditFollowupTemplateMutation } from '../../../../services/knockoutSlice';

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#646669',
    color: '#FBFCFC',
    boxShadow: theme.shadows[1],
    fontSize: 14,
  },
}));

const AddFollowup = ({
  type,
  dataField,
  followup,
  onClose,
}: {
  type: string;
  dataField: DataFieldExtendedWithSingleLocationAndFollowups;
  followup: KnockoutFollowupExtended | null;
  onClose: () => void;
}) => {
  const [followUpType, setFollowUpType] = useState<string>('');
  const [followUpValue, setFollowUpValue] = useState<string>('');
  const [followUpID, setFollowUpID] = useState<number | null>(null);
  const [followUpUserID, setFollowUpUserID] = useState<string | null>(null);
  const [createFollowUp] = useAddFollowupTemplateMutation();
  const [updateFollowUp] = useEditFollowupTemplateMutation();

  useEffect(() => {
    setFollowUpType(followup?.followupType || '');
    setFollowUpID(followup?.followupID || null);
    setFollowUpValue(followup?.value || '');
    setFollowUpUserID(followup?.followupUserID || null);
  }, [followup]);

  const checkReady = () => {
    if (
      followUpType === 'KnockoutScreen' &&
      followUpValue !== '' &&
      followUpID !== null
    ) {
      return true;
    } else if (
      followUpType === 'Task' &&
      followUpValue !== '' &&
      followUpID !== null
    ) {
      return true;
    } else if (
      followUpType === 'DefaultStakeholder' &&
      followUpValue !== '' &&
      followUpID !== null &&
      followUpUserID !== null
    ) {
      return true;
    }
    return false;
  };
  const isReady = checkReady();

  const handleClick = () => {
    if (type === 'Add Followup')
      createFollowUp({
        value: followUpValue,
        dataFieldID: dataField.id,
        followupID: followUpID || 0,
        followupUserID: followUpUserID || '',
        followupType: followUpType as KnockoutFollowupType,
      });
    else
      updateFollowUp({
        body: {
          value: followUpValue,
          dataFieldID: dataField.id,
          followupID: followUpID || 0,
          followupUserID: followUpUserID || '',
          followupType: followUpType as KnockoutFollowupType,
        },
        id: followup?.id || 0,
      });

    onClose();
  };

  return (
    <Box>
      <Divider />
      <Box sx={{ p: 2, ml: '8px', mr: '8px' }}>
        <Box sx={{ fontSize: '12px', color: '#646669', mb: '4px' }}>
          {dataField.name}
        </Box>
        <Box sx={{ fontSize: '14px', color: '#222222', fontWeight: '500' }}>
          {dataField.description}
        </Box>
      </Box>
      <Divider />
      <Box sx={{ m: '24px' }}>
        <Answer
          dataField={dataField}
          followup={followup}
          onChange={(changed) => {
            if (dataField.type === DataFieldType.yesNo) {
              changed === "I don't know"
                ? setFollowUpValue('idk')
                : setFollowUpValue(changed.toLowerCase());
            } else {
              setFollowUpValue(changed);
            }
          }}
        />
        <Box sx={{ mb: 2 }}>
          <FormLabel
            id="demo-controlled-radio-buttons-group"
            sx={{ color: '#222222' }}
          >
            Followup Type &nbsp;
            <LightTooltip title="This is the type of followup that the selected answer will trigger from the knockout process. Multiple followups can be created for a single answer.">
              <i
                className="fa-solid fa-circle-info"
                style={{ color: '#646669' }}
              ></i>
            </LightTooltip>
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={followUpType}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setFollowUpType(e.target.value)
            }
          >
            <FormControlLabel
              value="KnockoutScreen"
              control={<Radio />}
              label="Knockout Screen"
            />
            <FormControlLabel value="Task" control={<Radio />} label="Task" />
            <FormControlLabel
              value="DefaultStakeholder"
              control={<Radio />}
              label="Default Stakeholder"
            />
          </RadioGroup>
        </Box>
        <Box sx={{ color: '#222222', width: '216px' }}>
          {followUpType === 'Task'
            ? 'Followup Task'
            : followUpType === 'DefaultStakeholder'
            ? 'Department'
            : followUpType === 'KnockoutScreen'
            ? 'Followup Knockout Screen'
            : followUpType}
        </Box>
        <FollowupTypeDropDown
          type={followUpType}
          followup={followup}
          onChange={(val) => setFollowUpID(val)}
          onUserChange={(val) => setFollowUpUserID(val)}
        />
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          p: 2,
          '& > button': {
            m: 1,
          },
          zIndex: '0',
        }}
      >
        <Button
          variant="text"
          disabled={false}
          onClick={() => onClose()}
          sx={{ textTransform: 'none', zIndex: '0' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => handleClick()}
          disabled={!isReady}
          sx={{
            textTransform: 'none',
            backgroundColor: '#2372B9',
            font: 'normal normal 600 16px/22px Open Sans',
            zIndex: '0',
          }}
        >
          {type}
        </Button>
      </Box>
    </Box>
  );
};

export default AddFollowup;
