import React, { useCallback } from 'react';

//Button Grid imports
import { Grid, Container } from '@mui/material';
import Tile from '../Tile';
import {
  DataFieldOption,
  DataFieldUpdate,
} from '../../../shared/types/DataField';
import { useGetDataFieldTemplatesQuery } from '../../services/dataFieldsSlice';


interface ButtonGridProps {
  field: any;
  value?: number | number[];
  required?: boolean;
  index: number;
  data: DataFieldUpdate[];
  setData: React.Dispatch<React.SetStateAction<DataFieldUpdate[]>>;
  DataFields: any[];
  setDataFields: React.Dispatch<React.SetStateAction<any[]>>;
  setDataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  multiple?: boolean | undefined;
  readOnly?: boolean;
}

const TileSelect = ({
  field,
  index,
  data,
  DataFields,
  setDataFields,
  setData,
  setDataChanged,
  multiple,
  readOnly,
}: ButtonGridProps) => {
  const length = field.dataFieldOptions.length;
  
  const { data: dataFields } = useGetDataFieldTemplatesQuery();
  const getTooltipData : any= dataFields?.filter((ele: any) => ele?.id === field?.dataFieldTemplateID);
  const actualList = getTooltipData?.[0]

function updateTooltipData(data1:any, data2:any) {
if(data1?.id === data2.dataFieldTemplateID) {
  const tooltipMap: any = {};
  data1?.dataFieldOptions?.forEach((option: any) => {
    tooltipMap[option.value] = option.multiselectTooltipValue
  })
  const updatedOptionsList = data2.dataFieldOptions?.map((option: any) => {
    if(tooltipMap.hasOwnProperty(option.value)){
      return {
        ...option,
        multiselectTooltipValue : tooltipMap[option.value]
      };
    }
    return option;
  });
  const updatedData2 = {
    ...data2,
    dataFieldOptions: updatedOptionsList
  }
  return updatedData2;
}
  return data2
}
const updatedData = updateTooltipData(actualList, field)
  //filter object properties
  const filterFieldProperties = (datafield: DataFieldOption) => {
    const { id, value, selected } = datafield;
    return { id, value, selected };
  };

  const handleSelectData = useCallback(
    (fieldId: number, fieldOptionId: number, newArray: any) => {
      const newData: any = [...data];
      if (newData.length > 0) {
        const isPresent = newData.findIndex(
          (field: any) => field.id === fieldId
        );
        let newState = [];
        if (isPresent === -1) {
          newData.push({
            id: fieldId,
            dataFieldOptions: newArray,
          });
          setData(newData);
        } else {
          newState = data.map((dataFieldValue: any) => {
            return dataFieldValue.id === fieldId
              ? {
                  ...dataFieldValue,
                  dataFieldOptions: newArray,
                }
              : dataFieldValue;
          });
          setData(newState);
        }
      } else {
        newData.push({
          id: fieldId,
          dataFieldOptions: newArray,
        });
        setData(newData);
      }
    },
    [data, setData]
  );

  const selectValue = useCallback(
    (fieldId: number) => {
      const res = data.find((option: any) => option.id === fieldId);
      if (res?.dataFieldOptions) {
        const selectedOption = res.dataFieldOptions.find(
          (field: any) => field.selected === true
        );
        return selectedOption?.id;
      } else {
        return 0;
      }
    },
    [data]
  );

  // Select CHANGE EVENT
  const handleSelect = useCallback(
    (fieldId: any, index: number, value: number) => {
      if (setDataChanged !== undefined) setDataChanged(true);
      const newDataFields = [...DataFields];
      const newOptions = [...newDataFields[index].dataFieldOptions];
      const testDataField = newOptions.map(filterFieldProperties);
      const newData = testDataField.map((option) =>
        option.id === value
          ? { ...option, selected: true }
          : { ...option, selected: false }
      );
      const nDF = { ...newDataFields[index], dataFieldOptions: newData };
      newDataFields[index] = nDF;
      setDataFields(newDataFields);
      handleSelectData(fieldId, value, newData);
    },
    [setDataChanged, DataFields, handleSelectData, setDataFields]
  );

  const handleSelectMultiple = useCallback(
    (fieldId: any, index: number, selectedArray: any, value: any) => {
      const selectedArrayCopy = selectedArray.includes(value)
        ? selectedArray.filter((item: any) => item !== value)
        : [...selectedArray, value];
      const newDataFields = [...DataFields];
      const newOptions = [...newDataFields[index].dataFieldOptions];
      if (setDataChanged !== undefined) setDataChanged(true);
      const testDataField = newOptions.map(filterFieldProperties);
      const newData = testDataField.map((option) =>
        selectedArrayCopy.includes(option.id)
          ? { ...option, selected: true }
          : { ...option, selected: false }
      );
      const nDF = { ...newDataFields[index], dataFieldOptions: newData };
      newDataFields[index] = nDF;
      setDataFields(newDataFields);
      handleSelectData(fieldId, value, newData);
    },
    [setDataChanged, DataFields, handleSelectData, setDataFields]
  );

  const selectMultipleValue = useCallback(
    (fieldId: number) => {
      const res = data.find((option: any) => option.id === fieldId);
      return (
        res?.dataFieldOptions
          ?.filter((o: any) => o.selected)
          ?.map((o: any) => o.id) || []
      );
    },
    [data]
  );

  return (
    <Container sx={{ mt: 3 }} maxWidth="md">
      <Grid container columns={10} spacing={3}>
        {updatedData?.dataFieldOptions.map((option: DataFieldOption) => {
          const value: any = !!multiple
            ? selectMultipleValue(field.id)
            : selectValue(field.id);

          return (
            <Tile
              length={length as number}
              field={option}
              onClick={
                multiple
                  ? (e: any) =>
                      handleSelectMultiple(field.id, index, value, option.id)
                  : (e: any) => handleSelect(field.id, index, option.id)
              }
              value={value}
              selected={
                !!multiple ? value?.includes(option.id) : value === option.id
              }
              key={option.id}
            />
          );
        })}
      </Grid>
    </Container>
  );
};

export default TileSelect;
