import React from 'react';
import { Box, Container } from '@mui/material';
import { Outlet } from 'react-router';

const Login = () => {
  return (
    <>
      <Box
        component="main"
        sx={{
          alignItems: 'center',
          bgcolor: '#FBFCFC',
          flexDirection: 'column',
          p: 3,
        }}
      >
        <Container maxWidth="xl">
          <Container
            maxWidth="xl"
            sx={{
              bgcolor: '#FFFFFF',
              borderRadius: '10px',
              boxShadow: '0px 1px 5px #0000001A;',
              padding: '0px !important',
              marginTop: '25px',
            }}
          >
            <Outlet />
          </Container>
        </Container>
      </Box>
    </>
  );
};

export default Login;
