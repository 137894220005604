import React from 'react';
import { styled } from '@mui/material/styles';
import MuiTab, { TabProps } from '@mui/material/Tab';

const StyledTab = styled(MuiTab)<TabProps>(({ theme }) => ({
  color: theme.palette.gray.main,
  display: 'block',
  textTransform: 'none',
  padding: '12px',
  marginRight: '6px',
  font: 'normal normal 600 20px/19px Open Sans;',
  '&:hover': {
    backgroundColor: theme.palette.solidBlue.lightHover,
    color: theme.palette.solidBlue.main,
    borderRadius: '6px',
  },
  '&:active': {
    backgroundColor: theme.palette.solidBlue.lightActive,
    color: theme.palette.solidBlue.main,
  },
  '&:focus': {
    backgroundColor: theme.palette.solidBlue.lightActive,
    color: theme.palette.solidBlue.main,
    borderRadius: '6px',
  },
  '&.Mui-selected': {
    backgroundColor: theme.palette.solidBlue.lightActive,
    color: theme.palette.solidBlue.main,
    borderRadius: '6px',
  },
  '&.Mui-focusVisible': {
    color: theme.palette.solidBlue.main,
    borderRadius: '6px',
  },
}));

const Tab = (props: any) => <StyledTab {...props} />;

export default Tab;
