import React, { useMemo } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import { Divider, useTheme } from '@mui/material';
import { useNavigate, NavigateFunction } from 'react-router-dom';
import { useGetUserQuery } from '../../services/usersSlice';
import { useAccount } from '@azure/msal-react';
import { env } from '../../../shared/env';
import HelpIcon from '@mui/icons-material/Help';
import { useGetSettingsQuery } from '../../services/appSlice/appSlice';
import NavButton from '../NavButton';

import './styles.css';
import UserDisplay from '../UserDisplay/UserDisplay';
import ModalWidget from '../ModalWidget/ModalWidget';
import ApmLookup from '../ApmLookup';
import { useLocation } from 'react-router';
const Logo = require('../../assets/img/constellation-logo.png');

const pages = [
  {
    text: 'My SEPs',
    link: '/',
  },
  {
    text: 'All SEPs',
    link: '/all-seps',
  },
  {
    text: 'Create an SEP',
    link: '/create',
  },
  // {
  //   text: 'Do I need SEP ?',
  //   link: '/do-i-need-sep',
  // },
];
const settings = ['Logout'];

const ResponsiveAppBar = () => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showApmModal, setShowApmModal] = React.useState<boolean>(false);
  const open = Boolean(anchorEl);
  const { data } = useGetSettingsQuery();
  const reviewerTools = data
    ?.filter((help) => help.section === 'ReviewerTools')
    .sort((a, b) => a.order - b.order);
  const userHelp = data
    ?.filter((help) => help.section === 'UserHelp')
    .sort((a, b) => a.order - b.order);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const theme = useTheme();
  const location = useLocation();

  const account = useAccount();
  const roleList: any = useMemo(() => {
    if (env.REACT_APP_LOCAL_DEV_ADMIN === 'true') {
      return ['AuthSuperUser'];
    } else {
      return account?.idTokenClaims?.roles || [];
    }
  }, [account]);

  const editPermission =
    roleList && roleList.length
      ? roleList?.includes('AuthSuperUser') ||
        roleList?.includes('AuthSolutionArchitect') ||
        roleList?.includes('AuthPortfolioOwner') ||
        roleList?.includes('AuthSupply') ||
        roleList?.includes('AuthNuclearCyberSecurity') ||
        roleList?.includes('AuthThirdPartySecurity') ||
        roleList?.includes('AuthSecurity') ||
        roleList?.includes('AuthEA') ||
        roleList?.includes('AuthLegal')
        ? true
        : false
      : false;

  let isSuperUser;
  if (env.REACT_APP_LOCAL_DEV_ADMIN === 'true') {
    isSuperUser = true;
  } else {
    isSuperUser =
      roleList?.includes('AuthSuperUser') &&
      (process.env.NODE_ENV === 'test'
        ? process.env.REACT_APP_SHOW_ADMIN_FEATURES === 'true'
        : env.REACT_APP_SHOW_ADMIN_FEATURES === 'true');
  }

  const navigate: NavigateFunction = useNavigate();
  const { data: loggedInUser } = useGetUserQuery('me');
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const userName: string = loggedInUser?.displayName ?? '';

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleChangeRoute = (route: string) => {
    navigate(route);
    handleCloseNavMenu();
  };

  return (
    <AppBar
      elevation={0}
      position={location.pathname.includes('/task/') ? 'fixed' : 'sticky'}
      sx={{
        backgroundColor: theme.palette.common.white,
        borderBottom: '1px',
        borderColor: theme.palette.gray.lightGray1,
        borderStyle: 'solid',
        zIndex: location.pathname.includes('/task/') ? '999' : ''
      }}
      className="no-print"
    >
      <ModalWidget
        isOpen={showApmModal}
        children={<ApmLookup onClose={() => setShowApmModal(false)} />}
        title="APM Lookup"
        onClose={() => setShowApmModal(false)}
        expandModal={true}
      />
      <Container maxWidth={false}>
        <Toolbar disableGutters>
          <a href="/">
            <img
              src={Logo}
              height="50px"
              alt="Constellation"
              style={{ marginTop: '5px', display: 'block' }}
            />
          </a>
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="primary"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  key={page.text}
                  onClick={() => handleChangeRoute(page.link)}
                >
                  <Typography textAlign="center">{page.text}</Typography>
                </MenuItem>
              ))}
              {isSuperUser && (
                <MenuItem
                  key="superUser"
                  onClick={() => handleChangeRoute('/admin')}
                >
                  Admin
                </MenuItem>
              )}
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 0, mr: 3, display: { xs: 'none', md: 'flex' } }}>
            {pages.map((page) => (
              <NavButton
                key={page.text}
                arial-label="navigation buttons"
                onClick={() => handleChangeRoute(page.link)}
                className="nav-buttons"
                font="normal normal 600 14px/19px Open Sans;"
              >
                {page.text}
              </NavButton>
            ))}
            {isSuperUser && (
              <NavButton
                key="superUser_NavButton"
                arial-label="navigation buttons"
                className="nav-buttons"
                font="normal normal 600 14px/19px Open Sans;"
                onClick={() => handleChangeRoute('/admin')}
              >
                Admin
              </NavButton>
            )}
          </Box>
          <Box>
            <IconButton
              id="basic-button"
              aria-controls={open ? 'basic-menu' : undefined}
              aria-haspopup="true"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleClick}
            >
              <HelpIcon sx={{ fontSize: '25px' }} />
            </IconButton>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={() => setAnchorEl(null)}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
            >
              {userHelp?.map((item, index) => (
                <MenuItem
                  key={index}
                  onClick={() => window.open(item.value, '_blank')}
                >
                  {item.name}
                </MenuItem>
              ))}
              <Divider />
              {editPermission &&
                reviewerTools?.map((item, index) => (
                  <MenuItem
                    key={index}
                    onClick={() => window.open(item.value, '_blank')}
                  >
                    {item.name}
                  </MenuItem>
                ))}
              <MenuItem
                key="apm_lookup"
                onClick={() => {
                  setShowApmModal(true);
                  setAnchorEl(null);
                }}
              >
                APM Lookup
              </MenuItem>
            </Menu>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            {loggedInUser?.id && (
              <Tooltip title={userName}>
                <IconButton sx={{ p: 0 }}>
                  <UserDisplay showPicture userID={loggedInUser?.id} />
                </IconButton>
              </Tooltip>
            )}
            <Menu
              sx={{ mt: '35px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
};

export default ResponsiveAppBar;
