import {
  Box,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
} from '@mui/material/index';
import Select, { SelectChangeEvent } from '@mui/material/Select/index';
import React from 'react';
import { DataFieldLocationType } from '../../../../../shared/types/DataField';
import {
  AddDepartmentDataFieldState,
  textFieldSx,
} from './AddDepartmentDataField';

interface FirstStepProps {
  value: AddDepartmentDataFieldState;
  onNameChange: (value: string) => void;
  onDescriptionChange: (value: string) => void;
  onDataFieldLocationTypeChanged: (
    type:
      | DataFieldLocationType.Department
      | DataFieldLocationType.DepartmentReview
  ) => void;
}

export function FirstStep({
  onNameChange,
  onDescriptionChange,
  onDataFieldLocationTypeChanged,
  value,
}: FirstStepProps) {
  const handleDataFieldLocationTypeChanged = (e: SelectChangeEvent) => {
    onDataFieldLocationTypeChanged(
      e.target.value as
        | DataFieldLocationType.Department
        | DataFieldLocationType.DepartmentReview
    );
  };
  return (
    <Box>
      <Stack
        display="flex"
        direction="column"
        alignItems="left"
        spacing={4}
        sx={{ p: 4 }}
      >
        <Box>
          <InputLabel sx={{ color: '#222222' }} htmlFor="task-name">
            Data Field Name
          </InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="name"
            name="name"
            fullWidth
            value={value.name}
            placeholder="Ex. Does this request involve Nuclear?"
            helperText="This will be the question or statement needing to be answered"
            onChange={(e) => {
              onNameChange(e.target.value);
            }}
          />
        </Box>
        <Box>
          <InputLabel sx={{ color: '#222222' }} htmlFor="task-assignment-name">
            Data Field Description
          </InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="description"
            name="description"
            fullWidth
            value={value.description}
            multiline
            rows={4}
            placeholder="Describe the reason for this data field here…"
            onChange={(e) => {
              onDescriptionChange(e.target.value);
            }}
          />
        </Box>
        <Box>
          <InputLabel
            sx={{ color: '#222222' }}
            htmlFor="department-add-data-field-location-type"
          >
            Data Field Location Type
          </InputLabel>
          <Select
            id="data-field-select"
            value={value.locationType}
            onChange={handleDataFieldLocationTypeChanged}
            size="small"
            displayEmpty
            input={<OutlinedInput />}
            renderValue={(selected) => {
              if (selected === DataFieldLocationType.None) {
                return (
                  <Typography sx={{ color: '#7E8083' }}>
                    Choose a data field location type from the dropdown
                  </Typography>
                );
              }

              return selected;
            }}
          >
            <MenuItem disabled value={DataFieldLocationType.None}>
              <Typography sx={{ color: '#7E8083' }}>
                Choose a data field type from the dropdown
              </Typography>
            </MenuItem>
            <MenuItem value={DataFieldLocationType.Department}>
              Department Details
            </MenuItem>
            <MenuItem value={DataFieldLocationType.DepartmentReview}>
              Department Review
            </MenuItem>
          </Select>
        </Box>
      </Stack>
    </Box>
  );
}
