//TODO:  include test
import React, { useEffect, useState } from 'react';
import Button from '../Button';
import {
  Box,
  Divider,
  InputAdornment,
  Grid,
  Typography,
  CircularProgress,
  Tooltip,
  Link,
} from '@mui/material';

import StepperDots from '../StepperDots';
import { useTheme } from '@mui/material';
import TextField from '../TextField';
import SearchIcon from '@mui/icons-material/Search';
import InfoIcon from '@mui/icons-material/Info';

import { useGetApmQuery } from '../../services/appSlice/appSlice';
//import { useUpdateSepMutation } from '../../services/sepSlice';
import { useGetSepQuery } from '../../services/sepSlice';
import { skipToken } from '@reduxjs/toolkit/query';
import { env } from '../../../shared/env';

const ApmSearchComponent = ({sepID,handleApmIdChange}:any) => {
  const theme = useTheme();
  const { data: sep } = useGetSepQuery(Number(sepID) || skipToken);
  const [apm, setApm] = useState<string>('');
  const [isChanged, setIsChanged] = useState(false);
  const [searchField, setSearchField] = useState<null | string>(null);
  const [activelyTyping, setActivelyTyping] = useState<null | string>(sep?.apmID as string | null );
  const [errorMsg, setErrorMsg] = useState('');
  const { data, isError, isLoading, error } = useGetApmQuery(
    searchField || sep?.apmID || skipToken
  );
  //const [updateSep] = useUpdateSepMutation();
  const buttonValidation = searchField !== '' && (isChanged || isError);

  const handleChange = (e: any) => {
    setActivelyTyping(e.target.value);
    setIsChanged(true);
    setApm('');
  };

  const onEnterPress = (e: any) => e.key === 'Enter' && handleFind();

  const handleClickOpen = () => {
    window.open(env.REACT_APP_DO_I_NEED_APM_ID_URL);
  };

  const handleSave = (e: any) => {
   // handleSubmit(e, searchField);
   const apmID = searchField === '' || !searchField ? null : parseInt(searchField);
    // if (sepID)
    //   updateSep({
    //     id: sepID,
    //     body: { apmID: apmID },
    //   });
  };

  const handleFind = () => {
    if (activelyTyping !== null && activelyTyping !== '') {
      setSearchField(activelyTyping);
    } else {
      setErrorMsg('Add a correct APM ID');
    }
  };

  useEffect(() => {
    if (data) {
      setApm(data.name as string);
      setErrorMsg('');
      setIsChanged(false);
    }
    if (error && 'data' in error) {
      setApm('');
      setErrorMsg(error.data as string);
    }
  }, [data, error]);

  useEffect(() => {
    if(buttonValidation){
      const apmID = searchField === '' || !searchField ? null : parseInt(searchField);
      handleApmIdChange(apmID)
    }
    
  }, [searchField]);

  return (
    <>
      <Box mt={5} />
      {/* <Typography sx={{ font: 'normal normal 600 28px/38px Open Sans' }}>
        Nice work! Let's add an APM ID.
      </Typography> */}
      <Typography
        my={2}
        //mx={{ xs: 4, sm: 10, md: 20, lg: 30 }}
        sx={{
          font: 'normal normal normal 16px/22px Open Sans',
          color: theme.palette.gray.solidGray,
        }}
      >
       Please provide the APM ID in the below field (add APM ID and click on the magnifying glass, the system will look up the APM record in ServiceNow for access to remaining APM attributes). 
       <br/>If you need assistance finding or creating an APM record, please work with the IT Portfolio Owner. The  <a href={env.REACT_APP_IT_PORTFOLIO_OWNER_URL} target="_blank" rel="noopener"> IT Portfolio Owner </a> list can be used to identify the correct contact who oversees the area related to the scope of this request. 
       <br/>If you are not sure if you need an APM ID, please refer to this <a href={env.REACT_APP_CHEAT_SHEET_URL} target="_blank" rel="noopener"> APM Cheat Sheet </a>. If an APM ID is not required, you can indicate the justification in the field below.
       After you have completed the fields, please click on the "Save" button to capture the data. Once the data is saved, please click on "Complete" button above to inform Legal that the task is complete. 
 
      </Typography>
      <Link
        onClick={handleClickOpen}
        style={{
          color: '#2372B9',
          font: 'normal normal normal 16px/22px Open Sans',
          fontWeight: 'bold',
          textDecoration: 'none',
          cursor: 'pointer',
        }}
      >
        Do I need an APM ID?
      </Link>
      <Box mt={5} />
      <Grid container width={550}>
        <Grid item md={6}>
          {/* this is the error on console for being null */}
          <TextField
            label="APM ID"
            icon={
              <Tooltip placement="top" title="The APM ID from Service Now.">
                <InfoIcon sx={{ color: theme.palette.gray.solidGray }} />
              </Tooltip>
            }
            caption="Click the search icon for details"
            type="number"
            error={isError || errorMsg !== ''}
            helperText={errorMsg}
            onKeyUp={onEnterPress}
            endAdornment={
              <div onClick={handleFind}>
                <InputAdornment
                  sx={{ mr: 1, cursor: 'pointer' }}
                  position="end"
                >
                  <SearchIcon />
                </InputAdornment>
              </div>
            }
            onChange={handleChange}
            value={activelyTyping || ''}
            sx={{ width: { md: 200, lg: 250 }, mr: 1 }}
          />
        </Grid>
        <Grid item md={6}>
          <TextField
            disabled
            label="APM Name"
            sx={{ width: { xs: 250, sm: 300, md: 350, lg: 380 } }}
            value={apm}
            endAdornment={
              isLoading && (
                <InputAdornment
                  sx={{ mr: 1, cursor: 'pointer' }}
                  position="end"
                >
                  <CircularProgress
                    size="20px"
                    sx={{ color: theme.palette.gray.solidGray }}
                  />
                </InputAdornment>
              )
            }
          />
        </Grid>
      </Grid>
      {/** LOWER BUTTON CONTAINER */}
      {/* <Box sx={{ flexGrow: 1 }} />
      <Box sx={{ diplay: 'flex', minWidth: '100%', px: 6 }}>
        <Box
          sx={{
            display: 'flex',
            mt: 3,
            justifyContent: 'space-between',
            px: 2,
          }}
        >
          <Button
            size="large"
            variant="contained"
            onClick={handleSave}
            disabled={buttonValidation}
          >
            Save
          </Button>
        </Box>
      </Box> */}
    </>
  );
};

export default ApmSearchComponent;
