import * as React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

function CheckboxLabels(props: any) {
  return (
    <FormControlLabel
      control={<Checkbox onChange={props.onChange} checked={props.checked} />}
      label={props.label}
    />
  );
}

export default CheckboxLabels;
