import React, { useEffect, useRef, useState } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Grid, Divider, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import {
  DataFieldUpdate,
  DataFieldWithOptionsAndLocations,
} from '../../../shared/types/DataField';
import DataForm from '../../features/Knockout/DataForm';
import Button from '../Button';
import Checkbox from '../Checkbox';
import Toolbar from '../Toolbar';
import { TaskExtended, TaskStatus } from '../../../shared/types/Task';
import TasksTable from '../Tables/TasksTables';
import { useUpdateTasksMutation, useGetTaskTemplatesQuery } from '../../services/tasksSlice/tasksSlice';
import ModalWidget from '../ModalWidget/ModalWidget';
import StepPage from '../CreateTask/CreateTask';
import { IconCell } from '../TaskOverview/TaskHeader';
import UserDisplay from '../UserDisplay/UserDisplay';
import UserSearchBar from '../TaskOverview/AutoComplete';
import { User } from '../../../shared/types/User';
import {
  useGetDefaultStakeholdersForSEPQuery,
  useSetDefaultStakeholderForSEPDepartmentMutation,
} from '../../services/departmentSlice/departmentSlice';
import { useGetDepartmentsQuery } from '../../services/departmentSlice/departmentSlice';
import { Department } from '../../../shared/types/Department';
import { useGetSepQuery } from '../../services/sepSlice';
import { DefaultStakeholder } from '../../../shared/types/DefaultStakeholder';
import { ActionButtons } from '../CancelSave';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
  currentSubTab: string;
}

interface VerticalTabsProps {
  currentSubTab: string;
  data: DataFieldUpdate[];
  setData: React.Dispatch<React.SetStateAction<DataFieldUpdate[]>>;
  dataFieldsForDepartment: DataFieldWithOptionsAndLocations[];
  handleSubTabChange: (event: React.SyntheticEvent, newValue: string) => void;
  handleCancel: () => void;
  handleSave: () => void;
  locationID?: number;
  tasksForDepartment: TaskExtended[];
  sepID: number;
  filterTasks: boolean;
  handleTasksSwitch: () => void;
  setDataChanged?: React.Dispatch<React.SetStateAction<boolean>>;
  dataChanged?: boolean;
  currentDepartmentID: number | undefined;
  onNewTaskAdded: () => void;
  buttonValidation?: boolean;
  hidePendingDeptTasks?: boolean;
  hideFilterChange?: () => void
}

const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    font: 'normal normal normal 16px/22px Open Sans;',
    height: '48px !important',
    minHeight: '48px',
    padding: '10px 10px 10px 0px',
    width: '100%',
    alignSelf: 'start',
    '&.Mui-selected': {
      backgroundColor: theme.palette.blue.lightBlue1,
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  })
);

function TabPanel(props: TabPanelProps) {
  const { children, value, index, currentSubTab, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== currentSubTab}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
      style={{ width: '100%' }}
    >
      {value === currentSubTab && <Box sx={{ px: 0 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs({
  currentSubTab,
  data,
  dataFieldsForDepartment,
  setData,
  handleCancel,
  handleSubTabChange,
  handleSave,
  locationID,
  tasksForDepartment,
  sepID,
  filterTasks,
  handleTasksSwitch,
  setDataChanged,
  dataChanged,
  currentDepartmentID,
  onNewTaskAdded,
  buttonValidation,
  hidePendingDeptTasks,
  hideFilterChange
}: VerticalTabsProps) {
  const theme = useTheme();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isStakeholderEdit, setIsStakeholderEdit] = useState<boolean>(false);
  const stakeholderRef = useRef(null);
  const { data: defaultStakeholders } =
    useGetDefaultStakeholdersForSEPQuery(sepID);
  const { data: sep } = useGetSepQuery(sepID);

  const defaultStakeholder = React.useMemo(() => {
    return (
      defaultStakeholders?.find(
        (d: DefaultStakeholder) => d.departmentID === currentDepartmentID
      ) || null
    );
  }, [defaultStakeholders, currentDepartmentID]);
  const { data: departments } = useGetDepartmentsQuery();
  const { data: taskTemplates } = useGetTaskTemplatesQuery();

  const updatedTasks = tasksForDepartment?.map((task: any) => {
    const updatedTask = { ...task };
    taskTemplates?.forEach((newTask: any) => {
      if (updatedTask.taskTemplateID === newTask.id) {
        updatedTask.groupId = newTask.groupId;
        updatedTask.groupName = newTask.groupName; 
      }
    });
    return updatedTask;
  });

  const sortedTasks = updatedTasks.sort((a, b) => {
    const groupIdA = a.groupId !== null ? a.groupId : Infinity;
    const groupIdB = b.groupId !== null ? b.groupId : Infinity;
    return groupIdA - groupIdB; 
  });

  const department = React.useMemo(() => {
    return departments?.find((d: Department) => d.id === currentDepartmentID);
  }, [departments, currentDepartmentID]);
  const [updateDefaultStakeholder] =
    useSetDefaultStakeholderForSEPDepartmentMutation();
  const handleDefaultStakeholderChange = async (user: User) => {
    setIsStakeholderEdit(false);
    await updateDefaultStakeholder({
      userID: user.id,
      sepID,
      departmentID: currentDepartmentID as number,
    });
  };
  /** Whether the department existed at the time this SEP was created.
   * If the department did not exist at the time of SEP creation, all controls are disabled. */
  const departmentExisted =
    department && sep && department?.createdAt < sep?.createdAt;
  return departmentExisted ? (
    <>
      <ModalWidget
        isOpen={isOpen}
        title="Add Task"
        children={
          <StepPage
            isInit={!isOpen}
            sepID={sepID}
            departmentID={currentDepartmentID}
            onFinish={(newTask: TaskExtended) => {
              onNewTaskAdded();
              setIsOpen(false);
            }}
          />
        }
        onClose={() => setIsOpen(false)}
      />
      <Box sx={{ marginLeft: 5, marginBottom: 5 }}>
        <Typography fontSize="12px" color="gray.darkGray1">
          Default Stakeholder for {department?.name} for this SEP &nbsp; &nbsp;
          <IconCell
            icon="fa-regular fa-pencil"
            iconHover="fa-solid fa-pencil"
            onClick={() => setIsStakeholderEdit(true)}
          />
        </Typography>
        <Box ref={stakeholderRef} display="flex" alignItems="center">
          <UserDisplay
            userID={defaultStakeholder?.userID}
            user={defaultStakeholder?.user}
            showPicture
            showName
            title={`${department?.name}`}
          />
        </Box>
        <UserSearchBar
          type="stakeholder"
          show={isStakeholderEdit}
          anchor={stakeholderRef.current}
          adAppRoleName={department?.adAppRole}
          onClose={() => {
            setIsStakeholderEdit(false);
          }}
          onChange={(user: User) => handleDefaultStakeholderChange(user)}
        />
      </Box>
      <Box
        sx={{
          flexGrow: 1,
          bgcolor: 'background.paper',
          display: 'flex',
          overflow: 'auto',
        }}
      >
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={currentSubTab}
          onChange={handleSubTabChange}
          aria-label="Vertical tabs"
          sx={{ borderRight: 1, borderColor: 'divider', minWidth: 182 }}
          TabIndicatorProps={{
            style: { backgroundColor: theme.palette.blue.lightBlue1 },
          }}
        >
          <StyledTab
            value="DepartmentReview"
            icon={<i className="fa-solid fa-book-open"></i>}
            iconPosition="start"
            label="Review"
            {...a11yProps(0)}
          />
          {/* 
            Deparment tab disabled

          <StyledTab
            value="Comments"
            icon={<i className="fa-solid fa-comments"></i>}
            iconPosition="start"
            label="Comments"
            {...a11yProps(1)}
            sx={{ paddingLeft: 3.4 }}
          /> */}
          <StyledTab
            value="Department"
            icon={<i className="fa-regular fa-list"></i>}
            iconPosition="start"
            label="Details"
            {...a11yProps(2)}
          />
        </Tabs>
        <TabPanel value="Department" currentSubTab={currentSubTab} index={0}>
          <Box px={3}>
            <DataForm
              dataFields={dataFieldsForDepartment}
              data={data}
              setData={setData}
              locationID={locationID}
              setDataChanged={setDataChanged}
              currentSubTab={currentSubTab}
              currentDepartmentID={currentDepartmentID}
            />
          </Box>
          {dataChanged && (
            <ActionButtons
              handleCancel={handleCancel}
              handleSave={handleSave}
              buttonValidation={buttonValidation}
            />
          )}
        </TabPanel>
        {/* <TabPanel value="Comments" currentSubTab={currentSubTab} index={1}>
          <Comments
            sepID={sepID}
            comments={
              comments?.filter(
                (comment: CommentExtended) =>
                  comment.departmentID === currentDepartmentID &&
                  !comment.taskID
              ) || []
            }
            currentDepartmentID={currentDepartmentID}
          />
        </TabPanel> */}
        <TabPanel
          value="DepartmentReview"
          currentSubTab={currentSubTab}
          index={2}
        >
          <Box px={3}>
            <DataForm
              dataFields={dataFieldsForDepartment}
              data={data}
              setData={setData}
              setDataChanged={setDataChanged}
              currentSubTab={currentSubTab}
              currentDepartmentID={currentDepartmentID}
            />
            {dataChanged && (
              <ActionButtons
                handleCancel={handleCancel}
                handleSave={handleSave}
                buttonValidation={buttonValidation}
              />
            )}
          </Box>
          <br />
          {tasksForDepartment.length > 0 && (
            <>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  padding: '10px',
                }}
              >
                <Toolbar
                  title="Task Assigner"
                  caption="Choose tasks to assign to complete the review process"
                  hideToggle
                />
                <Button
                  variant="outlined"
                  size="small"
                  sx={{
                    '&.MuiButton-outlined': {
                      border: '2px solid #2372B9',
                      color: '#2372B9',
                      textTransform: 'none',
                      fontWeight: 700,
                      marginRight: '20px',
                    },
                  }}
                  onClick={() => setIsOpen(true)}
                >
                  + Create New Task
                </Button>
              </Box>
              <Divider />
              <br />
              <Box px={3}>
                <TaskAssigner
                  sepId={sepID}
                  tasksForDepartment={sortedTasks}
                />
                <br />
              </Box>
              <br />
              <Toolbar
                title="SEP Task History"
                caption="This is a list of all tasks that have been assigned up to this point for review"
                filter={filterTasks}
                onChange={handleTasksSwitch}
                hidePendingTasks={hidePendingDeptTasks}
                hideFilterChange={hideFilterChange}
              />
              <Divider />
              <Typography
                py={2}
                pl={3}
                sx={{ font: 'normal normal 600 14px/19px Open Sans;' }}
              >
                Requestor
              </Typography>
                <TasksTable
                  sepID={sepID}
                  tasks={tasksForDepartment.filter((e: any) => {
                    if(filterTasks && hidePendingDeptTasks) {
                      return e.enabled && e.status !== TaskStatus.pending && e.status !== TaskStatus.complete && e.assignedUserID === sep?.createdBy
                    } else if(hidePendingDeptTasks) {
                      return e.enabled && e.status !== TaskStatus.pending && e.assignedUserID === sep?.createdBy
                    } else if(filterTasks) {
                      return e.enabled && e.status !== TaskStatus.complete && e.assignedUserID === sep?.createdBy
                    } else {
                      return e.assignedUserID === sep?.createdBy && e.enabled
                    }
                  })}
                />
              <br />
              <Typography
                py={2}
                pl={3}
                sx={{ font: 'normal normal 600 14px/19px Open Sans;' }}
              >
                Stakeholders
              </Typography>
              <TasksTable
                sepID={sepID}
                tasks={tasksForDepartment.filter(
                  (e) =>
                    e.assignedUserID !== sep?.createdBy && e.enabled === true
                )}
              />
            </>
          )}
        </TabPanel>
      </Box>
    </>
  ) : (
    <Typography sx={{ marginLeft: 5 }} fontSize="12px" color="gray.darkGray1">
      This department did not exist at the time this SEP was created.
    </Typography>
  );
}

const TaskAssigner = ({ tasksForDepartment, sepId }: any) => {
  const [updateTasks, updateMutationStatus] = useUpdateTasksMutation();
  const [TasksForDepartment, setTasksForDepartment] = useState<any[]>([]);

  const handleCheck = (e: any, id: number) => {
    // const newState = TasksForDepartment?.map((obj) =>
    //   obj.id === id ? { ...obj, enabled: e.target.checked } : obj
    // );
    // setTasksForDepartment(newState);
    const isChecked = e.target.checked;
    const currentTask = TasksForDepartment?.find(task => task.id === id);
    const updatedTasks = TasksForDepartment?.map((task: any) => {
        if (currentTask.groupName && task.groupName === currentTask.groupName) {
            return { ...task, enabled: isChecked };
        }
        if (!currentTask.groupName && task.id === id) {
            return { ...task, enabled: isChecked };
        }
        return task;
    });
    setTasksForDepartment(updatedTasks);
  };

  const handleTasksCancel = () => {
    setTasksForDepartment(tasksForDepartment);
  };

  const handleTasksUpdate = () => {
    // Only send updates for tasks that have been changed
    const simplifiedTasks = TasksForDepartment.map(cleaner).filter(
      (a, idx) => a.enabled !== tasksForDepartment[idx].enabled
    );
    console.log("Tasks to be added/ Updated check for template id , default assignee",TasksForDepartment)
    updateTasks({ sepId: sepId, tasks: simplifiedTasks });
  };

  const cleaner = (obj: any) => {
    const { id, enabled,defaultAssignee, taskTemplateID, groupName, sepID } = obj;
    return { id, enabled, defaultAssignee, taskTemplateID, groupName, sepID  };
  };

  const compareArrays = (a: any[], b: any[]) =>
    a.length === b.length &&
    a.every((element, index) => element.enabled === b[index].enabled);

  const displayActionButtons = !compareArrays(
    tasksForDepartment,
    TasksForDepartment
  );

  useEffect(() => {
    setTasksForDepartment(tasksForDepartment);
  }, [tasksForDepartment]);

  return (
    <>
      <Grid container spacing={2}>
        {TasksForDepartment?.map((task: any, i: number) => {
          return (
            <Grid key={task.id} item md={4}>
              <Checkbox
                checked={task.enabled}
                onChange={(e: any) => handleCheck(e, task.id)}
                label={task.shortName}
              />
            </Grid>
          );
        })}
      </Grid>
      {displayActionButtons && (
        <ActionButtons
          loading={updateMutationStatus.isLoading}
          handleCancel={handleTasksCancel}
          handleSave={handleTasksUpdate}
        />
      )}
    </>
  );
};
