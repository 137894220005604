import React, { useState } from 'react';
import {
  Box,
  Divider,
  Stack,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import HoveredIcon from '../../HoveredIconButton';
import HoveredIconConfirm from '../../HoveredIconConfirmButton';
import FollowUp from './FollowUp';
import {
  useDeleteDataFieldLocationTemplateMutation,
  useUpdateDataFieldLocationTemplateMutation,
} from '../../../../services/dataFieldsSlice';

import ModalWidget from '../../../ModalWidget/ModalWidget';
import AddDataField from '../../DataFields/AddDataField';
import ReorderDataFieldsModal from '../../KnockoutScreens/DataFieldCard/ReorderDataFields';
import { DataFieldLocationType } from '../../../../../shared/types/DataField';

const DataFieldCard = ({ dataField, index, lastElement, type, dataFields, taskTemplate, locationName, locationDescription }: any) => {
  const [deleteDataFieldLocationTemplate] = useDeleteDataFieldLocationTemplateMutation();
  const [updateDataFieldTemplateLocation] = useUpdateDataFieldLocationTemplateMutation();
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [editDataField, setEditDataField] = useState<boolean>(false);
  const [isReorderModalOpen, setIsReorderModalOpen] = useState<boolean>(false);
   
  const handleDelete = () => {
    deleteDataFieldLocationTemplate(dataField.dataFieldLocation.id);
  };

  const handleDataFieldLocation = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const { name, checked } = event.target;
    const property = { [name]: checked };
    updateDataFieldTemplateLocation({
      id: dataField.dataFieldLocation.id,
      body: property,
    });
  };

  const toggleEdit = () => {
    setEditDataField(!editDataField);
  };

  const toggleReorderModal = () => {
    setIsReorderModalOpen(!isReorderModalOpen);
  };

  return (
    <>
      <ReorderDataFieldsModal
        isOpen={isReorderModalOpen}
        onClose={toggleReorderModal}
        datafields={dataFields}
        locationName={locationName}
        locationDescription={locationDescription || ''}
        type={DataFieldLocationType.Task}
      />
      {editDataField && (
        <ModalWidget
          isOpen={editDataField}
          title="Edit Data Field"
          onClose={toggleEdit}
      >
          <AddDataField
            isClose={!editDataField}
            dataField={dataField}
            toggleEdit={toggleEdit}
          />
        </ModalWidget>
      )}
      <Box
        sx={{
          backgroundColor: '#F8F9FA',
          padding: '16px 40px 16px 45px',
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>
            <Stack display="flex" flexDirection="row" alignItems="center">
              <Typography
                component="span"
                sx={{
                  color: '#646669',
                  fontSize: '12px',
                  fontWeight: '700',
                }}
              >
                {dataField?.name}
              </Typography>
              &nbsp;&nbsp;
              {!!dataField.length && (
                <i
                  className={`fa-sharp fa-solid fa-chevron-${
                    showDetail ? 'up' : 'down'
                  }`}
                  onClick={() => setShowDetail(!showDetail)}
                ></i>
              )}
            </Stack>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              {dataField.description || ''}
            </Typography>
          </Box>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              width: '115px',
            }}
          >
            <HoveredIconConfirm
              icon="fa-regular fa-trash"
              hoverIcon="fa-solid fa-trash"
              onClick={() => handleDelete()}
            />
            <HoveredIcon
              icon="fa-regular fa-pencil"
              hoverIcon="fa-solid fa-pencil"
              onClick={() => toggleEdit()}
            />
            <HoveredIcon
              icon="fa-regular fa-arrow-up-arrow-down"
              hoverIcon="fa-solid fa-arrow-up-arrow-down"
              onClick={() =>  setIsReorderModalOpen(true)}
            />
          </Stack>
        </Stack>
        <Box sx={{ paddingTop: '15px' }}>
          <FormControlLabel
            sx={{ '& > span': { fontSize: '14px' } }}
            control={
              <Checkbox
                name="required"
                onChange={handleDataFieldLocation}
                checked={dataField.dataFieldLocation.required}
              />
            }
            label="Required"
          />
          <FormControlLabel
            sx={{ '& > span': { fontSize: '14px' } }}
            control={
              <Checkbox
                name="readOnly"
                onChange={handleDataFieldLocation}
                checked={dataField.dataFieldLocation.readOnly}
              />
            }
            label="Read Only"
          />
        </Box>
        {showDetail && (
          <>
            <Divider sx={{ marginRight: '-40px' }} />
            {dataField.knockoutFollowups.map((e: any, index: number) => (
              <FollowUp key={index} followUp={e} />
            ))}
          </>
        )}
      </Box>
      {lastElement !== index && <Divider />}
    </>
  );
};

export default DataFieldCard;
