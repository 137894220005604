import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Box,
  Button,
} from '@mui/material';

const SepSubscriptionsModal = (
  {subscribedSeps, handleSave}:{subscribedSeps:any, handleSave:any}
) => {
  console.log("Subscribed SEPS from modal",subscribedSeps)

  
  return (
    <Box sx={{ p: 3, maxHeight: '500px', overflow: 'auto' }}>
      {subscribedSeps?.length >0 ?
      <Table sx={{ width: '100%' }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            <TableCell>SEP ID</TableCell>
            <TableCell>SEP Name</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subscribedSeps.map((sep:any, index:number) => {
            return (
              <TableRow key={index}>
                <TableCell>{sep.id}</TableCell>
                <TableCell>{sep.name}</TableCell>
                <TableCell>
                  <Button onClick={()=>handleSave(sep.id,sep.subscribers)}>Unsubscribe</Button>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table> : <div>No SEPs  subscribed yet</div>
      }
    </Box>
  );
};

export default SepSubscriptionsModal;
