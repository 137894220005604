import * as React from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OpenSansRegularWoff from '../assets/fonts/open-sans-v34-latin-regular.woff';
import OpenSansRegularWoff2 from '../assets/fonts/open-sans-v34-latin-regular.woff2';
import OpenSansRegularEot from '../assets/fonts/open-sans-v34-latin-regular.eot';
import OpenSansRegularTtf from '../assets/fonts/open-sans-v34-latin-regular.ttf';
import OpenSansRegularSvg from '../assets/fonts/open-sans-v34-latin-regular.svg';
import { CssBaseline } from '@mui/material';

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      status1: React.CSSProperties['color'];
      status2: React.CSSProperties['color'];
      status3: React.CSSProperties['color'];
    };
  }
  interface ThemeOptions {
    status?: {
      status1: React.CSSProperties['color'];
      status2: React.CSSProperties['color'];
      status3: React.CSSProperties['color'];
    };
  }
  interface TypeBackground {
    knockoutOne?: string;
  }
  interface Palette {
    gray: Palette['primary'];
    solidBlue: Palette['primary'];
    darkGreen: Palette['primary'];
    blue: Palette['primary'];
    orange: Palette['primary'];
    deepRed: Palette['primary'];
  }
  interface PaletteOptions {
    gray: PaletteOptions['primary'];
    solidBlue: PaletteOptions['primary'];
    darkGreen: PaletteOptions['primary'];
    blue: PaletteOptions['primary'];
    orange: PaletteOptions['primary'];
    deepRed: PaletteOptions['primary'];
  }
  interface PaletteColor {
    hover?: string;
    active?: string;
    lightHover?: string;
    lightActive?: string;
    lighter?: string;
    blue1?: string;
    blue2?: string;
    focus?: string;
    lightBlue1?: string;
    lightBlue2?: string;
    solidOrange?: string;
    lightOrange?: string;
    lightGreen?: string;
    solidGray?: string;
    darkGray1?: string;
    lightGray?: string;
    lightGray1?: string;
    lightGray2?: string;
    lightGray3?: string;
    status1?: string;
    status2?: string;
    status3?: string;
  }
  interface SimplePaletteColorOptions {
    hover?: string;
    active?: string;
    lightHover?: string;
    lightActive?: string;
    lighter?: string;
    blue1?: string;
    blue2?: string;
    focus?: string;
    lightBlue1?: string;
    lightBlue2?: string;
    solidOrange?: string;
    lightOrange?: string;
    lightGreen?: string;
    solidGray?: string;
    darkGray1?: string;
    lightGray?: string;
    lightGray1?: string;
    lightGray2?: string;
    lightGray3?: string;
    status1?: string;
    status2?: string;
    status3?: string;
  }
  interface TypographyVariants {
    title: React.CSSProperties;
  }

  // allow configuration using `createTheme`
  interface TypographyVariantsOptions {
    title?: React.CSSProperties;
  }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
  interface TypographyPropsVariantOverrides {
    title: true;
  }
}

type Props = {
  children: JSX.Element;
};

const theme = createTheme({
  status: {
    status1: '#EDF4FA',
    status2: '#FFFBF6',
    status3: '#F3F8F0',
  },
  palette: {
    primary: {
      main: '#0971f1',
    },
    gray: {
      main: '#222222',
      solidGray: '#7e8083',
      lighter: '#7e808340',
      darkGray1: '#646669',
      lightGray: '#F2F2F2',
      lightGray1: '#E5E9EA',
      lightGray2: '#F8F9FA',
      lightGray3: '#FBFCFC',
    },
    solidBlue: {
      main: '#2372B9',
      lightHover: 'rgb(35,114,185, .05)',
      lightActive: '#EDF4F9',
    },
    darkGreen: {
      main: '#6BA543',
      lightGreen: '#CADB2E',
    },
    blue: {
      main: '#007fa4', // SECONDARY blue
      blue1: '#1F67A6',
      blue2: '#1D5F99',
      focus: '#BCD4EA',
      lightBlue1: '#F3F8FC',
      lightBlue2: '#EDF4F9',
    },
    orange: {
      main: '#f47b27', // PRIMARIY solidOrange
      lightOrange: '#fbb254',
    },
    deepRed: { main: '#B70000' },
  },
  typography: {
    fontFamily: 'Open Sans',
    title: {
      font: 'normal normal 600 28px/38px Open Sans;',
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
      /* open-sans-regular - latin */
      @font-face {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        src: url(${OpenSansRegularEot}); /* IE9 Compat Modes */
        src: local(''),
             url(${OpenSansRegularEot}) format('embedded-opentype'), /* IE6-IE8 */
             url(${OpenSansRegularWoff2}) format('woff2'), /* Super Modern Browsers */
             url(${OpenSansRegularWoff}) format('woff'), /* Modern Browsers */
             url(${OpenSansRegularTtf}) format('truetype'), /* Safari, Android, iOS */
             url(${OpenSansRegularSvg}) format('svg'); /* Legacy iOS */
      }
      `,
    },
  },
});

export default function CustomStyles({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
