import { SequelizeTimestamps } from './Sequelize';

export enum TrackableType {
  Task = 'Task',
  SEP = 'SEP',
  Comment = 'Comment',
  Attachment = 'Attachment',
  DataField = 'DataField',
  DataFieldOption = 'DataFieldOption',
}

export enum ActivityAction {
  'Create' = 'Create',
  'Update' = 'Update',
  'Delete' = 'Delete',
}

export const icons: { [key: string]: string } = {
  CreateTask: 'fa-solid fa-pencil',
  UpdateTask: 'fa-solid fa-pencil',
  DeleteTask: 'fa-solid fa-pencil',
  CreateSEP: 'fa-solid fa-flag',
  UpdateSEP: 'fa-solid fa-pencil',
  DeleteSEP: 'fa-solid fa-pencil',
  CreateComment: 'fa-solid fa-comment',
  UpdateComment: 'fa-solid fa-comment',
  DeleteComment: 'fa-solid fa-comment',
  CreateAttachment: 'fa-solid fa-file',
  UpdateAttachment: 'fa-solid fa-file',
  DeleteAttachment: 'fa-solid fa-file',
  CreateDataField: 'fa-solid fa-pen-field',
  UpdateDataField: 'fa-solid fa-pen-field',
  DeleteDataField: 'fa-solid fa-pen-field',
  CreateDataFieldOption: 'fa-solid fa-pen-field',
  UpdateDataFieldOption: 'fa-solid fa-pen-field',
  DeleteDataFieldOption: 'fa-solid fa-pen-field',
};

export interface NewActivity {
  userID: string;
  sepID: number;
  trackableType: TrackableType;
  trackableID: number;
  action: ActivityAction;
  title: string;
  description?: string; // Further description (string)
  property?: string;
  oldValue?: string;
  newValue?: string;
  data?: string; // JSON encoded data relevant to this activity
}
export interface Activity extends NewActivity, SequelizeTimestamps {
  id: number;
}
export interface ActivitySearchResult {
  count: number;
  activities: Activity[];
}


export interface SearchParams {
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortAsc?: boolean;
  trackableType?: string;
  trackableID?: string;
  search?: string;
  sepID?: string;
  taskID?: string;
}
