import { sepAPI } from '../API';
import { User, SearchParams } from '../../../shared/types/User';
import { setSnackbarForEndpoint } from '../../utils/snackbar';

export const usersSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUser: builder.query<User, string | undefined>({
      query: (id) => `users/${id}`,
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting this user.',
        });
      },
    }),
    searchUsers: builder.query<User[], SearchParams>({
      query: (arg) => {
        const { search, adAppRoleName } = arg;
        return {
          url: `users`,
          params: {
            search: search,
            adAppRoleName: adAppRoleName,
          },
        };
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting this users.',
        });
      },
    }),
  }),
});

export const { useGetUserQuery, useSearchUsersQuery } = usersSlice;
