import * as React from 'react';
import MobileStepper from '@mui/material/MobileStepper';

export default function DotsMobileStepper(props: any) {
  return (
    <MobileStepper
      variant="dots"
      steps={props.steps}
      position="static"
      activeStep={props.activeStep}
      sx={{ maxWidth: '100%', flexGrow: 1, justifyContent: 'center' }}
      nextButton={<></>}
      backButton={<></>}
    />
  );
}
