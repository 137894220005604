import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { useNavigate } from 'react-router';
const Logo = require('../../assets/img/constellation-logo.png');

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
        marginTop: '-30px',
      }}
    >
      <img alt="Constellation" src={Logo} />
      <Typography variant="h5" sx={{ fontStyle: 'italic', mt: 3 }}>
        {' '}
        Page Not Found
      </Typography>
      <Button onClick={() => navigate('/')}>Return Home</Button>
    </Box>
  );
};

export default NotFound;
