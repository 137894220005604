import React, { useMemo } from 'react';
import { Box, Divider, Grid, Typography } from '@mui/material';
import { format, parseISO } from 'date-fns';
import { useGetSepQuery, useGetSepTasksQuery } from '../../services/sepSlice';
import './styles.css';
import SepReviewTeam from './SepReviewTeam';
import APMInfo from './APMInfo';
import { useGetSepStakeholderQuery } from '../../services/defaultStakeholderSlice';
import { TaskExtended } from '../../../shared/types/Task';

interface SepDetailProps {
  sepID: number;
  alternateName: string;
  alternateEmail: string;
}

const SepInfo = ({ sepID, alternateEmail, alternateName }: SepDetailProps) => {
  const { data: sep } = useGetSepQuery(sepID);
  const { data: tasks } = useGetSepTasksQuery(sepID);
  const { data: stakeHolders } = useGetSepStakeholderQuery(sepID);

  const reviewTeam = useMemo(() => {
    const enabledTasks: TaskExtended[] =
      tasks?.filter((e: any) => e.enabled) || [];

    // Create a Set of unique department IDs from enabledTasks
    const uniqueDepartmentIds = new Set(
      enabledTasks?.map((task) => task?.department?.id)
    );

    // Filter the stakeHolders based on the uniqueDepartmentIds and map to the desired structure
    return (
      stakeHolders
        ?.filter((stakeHolder) =>
          uniqueDepartmentIds.has(stakeHolder.departmentID)
        )
        .map((stakeHolder) => ({
          reviewer: stakeHolder.user ?? null,
          department: stakeHolder.department,
        })) || []
    );
  }, [tasks, stakeHolders]);

  return (
    <>
      <Box sx={{ padding: '30px 30px 10px 30px' }}>
        <Box sx={{ mb: 4 }}>
          <Typography
            sx={{
              font: ' normal normal 600 25px/34px Open Sans;',
            }}
          >
            {sep?.name}
          </Typography>
          <Typography
            sx={{
              font: 'normal normal normal 18px/24px Open Sans;',
              color: '#7E8083',
              mt: 1,
            }}
          >
            {`SEP ${sepID}`}
          </Typography>
        </Box>
        <Grid container spacing={0}>
          <Grid
            container
            item
            sx={{ display: 'flex', justifyContent: 'space-between', mb: 3 }}
            xs={12}
            md={12}
            lg={12}
          >
            <Grid item md={4}>
              <p className="sep-label">Requestor Name</p>
              <p className="sep-data">
                {sep?.creator?.givenName && sep?.creator?.surname
                  ? `${sep?.creator?.givenName} ${sep?.creator?.surname}`
                  : sep?.creator?.displayName}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className="sep-label">Requestor Email</p>
              <p className="sep-data">{sep?.creator.email}</p>
            </Grid>
            <Grid item md={4}>
              <p className="sep-label">Request Date</p>
              <p className="sep-data">
                {sep && format(parseISO(sep.createdAt as string), 'P')}
              </p>
            </Grid>
          </Grid>
          <Grid
            container
            item
            sx={{ display: 'flex', mb: 3 }}
            xs={12}
            md={12}
            lg={12}
          >
            <Grid item md={4}>
              <p className="sep-label">Alternate Contact</p>
              <p className="sep-data" data-testid="alternate_name">
                {alternateName}
              </p>
            </Grid>
            <Grid item md={4}>
              <p className="sep-label">Status</p>
              <p className="sep-data">{sep?.status}</p>
            </Grid>
          </Grid>
          <Grid container item sm={12} md={12} lg={12}>
            <p className="sep-label">Description</p>
          </Grid>
          <Grid container item sm={12} md={12} lg={12}>
            <div
              className="sep-description"
              dangerouslySetInnerHTML={{ __html: sep?.description ?? '' }}
            ></div>
          </Grid>
        </Grid>
      </Box>
      <Divider
        sx={{
          backgroundColor: '#F8F9FA',
          height: '8px',
          mt: 3,
          border: 'none',
        }}
      />
      {tasks && (
        <>
          <SepReviewTeam reviewerTeams={reviewTeam} />
          <Divider
            sx={{
              backgroundColor: '#F8F9FA',
              height: '8px',
              border: 'none',
            }}
          />
        </>
      )}
      {sep?.apmData && (
        <>
          <APMInfo apmData={sep?.apmData} />
          <Divider
            sx={{
              backgroundColor: '#F8F9FA',
              height: '8px',
              border: 'none',
            }}
          />
        </>
      )}
    </>
  );
};

export default SepInfo;
