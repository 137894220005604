import React from 'react';
import { Box, Typography, FormControlLabel } from '@mui/material';
import Switch from '../Switch';

interface Toolbar {
  title: string;
  caption: string;
  filter?: boolean;
  hidable?: boolean;
  hide?: boolean;
  hideToggle?: boolean;
  onChange?: () => void;
  hideFilterChange?: () => void;
  handleHide?: () => void;
  hidePendingTasks?: boolean
}

const SepToolbar: React.FC<Toolbar> = ({
  title,
  caption,
  hidable,
  hide,
  filter,
  hideToggle,
  onChange,
  handleHide,
  hideFilterChange,
  hidePendingTasks
}: Toolbar) => {
  const display = hideToggle ? { display: 'none' } : null;
  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          pl: 3,
          pr: 4,
          py: 1,
        }}
      >
        <Box>
          <Typography sx={{ font: 'normal normal 600 18px/24px Open Sans;' }}>
            {title}
            &nbsp;
            {hidable &&
              (hide ? (
                <i className="fa-regular fa-angle-up" onClick={handleHide}></i>
              ) : (
                <i
                  className="fa-regular fa-angle-down"
                  onClick={handleHide}
                ></i>
              ))}
          </Typography>
          <Typography variant="caption">{caption}</Typography>
        </Box>
        <Box sx={{display: 'flex', alignItems: 'center'}}>
        <FormControlLabel
          control={<Switch checked={hidePendingTasks} onChange={hideFilterChange} />}
          sx={{ marginRight: '20px', display}}
          label={
            <Typography
              sx={{ font: 'normal normal normal 14px/22px Open Sans;', mr: 2 }}
            >
              Hide Pending Tasks
            </Typography>
          }
          labelPlacement="start"
        />
        <FormControlLabel
          control={<Switch checked={filter} onChange={onChange} />}
          sx={{ alignItems: 'flex-end', display }}
          label={
            <Typography
              sx={{ font: 'normal normal normal 14px/22px Open Sans;', mr: 2 }}
            >
              Hide Complete Tasks
            </Typography>
          }
          labelPlacement="start"
        />
        </Box>
      </Box>
    </>
  );
};

export default SepToolbar;
