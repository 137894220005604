import { sepAPI } from '../API/sepAPI';
import {
  CreateKnockoutFollowupTemplateBody,
  CreateKnockoutScreenTemplateBody,
  KnockoutFollowup,
  KnockoutScreenTemplate,
  KnockoutScreenWithDataFields,
  UpdateKnockoutScreenTemplateBody,
} from '../../../shared/types/Knockout';
import { DataFieldUpdate } from '../../../shared/types/DataField';
import { setSnackbarForEndpoint } from '../../utils/snackbar';

export const knockoutSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    getKnockout: builder.query<KnockoutScreenWithDataFields[], number>({
      query: (sepId) => `knockouts/${sepId}`,
      keepUnusedDataFor: 0,
    }),
    sendKnockoutScreenAnswers: builder.mutation<
      KnockoutScreenWithDataFields[],
      { knockoutScreenID: number; dataFields: DataFieldUpdate[] }
    >({
      query: ({ knockoutScreenID, dataFields }) => {
        return {
          url: `knockout/${knockoutScreenID}`,
          method: 'PATCH',
          body: dataFields,
        };
      },
    }),
    getKnockoutScreenTemplate: builder.query<KnockoutScreenTemplate[], void>({
      query: () => `knockout-templates`,
      providesTags: ['Knockout-Templates'],
    }),
    getWorkflowDataFieldTemplate: builder.query<KnockoutScreenTemplate[], void>({
      query: () => `workflow-knockout-templates`,
      providesTags: ['Knockout-Templates'],
    }),
    deleteKnockoutScreenTemplate: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `knockout-template/${id}`,
          method: 'DELETE',
          params: { id: id },
        };
      },
      invalidatesTags: ['Knockout-Templates'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'KnockoutScreen-Template deleted successfully!',
          errorMessage:
            'There was a problem deleting your KnockoutScreen-Template.',
        });
      },
    }),

    addKnockoutScreenTemplate: builder.mutation<
      KnockoutScreenTemplate,
      CreateKnockoutScreenTemplateBody
    >({
      query: (data) => {
        return {
          url: `knockout-templates`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Knockout-Templates'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'KnockoutScreen-Template created successfully!',
          errorMessage:
            'There was a problem creating your KnockoutScreen-Template.',
        });
      },
    }),
    editKnockoutScreenTemplate: builder.mutation<
      KnockoutScreenTemplate,
      { id: number; body: UpdateKnockoutScreenTemplateBody }
    >({
      query: ({ id, body }) => {
        return {
          url: `knockout-template/${id}`,
          method: 'PATCH',
          body: body,
        };
      },
      invalidatesTags: ['Knockout-Templates'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'KnockoutScreen-Template updated successfully!',
          errorMessage:
            'There was a problem updating your KnockoutScreen-Template.',
        });
      },
    }),
    deleteFollowupTemplate: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `knockout-followup-templates/${id}`,
          method: 'DELETE',
          params: { id: id },
        };
      },
      invalidatesTags: ['Knockout-Templates', 'Followups'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'KnockoutScreen FollowUp deleted successfully!',
          errorMessage:
            'There was a problem deleting your KnockoutScreen FollowUp.',
        });
      },
    }),

    addFollowupTemplate: builder.mutation<
      KnockoutFollowup,
      CreateKnockoutFollowupTemplateBody
    >({
      query: (data) => {
        return {
          url: `knockout-followup-templates`,
          method: 'POST',
          body: { ...data },
        };
      },
      invalidatesTags: ['Knockout-Templates', 'Followups'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'KnockoutScreen FollowUp created successfully!',
          errorMessage:
            'There was a problem creating your KnockoutScreen FollowUp.',
        });
      },
    }),

    editFollowupTemplate: builder.mutation<
      KnockoutFollowup,
      { body: CreateKnockoutFollowupTemplateBody; id: number }
    >({
      query: ({ body, id }) => {
        return {
          url: `knockout-followup-templates/${id}`,
          method: 'PATCH',
          body: { ...body },
        };
      },
      invalidatesTags: ['Knockout-Templates', 'Followups'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'KnockoutScreen FollowUp updated successfully!',
          errorMessage:
            'There was a problem updating your KnockoutScreen FollowUp.',
        });
      },
    }),
  }),
});

export const {
  useGetKnockoutQuery,
  useSendKnockoutScreenAnswersMutation,
  useGetKnockoutScreenTemplateQuery,
  useGetWorkflowDataFieldTemplateQuery,
  useAddKnockoutScreenTemplateMutation,
  useEditKnockoutScreenTemplateMutation,
  useAddFollowupTemplateMutation,
  useEditFollowupTemplateMutation,
  useDeleteKnockoutScreenTemplateMutation,
  useDeleteFollowupTemplateMutation,
} = knockoutSlice;
