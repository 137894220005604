import React from 'react';
import { DataFieldLocationType } from '../../../../../shared/types/DataField';
import {
  useCreateDataFieldLocationTemplateMutation,
  useCreateDataFieldTemplateMutation,
} from '../../../../services/dataFieldsSlice';
import ModalWidget from '../../../ModalWidget/ModalWidget';
import AddDepartmentDataField, {
  AddDepartmentDataFieldHandler,
} from './AddDepartmentDataField';
import ApplyDataField from '../../DataFields/ApplyDataField';

export interface ModalProps {
  handle: string;
  setHandle: React.Dispatch<React.SetStateAction<string>>;
  locationID: number;
}

export const AddDepartmentDataFieldModal = ({
  handle,
  setHandle,
  locationID,
}: ModalProps) => {
  const isDepartment = true;
  const [createDataField] = useCreateDataFieldTemplateMutation();
  const [locType, setLocType] = React.useState<DataFieldLocationType>(
    DataFieldLocationType.Department
  );
  const [createDataFieldLocation] =
    useCreateDataFieldLocationTemplateMutation();

  const handleAddDataField: AddDepartmentDataFieldHandler = (
    df,
    locationType
  ) => {
    createDataField(df).then((res: any) => {
      if ('data' in res) {
        createDataFieldLocation({
          dataFieldID: res.data.id,
          locationID: locationID,
          locationType: locationType,
          required: false,
          readOnly: false,
          order: 0,
        });
      }
    });
    setHandle('');
  };

  const handleApplyDataField = (e: any) => {
    e.forEach((data: any) => {
      createDataFieldLocation({
        dataFieldID: data.id,
        locationID: locationID,
        locationType: locType,
        required: false,
        readOnly: false,
        order: 0,
      });
    });
    setHandle('');
  };

  const isOpen =
    handle === 'Add Data Field' || handle === 'Apply Data Field(s)';
  const handleClose = () => setHandle('');

  return (
    <ModalWidget
      title={handle}
      isOpen={isOpen}
      onClose={handleClose}
      children={
        handle === 'Add Data Field' ? (
          <AddDepartmentDataField onAdd={handleAddDataField} />
        ) : (
          <ApplyDataField
            onClose={handleClose}
            isDepartment={isDepartment}
            onApply={handleApplyDataField}
            closed={handle === 'Apply Data Field(s)'}
            setLocType={setLocType}
          />
        )
      }
    />
  );
};
