import React from 'react';
import {
  FormHelperText,
  InputLabel,
  styled,
  Box,
  Typography,
} from '@mui/material';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
import InputBase, { InputBaseProps } from '@mui/material/InputBase';

const MuiTextField = styled(InputBase)(({ theme }) => ({
  border: '1px solid',
  borderColor: theme.palette.gray.solidGray,
  borderRadius: '6px',
  paddingLeft: '8px',
  color: theme.palette.gray.main,
  font: 'normal normal normal 14px/22px Open Sans;',
  '&:hover': {
    border: '2px solid',
    borderColor: theme.palette.solidBlue.main,
  },
  '&:active': {
    border: '2px solid',
    borderColor: theme.palette.solidBlue.main,
  },
  '&.Mui-focused': {
    boxShadow: `0 0 0 3px ${theme.palette.blue.focus}`,
    backgroundColor: '#fff',
  },
  '&.Mui-disabled': {
    backgroundColor: theme.palette.gray.lightGray,
  },
}));

const StyledTextField = (props: InputBaseProps) => <MuiTextField {...props} />;

const TextFieldComponent = (props: any) => {
  const {
    label,
    caption,
    icon,
    error,
    helperText,
    readOnly,
    required,
    field,
    ...rest
  } = props;

  return (
    <>
      <Box key={field?.id}>
        <Typography className={required ? 'required' : ''} my={2}>
          {field?.name}
        </Typography>
        {label && (
          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <InputLabel>{label}</InputLabel>
            &nbsp;{icon}
          </Box>
        )}
        {props.isDomString ? (
          <RichTextEditor
            style={{ height: '240px' }}
            disabled={readOnly}
            value={props.value}
            setValue={(e) => props.onChange(e)}
          />
        ) : (
          <StyledTextField disabled={readOnly} {...rest} />
        )}
        <FormHelperText>{caption}</FormHelperText>
        <FormHelperText error={error}>{helperText}</FormHelperText>
      </Box>
    </>
  );
};

export default TextFieldComponent;
