import { sepAPI } from '../API';
import {
  Attachment,
  AttachmentExtended,
} from '../../../shared/types/Attachment';
import { setSnackbarForEndpoint } from '../../utils/snackbar';
interface BlobAndFileURLs {
  blobUrl: string;
  fileUrl: string;
}
export const attachmentSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    uploadNewAttachment: builder.mutation<Attachment, any>({
      query: (arg) => {
        const { sepID, taskID, data } = arg;
        return {
          url: `sep/${sepID}/attachment/upload`,
          params: { taskID },
          method: 'POST',
          body: data,
        };
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem uploading your attachment.',
        });
      },
      invalidatesTags: ['Attachments'],
    }),
    uploadNewUrlAttachment: builder.mutation<Attachment, any>({
      query: (arg) => {
        const { sepID, taskID, data } = arg;
        return {
          url: `attachment`,
          method: 'POST',
          body: {
            sepID: sepID,
            taskID: taskID,
            ...data,
          },
        };
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem uploading your attachment.',
        });
      },
      invalidatesTags: ['Attachments'],
    }),
    getAttachmentByID: builder.query<AttachmentExtended[], number>({
      query: (arg) => {
        return {
          url: `sep/${arg}/attachments`,
          method: 'GET',
        };
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem loading your attachments.',
        });
      },
      providesTags: ['Attachments'],
    }),
    downloadAttachmentFile: builder.query<
      BlobAndFileURLs | undefined,
      { id: number; mimeType: string }
    >({
      query: (arg) => {
        return {
          url: `attachment/download/${arg.id}`,
          headers: {
            'content-type': arg.mimeType,
          },
          responseHandler: async (response: any) => {
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            const file = new File([blob], blob.originalFileName || 'flerp', {
              type: arg.mimeType,
            });
            const fileUrl = URL.createObjectURL(file);
            return { blobUrl, fileUrl };
          },
        };
      },
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem downloading this attachment.',
        });
      },
    }),
    deleteAttachment: builder.mutation<number, number>({
      query: (arg) => {
        return {
          url: `attachment/${arg}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Attachments'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Attachment deleted successfully!',
          errorMessage: 'There was a problem deleting your attachments.',
        });
      },
    }),
  }),
});

export const {
  useUploadNewAttachmentMutation,
  useGetAttachmentByIDQuery,
  useUploadNewUrlAttachmentMutation,
  useDeleteAttachmentMutation,
} = attachmentSlice;
