import { Box, Typography } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import { TaskExtended, TaskStatus } from '../../../shared/types/Task';
import { useUpdateTaskStatusMutation } from '../../services/tasksSlice/tasksSlice';
import { useCreateCommentMutation } from '../../services/commentSlice/commentSlice';
import StatusButton from './StatusButton';
import StatusText from './StatusText';
import { CommentModal } from '../Comments/Comments';
import { CommentExtended } from '../../../shared/types/Comment';
import { useAccount } from '@azure/msal-react';
import { useGetUserQuery } from '../../services/usersSlice';
import { DataFieldWithOptionsAndLocations } from '../../../shared/types/DataField';
import DataForm from '../../features/Knockout/DataForm';
import useMapData from '../../hooks/useMapData';
import { ActionButtons } from '../CancelSave';
import { useUpdateDataFieldsMutation } from '../../services/dataFieldsSlice';
import { useGetDefaultStakeholdersForSEPQuery, useGetDepartmentsQuery } from '../../services/departmentSlice/departmentSlice';
import { useUpdateSepMutation, useGetSepQuery } from '../../services/sepSlice';
import { Department } from '../../../shared/types/Department';
import { replaceStakeholders } from '../../../frontend/utils/replaceStakeholders';
import { useUpdateTaskMutation } from '../../services/tasksSlice/tasksSlice';
import { useGetSepTasksQuery } from '../../services/sepSlice'
import { useGetApmQuery } from '../../services/appSlice/appSlice';

interface TrackTaskProps {
  task: TaskExtended;
  setCurNav: (navItem: string) => void;
  dataFields?: any[] //DataFieldWithOptionsAndLocations[];
  refetchData?: any;
}

const TrackTask = ({ task, setCurNav, dataFields, refetchData }: TrackTaskProps) => {
  const { sepID } = task;
  const [updateDataFields] = useUpdateDataFieldsMutation();
  const [updateTaskStatus] = useUpdateTaskStatusMutation();
  const [createCommentMutation] = useCreateCommentMutation();
  const { data, setData, buttonValidation } = useMapData({ dataFields, sepID });
  const [isModal, setIsModal] = useState(false);
  const [isCompleteTrigerred, setIsCompleteTrigerred] = useState(false)
  const [commentType, setCommentType] = useState<string>('');
  const { data: loggedInUser } = useGetUserQuery('me');
  const { data: departments } = useGetDepartmentsQuery();
  const [dataChanged, setDataChanged] = useState(false);
  const [value, setValue] = useState<string>('');
  const [currentDepartmentID, setCurrentDepartmentID] = useState(0);
  const [apmID,setApmId] = useState(0);
  const [updateSep] = useUpdateSepMutation();
  const { data: defaultStakeholders } = useGetDefaultStakeholdersForSEPQuery(sepID);
  const { data: sepTasks } = useGetSepTasksQuery(sepID);
  const [updateTaskMutation] = useUpdateTaskMutation();
  const { data: apmData} = useGetApmQuery(apmID);
const { data: sep } = useGetSepQuery(sepID);
const [sortedDatFields, setSortedDatFields] =useState<any | null>(null) ;

useEffect(() => {
  if (dataFields) {
    const dataFieldsSorted = dataFields.sort((a, b) => a.taskDataFieldLocation.order - b.taskDataFieldLocation.order) ||[]
    setSortedDatFields(dataFieldsSorted)
  }
}, [dataFields]);
  const handleDepartmentChange = (e: any) => {
    setCurrentDepartmentID(e.target.value);
  };

  const handlePrivateMessage = () => {
    setCurrentDepartmentID(userDepartments[0].id);
  };

  const handleRequest = async (status: TaskStatus | 'viewRequest') => {
    // In terms of Change request, add the modal to leave the comment.
    if (status === 'viewRequest') {
      setCurNav('Comments');
    } else if (status === TaskStatus.changesRequested) {
      setIsModal(true);
    } else {
      await updateTaskStatus({ taskID: Number(task.id), status: status });
    }
  };

  const checkbvDrMisalignment = (apmdata: any) => {
    const highTierValues = ['Tier 0', 'Tier 1', 'Tier 2']
    const mismatch = !((highTierValues.includes(apmdata?.emergency_tier) && apmdata?.business_criticality.toLowerCase() == 'high') ||
      (apmdata?.emergency_tier == 'Tier 3' && apmdata?.business_criticality.toLowerCase() == 'medium') ||
      (apmdata?.emergency_tier == 'Tier 4' && apmdata?.business_criticality.toLowerCase() == 'low'))
    return mismatch;
  }
  const handleSave = async () => {
    await updateDataFields({ sepId: sepID, dataFields: data });
    if (apmID > 0) {
      if(apmData){
        const isDataMisaligned = checkbvDrMisalignment(apmData)
      const bvDRTask = sepTasks && sepTasks.filter(
        (e: any) => e.taskTemplateID == 3040
      )[0];
     if (isDataMisaligned && bvDRTask) {
        updateTaskMutation({
          taskID: bvDRTask.id,
          task: {
            enabled: true,
            review: true,
          },
        });
      }
      }
      
      updateSep({
            id: sepID,
            body: { apmID: apmID },
          });
    }
    refetchData()
  };

  const handleCancel = () => {
    setDataChanged(false);
    if (dataFields) {
      setData(dataFields);
    }
  };
const updatedDescription =  replaceStakeholders(task?.description, defaultStakeholders, sep?.apmData)
  const account = useAccount();
  const isSuperUser = useMemo(() => {
    return account?.idTokenClaims?.roles?.includes('AuthSuperUser');
  }, [account]);

  const roleList: any = useMemo(() => {
    return account?.idTokenClaims?.roles;
  }, [account]);

  const userDepartments = useMemo(() => {
    const departmentsArray: any[] = [];
    if (roleList?.length) {
      departments?.forEach((department: Department) => {
        !isSuperUser
          ? roleList?.map(
              (departmentAccess: string) =>
                department.adAppRole === departmentAccess &&
                departmentsArray.push({
                  id: department.id,
                  label: department.name,
                })
            )
          : departmentsArray.push({
              id: department.id,
              label: department.name,
            });
      });
    }
    return departmentsArray;
  }, [departments, roleList, isSuperUser]);

  const handleCommentSave = async () => {
    const { data } = (await createCommentMutation({
      sepID,
      comment: value,
      changeRequest: isCompleteTrigerred? false : true,
      replyCommentID: undefined,
      departmentID: currentDepartmentID || undefined,
      taskID: task.id,
    })) as { data: CommentExtended };
    if (data && !isCompleteTrigerred) {
      setCommentType('');
      await updateTaskStatus({
        taskID: Number(task.id),
        status: isCompleteTrigerred? TaskStatus.complete : TaskStatus.changesRequested,
      });
    }
    setIsModal(false);
    setCurrentDepartmentID(0);
     if(isCompleteTrigerred){
      handleRequest(TaskStatus.complete)
      setIsCompleteTrigerred(false)
      
    }
  };

  if (loggedInUser?.id !== task.assignedUserID && !isSuperUser) {
    return (
      <>
        <Box mb={{ xs: '24px', md: '48px' }}>
          <Typography
            fontSize="28px"
            fontWeight="600"
            textAlign="center"
            color="gray.main"
            mb="16px"
          >
            <i className="fa-regular fa-face-smile-wink"></i>
            &nbsp;&nbsp;Nothing to track here! This task belongs to someone else
          </Typography>
        </Box>
        <Box>
          <Typography
            fontSize="18px"
            fontWeight="600"
            color="gray.main"
            mb="32px"
          >
            Task Information
          </Typography>
          <Box mb="24px">
            <Typography fontSize="14px" fontWeight="600" mb="16px">
              Detail
            </Typography>
            <Typography
              component="div"
              aria-label="task-description"
              sx={{
                paddingTop: '15px',
                width: 'fit-content',
                maxWidth: '1200px',
                overflowWrap: 'break-word',
              }}
              dangerouslySetInnerHTML={{ __html: updatedDescription ?? '' }}
            ></Typography>
          </Box>
        </Box>

        {dataFields && (
          <DataForm
            dataFields={sortedDatFields}
            data={data}
            setData={setData}
            currentDepartmentID={task.departmentID}
            currentSubTab="Task"
            setDataChanged={setDataChanged}
            sepID={sepID}
            setApmId ={setApmId}
          />
        )}
        {dataChanged && (
          <ActionButtons
            handleCancel={handleCancel}
            handleSave={handleSave}
            buttonValidation={buttonValidation}
          />
        )}
      </>
    );
  } else {
    return (
      <>
        <CommentModal
          superUser={!!isSuperUser}
          isOpen={isModal || isCompleteTrigerred}
          commentType={commentType}
          targetComment={undefined}
          loggedInUser={loggedInUser}
          setCommentType={setCommentType}
          handleClose={() =>{
          setIsCompleteTrigerred(false)
           setIsModal(false)}}
          userDepartments={userDepartments}
          value={value}
          setValue={setValue}
          handleSave={handleCommentSave}
          handleCancel={() => {
            setIsModal(false);
            setValue('');
            setIsCompleteTrigerred(false)
          }}
          handleDepartmentChange={handleDepartmentChange}
          currentDepartmentID={currentDepartmentID}
          handlePrivateMessage={handlePrivateMessage}
        />
        {/* <ModalWidget
          isOpen={isModal}
          children={
            <Paper elevation={0} sx={{ p: 2, backgroundColor: '#F8F9FA' }}>
              <Box sx={{ pl: 2, mt: 2 }}>
                <UserDisplay
                  userID={loggedInUser?.id || ''}
                  showName={true}
                  showPicture={true}
                  title="Requestor"
                />
              </Box>
              <Box sx={{ ml: 6, mr: 2, mt: 2 }}>
                <AddComment
                  onClose={() => setIsModal(false)}
                  onSubmit={(comment: string) => handleSubmit(comment)}
                />
              </Box>
            </Paper>
          }
          onClose={() => setIsModal(false)}
          title="Add new Comment"
        /> */}
        <Box mb={{ xs: '24px', md: '48px' }}>
          <Typography
            fontSize="28px"
            fontWeight="600"
            textAlign="center"
            color="gray.main"
            mb="16px"
          >
            {task.status === TaskStatus.complete && 'Nothing to do here!'}
            {task.status === TaskStatus.pending && 'Nothing to do here, yet!'}
            {task.status !== TaskStatus.pending &&
              task.status !== TaskStatus.complete && task.status !== TaskStatus.cancelled  &&
              'Let’s track your progress!'}
          </Typography>
          <Typography
            fontSize="20px"
            textAlign="center"
            color="gray.solidGray"
            mb="32px"
          >
            <StatusText task={task} />
          </Typography>
          <Box display="flex" justifyContent="center">
            <StatusButton
              status={task.status}
              review={task.review}
              onClick={ 
                 (status: TaskStatus | 'viewRequest') => {
                   if(status === 'complete' && !task.review) {
                  setIsCompleteTrigerred(true)
                  setCommentType('Complete')
                 }else {
                  handleRequest(status)
                  }
                  
                }
             
              }
            />
          </Box>
        </Box>
        <Box>
          <Typography
            fontSize="18px"
            fontWeight="600"
            color="gray.main"
            mb="32px"
          >
            Task Information
          </Typography>
          <Box>
            <Typography fontSize="14px" fontWeight="600" mb="16px">
              Detail
            </Typography>
            <Typography
              component="div"
              aria-label="task-description"
              sx={{
                paddingTop: '15px',
                width: 'fit-content',
                maxWidth: '1200px',
                overflowWrap: 'break-word',
              }}
              dangerouslySetInnerHTML={{ __html: updatedDescription ?? '' }}
            ></Typography>
          </Box>
        </Box>

        {dataFields && (
          <DataForm
            dataFields={sortedDatFields}
            data={data}
            setData={setData}
            currentDepartmentID={task.departmentID}
            currentSubTab="Task"
            setDataChanged={setDataChanged}
            sepID={sepID}
            setApmId ={setApmId}
          />
        )}
        {dataChanged && (
          <ActionButtons
            handleCancel={handleCancel}
            handleSave={handleSave}
            buttonValidation={buttonValidation}
          />
        )}
      </>
    );
  }
};

export default TrackTask;
