import React, { useState, useMemo, useEffect } from 'react';
import { Box, Divider, Tabs } from '@mui/material';
import SepHeader from './SepHeader';
import Tab from '../../components/Tabs';
import VerticalTabs from '../../components/VerticalTabs';
import { useAccount } from '@azure/msal-react';
import { useGetDepartmentsQuery } from '../../services/departmentSlice/departmentSlice';
import AttachmentUpload from '../../components/AttachmentUpload/AttachmentUpload';
import Comments from '../../components/Comments/Comments';
import Activities from '../../components/Activity/Activities';
import { useGetSepTasksQuery, useGetSepQuery } from '../../services/sepSlice';
import { useGetDataFieldsBySEPIDQuery } from '../../services/dataFieldsSlice';
import { useGetAttachmentByIDQuery } from '../../services/attachmentSlice/attachmentSlice';
import { useGetCommentsQuery } from '../../services/commentSlice/commentSlice';
import { useGetUserQuery } from '../../services/usersSlice';
import { env } from '../../../shared/env';

// types
import { DataFieldWithOptionsAndLocations } from '../../../shared/types/DataField';
import { Tasks } from './Tasks';
import { useUpdateDataFieldsMutation } from '../../services/dataFieldsSlice';
import { TaskExtended } from '../../../shared/types/Task';
import { Department } from '../../../shared/types/Department';
import useMapData from '../../hooks/useMapData';

//import { CommentExtended } from '../../../shared/types/Comment';

interface SepOverviewProps {
  sepID: number;
  onNewTask: () => void;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface TabProperties {
  label: string;
  id?: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ py: 3 }}>{children}</Box>}
    </div>
  );
}

const SepOverview = ({ sepID, onNewTask }: SepOverviewProps) => {
  const account = useAccount();
  const { data: loggedInUser, isLoading } = useGetUserQuery('me');
  const [updateDataFields] = useUpdateDataFieldsMutation();
  const [filterTasks, setFilterTasks] = useState(true);
  const [hidePendingTasks, setHidePendingTasks] = useState(true);
  const [hidePendingOtherTasks, setHidePendingOtherTasks] = useState(true);
  const [hidePendingDeptTasks, setHidePendingDeptTasks] = useState(true);
  const [hideOthers, setHideOthers] = useState(true);
  const [filterOthers, setFilterOthers] = useState(true);

  const [currentTab, setCurrentTab] = useState(0);
  const { data: departments } = useGetDepartmentsQuery();
  // Pull full task list since sep.tasks only contains enabled tasks
  const { data: tasks, refetch } = useGetSepTasksQuery(sepID);
  const [currentSubTab, setcurrentSubTab] = useState('DepartmentReview');
  const [dataChanged, setDataChanged] = useState(false);
  const sepData = useGetSepQuery(sepID);
  const creatorId = sepData.data?.creator.id;


  

  const roleList: any = useMemo(() => {
    if (env.REACT_APP_LOCAL_DEV_ADMIN === 'true') {
      return ['AuthSuperUser'];
    } else {
      return account?.idTokenClaims?.roles || [];
    }
  }, [account]);

  const { data: dataFields, refetch: rerun } =
    useGetDataFieldsBySEPIDQuery(sepID);
  const { data: attachments } = useGetAttachmentByIDQuery(sepID);
  const { data: comments } = useGetCommentsQuery(sepID);
  const handleHide = () => {
    setHideOthers(!hideOthers);
  };

  const handleTasksSwitch = () => {
    setFilterTasks(!filterTasks);
  };

  const handleCompletedSwitch = () => {
    setFilterOthers(!filterOthers);
  };

  //Auth user roles with departments
  const userTabs = useMemo(() => {
    let tabList: TabProperties[] = [{ label: 'Task List' }];
    if (roleList && roleList.length && departments) {
      const superUser = roleList?.includes('AuthSuperUser');
      departments.forEach((department: Department) => {
        const adRole = roleList?.find(
          (role: any) => role === department.adAppRole
        );
        if (adRole || superUser) {
          tabList.push({ label: department.name, id: department.id });
        }
      });
    }
    tabList = tabList.concat([
      { label: 'Attachments' },
      { label: 'Comments' },
      { label: 'Activity' },
    ]);
    return tabList;
  }, [roleList, departments]);

  const tabName = userTabs[currentTab].label;
  const standardTabNames = ['Task List', 'Attachments', 'Comments', 'Activity'];
  const currentDepartmentID = userTabs[currentTab].id;

  const dataFieldsForDepartment = useMemo(() => {
    const newDataFields: DataFieldWithOptionsAndLocations[] = [];
    if (currentDepartmentID) {
      dataFields?.forEach((field) => {
        const isPresent = field.dataFieldLocations.find(
          (location) =>
            location.locationType === currentSubTab &&
            location.locationID === currentDepartmentID
        );
        if (isPresent) {
          newDataFields.push(field);
        }
      });
    }
    return newDataFields;
  }, [currentSubTab, currentDepartmentID, dataFields]);

  const tasksForDepartment = useMemo(() => {
    const currentTasks: TaskExtended[] = [];
    tasks?.forEach((task: any) => {
      if (currentDepartmentID === task.departmentID) {
        currentTasks.push(task);
      }
    });
    return currentTasks;
  }, [currentDepartmentID, tasks]);

  const { data, setData, buttonValidation } = useMapData({
    dataFieldsForDepartment,
    currentDepartmentID,
    currentSubTab,
  });

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setData([]);
    setDataChanged(false);
    setCurrentTab(newValue);
  };

  const handleSubTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setData([]);
    setcurrentSubTab(newValue);
  };

  const handleCancel = () => {
    setDataChanged(false);
    setData(dataFieldsForDepartment);
  };

  const handleSave = async () => {
    await updateDataFields({ sepId: sepID, dataFields: data });
    rerun();
  };

  useEffect(()=> {
    if(creatorId !== loggedInUser?.id && !isLoading) {
      setHidePendingTasks(false)
      setHidePendingOtherTasks(false)
      setHidePendingDeptTasks(false)
      setFilterOthers(false)
      setFilterTasks(false)
    }
  },[creatorId, loggedInUser, isLoading])

  const standartTabSelector = (tabName: string): JSX.Element => {
    switch (tabName) {
      case 'Task List':
        return (
          <Tasks
            sepID={sepID}
            filterTasks={filterTasks}
            filterOthers={filterOthers}
            hideOthers={hideOthers}
            handleHide={handleHide}
            handleTasksSwitch={handleTasksSwitch}
            handleCompletedSwitch={handleCompletedSwitch}
            onNewTaskAdded={(newTask: TaskExtended) => {
              onNewTask();
              refetch();
            }}
            hideFilterChange={() => setHidePendingTasks(!hidePendingTasks)}
            hidePendingTasks={hidePendingTasks}
            hidePendingOtherTasks={hidePendingOtherTasks}
            hideFilterChangeOthers={() => setHidePendingOtherTasks(!hidePendingOtherTasks)}
          />
        );
      case 'Comments':
        return (
          <Comments
            sepID={sepID}
            comments={comments || []}
            roleList={roleList}
          />
        );

      case 'Activity':
        return <Activities sepID={sepID} />;
      default:
        return (
          <AttachmentUpload attachments={attachments || []} sepID={sepID} />
        );
    }
  };

  return (
    <>
      <SepHeader dataFields={dataFields} sepID={sepID} roleList={roleList} />
      <Divider />
      {/* Middle Desc */}
      <Box sx={{ mt: 4, mx: { sm: 2, md: 4 } }}>
        <Tabs
          scrollButtons
          value={currentTab}
          TabIndicatorProps={{ style: { backgroundColor: 'white' } }}
          variant="scrollable"
          onChange={handleTabChange}
        >
          {userTabs.map((link, index) => (
            <Tab key={index} id={link.id} label={link.label} />
          ))}
        </Tabs>
      </Box>

      {!standardTabNames.includes(tabName)
        ? userTabs.map((link, index) => (
            <TabPanel key={index} value={currentTab} index={index}>
              <VerticalTabs
                currentSubTab={currentSubTab}
                dataFieldsForDepartment={dataFieldsForDepartment}
                handleSubTabChange={handleSubTabChange}
                data={data}
                setData={setData}
                handleCancel={handleCancel}
                handleSave={handleSave}
                tasksForDepartment={tasksForDepartment}
                sepID={sepID}
                filterTasks={filterTasks}
                hidePendingDeptTasks={hidePendingDeptTasks}
                hideFilterChange={() => setHidePendingDeptTasks(!hidePendingDeptTasks)}
                handleTasksSwitch={handleTasksSwitch}
                setDataChanged={setDataChanged}
                dataChanged={dataChanged}
                buttonValidation={buttonValidation}
                currentDepartmentID={currentDepartmentID}
                onNewTaskAdded={() => {
                  onNewTask();
                  refetch();
                }}
              />
            </TabPanel>
          ))
        : standartTabSelector(tabName)}
    </>
  );
};

export default SepOverview;
