import React, { useState } from "react";
import { useNavigate } from "react-router";
import ModalWidget from "../../components/ModalWidget/ModalWidget";
import UnSubscribeModal from "../../components/SepTable/UnSubscribeModal";
import { useGetUserQuery } from "../../services/usersSlice";
import {
  useGetSubscribedSepsQuery,
  useUpdateSepSubscriptionsMutation,
} from "../../services/sepSlice";
export const Unsubscribe = () => {
  const [updateSepSubs] = useUpdateSepSubscriptionsMutation();
  const {
    data: loggedInUser,
    error: getUserError,
    isLoading: getUserIsLoading,
  } = useGetUserQuery("me");
  const navigate = useNavigate();
  const [isSubModalOpen, setIsSubModalOpen] = useState<boolean>(true);
  const {
    data: subscribedSeps,
    isError: subError,
    isLoading: subLoading,
    error,
    refetch,
  } = useGetSubscribedSepsQuery(loggedInUser?.email || "");
  const handleUnsubscribe = async (sepID: number, subscribers: string) => {
    const userEmail = "" + loggedInUser?.email;
    const multipleEntry = subscribers.includes("," + userEmail);
    const updatedSubscriberList = multipleEntry
      ? subscribers.replace("," + userEmail, "")
      : subscribers.replace(userEmail, "");
    if (sepID > 0) {
      await updateSepSubs({
        id: sepID,
        body: { subscribers: updatedSubscriberList },
      });
    }
    refetch();
    navigate("/");
  };
  return (
    <>
      <ModalWidget
        isOpen={isSubModalOpen}
        title="UnSubscribe this SEP"
        children={
          <UnSubscribeModal
            subscribedSeps={subscribedSeps}
            handleSave={handleUnsubscribe}
            isSepLoading={subLoading}
          />
        }
        onClose={() => navigate("/")}
      />
    </>
  );
};
