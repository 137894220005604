import React, { useEffect, useState } from 'react';
import {
  Box,
  Menu,
  MenuItem,
  TextField,
  InputAdornment,
  Divider,
  Button,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import {
  DataFieldExtendedWithSingleLocationAndFollowups,
  DataFieldType,
} from '../../../../../shared/types/DataField';
import { KnockoutFollowupExtended } from '../../../../../shared/types/Knockout';

const textFieldSx = {
  color: '#222222',
  '& > p': {
    margin: '5px 0px',
    color: '#646669',
    fontSize: '11px',
  },
};

const Answer = ({
  dataField,
  followup,
  onChange,
}: {
  dataField: DataFieldExtendedWithSingleLocationAndFollowups;
  followup: KnockoutFollowupExtended | null;
  onChange: (e: string) => void;
}) => {
  const isDropDown = (d: DataFieldType) => {
    if (d === DataFieldType.input) return false;
    if (d === DataFieldType.textarea) return false;
    if (d === DataFieldType.number) return false;
    if (d === DataFieldType.email) return false;
    if (d === DataFieldType.date) return false;
    if (d === DataFieldType.dateTime) return false;
    if (d === DataFieldType.textbox) return false;
    return true;
  };

  return (
    <Box>
      {isDropDown(dataField.type) ? (
        <DropDown
          dataField={dataField}
          value={followup ? followup.value : null}
          onChange={onChange}
        />
      ) : (
        <SearchField
          value={followup ? followup.value : ''}
          onChange={onChange}
        />
      )}
    </Box>
  );
};

const SearchField = ({
  value,
  onChange,
}: {
  value: string;
  onChange: (val: string) => void;
}) => {
  const [originValue, setOriginValue] = useState(value);
  useEffect(() => {
    setOriginValue(value);
  }, [value]);
  return (
    <Box>
      <Box sx={{ color: '#222222', width: '216px' }}>Answer</Box>
      <Box sx={{ mb: 3 }}>
        <TextField
          sx={textFieldSx}
          size="small"
          fullWidth
          value={originValue}
          placeholder="Type an answer here."
          onChange={(e) => {
            onChange(e.target.value);
            setOriginValue(e.target.value);
          }}
        />
      </Box>
    </Box>
  );
};

const DropDown = ({
  dataField,
  value,
  onChange,
}: {
  dataField: DataFieldExtendedWithSingleLocationAndFollowups;
  value: string | null;
  onChange: (val: string) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<string | null>(value);
  const [search, setSearch] = useState<string>('');
  const open = Boolean(anchorEl);
  const handleMenuOption = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  useEffect(() => {
    setSelected(value);
  }, [value]);
  const renderDropDownMenu = () => {
    if (dataField.type === DataFieldType.yesNo) {
      return ['Yes', 'No', "I don't know"];
    } else if (
      dataField.type === DataFieldType.toggle ||
      dataField.type === DataFieldType.checkbox
    ) {
      return ['true', 'false'];
    }
    return dataField.dataFieldOptions
      .filter((option) => option.value.includes(search))
      .map((e) => e.value);
  };

  const renderOption = (value: string) => {
    if (selected === value) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {value}
          <CheckIcon />
        </Box>
      );
    }
    return value;
  };

  return (
    <Box>
      <Box sx={{ color: '#222222', width: '216px' }}>Answer</Box>
      <Box sx={{ mb: 3 }}>
        <Button
          variant="outlined"
          id="demo-positioned-button"
          aria-controls={open ? 'demo-positioned-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleMenuOption}
          sx={{
            color: selected === null ? '#7E8083' : '#222222',
            textTransform: 'none',
            borderColor: '#7E8083',
          }}
        >
          {selected === null
            ? 'Choose an answer from the dropdown'
            : dataField.type === DataFieldType.yesNo
            ? selected === 'idk'
              ? "I don't know"
              : selected[0].toUpperCase() + selected.slice(1)
            : selected}
          &nbsp;&nbsp;
          <i className="fa-sharp fa-solid fa-chevron-down" />
        </Button>
        <Menu
          id="demo-positioned-menu"
          aria-labelledby="demo-positioned-button"
          anchorEl={anchorEl}
          keepMounted={true}
          open={!!anchorEl}
          disablePortal
          anchorReference="anchorPosition"
          anchorPosition={{ top: 240, left: 32 }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={() => setAnchorEl(null)}
        >
          <MenuItem disableTouchRipple={true}>
            <TextField
              placeholder="Search Answers…"
              size="small"
              sx={{ width: '100%' }}
              onChange={(e) => setSearch(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </MenuItem>
          <Divider />
          {renderDropDownMenu().map((item, index) => {
            return (
              <Box key={index}>
                <MenuItem
                  onClick={() => {
                    setSelected(item);
                    if (dataField.type === DataFieldType.yesNo)
                      if (item === "I don't know") {
                        onChange('idk');
                      } else {
                        onChange(item.toLowerCase());
                      }
                    onChange(item);
                    setAnchorEl(null);
                  }}
                >
                  {renderOption(item)}
                </MenuItem>
                <Divider />
              </Box>
            );
          })}
        </Menu>
      </Box>
    </Box>
  );
};

export default Answer;
