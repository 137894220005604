import React, { useState } from 'react';
import ModalWidget from '../../../ModalWidget/ModalWidget';
import { DataFieldWithOptionsAndSingleLocation } from '../../../../../shared/types/DataField';
import {
  Paper,
  Box,
  Stack,
  Divider,
  Typography,
  createTheme,
  useTheme,
} from '@mui/material';
import { ActionButtons } from '../../../CancelSave';
import { ThemeProvider } from '@emotion/react';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import './style.css';
import { StrictModeDroppable as Droppable } from '../../../../utils/StrictModeDroppable';
import { useUpdateDataFieldOrderLocationTemplateMutation } from '../../../../services/dataFieldsSlice';

export interface DataFieldModalProps {
  isOpen: boolean;
  onClose: () => void;
  datafields: DataFieldWithOptionsAndSingleLocation[];
  locationName: string;
  locationDescription: string;
  type: string;
}

const style = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          '&:hover': {
            backgroundColor: '#F3F8FC',
          },
        },
      },
    },
  },
});

const ReorderDataFieldsModal = ({
  isOpen,
  onClose,
  datafields,
  locationName,
  locationDescription,
  type,
}: DataFieldModalProps) => {
  return (
    <>
      <ModalWidget
        title={`Reorder ${type} Location Data Fields`}
        isOpen={isOpen}
        onClose={onClose}
        children={
          <>
            <DataFieldDraggableList
              locationName={locationName}
              locationDescription={locationDescription}
              datafields={datafields}
              onClose={onClose}
            />
          </>
        }
      />
    </>
  );
};

export default ReorderDataFieldsModal;

const DataFieldDraggableList = ({
  datafields,
  onClose,
  locationName,
  locationDescription,
}: {
  datafields: DataFieldWithOptionsAndSingleLocation[];
  onClose: () => void;
  locationName: string;
  locationDescription: string;
}) => {
  const [saveNewOrder] = useUpdateDataFieldOrderLocationTemplateMutation();
  const [reorderArray, setReorderArray] = useState(datafields || []);

  const onDragEnd = (result: any) => {
    if (!result || result.destination === null) return;
    const tempArray = [...reorderArray];
    const [reorderedItem] = tempArray.splice(result.source.index, 1) || [];
    tempArray.splice(result.destination.index, 0, reorderedItem);
    setReorderArray(tempArray);
  };

  const renderItem = getRenderItem(reorderArray);

  const handleSave = () => {
    const reorderedArray = reorderArray.map((datafield: any, i) => ({
      dataFieldLocationID: datafield.dataFieldLocation.id,
      order: i,
    }));
    const locationType = datafields[0].dataFieldLocation.locationType;
    saveNewOrder({ locationType, payload: reorderedArray });
    onClose();
  };

  const handleCancel = () => {
    // set the reorderArray to the original datafields
    setReorderArray(datafields);
  };

  return (
    <>
      <div style={{ height: '400px', overflow: 'auto' }}>
        <DataFieldLocationHeader
          locationName={locationName}
          locationDescription={locationDescription}
        />
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="datafield" renderClone={renderItem}>
            {(provided) => (
              <section {...provided.droppableProps} ref={provided.innerRef}>
                {/* {reorderArray.sort((a,b) => a.dataFieldLocation.order - b.dataFieldLocation.order)} */}
                {reorderArray.map((datafield, index) => {
                  return (
                    <Draggable
                      key={datafield.id}
                      draggableId={datafield.id.toString()}
                      index={index}
                    >
                      {renderItem}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </section>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <Divider sx={{ backgroundColor: '#7E8083' }} />
      <ActionButtons
        handleCancel={handleCancel}
        handleSave={handleSave}
        saveLabel="Save Arrangement"
      />
    </>
  );
};

const getRenderItem =
  (items: any) => (provided: any, snapshot: any, rubric: any) => {
    const theme = useTheme();
    const [hoverIndex, setHoverIndex] = useState(-1);
    return (
      <div
        {...provided.draggableProps}
        {...provided.dragHandleProps}
        ref={provided.innerRef}
      >
        <ThemeProvider theme={style}>
          <Paper
            className={snapshot.isDragging ? 'dragging' : ''}
            onMouseOver={() => setHoverIndex(rubric.source.index)}
            onMouseLeave={() => setHoverIndex(-1)}
            square={true}
            elevation={hoverIndex === rubric.source.index ? 3 : 0}
          >
            <Stack
              direction="row"
              spacing={2}
              p={2}
              sx={{
                alignItems: 'center',
              }}
            >
              {/** import svg  */}
              <Box
                px={2}
                style={{
                  color:
                    hoverIndex === rubric.source.index || snapshot.isDragging
                      ? theme.palette.blue.blue1
                      : '',
                }}
              >
                <i className="fa-solid fa-grip-dots-vertical"></i>
              </Box>
              <Stack direction="column">
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal normal 14px/22px Open Sans',
                    lettetSpacing: '0px',
                    color: '#646669',
                    opacity: 1,
                  }}
                >
                  {`Data Field ${rubric.source.index + 1}`}
                </Typography>
                <Typography
                  sx={{
                    textAlign: 'left',
                    font: 'normal normal normal 16px/22px Open Sans',
                    letterSpacing: '0px',
                    color: '#222222',
                    opacity: 1,
                  }}
                >
                  {items[rubric.source.index].name}
                </Typography>
              </Stack>
            </Stack>
          </Paper>
        </ThemeProvider>
        <Divider />
      </div>
    );
  };

const DataFieldLocationHeader = ({
  locationName,
  locationDescription,
}: {
  locationName: string;
  locationDescription: string;
}) => {
  return (
    <>
      <Paper square={true} sx={{ backgroundColor: '#F8F9FA  ' }}>
        <Stack
          direction="row"
          spacing={2}
          p={2}
          sx={{
            alignItems: 'center',
          }}
        >
          <Stack direction="column">
            <Typography
              sx={{
                textAlign: 'left',
                font: 'normal normal normal 14px/22px Open Sans',
                lettetSpacing: '0px',
                color: '#646669',
                opacity: 1,
                paddingBottom: '6px',
              }}
            >
              {locationName}
            </Typography>
            <div
              style={{
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: '25rem',
              }}
            >
              <Typography
                noWrap
                sx={{
                  textAlign: 'left',
                  font: 'normal normal normal 16px/22px Open Sans',
                  letterSpacing: '0px',
                  color: '#222222',
                  opacity: 1,
                }}
              >
                {locationDescription}
              </Typography>
            </div>
          </Stack>
        </Stack>
      </Paper>
      <Divider />
    </>
  );
};
