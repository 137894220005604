import React, { useRef, useContext } from 'react';
import { Box, TextField, Stack, InputLabel } from '@mui/material';
import { TaskContext } from './CreateTask';
import RichTextEditor from '../RichTextEditor/RichTextEditor';
const FirstStep = () => {
  const { task, setTask } = useContext(TaskContext);
  const ref = useRef(null);
  const textFieldSx = {
    color: '#222222',
    '& > p': {
      margin: '5px 0px',
      color: '#646669',
      fontSize: '11px',
    },
  };
  return (
    <Stack
      display="flex"
      direction="column"
      alignItems="left"
      spacing={4}
      sx={{ p: 4 }}
    >
      <Box>
        <InputLabel sx={{ color: '#222222' }} htmlFor="task-name">
          Task Name
        </InputLabel>
        <TextField
          sx={textFieldSx}
          size="small"
          id="task-name"
          fullWidth
          value={task.name || ''}
          placeholder="Ex. Please have vendor complete the Cloud Security Requirements Mat…"
          helperText="This should be the actual task name that will display on an SEPs Task List"
          onChange={(e) => {
            setTask({ ...task, name: e.target.value });
          }}
        />
      </Box>
      <Box>
        <InputLabel
          sx={{ color: '#222222' }}
          ref={ref}
          htmlFor="task-assignment-name"
        >
          Task Assignment Name
        </InputLabel>
        <TextField
          sx={textFieldSx}
          size="small"
          id="task-shortName"
          fullWidth
          value={task.shortName || ''}
          placeholder="Ex. Please have vendor complete the Cloud Security Requirements Mat…"
          helperText="This name appears in the Task Assigner options on the Review tab of an SEP’s Overview"
          onChange={(e) => {
            setTask({ ...task, shortName: e.target.value });
          }}
        />
      </Box>
      <Box>
        <InputLabel htmlFor="task-description" sx={{ color: '#222222' }}>
          Task Description
        </InputLabel>
        <RichTextEditor
          value={task.description ?? ''}
          setValue={(val) => setTask({ ...task, description: val })}
        />
        {/* <TextField 
          size="small"
          id="task-description"
          fullWidth
          placeholder="Ex. We need to understand the scope to determine which teams will need to review this request. Please select all that apply."
          multiline
          rows={4}
          value={task.description || ''}
          onChange={(e) => {
            setTask({ ...task, description: e.target.value });
          }}
        /> */}
      </Box>
    </Stack>
  );
};

export default FirstStep;
