import React from 'react';
import { Box, Divider, Typography } from '@mui/material';
import AdminHeader from '../../../components/Admin/AdminHeader';
import QuickHelpMenu from '../../../components/Admin/SEPApplication/QuickHelpMenu';
import { useGetSettingsQuery } from '../../../services/appSlice/appSlice';
import ReviewerTools from '../../../components/Admin/SEPApplication/ReviewerTools';

const SEPApplication = () => {
  const { data } = useGetSettingsQuery();

  const reviewerTools = data
    ?.filter((help) => help.section === 'ReviewerTools')
    .sort((a, b) => a.order - b.order);
  const userHelp = data
    ?.filter((help) => help.section === 'UserHelp')
    .sort((a, b) => a.order - b.order);

  return (
    <Box sx={{ minHeight: '500px' }}>
      <AdminHeader title="SEP Application" />
      <Box sx={{ padding: '0px 40px 15px 40px' }}>
        <Typography
          variant="h2"
          component="div"
          sx={{
            marginTop: '16px',
            fontSize: '11px',
            color: '#7E8083',
          }}
        >
          <i className="fa-solid fa-circle-info"></i>
          &nbsp;&nbsp;Drag to rearrange order in Quick Help Menu
        </Typography>
        {userHelp && <QuickHelpMenu items={userHelp} />}
      </Box>

      <Divider />
      <Box sx={{ padding: '0px 40px 15px 40px' }}>
        <Typography
          variant="h2"
          component="div"
          sx={{
            marginTop: '16px',
            fontSize: '11px',
            color: '#7E8083',
          }}
        >
          <i className="fa-solid fa-circle-info"></i>
          &nbsp;&nbsp;The section below is only visible to Stakeholder Users
        </Typography>
        {reviewerTools && <ReviewerTools items={reviewerTools} />}
      </Box>
    </Box>
  );
};

export default SEPApplication;
