import React, { useState } from 'react';
import { Box, Typography, Divider, Button } from '@mui/material';
import StepPage from '../../components/CreateTask/CreateTask';

//types
import { TaskExtended, TaskStatus } from '../../../shared/types/Task';
import TasksTable from '../../components/Tables/TasksTables';
import SepToolbar from '../../components/Toolbar/Toolbar';
import ModalWidget from '../../components/ModalWidget/ModalWidget';
import { useGetSepTasksQuery } from '../../services/sepSlice';
import { useGetUserQuery } from '../../services/usersSlice';

const flexContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
};

interface TasksProps {
  sepID: number;
  filterTasks: boolean;
  filterOthers: boolean;
  hideOthers: boolean;
  handleHide: () => void;
  handleTasksSwitch: () => void;
  handleCompletedSwitch: () => void;
  onNewTaskAdded: (newTask: TaskExtended) => void;
  hidePendingTasks?: boolean
  hideFilterChange?: () => void
  hidePendingOtherTasks?: boolean
  hideFilterChangeOthers?: () => void
}

export const Tasks = ({
  sepID,
  filterTasks,
  filterOthers,
  hideOthers,
  handleHide,
  handleTasksSwitch,
  handleCompletedSwitch,
  onNewTaskAdded,
  hidePendingTasks,
  hideFilterChange,
  hidePendingOtherTasks,
  hideFilterChangeOthers
}: TasksProps) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const { data: tasks } = useGetSepTasksQuery(sepID);
  const { data: loggedInUser } = useGetUserQuery('me');
  /** The enabled non-complete tasks assigned to the logged-in user */
  const nonCompleteUserAssignedTasks = React.useMemo(() => {
    return (
      tasks?.filter(
        (e: any) =>
          e.enabled &&
          e.status !== TaskStatus.complete && e.status !== TaskStatus.cancelled &&
          e.assignedUserID === loggedInUser?.id
      ) || []
    );
  }, [tasks, loggedInUser?.id]);

  const pendingUserAssignedTasks = React.useMemo(() => {
    return (
      tasks?.filter(
        (e: any) =>
          e.enabled &&
          e.status !== TaskStatus.pending &&
          e.assignedUserID === loggedInUser?.id
      ) || []
    );
  }, [tasks, loggedInUser?.id]);
  /** The enabled non-complete non-pending tasks assigned to the logged-in user */
  const nonCompleteNonPendingUserAssignedTasks = React.useMemo(() => {
    return nonCompleteUserAssignedTasks?.filter(
      (e: any) => e.status !== TaskStatus.pending
    );
  }, [nonCompleteUserAssignedTasks]);
  
  const combinedTasks = React.useMemo(() => {
    if(hidePendingTasks && filterTasks) {
      return nonCompleteUserAssignedTasks?.filter((e:any) => e.status !== TaskStatus.pending)
    } else if(hidePendingTasks) {
      return pendingUserAssignedTasks
    } else if(filterTasks) {
      return nonCompleteUserAssignedTasks
    } else {
      return tasks?.filter((e: any) => e.enabled && e.assignedUserID === loggedInUser?.id)
    }
  }, [hidePendingTasks, filterTasks, pendingUserAssignedTasks, nonCompleteUserAssignedTasks, tasks, loggedInUser?.id])
  return (
    <>
      <ModalWidget
        isOpen={isOpen}
        title="Add Task"
        children={
          <StepPage
            isInit={!isOpen}
            sepID={sepID}
            onFinish={(newTask: TaskExtended) => {
              onNewTaskAdded(newTask);
              setIsOpen(false);
            }}
          />
        }
        onClose={() => setIsOpen(false)}
      />
      {nonCompleteNonPendingUserAssignedTasks.length > 0 && (
        <Box sx={{ ...flexContainer, flexDirection: 'column', mt: 4 }}>
          <Typography variant="title">You've got work to do!</Typography>
          <Box sx={{ width: '35%' }}>
            <p className="tasks-info">
              My Tasks shows the tasks on your plate. Click on a task to view
              its details, track key steps in your process, upload attachments,
              and more!
            </p>
          </Box>
        </Box>
      )}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          pl: 3,
          pr: 4,
          py: 1,
        }}
      >
        <Button
          variant="outlined"
          size="small"
          sx={{
            '&.MuiButton-outlined': {
              border: '2px solid #2372B9',
              color: '#2372B9',
              textTransform: 'none',
              fontWeight: 700,
            },
          }}
          onClick={() => setIsOpen(true)}
        >
          + Create New Task
        </Button>
      </Box>
      <SepToolbar
        title="My Tasks"
        caption="These tasks are to be completed by you. Click on a task for more information"
        filter={filterTasks}
        onChange={handleTasksSwitch}
        hideFilterChange={hideFilterChange}
        hidePendingTasks={hidePendingTasks}
      />
      <Divider />
        <TasksTable
          sepID={sepID}
          tasks={combinedTasks || []}
          isMyTask={true}
        />
      <Box>
        <SepToolbar
          title="Tasks for Others"
          caption="These tasks are to be completed by others but feel free to expand this area and see what everyone else is up to"
          hidable
          filter={filterOthers}
          hide={hideOthers}
          onChange={handleCompletedSwitch}
          handleHide={handleHide}
          hideFilterChange={hideFilterChangeOthers}
          hidePendingTasks={hidePendingOtherTasks}
        />
        <Divider />
        {hideOthers ? (
            <TasksTable
              sepID={sepID}
              tasks={tasks?.filter((e: any) => {
                    if(hidePendingOtherTasks && filterOthers) {
                      return e.enabled && e.status !== TaskStatus.pending && e.status !== TaskStatus.complete &&  e.status !== TaskStatus.cancelled &&  e.assignedUserID !== loggedInUser?.id
                    } else if(hidePendingOtherTasks) {
                      return e.enabled && e.status !== TaskStatus.pending && e.assignedUserID !== loggedInUser?.id
                    } else if(filterOthers) {
                      return e.enabled && e.status !== TaskStatus.complete && e.status !== TaskStatus.cancelled && e.assignedUserID !== loggedInUser?.id
                    } else {
                      return e.enabled && e.assignedUserID !== loggedInUser?.id
                    }
                  }) || [] }
            />
        ) : (
          <></>
        )}
      </Box>
    </>
  );
};
