import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Checkbox,
  Box,
} from '@mui/material';
import { HeadCell } from './SepTableBody';

const EditColumnModal = ({
  visibleColumns,
  setVisibleColumns,
}: {
  visibleColumns: HeadCell[];
  setVisibleColumns: (cols: HeadCell[]) => void;
}) => {
  return (
    <Box sx={{ p: 3, maxHeight: '500px', overflow: 'auto' }}>
      <Table sx={{ width: '100%' }} aria-labelledby="tableTitle" size="medium">
        <TableHead>
          <TableRow>
            <TableCell>No</TableCell>
            <TableCell>Column Name</TableCell>
            <TableCell>Visible</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {visibleColumns.map((headCell, index) => {
            return (
              <TableRow key={index}>
                <TableCell>{index}</TableCell>
                <TableCell>{headCell.label}</TableCell>
                <TableCell>
                  <Checkbox
                    checked={visibleColumns[index].visible}
                    onChange={(e) => {
                      const columns = visibleColumns.map((col) => {
                        if (col.id === headCell.id)
                          return {
                            ...col,
                            visible: e.target.checked,
                          };
                        else {
                          return col;
                        }
                      });
                      setVisibleColumns(columns);
                    }}
                  />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default EditColumnModal;
