import React, { useState } from 'react';

import { Box } from '@mui/material';

import AdminHeader from '../../../components/Admin/AdminHeader';
import AddButton from '../../../components/Admin/AddButton';
import ModalWidget from '../../../components/ModalWidget/ModalWidget';
import DepartmentScreenCard from '../../../components/Admin/Departments/DepartmentScreenCard';

import { useGetDepartmentsExtendedQuery } from '../../../services/departmentSlice/departmentSlice';
import AddDepartment from '../../../components/Admin/Departments/AddDepartment';
import { DepartmentExtended } from '../../../../shared/types/Department';

import { CircularProgress } from '@mui/material';
import DepartmentOrder from '../../../components/Admin/Departments/DepartmentOrder';

const Departments = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [edit, setEdit] = useState<DepartmentExtended | undefined>(undefined);
  const [isOrderChange, setIsOrderChange] = useState<boolean>(false);

  const { data, isLoading } = useGetDepartmentsExtendedQuery();

  const handleEditTriggered = (e: DepartmentExtended) => {
    setEdit(e);
    setIsOpen(true);
  };
  const handleEditOrder = () => {
    setIsOrderChange(true);
  }
  const handleDepartmentReorderModalClose = () => {
    setIsOrderChange(!isOrderChange);
  }

  return (
    <>
      {isLoading ? (
        <Box pt={10} sx={{ display: 'flex', justifyContent: 'center' }}>
          <CircularProgress data-testid="loading-spinner" size={100} />
        </Box>
      ) : (
        <Box sx={{ minHeight: '500px' }}>
          <AdminHeader
            title="Departments"
            editDepartmentOrder={true}
            departmentNames={data}
            onEditTriggered={handleEditOrder}
          />
          <MappedListComponent
            data={data}
            onEditTriggered={handleEditTriggered}
          />
          <ModalWidget
            title={edit === undefined ? 'Add Department' : 'Edit Department'}
            isOpen={isOpen}
            onClose={() => {
              setIsOpen(false);
              setEdit(undefined);
            }}
            children={
              <AddDepartment
                onClose={() => {
                  setIsOpen(false);
                  setEdit(undefined);
                }}
                value={edit}
              />
            }
          />
          <ModalWidget
            title={'Change Department Order'}
            isOpen={isOrderChange}
            onClose={() => {
              setIsOrderChange(false);
            }}
            children={
              <DepartmentOrder
                departmentData = {data}
                onClose = {handleDepartmentReorderModalClose}
              />
            }
          />
          <AddButton
            tab="Department"
            onAdd={(tab: string) => {
              setIsOpen(true);
            }}
          />
        </Box>
      )}
    </>
  );
};

export default Departments;

const MappedListComponent = React.memo(
  ({
    data,
    onEditTriggered,
  }: {
    data?: DepartmentExtended[];
    onEditTriggered: (e: DepartmentExtended) => void;
  }) => {
    return (
      <>
        {data?.map((department, index) => (
          <DepartmentScreenCard
            key={index}
            department={department}
            onEditTriggered={(e) => {
              onEditTriggered(e);
            }}
          />
        ))}
      </>
    );
  }
);
