import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Divider,
} from '@mui/material';

interface ConfirmDialogProps {
  open: boolean;
  title?: string;
  content?: string;
  onConfirm: (isConfirmed: boolean) => void;
}

const ConfirmDialog = ({
  open,
  title,
  content,
  onConfirm,
}: ConfirmDialogProps) => {
  return (
    <Dialog
      open={open}
      onClose={() => onConfirm(false)}
      aria-labelledby="draggable-dialog-title"
    >
      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
        {title || 'Confirm'}
      </DialogTitle>
      <Divider />
      <DialogContent sx={{ minWidth: '500px' }}>
        <DialogContentText>
          {content || 'Are you sure you want to delete this?'}
        </DialogContentText>
      </DialogContent>
      <Divider />
      <DialogActions>
        <Button
          sx={{ color: '#2372B9', textTransform: 'none' }}
          variant="text"
          autoFocus
          onClick={() => onConfirm(false)}
        >
          Cancel
        </Button>
        <Button
          sx={{
            backgroundColor: '#2372B9',
            textTransform: 'none',
          }}
          variant="contained"
          onClick={() => onConfirm(true)}
        >
          {title === 'Delete Attachment' && (
            <>
              <i className="fa-solid fa-trash"></i>&nbsp;&nbsp;
            </>
          )}
          {title === 'Delete Attachment' ? title : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
