import React, { useState, useMemo } from 'react';

import { Box, Stack, Typography, Divider } from '@mui/material';

import HoveredIcon from '../../HoveredIconButton';
import HoveredIconConfirm from '../../HoveredIconConfirmButton';
import DataFieldCard from '../../KnockoutScreens/DataFieldCard';
import DropDown from '../../DropDown';
import { DepartmentExtended } from '../../../../../shared/types/Department';
import { DataFieldExtendedWithSingleLocationAndFollowups } from '../../../../../shared/types/DataField';
import { useDeleteDepartmentMutation } from '../../../../services/departmentSlice/departmentSlice';
import { AddDepartmentDataFieldModal } from './AddDepartmentDataFieldModal';
import ModalWidget from '../../../ModalWidget/ModalWidget';
import AddDepartmentDataField from './AddDepartmentDataField';

const DepartmentScreenCard = ({
  department,
  onEditTriggered,
}: {
  department: DepartmentExtended;
  onEditTriggered: (edit: DepartmentExtended) => void;
}) => {
  const [showDetail, setShowDetail] = useState<boolean>(false);
  const [handle, setHandle] = useState<string>('');
  const [originDataFieldEdit, setOriginDataFieldEdit] = useState<
    DataFieldExtendedWithSingleLocationAndFollowups | undefined
  >(undefined);
  const [deleteDepartment] = useDeleteDepartmentMutation();
  const details =
    useMemo(() => {
      return (
        department &&
        department.departmentDataFields &&
        department.departmentDataFields.filter(
          (field) => field.dataFieldLocation.locationType === 'Department'
        )
      );
    }, [department]) || [];

  const reviews =
    useMemo(() => {
      return (
        department &&
        department.departmentDataFields &&
        department.departmentDataFields.filter(
          (field) => field.dataFieldLocation.locationType === 'DepartmentReview'
        )
      );
    }, [department]) || [];

  return (
    <>
      {handle.length ? (
        <AddDepartmentDataFieldModal
          handle={handle}
          setHandle={setHandle}
          locationID={department.id}
        />
      ) : null}
      <ModalWidget
        isOpen={!!originDataFieldEdit}
        title="Edit Data Field"
        children={
          <AddDepartmentDataField
            dataField={originDataFieldEdit}
            toggleEdit={() => setOriginDataFieldEdit(undefined)}
            onClose={() => setOriginDataFieldEdit(undefined)}
          />
        }
        onClose={() => setOriginDataFieldEdit(undefined)}
      />
      <Box
        data-testid="department-screen-card"
        sx={{
          borderLeft: 'solid 5px #2372B9',
          padding: '30px 40px',
          width: '100%',
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box alignItems="center">
            <Typography
              component="span"
              sx={{
                fontSize: '18px',
                fontWeight: '700',
              }}
            >
              {department.name}
            </Typography>
            &nbsp;&nbsp;
            {department.departmentDataFields &&
              department.departmentDataFields.length > 0 && (
                <i
                  className={`fa-sharp fa-solid fa-chevron-${
                    showDetail ? 'up' : 'down'
                  }`}
                  onClick={() => setShowDetail(!showDetail)}
                ></i>
              )}
          </Box>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              width: '105px',
            }}
          >
            {/* Delete Button for KnockoutScreen... */}
            <HoveredIconConfirm
              icon="fa-regular fa-trash"
              hoverIcon="fa-solid fa-trash"
              onClick={() => deleteDepartment(department.id)} //handleDelete()}
            />
            <HoveredIcon
              icon="fa-regular fa-pencil"
              hoverIcon="fa-solid fa-pencil"
              onClick={() => onEditTriggered(department)} //onEditTriggered(knockoutScreen)}
            />
            <DropDown
              icon="fa-regular fa-square-plus"
              hoverIcon="fa-solid fa-square-plus"
              items={['Apply Data Field(s)', 'Add Data Field']}
              onSelect={(e) => setHandle(e)}
            />
          </Stack>
        </Stack>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignContent: 'center',
            mt: 2,
          }}
        >
          <Box sx={{ mr: 2, minWidth: '150px' }}>
            <Box
              data-testid="department-name"
              sx={{ fontSize: '12px', color: '#646669' }}
            >
              Department Name
            </Box>
            <Box sx={{ fontSize: '14px', color: '#222222', mt: 0.5 }}>
              {department.name}
            </Box>
          </Box>
          <Box sx={{ mr: 2, minWidth: '180px' }}>
            <Box sx={{ fontSize: '12px', color: '#646669' }}>Azure AD Role</Box>
            <Box sx={{ fontSize: '14px', color: '#222222', mt: 0.5 }}>
              {department.adAppRole}
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
      {showDetail && (
        <>
          {!!details.length && (
            <Box
              sx={{
                pl: '40px',
                pt: '24px',
                backgroundColor: '#F8F9FA',
              }}
            >
              <Typography
                sx={{
                  borderBottom: '1px solid #E5E9EA',
                  color: '#222222',
                  fontSize: '11px',
                  fontWeight: 700,
                }}
              >
                Details
              </Typography>
            </Box>
          )}
          {details
            .sort(
              (a, b) => a.dataFieldLocation.order - b.dataFieldLocation.order
            )
            .map((dataField, index) => (
              <DataFieldCard
                key={index}
                dataField={{ ...dataField, knockoutFollowups: [] }}
                type={'department'}
                datafields={details}
                locationName={department.name}
                locationDescription={''}
                onEdit={() =>
                  setOriginDataFieldEdit(
                    dataField as DataFieldExtendedWithSingleLocationAndFollowups
                  )
                }
              />
            ))}
          {!!reviews.length && (
            <>
              <Box
                sx={{
                  height: '8px',
                  background: '#E5E9EA 0% 0% no-repeat padding-box',
                  border: '0px',
                }}
              ></Box>
              <Box
                sx={{
                  pl: '40px',
                  pt: '24px',
                  backgroundColor: '#F8F9FA',
                }}
              >
                <Typography
                  sx={{
                    borderBottom: '1px solid #E5E9EA',
                    color: '#222222',
                    fontSize: '11px',
                    fontWeight: 700,
                  }}
                >
                  Review
                </Typography>
              </Box>
            </>
          )}
          {reviews
            .sort(
              (a, b) => a.dataFieldLocation.order - b.dataFieldLocation.order
            )
            .map((dataField, index) => (
              <DataFieldCard
                key={index}
                dataField={{ ...dataField, knockoutFollowups: [] }}
                type={'Review'}
                datafields={reviews}
                locationName={department.name}
                locationDescription={department.name}
                onEdit={() => null}
              />
            ))}
        </>
      )}
    </>
  );
};

export default DepartmentScreenCard;
