import React from 'react';
import { Outlet } from 'react-router';

const Login = () => {
  return (
    <>
      <Outlet />
    </>
  );
};

export default Login;
