import { microsoftAPI } from '../API/microsoftAPI';

export const microsoftSlice = microsoftAPI.injectEndpoints({
  endpoints: (builder) => ({
    getUserPhoto: builder.query<string, string | undefined>({
      query: (id: string | 'me') => {
        return {
          url: (id === 'me' ? 'me' : 'users/' + id) + '/photo/$value',
          cache: 'no-cache',
          headers: {
            'content-type': 'image/png',
          },
          responseHandler: async (response: any) => {
            return window.URL.createObjectURL(await response.blob());
          },
        };
      },
    }),
  }),
});

export const { useGetUserPhotoQuery } = microsoftSlice;
