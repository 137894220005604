import React, { useState, useMemo, useEffect } from 'react';
import { Box } from '@mui/material';
import ActivityHeader from './ActivityHeader';
import TimeLine from './TimeLine';
import PageNavigation from '../PageNavigation/PageNavigation';
import { useGetActivitiesQuery } from '../../services/activitySlice/activitySlice';
import { SearchParams } from '../../../shared/types/Activity';

const initialSearchParam: SearchParams = {
  sortBy: 'createdAt',
  sortAsc: true,
  search: '',
  trackableType: '',
};

const Activities = ({ sepID, taskID }: { sepID?: number; taskID?: number }) => {
  const [page, setPage] = useState<number>(0);
  const [searchText, setSearchText] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');
  const [searchParameters, setSearchParameters] = useState<SearchParams>({
    ...initialSearchParam,
    sepID: sepID?.toString(),
    trackableType: taskID === undefined ? '' : 'Task',
    trackableID: taskID?.toString(),
  });
  const [rowsPerPage, setRowsPerPage] = useState<number>(25);
  const { data } = useGetActivitiesQuery({
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    ...searchParameters,
    search: searchFilter,
    // Group together DataField and DataFieldOption so user does have to pick between one or the other.
    trackableType:
      searchParameters.trackableType === 'DataField'
        ? 'DataField,DataFieldOption'
        : searchParameters.trackableType,
  });

  const rows = useMemo(() => {
    if (data) {
      return data.activities;
    }
    return [];
  }, [data]);

  useEffect(() => {
    // Debounce our search function here so we can wait 500ms for the user to stop typing
    const delayDebounceFn = setTimeout(() => {
      if (searchText.length >= 3) {
        setSearchFilter(searchText);
      } else {
        setSearchFilter('');
      }
      // Changing searchFilter should reset pagination.
      setPage(0);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [searchText, setSearchFilter]);

  return (
    <Box sx={{ mt: 6 }}>
      <ActivityHeader
        sort={searchParameters.sortAsc || false}
        search={searchText}
        select={searchParameters.trackableType || 'All'}
        onSearch={setSearchText}
        onSort={(e) => {
          setSearchParameters({
            ...searchParameters,
            sortAsc: e,
          });
          setPage(0);
        }}
        onSelect={(e) => {
          setSearchParameters({
            ...searchParameters,
            trackableType: e === 'All' ? '' : e,
          });
          setPage(0);
        }}
      />
      <TimeLine activities={rows} />
      <PageNavigation
        count={data?.count ?? 0}
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
};

export default Activities;
