import React from 'react';
import { Box } from '@mui/material';

type Props = {
  children: JSX.Element;
};

export const LoadingContainer = (props: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        alignItems: 'center',
        height: '100%',
        minWidth: '100vw',
      }}
    >
      {props.children}
    </Box>
  );
};
