import React, { useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import { Task } from '../../../shared/types/Task';
import { Department } from '../../../shared/types/Department';
import { CommentExtended } from '../../../shared/types/Comment';
import { AttachmentExtended } from '../../../shared/types/Attachment';
import StatusCell from '../SepTable/StatusCell';

interface IconCellProps {
  icon: string;
  iconHover: string;
  value: string;
}

export const IconCell = ({ icon, iconHover, value }: IconCellProps) => {
  const [className, setClassName] = useState(icon);
  return (
    <div
      onMouseOver={() => setClassName(iconHover)}
      onMouseLeave={() => setClassName(icon)}
    >
      <i className={className}></i>
      &nbsp;&nbsp;
      {value}
    </div>
  );
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.gray.light,
    fontSize: 14,
    paddingBottom: 4,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.gray.main,
  '&:hover': {
    color: theme.palette.solidBlue.main,
  },
}));

const DependentTasksTable = ({
  task,
  departments,
  comments,
  attachments,
}: {
  task: Task[];
  departments: Department[];
  comments: CommentExtended[];
  attachments: AttachmentExtended[];
}) => {
  return (
    <Box sx={{ m: 2, maxHeight: '500px', overflow: 'auto' }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Task</StyledTableCell>
              <StyledTableCell>Department</StyledTableCell>
              <StyledTableCell>Status</StyledTableCell>
              <StyledTableCell colSpan={2}>Activity</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {task.map((t, index) => (
              <TableRow key={index}>
                <StyledTableCell>
                  <StyledLink
                    sx={{ fontSize: '14px' }}
                    className="blue-text"
                    to={`/sep/${t.sepID}/task/${t.id}`}
                  >
                    {t.name}
                  </StyledLink>
                </StyledTableCell>
                <StyledTableCell>
                  {
                    departments.filter(
                      (department) => department.id === t.departmentID
                    )[0].name
                  }
                </StyledTableCell>
                <StyledTableCell>
                  <StatusCell status={t.status} />
                </StyledTableCell>
                <StyledTableCell>
                  <IconCell
                    icon="fa-regular fa-comment"
                    iconHover="fa-solid fa-comment task-icon"
                    value={comments
                      .filter((comment) => comment.taskID === t.id)
                      .length.toString()}
                  />
                </StyledTableCell>
                <StyledTableCell>
                  <IconCell
                    icon="fa-regular fa-paperclip-vertical"
                    iconHover="fa-solid fa-paperclip task-icon"
                    value={attachments
                      .filter((attachment) => attachment.taskID === t.id)
                      .length.toString()}
                  />
                </StyledTableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default DependentTasksTable;
