import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material/index';
import Select, { SelectChangeEvent } from '@mui/material/Select/index';
import React from 'react';
import {
  CreateDataFieldOptionTemplateBody,
  DataFieldType,
} from '../../../../../shared/types/DataField';
import HoveredIcon from '../../HoveredIconButton';
import {
  getOriginValue,
  prettierValue,
  textFieldSx,
} from './AddDepartmentDataField';

interface SecondStepProps {
  options: CreateDataFieldOptionTemplateBody[];
  type: string;
  onDataFieldTypeChanged: (type: DataFieldType) => void;
  onOptionValueChanged: (value: string, index: number) => void;
  onOptionIconChanged: (value: string, index: number) => void;
  onOptionDeleted: (index: number) => void;
  onNewOptionAdded: () => void;
}

export function SecondStep({
  options,
  type,
  onDataFieldTypeChanged,
  onOptionValueChanged,
  onOptionIconChanged,
  onOptionDeleted,
  onNewOptionAdded,
}: SecondStepProps) {
  const isTileSelect = type === 'tileSelect' || type === 'tileMultiSelect';
  const hasOption = type === 'select' || type === 'multiSelect' || isTileSelect;
  return (
    <Box sx={{ p: 4 }}>
      <Box sx={{ width: '338px', mb: '30px' }}>
        <InputLabel id="demo-simple-select-label">Data Field Type</InputLabel>
        <Select
          id="data-field-select"
          value={prettierValue(type)}
          onChange={(e: SelectChangeEvent) => {
            onDataFieldTypeChanged(
              getOriginValue(e.target.value) as DataFieldType
            );
          }}
          size="small"
          displayEmpty
          input={<OutlinedInput />}
          renderValue={(selected) => {
            if (selected.length === 0) {
              return (
                <Typography sx={{ color: '#7E8083' }}>
                  Choose a data field type from the dropdown
                </Typography>
              );
            }

            return selected;
          }}
        >
          <MenuItem disabled value="">
            <Typography sx={{ color: '#7E8083' }}>
              Choose a data field type from the dropdown
            </Typography>
          </MenuItem>
          {Object.keys(DataFieldType).map((key, index) => {
            const indexOfKey = Object.keys(DataFieldType).indexOf(key);
            const value = Object.values(DataFieldType)[indexOfKey];
            return (
              <MenuItem key={index} value={key}>
                {prettierValue(value)}
              </MenuItem>
            );
          })}
        </Select>
      </Box>
      {hasOption && (
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Box sx={{ color: '#222222', width: '216px' }}>
              Data Field Options
            </Box>

            {isTileSelect && (
              <Box sx={{ color: '#222222', width: '216px' }}>
                Data Field Option Icon
              </Box>
            )}
            {hasOption && <Box sx={{ width: '25px' }}></Box>}
          </Box>
          {options.map((option, i) => (
            <MappedComponent
              key={i}
              index={i}
              option={option}
              isTileSelect={isTileSelect}
              onOptionValueChanged={onOptionValueChanged}
              onOptionIconChanged={onOptionIconChanged}
              onOptionDeleted={onOptionDeleted}
            />
          ))}
        </Box>
      )}
      {hasOption && (
        <Button
          sx={{ textTransform: 'none', mt: '5px' }}
          variant="text"
          onClick={onNewOptionAdded}
        >
          <i className="fa-solid fa-plus"></i> &nbsp; Add option
        </Button>
      )}
    </Box>
  );
}

interface MappedComponentProps {
  index: number;
  isTileSelect: boolean;
  option: CreateDataFieldOptionTemplateBody;
  onOptionValueChanged: (value: string, index: number) => void;
  onOptionIconChanged: (value: string, index: number) => void;
  onOptionDeleted: (index: number) => void;
}

const MappedComponent: React.FC<MappedComponentProps> = ({
  option,
  index,
  isTileSelect,
  onOptionValueChanged,
  onOptionIconChanged,
  onOptionDeleted,
}) => {
  const handleOptionValueChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    onOptionValueChanged(e.target.value, index);
  };

  const handleIconChanged = (e: React.ChangeEvent<HTMLInputElement>) => {
    onOptionIconChanged(e.target.value, index);
  };

  const handleDeleteOption = () => {
    onOptionDeleted(index);
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
      <Box sx={{ mb: '5px' }}>
        <TextField
          sx={textFieldSx}
          size="small"
          id="datafieldOption"
          name="datafieldOption"
          fullWidth
          rows={4}
          value={option.value}
          placeholder="Type an option here."
          onChange={handleOptionValueChanged}
        />
      </Box>
      {isTileSelect && (
        <Box>
          <TextField
            sx={textFieldSx}
            size="small"
            id="datafieldOption"
            name="datafieldOption"
            fullWidth
            rows={4}
            value={option.icon || ''}
            placeholder="Paste a Font Awesome icon name."
            onChange={handleIconChanged}
          />
        </Box>
      )}
      <Box sx={{ mt: '7px' }}>
        <HoveredIcon
          icon="fa-regular fa-trash"
          hoverIcon="fa-solid fa-trash"
          onClick={handleDeleteOption}
        />
      </Box>
    </Box>
  );
};
