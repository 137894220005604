import * as React from 'react';
import { styled } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';

const MuiSwitch = styled((props: SwitchProps) => (
  <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
))(({ theme }) => ({
  width: 50,
  height: 26,
  padding: 0,
  '&:hover': {
    '& .MuiSwitch-track': {
      backgroundColor: theme.palette.blue.lightBlue1,
      border: `1px solid ${theme.palette.solidBlue.main}`,
      '&:before': {
        color: theme.palette.solidBlue.main,
      },
    },
    '& .MuiSwitch-thumb': {
      border: `1px solid ${theme.palette.solidBlue.main}`,
    },
  },
  '&:active': {
    '& .MuiSwitch-thumb': {
      color: theme.palette.blue.lightBlue1,
    },
  },

  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 0,
    transitionDuration: '100ms',
    '&.Mui-checked': {
      transform: 'translateX(24px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.solidBlue.main,
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: theme.palette.blue.lightBlue1,
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 26,
    height: 26,
    border: '1px solid #7E8083',
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#F2F2F2',
    border: '1px solid #7E8083',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 100,
    }),
    '&:after, &:before': {
      fontSize: '11px',
      position: 'absolute',
      top: '4px',
    },
    '&:after': {
      content: "'On'",
      color: theme.palette.common.white,
      left: '8px',
    },
    '&:before': {
      color: theme.palette.gray.lightGray,
      content: "'Off'",
      right: '7px',
      '&:hover': {
        backgroundColor: theme.palette.blue.lightBlue1,
        color: theme.palette.solidBlue,
      },
    },
  },
}));

const StyledSwitch = (props: SwitchProps) => <MuiSwitch {...props} />;

export default StyledSwitch;
