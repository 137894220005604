import React, { useState, useMemo, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Popper from '@mui/material/Popper';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Autocomplete, {
  AutocompleteCloseReason,
  autocompleteClasses,
} from '@mui/material/Autocomplete';
import Stack from '@mui/material/Stack';
import InputBase from '@mui/material/InputBase';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import { Divider, InputAdornment } from '@mui/material';

interface PopperComponentProps {
  anchorEl?: any;
  disablePortal?: boolean;
  open: boolean;
}

const StyledAutocompletePopper = styled('div')(({ theme }) => ({
  [`& .${autocompleteClasses.paper}`]: {
    boxShadow: 'none',
    margin: 0,
    color: 'inherit',
    fontSize: 13,
    width: '300px',
  },
  [`& .${autocompleteClasses.listbox}`]: {
    backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
    padding: 0,
    [`& .${autocompleteClasses.option}`]: {
      minHeight: 'auto',
      alignItems: 'flex-start',
      padding: 8,
      borderBottom: `1px solid  ${
        theme.palette.mode === 'light' ? ' #eaecef' : '#30363d'
      }`,
      '&[aria-selected="true"]': {
        backgroundColor: 'transparent',
      },
      [`&.${autocompleteClasses.focused}, &.${autocompleteClasses.focused}[aria-selected="true"]`]:
        {
          backgroundColor: theme.palette.action.hover,
        },
    },
  },
  [`&.${autocompleteClasses.popperDisablePortal}`]: {
    position: 'relative',
  },
}));

function PopperComponent(props: PopperComponentProps) {
  const { disablePortal, anchorEl, open, ...other } = props;
  return <StyledAutocompletePopper {...other} />;
}

const StyledPopper = styled(Popper)(({ theme }) => ({
  border: `1px solid ${theme.palette.mode === 'light' ? '#e1e4e8' : '#30363d'}`,
  boxShadow: `0 8px 24px ${
    theme.palette.mode === 'light' ? 'rgba(149, 157, 165, 0.2)' : 'rgb(1, 4, 9)'
  }`,
  borderRadius: 6,
  width: '300px',
  zIndex: theme.zIndex.modal,
  fontSize: 13,
  color: theme.palette.mode === 'light' ? '#24292e' : '#c9d1d9',
  backgroundColor: theme.palette.mode === 'light' ? '#fff' : '#1c2128',
}));

const StyledInput = styled(InputBase)(({ theme }) => ({
  padding: 3,
  width: '270px',
  borderRadius: 4,
  outline: 'none',
  border: '1px solid #7E8083',
  backgroundColor: '#FBFCFC',
  '& input': {
    padding: '4px',
  },
  fontSize: 14,
  '&:focus': {
    outline: 'none',
  },
}));

export default function AutoComplete({
  type,
  show,
  adAppRoleName,
  anchor,
  showConfirmButton,
  data,
  onClose,
  onChange,
  helperText,
}: {
  type: string;
  show: boolean;
  adAppRoleName?: string;
  anchor: null | HTMLElement;
  showConfirmButton?: boolean;
  data: Array<any>;
  onClose: () => void;
  onChange: (changedValue: any) => void;
  helperText?: string;
}) {
  const [pendingValue, setPendingValue] = React.useState<any>(undefined);
  const [text, setText] = useState<string>('');
  const [searchFilter, setSearchFilter] = useState<string>('');

  const rows = useMemo(() => {
    if (data) {
      return data.filter((e) =>
        Object.values(e).some((value) =>
          JSON.stringify(value).includes(searchFilter)
        )
      );
    }
    return [];
  }, [data, searchFilter]);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (text.length >= 3) {
        setSearchFilter(text);
      } else {
        setSearchFilter('');
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [text, setSearchFilter]);

  const handleChange = (e: any) => {
    if (e === undefined) return;
    onChange(e);
  };

  const handleClose = () => {
    setText('');
    setPendingValue(undefined);
    onClose();
  };

  return (
    <StyledPopper open={show} anchorEl={anchor} placement="bottom-start">
      <ClickAwayListener onClickAway={handleClose}>
        <div>
          <Autocomplete
            open
            onClose={(
              event: React.ChangeEvent<{}>,
              reason: AutocompleteCloseReason
            ) => {
              if (reason === 'escape') {
                handleClose();
              }
            }}
            onInputChange={(event, value: string, reason: string) => {
              if (
                value.length > 0 &&
                reason === 'input' &&
                !rows.find((e) => e.name.includes(value))
              ) {
                setText(value);
              }
            }}
            onChange={(event, newValue, reason) => {
              setPendingValue(newValue ? newValue : undefined);
            }}
            disableCloseOnSelect
            PopperComponent={PopperComponent}
            renderTags={() => null}
            noOptionsText="No tasks results found."
            renderOption={(props, option, { selected }) => (
              <li {...props} style={{ padding: 0 }}>
                <Box
                  onClick={() =>
                    showConfirmButton || showConfirmButton === undefined
                      ? null
                      : handleChange(option)
                  }
                  sx={{
                    flexGrow: 1,
                    padding: '8px',
                    backgroundColor: selected ? '#F3F8FC' : '#fff',
                    color: selected ? '#2372B9' : '#222',
                    '&: hover': {
                      color: '#2372B9',
                      backgroundColor: '#F3F8FC',
                    },
                    '&: hover > span': {
                      color: '#646669',
                    },
                    '& span': {
                      color: '#646669',
                    },
                  }}
                >
                  {option?.name}
                </Box>
              </li>
            )}
            options={rows}
            filterOptions={(options, state) => options}
            getOptionLabel={(option) => option.displayName || ''}
            renderInput={(params) => (
              <>
                <Box sx={{ padding: '8px 16px', backgroundColor: '#E5E9EA' }}>
                  <StyledInput
                    size="small"
                    ref={params.InputProps.ref}
                    inputProps={params.inputProps}
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    autoFocus
                    placeholder={'Search for a task...'}
                  />
                </Box>
                <Divider />
              </>
            )}
          />
          {(showConfirmButton || showConfirmButton === undefined) && (
            <>
              <Divider />
              <Stack
                direction="row"
                justifyContent="right"
                spacing={2}
                sx={{
                  p: 1,
                }}
              >
                <Button
                  size="small"
                  variant="text"
                  onClick={handleClose}
                  sx={{ textTransform: 'none' }}
                >
                  Cancel
                </Button>
                <Button
                  size="small"
                  variant="outlined"
                  onClick={() => handleChange(pendingValue)}
                  sx={{ textTransform: 'none' }}
                >
                  {type === 'assigner'
                    ? 'Save Assignee'
                    : 'Save Resource Owner'}
                </Button>
              </Stack>
            </>
          )}
        </div>
      </ClickAwayListener>
    </StyledPopper>
  );
}
