import { SequelizeTimestamps } from './Sequelize';
import { UserShort } from './User';

export enum SEPPhase {
  knockout = 'knockout',
  initiate = 'initiate',
  design = 'design',
  implement = 'implement',
  complete = 'complete',
}

export enum SEPStatus {
  active = 'active',
  approved = 'approved',
  rejected = 'rejected',
  expired = 'expired',
  cancelled = 'cancelled',
}

export enum WORKFLOWTYPE {
  designReview = 'SEP Design Review',
  iotLabs = 'IoT Lab Approval',
  saConsultation ='Solution Architect Consultation'
}
export interface SEP extends SequelizeTimestamps {
  id: number;
  name?: string | null;
  description?: string | null;
  createdBy: string;
  locked: boolean;
  template: boolean;
  deletedAt?: string;
  apmID?: number | null;
  apmData?: string | null;
  phase: SEPPhase;
  status: SEPStatus;
  subscribers: string | null;
  workFlowType?: string; 
}

export enum NewWorkflowType {
  solutionArchitectConsultation = 'Solution Architect Consultation',
  sepDesignReview = 'SEP Design Review',
}

export interface SEPSearchRow extends SEP {
  /** The creator/requestor of the SEP */
  creator: UserShort;
  /** The number of enabled tasks assigned to the user that are in "todo", "inReview", or "changesRequested" status */
  userActiveTaskCount: number;
  /** The number of enabled tasks assigned to the user that are in "pending" status */
  userPendingTaskCount: number;
  /** The number of enabled tasks NOT assigned to the user that are in "todo", "inReview", or "changesRequested" status  */
  otherTaskCount: number;
  /** The number of enabled initiate phase tasks that are in "complete" status */
  initiateTasksCompleteCount: number;
  /** The total number of enabled initiate phase tasks */
  initiateTasksTotalCount: number;
  /** The number of enabled design phase tasks that are in "complete" status */
  designTasksCompleteCount: number;
  /** The total number of enabled design phase tasks */
  designTasksTotalCount: number;
  /** The number of enabled implement phase tasks that are in "complete" status */
  implementTasksCompleteCount: number;
  /** The total number of enabled implement phase tasks */
  implementTasksTotalCount: number;
}

export interface SEPSearchResult {
  count: number;
  seps: SEPSearchRow[];
}

export interface UpdateSEPBody {
  name?: string;
  createdBy?: string;
  description?: string | null;
  apmID?: number | null;
  apmData?:any;
  subscribers?: string | null;
  workFlowType?: string
}

export interface CreateSEPBody {
  name?: string;
  description?: string | null;
  apmID?: number | null;
  subscribers?: string | null;
  workFlowType?: string;
}

export interface GetSEPResponse extends SEP {
  creator: UserShort;
}

export interface SearchParams {
  limit?: number;
  offset?: number;
  sortBy?: string;
  sortAsc?: boolean;
  status?: string;
  phase?: string;
  assigneeId?: string;
  search?: string;
  createdBy?: string;
  mine?: boolean;
  myToDos?: boolean;
  statusFilter?: string;
  workFlowType?: string;
}

export interface UpdateSEPStatusBody {
  sepID: number;
  status: SEPStatus;
  reason: string;
}
