import React, { useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { useNavigate, useLocation } from 'react-router';
import { usePartialSepQuery } from '../../services/sepSlice';
import { useGetUserQuery } from '../../services/usersSlice';
import { LoadingContainer } from '../../containers/LoadingContainer';
import { CreateSEP } from './CreateSEP';

const Redirect = () => {
  // let validUser = ''
  const user = useGetUserQuery('me');
  const {
    data: SEPSearch,
    isLoading,
    error,
  } = usePartialSepQuery(
    user.data !== undefined ? user.data.id : 'no user found',
    { skip: !user.isSuccess, refetchOnMountOrArgChange: true }
  );
  const navigate = useNavigate();
  const location = useLocation();
  const editSep = location?.state?.condition;

  useEffect(() => {
    if (editSep !== null && editSep === 'edit') {
      return;
    } else if (SEPSearch !== undefined && SEPSearch.count > 0) {
      navigate(`/knockout/${SEPSearch?.seps[0].id}`);
    }
  }, [editSep, SEPSearch, navigate]);

  if (isLoading) {
    return (
      <>
        <LoadingContainer>
          <CircularProgress size={100} />
        </LoadingContainer>
      </>
    );
  }

  if (error && SEPSearch === undefined) {
    return <>Something went wrong...</>;
  }

  return <>{SEPSearch && <CreateSEP {...SEPSearch} />}</>;
};

export default Redirect;
