import React from 'react';
import Box from '@mui/material/Box';
import { APM } from '../../../shared/types/APM';
import styled from '@mui/material/styles/styled';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  borderBottom: 'none',
  padding: 0,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  border: 0,
  padding: 0,
}));

export const DataField = ({
  field,
  value,
}: {
  field: string;
  value: string;
}) => {
  return (
    <Box sx={{ p: 2, pt: 0 }}>
      <Box
        aria-label="field"
        sx={{
          font: 'normal normal normal 12px/22px Open Sans',
          color: '#7E8083',
          pt: 1,
          height: '30px',
        }}
      >
        {field}
      </Box>
      <Box
        aria-label="value"
        sx={{
          font: 'normal normal normal 14px/22px Open Sans',
          pt: 1,
          height: '30px',
        }}
      >
        {value}
      </Box>
    </Box>
  );
};

const APMFields = ({ apm }: { apm: APM }) => {
  return (
    <Box sx={{ p: 4, minWidth: '200px' }}>
      <Box sx={{ fontSize: '16px', fontWeight: '700', p: 2 }}>
        APM Record Details
      </Box>
      <TableContainer>
        <Table sx={{ minWidth: 700 }} aria-label="customized table">
          <TableBody>
            <StyledTableRow key={1}>
              <StyledTableCell>
                <DataField field="Application Name" value={apm?.name || ''} />
              </StyledTableCell>
              <StyledTableCell>
                <DataField
                  field="Additional Regulatory Requirements"
                  value={apm?.u_additional_regulatory_requirement || ''}
                />
              </StyledTableCell>
              <StyledTableCell>
                <DataField
                  field="Support Vendor"
                  value={apm?.support_vendor || ''}
                />
              </StyledTableCell>
              <StyledTableCell>
                <DataField
                  field="Development Type"
                  value={apm?.install_type || ''}
                />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={2}>
              <StyledTableCell>
                <DataField
                  field="Data Classification"
                  value={apm?.data_classification || ''}
                />
              </StyledTableCell>
              <StyledTableCell>
                <DataField
                  field="Business Value"
                  value={apm?.business_criticality || ''}
                />
              </StyledTableCell>
              <StyledTableCell>
                <DataField
                  field="Functional Area"
                  value={apm?.u_functional_area || ''}
                />
              </StyledTableCell>
              <StyledTableCell>
                <DataField field="Type" value={apm?.u_type || ''} />
              </StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={3}>
              <StyledTableCell>
                <DataField
                  field="Compliance Flag"
                  value={apm?.u_compliance_flag || ''}
                />
              </StyledTableCell>
              <StyledTableCell>
                <DataField field="DR Tier" value={apm?.emergency_tier || ''} />
              </StyledTableCell>
              <StyledTableCell>
                <DataField
                  field="Portfolio"
                  value={apm?.u_apm_portfolio || ''}
                />
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={4}>
              <StyledTableCell>
                <DataField field="PII" value={apm?.u_pii || ''} />
              </StyledTableCell>
              {/* <StyledTableCell>
                <DataField
                  field="DR Gap"
                  value={
                    apm?.u_gap_between_dr_tier_designation_and_architected_dr ||
                    ''
                  }
                />
              </StyledTableCell> */}
              <StyledTableCell>
                <DataField
                  field="Portfolio Owner"
                  value={apm?.u_portfolio_owner || ''}
                />
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
            <StyledTableRow key={5}>
              <StyledTableCell>
                <DataField field="PI" value={apm?.u_pi || ''} />
              </StyledTableCell>
              <StyledTableCell>
                <DataField
                  field="Lifecycle Status"
                  value={apm?.install_status || ''}
                />
              </StyledTableCell>
              <StyledTableCell>
                <DataField
                  field="User Count"
                  value={apm?.u_active_user_count || ''}
                />
              </StyledTableCell>
              <StyledTableCell></StyledTableCell>
            </StyledTableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default APMFields;
