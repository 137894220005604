import { Container } from '@mui/material';
import React, { useEffect } from 'react';
import { Outlet } from 'react-router';
import Appbar from '../components/Appbar';

const KnockoutContainer = () => {
  useEffect(() => {
    document.body.style.backgroundColor = '#fff';
  }, []);

  return (
    <>
      <Appbar />
      <Container
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexGrow: 1,
          minWidth: '100vw',
          backgroundColor: '##ffff',
          p: 2,
          minHeight: '90vh',
          flexDirection: 'column',
        }}
      >
        <Outlet />
      </Container>
    </>
  );
};

export default KnockoutContainer;
