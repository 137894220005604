import React from 'react';
import { Box, Button } from '@mui/material';

const AddButton = ({
  tab,
  onAdd,
}: {
  tab: string;
  onAdd: (tab: string) => void;
}) => {
  return (
    <Box
      sx={{ padding: '40px 0px', display: 'flex', justifyContent: 'center' }}
    >
      <Button
        variant="outlined"
        size="small"
        sx={{
          '&.MuiButton-outlined': {
            border: '2px solid #2372B9',
            color: '#2372B9',
            textTransform: 'none',
            fontWeight: 700,
          },
        }}
        onClick={() => onAdd(tab)}
      >
        + Add {tab}
      </Button>
    </Box>
  );
};

export default AddButton;
