import React, { useEffect } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import SepOverview from '../SepOverview';
import TaskOverview from '../TaskOverview';
import { useParams } from 'react-router-dom';
import { useGetSepQuery, useGetSepTasksQuery } from '../../services/sepSlice';
import { skipToken } from '@reduxjs/toolkit/query';

const flexContainer = {
  display: 'flex',
  justifyContent: 'center',
  alignContent: 'center',
  alignItems: 'center',
};

const SEP = () => {
  const { sepId, taskId } = useParams();
  const {
    data: sep,
    isLoading: sepLoading,
    error,
    refetch,
  } = useGetSepQuery(Number(sepId) || skipToken);
  const { data: tasks } = useGetSepTasksQuery(parseInt(sepId as string));
  useEffect(() => {
    refetch();
  }, [refetch, taskId, sepId]);

  if (sepLoading) {
    return (
      <Box sx={{ ...flexContainer, minHeight: '100vh' }}>
        <CircularProgress size={100} />
      </Box>
    );
  } else if (sep === undefined && error) {
    return (
      <Box sx={{ ...flexContainer, minHeight: '100vh' }}>
        <Typography variant="h6"> No SEP found</Typography>
      </Box>
    );
  }

  if (taskId === undefined && sep) {
    return (
      <SepOverview
        sepID={parseInt(sepId as string)}
        onNewTask={() => refetch()}
      />
    );
  }

  const task = tasks?.find((e: any) => e.id === Number(taskId));
  if (task === undefined) {
    return (
      <Box sx={{ ...flexContainer, minHeight: '100vh' }}>
        <Typography variant="h6"> No Task found</Typography>
      </Box>
    );
  }
  return <TaskOverview task={task} />;
};

export default SEP;
