import React, { useState, useEffect } from 'react';
import {
  Box,
  Divider,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Button,
  Tabs,
} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import {
  DataFieldLocationType,
  DataFieldWithOptionsAndLocations,
} from '../../../../shared/types/DataField';
import { useGetDataFieldTemplatesQuery } from '../../../services/dataFieldsSlice';
import { prettierValue } from './AddDataField';
import { Tab, TabProps } from '@mui/material';

interface ExtendedTabProps extends TabProps {
  name: string;
}
interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

const CustomTab = (props: ExtendedTabProps) => {
  const { name, ...other } = props;
  return <Tab {...other} />;
};

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const DataFieldMenuItem = ({
  item,
  onSelected,
  onUnSelected,
  isDepartment,
}: {
  item: DataFieldWithOptionsAndLocations;
  onSelected: (item: DataFieldWithOptionsAndLocations) => void;
  onUnSelected: (item: DataFieldWithOptionsAndLocations) => void;
  isDepartment?: boolean;
}) => {
  const [checked, setChecked] = useState<boolean>(false);
  return (
    <Box sx={{ py: 1 }}>
      <FormControlLabel
        sx={{ '& > span': { fontSize: '14px' } }}
        control={
          <Checkbox
            checked={checked}
            onChange={(e) => {
              setChecked(e.target.checked);
              e.target.checked ? onSelected(item) : onUnSelected(item);
            }}
          />
        }
        label={item.name}
      />
      <Typography sx={{ ml: '32px', fontSize: '11px', color: '#646669' }}>
        {prettierValue(item.type)}
        {item.dataFieldOptions.length > 0
          ? ` | ${item.dataFieldOptions.length} options`
          : ''}
      </Typography>
      <Divider sx={{ mt: 2 }} />
    </Box>
  );
};

const DebounceSearchField = ({
  onChange,
}: {
  onChange: (e: string) => void;
}) => {
  const [text, setText] = useState<string>('');
  useEffect(() => {
    // Debounce our search function here so we can wait 500ms for the user to stop typing
    const delayDebounceFn = setTimeout(() => {
      if (text.length >= 3) {
        onChange(text);
      } else {
        onChange('');
      }
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  }, [text, onChange]);

  return (
    <TextField
      placeholder="Search Data Fields…"
      size="small"
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      onChange={(e) => setText(e.target.value)}
    />
  );
};

const ApplyDataField = ({
  onClose,
  onApply,
  closed,
  isDepartment,
  setLocType,
}: {
  onClose: () => void;
  onApply: (dataFields: DataFieldWithOptionsAndLocations[]) => void;
  closed: boolean;
  isDepartment?: boolean;
  setLocType?: React.Dispatch<React.SetStateAction<DataFieldLocationType>>;
}) => {
  const [selected, setSelected] = useState<DataFieldWithOptionsAndLocations[]>(
    []
  );
  const [searchFilter, setSearchFilter] = useState<string>('');
  const { data } = useGetDataFieldTemplatesQuery();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setLocType &&
      setLocType(
        newValue === 0
          ? DataFieldLocationType.Department
          : DataFieldLocationType.DepartmentReview
      );
  };
  const filterSearch = (item: DataFieldWithOptionsAndLocations) => {
    if (isDepartment) {
      return (
        item.value
          ?.toString()
          .toLowerCase()
          .includes(searchFilter.toLowerCase()) ||
        item.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
        (item.description || '')
          .toLowerCase()
          .includes(searchFilter.toLowerCase()) ||
        item.createdAt.toLowerCase().includes(searchFilter.toLowerCase()) ||
        item.createdBy.toLowerCase().includes(searchFilter.toLowerCase()) ||
        prettierValue(item.type.toString())
          .toLowerCase()
          .includes(searchFilter.toLowerCase())
      );
    }
    return (
      item.value
        ?.toString()
        .toLowerCase()
        .includes(searchFilter.toLowerCase()) ||
      item.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
      (item.description || '')
        .toLowerCase()
        .includes(searchFilter.toLowerCase()) ||
      item.createdAt.toLowerCase().includes(searchFilter.toLowerCase()) ||
      item.createdBy.toLowerCase().includes(searchFilter.toLowerCase()) ||
      item.type.toString().toLowerCase().includes(searchFilter.toLowerCase())
    );
  };

  useEffect(() => {
    if (closed) {
      setSelected([]);
    }
  }, [closed]);

  if (isDepartment) {
    return (
      <Box sx={{ p: 2 }}>
        <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <CustomTab
              label="Department"
              sx={{ width: '50%', textTransform: 'none', fontWeight: '700' }}
              {...a11yProps(0)}
              name="Department"
            />
            <CustomTab
              label="Review"
              sx={{ width: '50%', textTransform: 'none', fontWeight: '700' }}
              {...a11yProps(1)}
              name="DeparmentReview"
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  textAlign: 'center',
                  height: '40px',
                  pt: '7px',
                  fontWeight: '700',
                }}
              >
                Data Fields Selected ({selected.length})
              </Box>
              <DebounceSearchField
                onChange={(e) => {
                  setSearchFilter(e);
                }}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'block',
              overflow: 'auto',
              p: 2,
              maxHeight: '400px',
            }}
          >
            {data &&
              data
                .filter(filterSearch)
                .map((e, i) => (
                  <DataFieldMenuItem
                    key={i}
                    item={e}
                    onSelected={(item) => setSelected([...selected, item])}
                    onUnSelected={(item) =>
                      setSelected(
                        selected.filter((remove) => remove.id !== item.id)
                      )
                    }
                  />
                ))}
          </Box>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Box sx={{ p: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
              <Box
                sx={{
                  textAlign: 'center',
                  height: '40px',
                  pt: '7px',
                  fontWeight: '700',
                }}
              >
                Data Fields Selected ({selected.length})
              </Box>
              <DebounceSearchField
                onChange={(e) => {
                  setSearchFilter(e);
                }}
              />
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              display: 'block',
              overflow: 'auto',
              p: 2,
              maxHeight: '400px',
            }}
          >
            {data &&
              data
                .filter(filterSearch)
                .map((e, i) => (
                  <DataFieldMenuItem
                    key={i}
                    item={e}
                    onSelected={(item) => setSelected([...selected, item])}
                    onUnSelected={(item) =>
                      setSelected(
                        selected.filter((remove) => remove.id !== item.id)
                      )
                    }
                  />
                ))}
          </Box>
        </TabPanel>
        <Divider />
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'right',
            p: 2,
            '& > button': {
              m: 1,
            },
          }}
        >
          <Button
            variant="text"
            disabled={false}
            onClick={() => onClose()}
            sx={{ textTransform: 'none' }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() => onApply(selected)}
            disabled={!selected.length}
            sx={{
              textTransform: 'none',
              backgroundColor: '#2372B9',
              font: 'normal normal 600 16px/22px Open Sans',
            }}
          >
            Apply {value === 0 ? ' to Department' : ' to Review'}
          </Button>
        </Box>
      </Box>
    );
  }

  return (
    <Box>
      <Divider />
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box
            sx={{
              textAlign: 'center',
              height: '40px',
              pt: '7px',
              fontWeight: '700',
            }}
          >
            Data Fields Selected ({selected.length})
          </Box>
          <DebounceSearchField
            onChange={(e) => {
              setSearchFilter(e);
            }}
          />
        </Box>
      </Box>
      <Divider />
      <Box
        sx={{ display: 'block', overflow: 'auto', p: 2, maxHeight: '400px' }}
      >
        {data &&
          data
            .filter(filterSearch)
            .map((e, i) => (
              <DataFieldMenuItem
                key={i}
                item={e}
                onSelected={(item) => setSelected([...selected, item])}
                onUnSelected={(item) =>
                  setSelected(
                    selected.filter((remove) => remove.id !== item.id)
                  )
                }
              />
            ))}
      </Box>
      <Divider />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'right',
          p: 2,
          '& > button': {
            m: 1,
          },
        }}
      >
        <Button
          variant="text"
          disabled={false}
          onClick={() => onClose()}
          sx={{ textTransform: 'none' }}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          onClick={() => onApply(selected)}
          disabled={!selected.length}
          sx={{
            textTransform: 'none',
            backgroundColor: '#2372B9',
            font: 'normal normal 600 16px/22px Open Sans',
          }}
        >
          Apply Data Field(s)
        </Button>
      </Box>
    </Box>
  );
};

export default ApplyDataField;
