import React, { useMemo, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Link } from 'react-router-dom';
import {
  TaskExtended,
  TaskStatus,
  Task,
  ReadableTaskStatus,
} from '../../../shared/types/Task';
import { CommentExtended } from '../../../shared/types/Comment';
import StatusCell from '../SepTable/StatusCell';
import './style.css';
import { Typography } from '@mui/material';
import { useGetAttachmentByIDQuery } from '../../services/attachmentSlice/attachmentSlice';
import { useGetCommentsQuery } from '../../services/commentSlice/commentSlice';
import ModalWidget from '../ModalWidget/ModalWidget';
import DependentTasksTable from './DependentTasksTable';
import { useGetDepartmentsQuery } from '../../services/departmentSlice/departmentSlice';
import { useAccount } from '@azure/msal-react';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: theme.palette.gray.light,
    fontSize: 14,
    paddingBottom: 4,
    minWidth: '100px',
    padding: '8px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.gray.main,
  '&:hover': {
    color: theme.palette.solidBlue.main,
  },
}));

const TaskInfoCell = ({
  task,
  comments,
  sepID,
  taskID,
  onShowDependentList,
  isMyTask,
  superUser,
}: {
  task: TaskExtended;
  comments: CommentExtended[];
  sepID: number;
  taskID: number;
  onShowDependentList: (tasks: Task[]) => void;
  isMyTask: boolean;
  superUser: boolean;
}) => {
  const pending = task.status === TaskStatus.pending;
  const dependentTasks = task.parentTasks.filter((t) => t.enabled);

  const taskComments: CommentExtended[] = superUser
    ? comments.filter((c) => c.taskID === task.id)
    : comments.filter(
        (c) => c.taskID === task.id && !c.departmentID && !c.taskID
      );
  const [hover, setHover] = useState<boolean>(false);
  return (
    <Box
      onMouseOver={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <StyledLink to={`/sep/${sepID}/task/${taskID}`}>
        <Typography
          sx={{
            float: 'left',
            minWidth: '16px',
            maxWidth: '16px',
            minHeight: '4px',
          }}
        >
          {isMyTask ? (
            <i
              style={{
                fontSize: '12px',
                color:
                  task.status === TaskStatus.complete && !hover
                    ? '#6BA543'
                    : '#2372B9',
              }}
              className={
                hover
                  ? 'fas fa-sign-in-alt'
                  : task.status === TaskStatus.complete
                  ? 'fa-solid fa-check'
                  : 'fa-solid fa-circle-small'
              }
            />
          ) : (
            <i
              style={{
                fontSize: '12px',
                color:
                  task.status === TaskStatus.complete && !hover
                    ? '#6BA543'
                    : '#2372B9',
              }}
              className={
                hover
                  ? 'fas fa-sign-in-alt'
                  : task.status === TaskStatus.complete
                  ? 'fa-solid fa-check'
                  : ''
              }
            />
          )}
        </Typography>
        <Typography>{task.name}</Typography>
      </StyledLink>
      <Box sx={{ display: 'flex', marginTop: '8px', marginLeft: '16px' }}>
        {pending && dependentTasks.length > 0 && (
          <>
            <Typography
              sx={{
                marginTop: '3px',
                marginRight: '16px',
                height: '8px',
                width: '8px',
                border: '1px solid gray',
                borderTop: '0px',
                borderRight: '0px',
              }}
            ></Typography>
            <em className="normal-text">
              This task is dependent on{' '}
              <span className="blue-text">
                {dependentTasks.length === 1 ? (
                  <Link
                    className="blue-text"
                    to={`/sep/${sepID}/task/${dependentTasks[0].id}`}
                  >
                    {'another task'}
                  </Link>
                ) : (
                  <em
                    className="blue-text"
                    onClick={() => onShowDependentList(dependentTasks)}
                  >
                    {`${dependentTasks.length} other tasks `}
                  </em>
                )}
              </span>
              {dependentTasks.length === 1 && (
                <>
                  {` that is currently in `}
                  <b>{ReadableTaskStatus[dependentTasks[0].status]}</b>
                  {' status.'}
                </>
              )}
              {dependentTasks.length > 1 && (
                <>that are in various other statuses.</>
              )}
            </em>
          </>
        )}
        {pending && dependentTasks.length === 0 && <></>}
        {!pending && taskComments.length > 0 && (
          <>
            <Typography
              sx={{
                marginTop: '3px',
                marginRight: '16px',
                height: '8px',
                width: '8px',
                border: '1px solid gray',
                borderTop: '0px',
                borderRight: '0px',
              }}
            ></Typography>
            <em className="normal-text">Latest Comment:</em>
            <span
              className="blue-text"
              style={{ width: '250px', marginLeft: '8px' }}
            >
              <StyledLink
                to={{
                  pathname: `/sep/${sepID}/task/${taskID}`,
                }}
                className="blue-text"
                dangerouslySetInnerHTML={{
                  __html: taskComments[taskComments.length - 1].comment,
                }}
              ></StyledLink>
            </span>
          </>
        )}
        {!pending && taskComments.length === 0 && <></>}
      </Box>
    </Box>
  );
};

interface IconCellProps {
  icon: string;
  iconHover: string;
  value: string;
}

export const IconCell = ({ icon, iconHover, value }: IconCellProps) => {
  const [className, setClassName] = useState(icon);
  return (
    <div
      onMouseOver={() => setClassName(iconHover)}
      onMouseLeave={() => setClassName(icon)}
    >
      <i className={className}></i>
      &nbsp;&nbsp;
      {value}
    </div>
  );
};

interface TasksTableProps {
  tasks: TaskExtended[];
  sepID: number;
  noBorder?: boolean;
  isMyTask?: boolean;
}

export default function TasksTable({
  tasks,
  sepID,
  noBorder,
  isMyTask= false,
}: TasksTableProps) {
  const { data: attachments } = useGetAttachmentByIDQuery(sepID);
  const { data: comments } = useGetCommentsQuery(sepID);
  const { data: departments } = useGetDepartmentsQuery();
  const [showModal, setShowModal] = useState<Task[]>([]);
  const phases = ['initiate', 'design', 'implement'];
  const groupedTasks: { [key: string]: TaskExtended[] } = {};
    phases.forEach((phase) => {
    groupedTasks[phase] = tasks.filter((task) => task.phase === phase);
  });
  const getBackgroundColor = (phase: string)=> {
    switch (phase) {
      case 'initiate':
        return 'rgba(65, 105, 225, 1)'; // Transparent blue
      case 'design':
        return 'rgba(255, 165, 0, 0.6)';
      case 'implement':
        return 'rgba(0, 128, 0, 0.6)'; 
      default:
        return 'transparent'; 
    }
  };
  const getTextColor = (phase: string) => {
    switch (phase) {
      case 'initiate':
        return 'blue';
      case 'design':
        return 'orange';
      case 'implement':
        return 'green';
      default:
        return '0px'; // Default text color
    }
  };

  const getBrRadius = (phase: string) => {
    switch (phase) {
      case 'initiate':
        return 'lightgrey';
      case 'design':
        return 'lightgrey';
      case 'implement':
        return 'lightgrey';
      default:
        return 'transparent';
    }
  };
  const account = useAccount();
  const roleList: any = useMemo(() => {
    return account?.idTokenClaims?.roles;
  }, [account]);
  const superUser =
    roleList?.includes('AuthSuperUser') || roleList?.includes('AuthLegal');

    const renderSeparator = (isMyTask: boolean) => {
      if (isMyTask) {
        return (
          <div style={{ borderBottom: '3px solid #999', margin: '10px 0' }} />
        );
      }
      return null;
    };
    if (tasks.length === 0) {
      return (
        <Typography pl={4}>
          No current tasks at this time.
        </Typography>
      );
    }
    return (
      <>
        <ModalWidget
          isOpen={!!showModal.length}
          title="All Dependent Tasks"
          children={
            <DependentTasksTable
              task={showModal}
              departments={departments || []}
              attachments={attachments || []}
              comments={comments || []}
            />
          }
          onClose={() => setShowModal([])}
        /> 
         <TableContainer>
        {phases.map((phase) => {
          const tasksInPhase = groupedTasks[phase];

          if (tasksInPhase.length === 0) {
            return null;
          }

          return (
            <div key={phase}>
              <Typography variant="h6" sx={{ marginTop: '16px', backgroundColor: getBackgroundColor(phase), paddingLeft: '16px' }}>
                {phase.charAt(0).toUpperCase() + phase.slice(1)} Phase
              </Typography>
              <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                  <StyledTableCell sx={{ width: '6%', textAlign: 'center' }} align="center"></StyledTableCell>
<StyledTableCell sx={{ width: '40%', textAlign: 'left' }} align="left">Task</StyledTableCell>
<StyledTableCell sx={{ width: '10%', textAlign: 'center' }} align="center">Phase</StyledTableCell>
<StyledTableCell sx={{ width: '20%', textAlign: 'center' }} align="center">Assigned to <div>(who has next action)</div></StyledTableCell>
<StyledTableCell sx={{ width: '20%', textAlign: 'center' }} align="center">Department<div>(Dept Contact)</div></StyledTableCell>
<StyledTableCell sx={{ width: '0%', textAlign: 'center' }} align="center">Status</StyledTableCell>
<StyledTableCell align="center" colSpan={2}>Activity</StyledTableCell>
<StyledTableCell sx={{ width: '6%', textAlign: 'center' }} align="center"></StyledTableCell>


                  </TableRow>
                </TableHead>
                <TableBody>
                  {groupedTasks[phase].map((row, i) => (
                    <StyledTableRow
                      key={row.name + i}
                      sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                    >
                      <StyledTableCell sx={{ width: '6px' }}></StyledTableCell>
                      <StyledTableCell
                        sx={{ minWidth: '400px', paddingRight: '40px' }}
                        component="th"
                        scope="row"
                      >
                        <TaskInfoCell
                          task={row}
                          comments={comments || []}
                          sepID={sepID}
                          taskID={row.id}
                          onShowDependentList={(tasks) => {
                            setShowModal(tasks);
                          }}
                          isMyTask={!!isMyTask}
                          superUser={superUser}
                        />
                      </StyledTableCell>
                      <StyledTableCell sx={{ paddingLeft: '100px' }} align="right">
                        <div style={{ display: 'flex', alignItems: 'right' }}>
                          <div
                            style={{
                              width: '10px',
                              height: '10px',
                              backgroundColor: getBackgroundColor(row.phase),
                              marginRight: '8px',
                              borderRadius: '50%',
                            }}
                          />
                          <div style={{ display: 'flex',alignItems: 'left', background: getBrRadius(row.phase), borderRadius: '15px', padding: '2px 20px' }}>
                            <span style={{ color: getTextColor(row.phase) , textTransform: 'capitalize' }}>
                              {row.phase}
                            </span>
                          </div>
                        </div>
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.assignee?.givenName} {row?.assignee?.surname}
                      </StyledTableCell>
                      <StyledTableCell align="center">
                        {row?.department?.name}
                        {row.defaultReviewer?.displayName && (
    <Typography variant="body2" color="textSecondary">
     { row.defaultReviewer?.displayName}
    </Typography>
  )}
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <StatusCell status={row.status} />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <IconCell
                          icon="fa-regular fa-comment"
                          iconHover="fa-solid fa-comment task-icon"
                          value={
                            comments
                              ?.filter((e) => e.taskID === row.id)
                              ?.length.toString() || '0'
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell align="right">
                        <IconCell
                          icon="fa-regular fa-paperclip-vertical"
                          iconHover="fa-solid fa-paperclip task-icon"
                          value={
                            attachments
                              ?.filter((e) => e.taskID === row.id)
                              ?.length.toString() || '0'
                          }
                        />
                      </StyledTableCell>
                      <StyledTableCell
                        sx={{ width: '6px' }}
                        align="right"
                      ></StyledTableCell>

                    </StyledTableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
          );
        })}
        {renderSeparator(isMyTask)}
        </TableContainer>
      </>
    );
  }