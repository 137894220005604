import React, { useEffect } from 'react';
import {
  Paper,
  Box,
  Typography,
  Stack,
  Button,
  IconButton,
} from '@mui/material';
import UserDisplay from '../UserDisplay/UserDisplay';
import { CommentExtended } from '../../../shared/types/Comment';
import { TaskStatus } from '../../../shared/types/Task';
import StatusCell from '../SepTable/StatusCell';
import {
  useGetCommentsQuery,
  useUpdateUserReadCommentMutation,
} from '../../services/commentSlice/commentSlice';
import { useGetDepartmentsQuery } from '../../services/departmentSlice/departmentSlice';
import { Link } from 'react-router-dom';
import { useVisibility } from '../../hooks/useVisibility';
import { useTheme } from '@mui/material/styles';

const formatAMPM = (date: Date) => {
  let hours = date.getHours();
  const minutes = date.getMinutes().toString();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  const temp = Number.parseInt(minutes) < 10 ? '0' + minutes : minutes;
  const strTime = hours + ':' + temp + ' ' + ampm;
  return strTime;
};

const formatDate = (date: Date) => {
  const curDate = new Date();
  const difference = Math.abs(curDate.getTime() - date.getTime());
  const TotalDays = Math.floor(difference / (1000 * 3600 * 24));
  if (TotalDays === 0) return 'Today';
  if (TotalDays === 1) return 'Yesterday';
  return `${TotalDays} days ago`;
};

const getcommentDateInfo = (dateStr: string) => {
  const temp = new Date(dateStr);
  return formatAMPM(temp) + ' | ' + formatDate(temp);
};

const CardDescription = ({ children }: any) => (
  <Box
    sx={{ display: 'flex', alignItems: 'center', pl: 3, pb: 1, color: 'grey' }}
  >
    {children}
  </Box>
);

interface CommentCardProps {
  comment: CommentExtended;
  loggedInUser: any;
  handleCommentModal: (type: string, comment: CommentExtended) => void;
  handleDeleteComment?: (comment: CommentExtended) => void;
  handleEditComment?: (comment: CommentExtended) => void;
  detailView?: boolean;
}

const CommentCard = ({
  comment,
  loggedInUser,
  handleCommentModal,
  handleDeleteComment,
  detailView,
}: CommentCardProps) => {
  const { data: comments } = useGetCommentsQuery(comment.sepID);
  const { data: departments } = useGetDepartmentsQuery();
  const [ref, isVisible] = useVisibility();
  const [isCommentNew] = React.useState(() => comment.readAt === null);
  const [initialRender, setInitialRender] = React.useState(false);
  const [markCommentAsRead] = useUpdateUserReadCommentMutation();

  const theme = useTheme();

  const findDepartmentLabel = () => {
    const department = departments?.find(
      (department) => comment.departmentID === department.id
    );
    return department?.name;
  };

  const replyComment = React.useMemo(() => {
    return comments?.find(
      (c: CommentExtended) => c?.id === comment.replyCommentID
    );
  }, [comments, comment.replyCommentID]);

  useEffect(() => {
    // If the comment is new and visible, update its status
    if (!initialRender) {
      if (isCommentNew && isVisible) {
        markCommentAsRead(comment.id);
        setInitialRender(true);
      }
    }
  }, [comment, markCommentAsRead, isCommentNew, isVisible, initialRender]);

  return (
    <div ref={ref}>
      <Paper
        elevation={0}
        sx={{
          my: 2,
          p: 2,
          mx: 4,
          backgroundColor: '#F8F9FA',
          fontFamily: 'Open Sans, Regular',
          border: comment.changeRequest ? '2px solid #F47B27' : '0px',
        }}
      >
        {comment.departmentID && (
          <CardDescription
            key={comment?.id + '-' + comment.departmentID}
            children={
              <>
                <i className="fa-solid fa-lock"></i>
                &nbsp;
                <Typography>Private Comment </Typography>
                <Typography variant="caption">
                  |{findDepartmentLabel()}
                </Typography>
              </>
            }
          />
        )}
        {comment.taskID && comment.task && (
          <CardDescription
            key={comment?.id + '-' + comment.taskID}
            children={
              <>
                <i className="fa-solid fa-list-check"></i>
                &nbsp;
                <Typography>
                  Message from Task:
                  <Link to={`/sep/${comment.sepID}/task/${comment.taskID}`}>
                    {comment.task.name}
                  </Link>
                </Typography>
              </>
            }
          />
        )}
        {comment.changeRequest && (
          <StatusCell status={TaskStatus.changesRequested} />
        )}
        {replyComment && <TargetCommentBar comment={replyComment} />}
        <Stack
          direction="row"
          spacing={2}
          display="flex"
          justifyContent="space-between"
          sx={{ pl: 2 }}
        >
          <Box>
            {isCommentNew && (
              <div
                style={{
                  height: '10px',
                  width: '10px',
                  backgroundColor: theme.palette.blue.blue1,
                  borderRadius: '50%',
                }}
              ></div>
            )}
            <UserDisplay
              user={comment.creator}
              userID={comment.createdBy}
              showName={true}
              showPicture={true}
              title="Requestor"
            />
          </Box>

          <Typography
            component="div"
            sx={{
              p: 2,
              color: '#888',
              fontSize: '12px',
              textAlign: 'right',
              minWidth: '300px',
            }}
          >
            {getcommentDateInfo(
              comment.updatedAt === '' ? comment.createdAt : comment.updatedAt
            )}
          </Typography>
        </Stack>
        <Box
          sx={{
            ml: 6,
            mr: 2,
            mt: 1,
          }}
        >
          <div
            dangerouslySetInnerHTML={{
              __html:
                comment.comment
                  ?.replaceAll('<p>', '<div style="word-wrap: break-word;">')
                  .replaceAll('</p>', '</div>') ?? '',
            }}
          />
        </Box>
        {detailView ? (
          <Box sx={{ height: '16px', width: '100%' }}></Box>
        ) : (
          <Stack
            direction="row"
            spacing={2}
            display="flex"
            justifyContent="space-between"
            sx={{ pl: 3, pr: 2, pt: 2 }}
          >
            <Button
              variant="text"
              size="small"
              onClick={() => {
                handleCommentModal('Reply', comment);
              }}
            >
              Reply
            </Button>
            {loggedInUser && comment?.creator?.id === loggedInUser?.id && (
              <Stack
                direction="row"
                spacing={2}
                display="flex"
                justifyContent="right"
              >
                <IconButton
                  component="label"
                  size="small"
                  onClick={() =>
                    handleDeleteComment && handleDeleteComment(comment)
                  }
                >
                  <i
                    className="fa-regular fa-trash"
                    style={{ fontSize: '0.8125rem' }}
                  ></i>
                </IconButton>
                <IconButton
                  component="label"
                  size="small"
                  onClick={() => handleCommentModal('Edit', comment)}
                >
                  <i
                    className="fa-regular fa-pen"
                    style={{ fontSize: '0.8125rem' }}
                  ></i>
                </IconButton>
              </Stack>
            )}
          </Stack>
        )}
      </Paper>
    </div>
  );
};

export const TargetCommentBar = ({ comment }: { comment: CommentExtended }) => {
  return (
    <Box
      sx={{
        p: 0,
        pl: 2,
        ml: 6,
        mb: 1,
        mr: 2,
        borderLeft: '5px solid #2372B9',
        color: '#2372B9 !important',
      }}
    >
      <Typography sx={{ fontSize: '14px', fontWeight: 600 }}>
        Reply to{' '}
        {comment.creator.givenName && comment.creator.surname
          ? `${comment.creator.givenName} ${comment.creator.surname}`
          : comment.creator.displayName}
      </Typography>
      <Box
        className="force-reply"
        sx={{
          fontSize: '14px',
          maxHeight: 60,
          overflow: 'hidden',
          color: '#2372B9 !important',
          WebkitMaskImage: 'linear-gradient(180deg, #000 50%, transparent)',
        }}
      >
        <div
          dangerouslySetInnerHTML={{
            __html: comment.comment.replaceAll(
              /style="[a-zA-Z0-9:;.\s()\-,]*"/gi,
              ''
            ),
          }}
        />
      </Box>
    </Box>
  );
};

export default CommentCard;
