import React, { useState, useRef } from "react";
import {
  Box,
  Divider,
  Typography,
  Button,
  createTheme,
  Paper,
  Stack,
} from "@mui/material";
import { ThemeProvider } from "@emotion/react";
import { useReOrderDepartmentMutation } from "../../../../../frontend/services/departmentSlice/departmentSlice";


const DepartmentOrder = ({
  departmentData,
  onClose,
}: {
  departmentData: any;
  onClose: () => void;
}) => {
  const style = createTheme({
    components: {
      MuiPaper: {
        styleOverrides: {
          root: {
            "&:hover": {
              backgroundColor: "#F3F8FC",
            },
          },
        },
      },
    },
  });

  const dragItem = useRef<number | null>(null);
  const dragOverItem = useRef<number | null>(null);
  // const [list, setList] = useState<string[]>(
  //   [...departmentData].sort((a: any, b: any) => a.orderId - b.orderId)
  // );
  const [list, setList] = useState<string[]>(
    [...departmentData].sort((a: any, b: any) => {
      if(a.orderId === null && b.orderId !== null){
          return 1;
      } else if(a.orderId !== null && b.orderId === null) {
        return -1;
      } else {
        return (a.orderId || 0 - b.orderId || 0)
      }
    })
  );
  
  const [saveNewOrder] = useReOrderDepartmentMutation();

  const dragStart = (e: any, position: number) => {
    dragItem.current = position;
    e.currentTarget.style.cursor = "grabbing";
  };

  const dragEnter = (e: any, position: number) => {
    dragOverItem.current = position;
    e.currentTarget.style.cursor = "grabbing";
  };
  const dragLeave = (e: any) => {
    e.currentTarget.style.cursor = "grab";
  };

  const drop = () => {
    if (dragItem.current !== null && dragOverItem.current !== null) {
      const copyListItems = [...list];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1); 
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setList(copyListItems);
    }
  };

  const handleChangeDepartmentOrder = () => {
    const reOrderList = list?.map((dep: any, i) => ({
      orderId: i,
      id: dep?.id,
    }));
    saveNewOrder({ payload: reOrderList });
    onClose();
  };
  return (
    <>
      <div style={{ height: "400px", overflow: "auto" }}>
        {list?.map((dep: any, index: any) => (
          <div
            onDragStart={(e) => dragStart(e, index)}
            onDragEnter={(e) => dragEnter(e, index)}
            onDragEnd={drop}
            onDragLeave={dragLeave}
            key={dep?.id}
            draggable
          >
            <ThemeProvider theme={style}>
              <Paper square={true}>
                <Stack
                  direction="row"
                  spacing={2}
                  p={2}
                  sx={{
                    alignItems: "center",
                  }}
                >
                  <Box>
                    <i className="fa-solid fa-grip-dots-vertical"></i>
                  </Box>
                  <Stack direction="column">
                    <Typography
                      sx={{
                        textAlign: "left",
                        font: "normal normal normal 16px/22px Open Sans",
                        letterSpacing: "0px",
                        color: "#222222",
                        opacity: 1,
                      }}
                    >
                      {dep?.name}
                    </Typography>
                  </Stack>
                </Stack>
              </Paper>
            </ThemeProvider>
          </div>
        ))}
      </div>
      <Divider sx={{ backgroundColor: "#7E8083" }} />
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
          p: 2,
          "& > button": {
            m: 1,
          },
        }}
      >
        <Button
          variant="contained"
          onClick={handleChangeDepartmentOrder}
          sx={{
            textTransform: "none",
            backgroundColor: "#2372B9",
            font: "normal normal 600 16px/22px Open Sans",
          }}
        >
          Save Arrangements
        </Button>
      </Box>
    </>
  );
};

export default DepartmentOrder;
