import React from 'react';
import ModalWidget from '../ModalWidget/ModalWidget';
import { ActionButtons } from '../CancelSave';
import { Divider, TextField, Typography, Box } from '@mui/material';

const StatusModal = ({
  status,
  open,
  name,
  handleChange,
  handleStatusModalClose,
  reason,
  handleStatusSave,
}: {
  status: string;
  open: boolean;
  name: string;
  handleStatusModalClose: () => void;
  handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  reason: string;
  handleStatusSave: () => void;
}) => {
  return (
    <>
      <ModalWidget
        isOpen={open}
        onClose={handleStatusModalClose}
        title={`${status === 'rejected' ? 'Reject' : 'Cancel'} SEP`}
        children={
          <>
            <Box
              px={6}
              py={4}
              sx={{
                display: 'flex',
                justifyContent: 'center',
                flexDirection: 'column',
                gap: '20px',
              }}
            >
              <Typography>
                {`Are you sure you want to ${status} ${name}?`}
              </Typography>
              <TextField
                multiline
                label={`Describe the reason for ${
                  status === 'rejected' ? 'rejecting' : 'canceling'
                } the selected SEP`}
                minRows={3}
                onChange={handleChange}
                value={reason}
              />
            </Box>
            <Divider />
            <ActionButtons
              handleCancel={handleStatusModalClose}
              handleSave={handleStatusSave}
              saveLabel="Continue"
            />
          </>
        }
      />
    </>
  );
};

export default StatusModal;
