import React, { useState } from 'react';
import {
  Box,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import { SEPSearchRow } from '../../../shared/types/SEP';
import SepTableRow from './SepTableRow';
import EnhancedTableHead from './EnhancedTableHead';

export interface HeadCell {
  id: string;
  key: string;
  label: string;
  visible: boolean;
}

export const headCells: readonly HeadCell[] = [
  {
    id: 'id',
    key: 'id',
    label: 'SEP#',
    visible: true,
  },
  {
    id: 'name',
    key: 'name',
    label: 'SEP Name',
    visible: true,
  },
  {
    id: 'taskProgress',
    key: '',
    label: 'Task Progress',
    visible: true,
  },
  {
    id: 'requestor',
    key: 'creator.displayName',
    label: 'Requestor',
    visible: true,
  },
  {
    id: 'created_at',
    key: 'createdAt',
    label: 'Request Date',
    visible: true,
  },
  {
    id: 'status',
    key: '',
    label: 'Status',
    visible: true,
  },
  {
    id: 'phase',
    key: 'phase',
    label: 'Phase',
    visible: true,
  },
  {
    id: 'workFlowType',
    key: 'workFlowType',
    label: 'workFlowType',
    visible: true,
  },
  {
    id: 'requestProgress',
    key: '',
    label: 'Request Progress',
    visible: true,
  },
];

export const getStatus = (data: SEPSearchRow) => {
  const { otherTaskCount, userActiveTaskCount, userPendingTaskCount } = data;
  if (userActiveTaskCount > 0) return 'todo';
  if (userPendingTaskCount > 0) return 'pending';
  if (otherTaskCount > 0) return 'waiting';
  return 'complete';
};

export const getTaskProgress = (data: SEPSearchRow) => {
  const { otherTaskCount, userActiveTaskCount, userPendingTaskCount } = data;

  if (window.location.href.replaceAll('/', '').slice(-8) === 'all-seps') {
    return `${
      userActiveTaskCount + userPendingTaskCount + otherTaskCount
    } tasks are available`;
  }
  if (userActiveTaskCount > 0) {
    return `You have ${userActiveTaskCount} tasks to complete`;
  }
  if (userPendingTaskCount > 0) {
    return `You have ${userPendingTaskCount} tasks pending`;
  }
  if (otherTaskCount > 0) {
    return `${otherTaskCount} tasks for others`;
  }
  return `All tasks complete`;
};

function NoSEP() {
  const [isHover, setIsHover] = useState<boolean>(false);
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      py="36px"
    >
      <Box
        display="flex"
        alignItems="center"
        mb="20px"
        sx={{ cursor: 'pointer' }}
        onMouseOver={() => setIsHover(true)}
        onMouseLeave={() => setIsHover(false)}
      >
        {isHover ? (
          <i
            className="fa-solid fa-plus"
            style={{ color: '#2372B9', width: '16px', fontSize: '18px' }}
          ></i>
        ) : (
          <i
            className="fa-solid fa-file-magnifying-glass"
            style={{ color: '#7e8083', width: '16px', fontSize: '18px' }}
          ></i>
        )}
        <Typography color="gray.solidGray" fontSize="18px" ml="8px">
          You don’t have any SEPs
        </Typography>
      </Box>
      <Typography
        color="gray.solidGray"
        fontSize="12px"
        maxWidth="240px"
        textAlign="center"
      >
        Choose{' '}
        <Typography
          component="span"
          color="gray.solidGray"
          fontSize="12px"
          fontWeight="600"
        >
          Create an SEP
        </Typography>{' '}
        from the navigation options to begin creating an SEP
      </Typography>
    </Box>
  );
}

const SepTableBody = ({
  rows,
  count,
  sortBy,
  setSortBy,
  sortAsc,
  setSortAsc,
  headers,
  selected,
  setSelected,
  loggedInUser
}: {
  rows: SEPSearchRow[];
  count: number;
  sortBy: string;
  setSortBy: (sortBy: string) => void;
  sortAsc: boolean;
  setSortAsc: (sortAsc: boolean) => void;
  headers: HeadCell[];
  selected: SEPSearchRow[];
  setSelected: (selected: SEPSearchRow[]) => void;
  loggedInUser : any;
}) => {
  
  const filteredRows = loggedInUser.length >0? rows.filter((row)=>(row?.creator.id === loggedInUser) || row?.phase !== 'knockout') : rows
  const [detailShowed, setDetailShowed] = useState<number>(-1);
  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = sortBy === property && sortAsc;
    setSortAsc(!isAsc);
    setSortBy(property);
  };

  // Avoid a layout jump when reaching the last page with empty rows.
  return (
    <Box sx={{ width: '100%' }}>
      {count ? (
        <TableContainer
          sx={{
            maxWidth: '100%',
            maxHeight: 'calc(100vh - 400px)',
            /* width */
            '&::-webkit-scrollbar': {
              width: '10px',
              height: '10px',
            },
            /* Track */
            '&::-webkit-scrollbar-track': {
              background: '#f1f1f1',
            },
            /* Handle */
            '&::-webkit-scrollbar-thumb': {
              background: '#888',
              borderRadius: '10px',
              /* Handle on hover */
              '&:hover': {
                background: '#555',
              },
            },
          }}
        >
          <Table stickyHeader aria-labelledby="tableTitle" size="medium">
            <EnhancedTableHead
              sortAsc={sortAsc}
              sortBy={sortBy}
              selected={selected.length}
              all={filteredRows.length}
              onRequestSort={handleRequestSort}
              onSelect={(val) => {
                setSelected(val ? filteredRows : []);
              }}
              headers={headers}
            />
            <TableBody>
              {filteredRows.map((row: SEPSearchRow, key: number) => {
                return (
                  <SepTableRow
                    row={row}
                    key={key}
                    checked={selected.some((e) => e.id === filteredRows[key].id)}
                    onSelect={(e) =>
                      e
                        ? setSelected([...selected, row])
                        : setSelected(selected.filter((e) => e.id !== row.id))
                    }
                    onDetailShow={(e) => {
                      if (e) setDetailShowed(row.id);
                      else setDetailShowed(-1);
                    }}
                    detailShow={detailShowed === row.id}
                    headers={headers}
                  />
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <NoSEP />
      )}
    </Box>
  );
};

export default SepTableBody;
