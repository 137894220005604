import React from 'react';
import { Box, Divider, Stack, Typography } from '@mui/material';
import HoveredIconConfirm from '../HoveredIconConfirmButton';

import { useDeleteDataFieldOptionTemplateMutation } from '../../../services/dataFieldsSlice';

const DataFieldOptionCard = ({ datafieldOption, index }: any) => {
  const [deleteDataFieldOption] = useDeleteDataFieldOptionTemplateMutation();

  const handleDeleteOption = () => {
    deleteDataFieldOption(datafieldOption.id);
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F8F9FA',
          padding: '16px 40px 16px 45px',
        }}
      >
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
        >
          <Box>
            <Stack display="flex" flexDirection="row" alignItems="center">
              <Typography
                component="span"
                sx={{
                  color: '#646669',
                  fontSize: '12px',
                  fontWeight: '700',
                }}
              >
                {`Option ${index + 1}`}
              </Typography>
              &nbsp;&nbsp;
            </Stack>
            <Typography
              component="span"
              sx={{
                fontSize: '14px',
                fontWeight: '700',
              }}
            >
              {datafieldOption.value}
            </Typography>
          </Box>
          <Stack
            display="flex"
            flexDirection="row"
            justifyContent="space-between"
            sx={{
              width: '40px',
            }}
          >
            <HoveredIconConfirm
              icon="fa-regular fa-trash"
              hoverIcon="fa-solid fa-trash"
              onClick={() => handleDeleteOption()}
            />
          </Stack>
        </Stack>
      </Box>
      <Divider />
    </>
  );
};

export default DataFieldOptionCard;
