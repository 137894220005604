import React, { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';

export default function DropDown({
  icon,
  hoverIcon,
  selected,
  items,
  onSelect,
}: {
  icon: string;
  hoverIcon: string;
  selected?: string;
  items: string[];
  onSelect: (str: string) => void;
}) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [hover, setHover] = useState<boolean>(false);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <IconButton
        id="basic-button"
        aria-label="menuBtn"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        sx={{
          width: '25px',
          height: '25px',
          borderRadius: '3px',
          fontSize: '15px',
          color: hover ? '#2372B9' : '#646669',
        }}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleClick}
      >
        {selected || ''}
        <i className={hover ? hoverIcon : icon}></i>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {items.map((e, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              onSelect(e);
              handleClose();
            }}
            sx={{
              fontSize: '14px',
              color: '#222222',
              '&:hover': {
                backgroundColor: '#F3F8FC',
              },
            }}
          >
            {e}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
}
