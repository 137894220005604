import * as React from 'react';

//Button Grid imports
import { Grid, Box, useTheme, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import Paper, { PaperProps } from '@mui/material/Paper';
import { DataFieldOption } from '../../../shared/types/DataField';

interface StyledPaperProps extends PaperProps {
  selected?: boolean;
}

const Item = styled(Paper, {
  shouldForwardProp: (prop) => prop !== 'selected',
})<StyledPaperProps>(({ selected, theme }) => ({
  cursor: 'pointer',
  backgroundColor: '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  height: 168,
  width: 168,
  borderRadius: '10px',
  boxShadow: '0px 1px 5px #0000001A',
  background: '#FFFFFF 0% 0% no-repeat padding-box',
  opacity: 1,
  '&:hover': {
    border: '1.3px solid #2372B9',
  },
  ...(selected && {
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    border: '1.3px solid #6BA543',
    background: '#F7FBF5 0% 0% no-repeat padding-box',
    boxShadow: '10px 20px 30px #0000001A',
  }),
}));

interface TileProps {
  field: DataFieldOption;
  length: number;
  onClick: (e: any) => void;
  value: number | number[] | string | undefined;
  selected: boolean;
}

const CustomButton = ({
  length,
  field,
  onClick,
  value,
  selected,
}: TileProps) => {
  const { icon, value: title, description, multiselectTooltipValue } = field;
  const theme = useTheme();
  const saveOptions = () => {
    switch (length) {
      case 1:
        return 12;
      case 2:
      case 3:
      case 4:
        return 3;
      default:
        return 2;
    }
  };

  return (
    <>
    <Tooltip placement="bottom" title={multiselectTooltipValue?.trim()}>
      <Grid item xs={6} sm={4} md={3} lg={saveOptions()} xl={saveOptions()}>
        <Item val={value} onClick={onClick} selected={selected}>
          {/* Icon Box */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              fontSize: 18,
              color: '#7E8083',
            }}
          >
            {description && (
              <Tooltip placement="top" title={description}>
                {<i className={`fa-solid fa-circle-info`}></i>}
              </Tooltip>
            )}
          </Box>
          <Box
            pb={3}
            pt={description === null ? 2 : 0}
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-evenly',
              alignItems: 'center',
              height: '100%',
            }}
          >
            {/* Icon Box */}
            <Box
              sx={{
                width: 64,
                height: 64,
                display: 'flex',
                justifyContent: 'center',
                fontSize: 40,
                color: !selected ? '#2372B9' : '#6BA543',
              }}
            >
              {!selected ? (
                <i className={`fa-solid fa-${icon || 'atom'}`}></i>
              ) : (
                <i className="fa-solid fa-circle-check"></i>
              )}
            </Box>
            {/* Caption Box */}
            <Box>
              <p
                style={{
                  font: ' 14px/22px Open Sans',
                  fontWeight: 600,
                  color: theme.palette.gray.solidGray,
                }}
              >
                {title}
              </p>
            </Box>
          </Box>
        </Item>
      </Grid>
      </Tooltip>
    </>
  );
};

export default CustomButton;
