import React, { useEffect, useState } from 'react';
import {
  Fade,
  Paper,
  ClickAwayListener,
  Popper,
  Box,
  Button,
  TextField,
  MenuList,
  MenuItem,
  InputAdornment,
  Divider,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CheckIcon from '@mui/icons-material/Check';
import UserSearchBar from '../../../TaskOverview/AutoComplete';
import { useGetDepartmentsQuery } from '../../../../../frontend/services/departmentSlice/departmentSlice';
import { useGetTaskTemplatesQuery } from '../../../../../frontend/services/tasksSlice/tasksSlice';
import { useGetKnockoutScreenTemplateQuery } from '../../../../../frontend/services/knockoutSlice';
import { KnockoutFollowupExtended } from '../../../../../shared/types/Knockout';

const FollowupTypeDropDown = ({
  type,
  followup,
  onChange,
  onUserChange,
}: {
  type: string;
  followup: KnockoutFollowupExtended | null;
  onChange: (val: number) => void;
  onUserChange: (userID: string) => void;
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [selected, setSelected] = useState<string | null>(null);
  const [search, setSearch] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [adAppRole, setAdAppRole] = useState<string>('');

  const departments = useGetDepartmentsQuery();
  const tasks = useGetTaskTemplatesQuery();
  const knockoutScreens = useGetKnockoutScreenTemplateQuery();

  useEffect(() => {
    if (type === 'Task') setSelected(followup?.followupTask?.name || null);
    if (type === 'KnockoutScreen')
      setSelected(followup?.followupScreen?.name || null);
  }, [followup, type]);

  const data =
    type === 'DefaultStakeholder'
      ? departments?.data
        ? departments.data
        : []
      : type === 'Task'
      ? tasks?.data
        ? tasks.data
        : []
      : knockoutScreens?.data
      ? knockoutScreens.data
      : [];
  const handleMenuOption = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const renderDropDownMenu = () => {
    return data
      .map((e) => ({
        name: e.name,
        id: e.id,
        role: 'adAppRole' in e ? e.adAppRole : '',
      }))
      .filter((f: any) => f.name.toLowerCase().includes(search.toLowerCase()));
  };

  const renderOption = (value: any) => {
    if (selected === value.name) {
      return (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          {value.name}
          <CheckIcon />
        </Box>
      );
    }
    return value.name;
  };

  return (
    <Box>
      <Box sx={{ mb: 3 }}>
        <Button
          variant="outlined"
          id="demo-followup-type-menu-button"
          aria-describedby="follow-up"
          aria-controls={open ? 'followup-type-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleMenuOption}
          sx={{
            color: selected === null ? '#7E8083' : '#222222',
            textTransform: 'none',
            borderColor: '#7E8083',
          }}
        >
          {selected === null ? 'Choose an option from the dropdown' : selected}
          &nbsp;&nbsp;
          <i className="fa-sharp fa-solid fa-chevron-down" />
        </Button>
        <Popper
          id="follow-up"
          open={open}
          anchorEl={anchorEl}
          transition
          disablePortal
          sx={{ zIndex: 1 }}
        >
          {({ TransitionProps, placement }) => (
            <Fade
              {...TransitionProps}
              style={{
                transformOrigin:
                  placement === 'bottom' ? 'center top' : 'center bottom',
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={() => setOpen(false)}>
                  <MenuList autoFocusItem={open}>
                    <MenuItem disableTouchRipple={true}>
                      <TextField
                        placeholder={`Search ${
                          type === 'DefaultStakeholder'
                            ? 'Departments'
                            : type === 'Task'
                            ? 'Tasks'
                            : 'Knockout Screens'
                        }…`}
                        size="small"
                        sx={{ width: '100%' }}
                        onChange={(e) => setSearch(e.target.value)}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment position="start">
                              <SearchIcon />
                            </InputAdornment>
                          ),
                        }}
                      />
                    </MenuItem>
                    <Divider />
                    <Box sx={{ maxHeight: '300px', overflow: 'auto' }}>
                      {renderDropDownMenu().map((item: any, index: number) => {
                        return (
                          <Box key={index}>
                            <MenuItem
                              onClick={() => {
                                setSelected(item.name);
                                onChange(item.id);
                                setAnchorEl(null);
                                if (type === 'DefaultStakeholder')
                                  setAdAppRole(item.adAppRole);
                                setOpen(false);
                              }}
                            >
                              {renderOption(item)}
                            </MenuItem>
                            <Divider />
                          </Box>
                        );
                      })}
                    </Box>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Fade>
          )}
        </Popper>
      </Box>
      {type === 'DefaultStakeholder' && (
        <>
          <Box sx={{ color: '#222222', width: '216px' }}>
            Default Stakeholder
          </Box>
          <UserSearch
            adAppRole={adAppRole}
            disabled={adAppRole === ''}
            onChange={onUserChange}
          />
        </>
      )}
    </Box>
  );
};

export const UserSearch = ({
  adAppRole,
  disabled,
  onChange,
  existDefault,
  user,
}: {
  adAppRole: string;
  disabled: boolean;
  onChange: (e: string) => void;
  existDefault?: boolean;
  user?: string;
}) => {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState('');
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  useEffect(() => {
    setSelected(user || '');
  }, [user]);
  return (
    <Box>
      <Button
        variant="outlined"
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        disabled={disabled}
        onClick={(e) => {
          setOpen(!open);
          setAnchorEl(e.currentTarget);
        }}
        sx={{
          color: selected === '' ? '#7E8083' : '#222222',
          textTransform: 'none',
          borderColor: '#7E8083',
          zIndex: '0',
        }}
      >
        {selected === ''
          ? existDefault
            ? 'Default'
            : 'Choose a user from the dropdown'
          : selected}
        &nbsp;&nbsp;
        <i className="fa-sharp fa-solid fa-chevron-down" />
      </Button>
      <UserSearchBar
        show={open}
        type="knockoutScreen"
        adAppRoleName={adAppRole}
        anchor={anchorEl}
        showConfirmButton={false}
        onClose={() => setOpen(false)}
        user={selected}
        onChange={(user) => {
          onChange(user.id);
          setSelected(user.displayName || '');
          setOpen(false);
        }}
      />
    </Box>
  );
};

export default FollowupTypeDropDown;
