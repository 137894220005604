import { Box, Button, Divider, Typography } from '@mui/material';
import React, { useEffect, useMemo } from 'react';
import { useLazyExportTasksQuery } from '../../services/tasksSlice/tasksSlice';
import StatusModal from '../StatusModal/StatusModal';
import { useUpdateSepStatusMutation, useUpdateSepMutation,useUpdateSepSubscriptionsMutation } from '../../services/sepSlice';
import { useGetUserQuery } from '../../services/usersSlice';
import { useAccount } from '@azure/msal-react';
import { env } from '../../../shared/env';

const SepTableHeader = ({
  searchFilter,
  resultNumber,
  onModalOpen,
  hideExportButton,
  selected,
  refreshSubs
}: {
  count: number;
  sortBy?: string;
  sortAsc?: boolean;
  status?: string;
  searchFilter?: string;
  resultNumber: number;
  assigneeId?: string;
  onModalOpen: () => void;
  hideExportButton?: boolean;
  selected: any;
  refreshSubs: () => void;
}) => {
  const [updateSepStatus] = useUpdateSepStatusMutation();
  const [updateSep] = useUpdateSepMutation();
  const [updateSepSubs] = useUpdateSepSubscriptionsMutation();
  const [download, { data: fileUrl, isSuccess }] = useLazyExportTasksQuery();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [status, setStatus] = React.useState<string>('');
  const [reason, setReason] = React.useState<string>('');
  const { data: loggedInUser } = useGetUserQuery('me');

  useEffect(() => {
    if (isSuccess && fileUrl) {
      const link = document.createElement('a');
      link.href = fileUrl;
      link.setAttribute('download', 'tasks.csv');
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }, [isSuccess, fileUrl]);

  const account = useAccount();
  const roleList: any = useMemo(() => {
    if (env.REACT_APP_LOCAL_DEV_ADMIN === 'true') {
      return ['AuthSuperUser'];
    } else {
      return account?.idTokenClaims?.roles || [];
    }
  }, [account]);

  let isSuperUser;
  if (env.REACT_APP_LOCAL_DEV_ADMIN === 'true') {
    isSuperUser = true;
  } else {
    isSuperUser =
      roleList?.includes('AuthSuperUser') &&
      (process.env.NODE_ENV === 'test'
        ? process.env.REACT_APP_SHOW_ADMIN_FEATURES === 'true'
        : env.REACT_APP_SHOW_ADMIN_FEATURES === 'true');
  }

  const handleDownload = () => {
    download();
  };

  const handleStatusChange = (sepID: number, status: string) => {
    setStatus(status);
    setIsModalOpen(true);
  };

  const handleStatusModalClose = () => {
    setIsModalOpen(false);
    setReason('');
  };
  const handleSubscription = async(selected:any[]) => {
    
    await Promise.all(selected.map(async (sep)=>{
      if (!sep.subscribers || !sep.subscribers.includes(loggedInUser?.email)){
        const subs = sep.subscribers == null ? loggedInUser?.email : sep.subscribers + ','+ loggedInUser?.email
        await updateSepSubs({
          id: sep.id,
          body: { subscribers: subs },
        })
      }
     
    })
  )
    refreshSubs();
  };
  

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setReason(e.target.value);
  };

  const handleStatusSave = () => {
    updateSepStatus({
      sepID: selected[0].id,
      status: status,
      reason: reason,
    });
    setIsModalOpen(false);
    setReason('');
  };

  const enableStatus = selected.length === 1 && isSuperUser;
  const isSubscriptionEnabled = selected.length >=1;

  return (
    <>
      {enableStatus && (
        <StatusModal
          status={status}
          open={isModalOpen}
          name={selected[0]?.name}
          handleStatusModalClose={handleStatusModalClose}
          handleChange={handleChange}
          reason={reason}
          handleStatusSave={handleStatusSave}
        />
      )}
      <Box>
        <Divider />
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          gap="8px"
          py="10px"
          sx={{
            px: { xs: '24px', md: '40px' },
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Typography color="gray.main" fontSize="14px" fontWeight="600">
            {new Intl.NumberFormat('en-US').format(resultNumber)} Results
            {searchFilter && ` for '${searchFilter}'`}
          </Typography>

          <Box display="flex" alignItems="center" flexWrap="nowrap">
            {isSubscriptionEnabled && (
              <Button
              variant="text"
              sx={{ py: '4px', textTransform: 'inherit' }}
              onClick={() => handleSubscription(selected)}
            >
              <i className="fa-sharp fa-solid fa-bell"></i>
              <Typography
                component="span"
                fontSize="12px"
                fontWeight="600"
                whiteSpace="nowrap"
                ml="4px"
              >
                Subscribe
              </Typography>
            </Button>
            )}
            {enableStatus && (
              <>
                <Button
                  variant="text"
                  sx={{ py: '4px', textTransform: 'inherit' }}
                  onClick={() => handleStatusChange(selected[0].id, 'rejected')}
                >
                  <i className="fa-sharp fa-regular fa-circle-minus"></i>
                  <Typography
                    component="span"
                    fontSize="12px"
                    fontWeight="600"
                    whiteSpace="nowrap"
                    ml="4px"
                  >
                    Reject
                  </Typography>
                </Button>
                <Button
                  variant="text"
                  sx={{ py: '4px', textTransform: 'inherit' }}
                  onClick={() =>
                    handleStatusChange(selected[0].id, 'cancelled')
                  }
                >
                  <i className="fa-regular fa-ban"></i>
                  <Typography
                    component="span"
                    fontSize="12px"
                    fontWeight="600"
                    whiteSpace="nowrap"
                    ml="4px"
                  >
                    Cancelled
                  </Typography>
                </Button>
                |
              </>
            )}
            {!hideExportButton && (
              <Button
                variant="text"
                sx={{ py: '4px', textTransform: 'inherit' }}
                onClick={handleDownload}
              >
                <i
                  className="fa-solid fa-file"
                  style={{ color: '#2372B9' }}
                ></i>
                <Typography
                  component="span"
                  fontSize="12px"
                  fontWeight="600"
                  whiteSpace="nowrap"
                  ml="4px"
                >
                  Export to CSV
                </Typography>
              </Button>
            )}

            <Button
              variant="text"
              sx={{ py: '4px', textTransform: 'inherit' }}
              onClick={() => onModalOpen()}
            >
              <i className="fa-solid fa-table" style={{ color: '#2372B9' }}></i>
              <Typography
                component="span"
                fontSize="12px"
                fontWeight="600"
                whiteSpace="nowrap"
                ml="4px"
              >
                Edit Columns
              </Typography>
            </Button>
          </Box>
        </Box>
        <Divider />
      </Box>
    </>
  );
};

export default SepTableHeader;
