import React, { useMemo } from 'react';
import { useAccount } from '@azure/msal-react';
import { useGetDataFieldsBySEPIDQuery } from '../../services/dataFieldsSlice';
import SepInfo from './SepInfo';
import SepComments from './SepComments';
import { useParams } from 'react-router-dom';

const SepDetails = () => {
  const account = useAccount();
  const { sepId } = useParams();

  const roleList: any = useMemo(() => {
    return account?.idTokenClaims?.roles;
  }, [account]);

  const { data: dataFields } = useGetDataFieldsBySEPIDQuery(
    sepId ? Number.parseInt(sepId) : 0
  );

  const nameDf = dataFields?.filter((df) => df.name === 'Alternate Contact');
  const emailDf = dataFields?.filter(
    (df) => df.name === 'Alternate Contact Email'
  );

  return (
    <div className="section-to-print">
      <SepInfo
        sepID={sepId ? Number.parseInt(sepId) : 0}
        alternateName={
          nameDf
            ? nameDf.length > 0
              ? nameDf[0].value?.toString() || ''
              : ''
            : ''
        }
        alternateEmail={
          emailDf
            ? emailDf.length > 0
              ? emailDf[0].value?.toString() || ''
              : ''
            : ''
        }
      />
      <SepComments
        sepID={sepId ? Number.parseInt(sepId) : 0}
        roleList={roleList}
      />
    </div>
  );
};

export default SepDetails;
