import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Stack,
  Checkbox,
  FormControlLabel,
  InputLabel,
  Typography,
} from '@mui/material';

import {
  CreateKnockoutScreenTemplateBody,
  KnockoutScreenTemplate,
} from '../../../../../shared/types/Knockout';
import {
  useAddKnockoutScreenTemplateMutation,
  useEditKnockoutScreenTemplateMutation,
} from '../../../../services/knockoutSlice';
import RichTextEditor from '../../../../components/RichTextEditor/RichTextEditor';

const textFieldSx = {
  color: '#222222',
  '& > p': {
    margin: '5px 0px',
    color: '#646669',
    fontSize: '11px',
  },
};

const CustomCheckBox = ({
  starter,
  onChange,
}: {
  starter: boolean;
  onChange: (e: boolean) => void;
}) => {
  return (
    <FormControlLabel
      sx={{
        [`& .MuiTypography-root`]: {
          color: '#222222',
        },
      }}
      label="Starter Screen (Always show this knockout screen)"
      control={
        <Checkbox
          checked={starter}
          onChange={(e) => onChange(!starter)}
          id="start"
          name="start"
        />
      }
    />
  );
};

interface AddKnockoutScreenProps {
  knockoutScreen: KnockoutScreenTemplate;
  newOrder: number;
  onClose: () => void;
}

const AddKnockoutScreen = ({
  knockoutScreen,
  newOrder,
  onClose,
}: AddKnockoutScreenProps) => {
  const [screenName, setScreenName] = useState<string>(knockoutScreen.name);
  const [description, setDescription] = useState<string>(
    knockoutScreen.description ?? ''
  );
  const [starter, setStarter] = useState<boolean>(
    knockoutScreen.starter ?? false
  );

  useEffect(() => {
    setScreenName(knockoutScreen.name);
    setDescription(knockoutScreen.description ?? '');
    setStarter(knockoutScreen.starter ?? false);
  }, [knockoutScreen]);

  const [addKnockoutScreenMutation] = useAddKnockoutScreenTemplateMutation();
  const [editKnockoutScreen] = useEditKnockoutScreenTemplateMutation();
  const handleClick = (type: string) => {
    const data = {
      name: screenName,
      description,
      starter,
      order: starter
        ? knockoutScreen.order === undefined
          ? newOrder
          : knockoutScreen.order
        : undefined,
    } as CreateKnockoutScreenTemplateBody;
    if (type === 'Add') {
      addKnockoutScreenMutation(data).then(() => {
        setScreenName('');
        setDescription('');
        setStarter(false);
        onClose();
      });
    } else if (type === 'Edit') {
      editKnockoutScreen({ id: knockoutScreen.id, body: data }).then(() => {
        setScreenName('');
        setDescription('');
        setStarter(false);
        onClose();
      });
    }
  };

  return (
    <Box>
      <Stack
        display="flex"
        direction="column"
        alignItems="left"
        spacing={4}
        sx={{ p: 4 }}
      >
        <Box>
          <InputLabel sx={{ color: '#222222' }} htmlFor="task-name">
            Knockout Screen Title
          </InputLabel>
          <TextField
            sx={textFieldSx}
            size="small"
            id="name"
            name="name"
            fullWidth
            value={screenName}
            placeholder="Keep it 2-3 sentences. Briefly describe why this screen is here and how it helps the process."
            helperText="Keep it short and to the point. Make it sound human and be supportive when possible."
            onChange={(e) => setScreenName(e.target.value)}
          />
        </Box>
        <Box>
          <InputLabel sx={{ color: '#222222' }} htmlFor="task-assignment-name">
            Knockout Screen Subtitle
          </InputLabel>
          <RichTextEditor
            value={description}
            placeholder="This is a mock subtitle for example purposes only. Save button becomes active once all fields have input."
            setValue={setDescription}
            style={{ height: '120px' }}
          />
          <Typography
            sx={{
              margin: '5px 0px',
              color: '#646669',
              fontSize: '11px',
            }}
          >
            Keep it 2-3 sentences. Briefly describe why this screen is here and
            how it helps the process.
          </Typography>
        </Box>
        <CustomCheckBox starter={starter} onChange={(e) => setStarter(e)} />
      </Stack>
      <Stack
        direction="row"
        display="flex"
        justifyContent="right"
        spacing={2}
        sx={{ px: 4, pb: 4 }}
      >
        <Button
          sx={{ color: '#2372B9', textTransform: 'none' }}
          variant="text"
          onClick={() => onClose()}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          sx={{ backgroundColor: '#2372B9', textTransform: 'none' }}
          variant="contained"
          disabled={!screenName || !description}
          onClick={() =>
            handleClick(knockoutScreen.name === '' ? 'Add' : 'Edit')
          }
        >
          {`${knockoutScreen.name === '' ? 'Add' : 'Edit'} Knockout Screen`}
        </Button>
      </Stack>
    </Box>
  );
};

export default AddKnockoutScreen;
