import React, { useState, useEffect } from 'react';
import { Box, Button, InputLabel, Stack, TextField } from '@mui/material';
import { Setting } from '../../../../shared/types/Settings';
import {
  useUpdateSettingMutation,
  useInsertSettingMutation,
} from '../../../services/appSlice/appSlice';

const textFieldSx = {
  color: '#222222',
  '& > p': {
    margin: '5px 0px',
    color: '#646669',
    fontSize: '11px',
  },
};

const UpsertMenuItem = ({
  item,
  onClose,
  section,
  maxOrder,
}: {
  item: Setting | undefined;
  onClose: () => void;
  section: string;
  maxOrder: number;
}) => {
  const [displayText, setDisplayText] = useState('');
  const [url, setUrl] = useState('');
  const [updateSetting] = useUpdateSettingMutation();
  const [insertSetting] = useInsertSettingMutation();
  useEffect(() => {
    setDisplayText(item?.name || '');
    setUrl(item?.value || '');
  }, [item]);

  const handleUpsert = () => {
    if (item) {
      // update.
      updateSetting({
        id: item.id,
        body: {
          name: displayText,
          value: url,
          section: section,
          order: item.order,
        },
      });
    } else {
      insertSetting({
        name: displayText,
        value: url,
        section: section,
        order: maxOrder,
      });
    }
    onClose();
  };

  return (
    <Box sx={{ padding: '24px' }}>
      <Box sx={{ marginBottom: '24px' }}>
        <InputLabel
          sx={{
            color: '#222222',
          }}
          htmlFor="task-name"
        >
          Display Text
        </InputLabel>
        <TextField
          sx={textFieldSx}
          size="small"
          id="name"
          name="name"
          fullWidth
          data-testid="task-name"
          value={displayText}
          placeholder={`Ex. "Acronym Glossary"`}
          helperText="This will appear in the Quick Help Menu"
          onChange={(e) => setDisplayText(e.currentTarget.value)}
        />
      </Box>
      <Box>
        <InputLabel
          sx={{
            color: '#222222',
          }}
          htmlFor="task-name"
        >
          URL
        </InputLabel>
        <TextField
          sx={textFieldSx}
          size="small"
          id="name"
          name="name"
          fullWidth
          data-testid="task-name"
          value={url}
          placeholder={`Ex. "https://www.constellation.com"`}
          onChange={(e) => setUrl(e.currentTarget.value)}
        />
      </Box>

      <Stack
        direction="row"
        display="flex"
        justifyContent="right"
        spacing={2}
        sx={{ pb: 1, pt: 3 }}
      >
        <Button
          type="submit"
          sx={{ backgroundColor: '#2372B9', textTransform: 'none' }}
          variant="contained"
          disabled={url === '' || displayText === ''}
          onClick={() => handleUpsert()}
        >
          {item ? 'Edit Menu Item' : 'Add Menu Item'}
        </Button>
      </Stack>
    </Box>
  );
};

export default UpsertMenuItem;
