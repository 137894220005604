import React from 'react';
import { ToggleButtonGroup, styled, Box, Typography } from '@mui/material';
import ToggleButton, { ToggleButtonProps } from '@mui/material/ToggleButton';
import { DataField } from '../../../shared/types/DataField';

const MuiToggleButton = styled(ToggleButton)(({ theme }) => ({
  border: ' 1px solid !important',
  borderColor: theme.palette.gray.solidGray,
  paddingLeft: 12,
  paddingRight: 12,
  borderRadius: '6px !important',
  color: theme.palette.gray.darkGray1,
  '&:hover': {
    border: `2px solid ${theme.palette.solidBlue.main} !important`,
  },
  '&:active': {
    border: `2px solid ${theme.palette.solidBlue.main} !important`,
  },
  '&:not(:last-of-type),&:not(:first-of-type)': {
    '&.Mui-selected': {
      border: `2px solid ${theme.palette.solidBlue.main} !important`,
      background: `${theme.palette.blue.lightBlue1} !important`,
      color: theme.palette.gray.main,
    },
  },
  '&:not(:last-of-type)': {
    marginLeft: 3,
    marginRight: 6,
    borderRadius: '6px !important',
    border: ' 2px solid #222222',
  },
  '&:not(:first-of-type)': {
    marginLeft: 3,
    marginRight: 6,
    borderRadius: '6px !important',
    border: ' 2px solid #222222',
  },
}));

interface ToggleButtonExtended extends ToggleButtonProps {
  onChange: (e: any) => void;
  value: {};
  readOnly?: boolean;
  required?: boolean;
  field: DataField;
}

const YesNo = ({
  onChange,
  field,
  value,
  readOnly,
  required,
}: ToggleButtonExtended) => {
  return (
    <Box key={field.id}>
      <Typography className={required ? 'required' : ''} my={2}>
        {field.name}
      </Typography>
      <ToggleButtonGroup
        exclusive={true}
        onChange={onChange}
        value={value}
        sx={{ marginLeft: 1, display: 'flex', justifyContent: 'center' }}
        defaultValue={undefined}
        disabled={readOnly || undefined}
      >
        <MuiToggleButton value="yes">Yes</MuiToggleButton>
        <MuiToggleButton value="no">No</MuiToggleButton>
        <MuiToggleButton value="idk">I don't know</MuiToggleButton>
      </ToggleButtonGroup>
    </Box>
  );
};

export default YesNo;
