import React, { useState } from 'react';
import { IconButton } from '@mui/material';
import ConfirmDialog from '../ConfirmDialog';

const HoveredIconConfirm = ({
  icon,
  hoverIcon,
  title,
  content,
  onClick,
}: {
  icon: string;
  hoverIcon: string;
  title?: string;
  content?: string;
  onClick: () => void;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [hover, setHover] = useState<boolean>(false);
  return (
    <>
      <ConfirmDialog
        open={open}
        title={title}
        content={content}
        onConfirm={(isConfirmed) => {
          isConfirmed && onClick();
          setOpen(false);
        }}
      />
      <IconButton
        sx={{
          width: '25px',
          height: '25px',
          borderRadius: '3px',
          fontSize: '15px',
          color: hover ? '#2372B9' : '#646669',
        }}
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => setOpen(true)}
      >
        <i className={hover ? hoverIcon : icon}></i>
      </IconButton>
    </>
  );
};

export default HoveredIconConfirm;
