import React, { useState } from 'react';
import Departments from './Departments';
import KnockoutScreens from './KnockoutScreen';
import Tasks from './Tasks';
import { DataFields } from './DataFields';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { Box, Typography } from '@mui/material';
import { styled, useTheme } from '@mui/material/styles';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import { useAccount } from '@azure/msal-react';
import { useNavigate } from 'react-router';
import { env } from '../../../shared/env';
import { SEPApplication } from './SEPApplication';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: string;
  currentSubTab: string;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, currentSubTab, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== currentSubTab}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === currentSubTab && <Box sx={{ px: 0 }}>{children}</Box>}
    </Box>
  );
}

function a11yProps(index: number) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

const StyledTab = styled((props: any) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    font: 'normal normal normal 16px/22px Open Sans;',
    height: '48px !important',
    minHeight: '48px',
    padding: '10px 10px 10px 0px',
    width: '100%',
    alignSelf: 'start',
    '&:hover': {
      color: '#2372B9',
    },
    '&.MuiTab-root': {
      textAlign: 'right',
      justifyContent: 'left ! important',
      paddingLeft: '30px',
    },
    '&.Mui-selected': {
      backgroundColor: theme.palette.blue.lightBlue1,
      color: '#2372B9',
    },
    '&.Mui-focusVisible': {
      backgroundColor: 'rgba(100, 95, 228, 0.32)',
    },
  })
);

const Admin = () => {
  const [currentSubTab, setCurrentSubTab] =
    useState<string>('Knockout Screens');

  const handleSubTabChange = (
    event: React.SyntheticEvent,
    newValue: string
  ) => {
    setCurrentSubTab(newValue);
  };
  const theme = useTheme();
  const account = useAccount();

  const roleList: any = React.useMemo(() => {
    if (env.REACT_APP_LOCAL_DEV_ADMIN === 'true') {
      return ['AuthSuperUser'];
    } else {
      return account?.idTokenClaims?.roles || [];
    }
  }, [account]);

  const isSuperUser =
    roleList?.includes('AuthSuperUser') &&
    (process.env.NODE_ENV === 'test'
      ? process.env.REACT_APP_SHOW_ADMIN_FEATURES === 'true'
      : env.REACT_APP_SHOW_ADMIN_FEATURES === 'true');
  const navigate = useNavigate();

  React.useEffect(() => {
    if (!isSuperUser) {
      navigate('/');
    }
  }, [isSuperUser, navigate]);
  return (
    <>
      <Box
        display="flex"
        sx={{
          bgcolor: 'background.paper',
          overflowY: 'auto',
        }}
      >
        <Box
          sx={{
            borderRight: 1,
            borderColor: 'divider',
            width: 202,
          }}
        >
          <Typography
            variant="h6"
            component="div"
            sx={{
              paddingLeft: '30px',
              paddingTop: '30px',
              paddingBottom: '10px',
              fontWeight: '600',
              fontSize: '14px',
              minWidth: 200,
            }}
          >
            Manage
          </Typography>
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={currentSubTab}
            onChange={handleSubTabChange}
            aria-label="Vertical tabs"
            sx={{
              minWidth: 200,
              '& .MuiTabs-flexContainer': {
                flexDirection: 'column',
                justifyContent: 'flex-start',
              },
              textALign: 'left',
            }}
            TabIndicatorProps={{
              style: { backgroundColor: theme.palette.blue.lightBlue1 },
            }}
          >
            <StyledTab
              value="Knockout Screens"
              icon={<i className="fa-solid fa-window"></i>}
              iconPosition="start"
              label="Knockout Screens"
              {...a11yProps(0)}
              onClick={() => setCurrentSubTab('Knockout Screens')}
            />
            <StyledTab
              value="Tasks"
              icon={<i className="fa-regular fa-list-check"></i>}
              iconPosition="start"
              label="Tasks"
              {...a11yProps(1)}
              onClick={() => setCurrentSubTab('Tasks')}
            />
            <StyledTab
              value="Data Fields"
              icon={<i className="fa-solid fa-pen-field"></i>}
              iconPosition="start"
              label="Data Fields"
              {...a11yProps(2)}
              onClick={() => setCurrentSubTab('Data Fields')}
            />
            <StyledTab
              value="Departments"
              icon={<i className="fa-solid fa-users"></i>}
              iconPosition="start"
              label="Departments"
              {...a11yProps(3)}
              onClick={() => setCurrentSubTab('Departments')}
            />
            <StyledTab
              value="SEP Application"
              icon={<CorporateFareIcon />}
              iconPosition="start"
              label="SEP Application"
              {...a11yProps(4)}
              onClick={() => setCurrentSubTab('SEP Application')}
            />
          </Tabs>
        </Box>
        <Box sx={{ flexGrow: 1, flexBasis: 'auto', width: '80%' }}>
          <TabPanel
            value="Knockout Screens"
            currentSubTab={currentSubTab}
            index={0}
          >
            <KnockoutScreens />
          </TabPanel>
          <TabPanel value="Tasks" currentSubTab={currentSubTab} index={1}>
            <Tasks />
          </TabPanel>
          <TabPanel value="Data Fields" currentSubTab={currentSubTab} index={2}>
            <DataFields />
          </TabPanel>
          <TabPanel value="Departments" currentSubTab={currentSubTab} index={3}>
            <Departments />
          </TabPanel>
          <TabPanel
            value="SEP Application"
            currentSubTab={currentSubTab}
            index={4}
          >
            <SEPApplication />
          </TabPanel>
        </Box>
      </Box>
    </>
  );
};

export default Admin;
