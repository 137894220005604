export const replaceStakeholders = (desc: any, responseData: any,apmData: any) => {
  const parsedApmData = JSON.parse(apmData);
  const placeholderRegex = /{([A-Za-z\s]+?)stakeholder}/gi;
  const apmDataMapping: any = {
    varAPMID: "number",
    varDRTier: "emergency_tier",
    varBV: "business_criticality",
    varPortfolio: "u_apm_portfolio",
    varPortfolioOwner: "u_portfolio_owner",
    varName: "name"
  }
  const apmDataPlaceholderRegex = new RegExp(`{(${Object.keys(apmDataMapping).join('|')})}`, 'gi')
  const replacePlaceholder = (match: any, placeholderName: any) => {
    const normalizedPlaceholder = placeholderName
      .trim()
      .replace(/\s+/g, "")
      .toLowerCase();
    const matchingDepartment = responseData?.find(
      (item: any) =>
        item?.department?.name?.trim()?.replace(/\s+/g, "")?.toLowerCase() ===
        normalizedPlaceholder
    );
    if (
      matchingDepartment &&
      matchingDepartment?.user &&
      matchingDepartment?.user?.givenName
    ) {
      return `<b>${matchingDepartment?.user?.givenName} ${matchingDepartment?.user?.surname}</b>`;
    } else {
      return "<b>User assigment is still pending</b>";
    }
  };
  const replaceApmVariables = (match: any, placeholderName: any) => {
    const key = apmDataMapping[placeholderName]
    if (parsedApmData) {
      return `<b>${parsedApmData[key]}</b>`;
    } else {
      return "<b>No APM Data Found</b>";
    }
  };
  desc = desc?.replace(apmDataPlaceholderRegex, replaceApmVariables);
  return desc?.replace(placeholderRegex, replacePlaceholder);
};
