import React, { useState } from 'react';
import {
  MenuItem,
  FormControl,
  Box,
  Typography,
  Stack,
  InputLabel,
  Select,
  Divider,
} from '@mui/material';
import {
  TaskTemplate,
  TaskWithTaskDependency,
  ValidTaskDependencyStatus,
} from '../../../../../shared/types/Task';
import { ActionButtons } from '../../../CancelSave';
import {
  useAddTaskDependencyMutation,
  useUpdateTaskDependencyMutation,
} from '../../../../services/tasksSlice/tasksSlice';

interface TaskDropDownProps {
  options: TaskTemplate[];
  description: string;
  taskTemplate: TaskTemplate;
  setHandle?: React.Dispatch<React.SetStateAction<string>>;
  setEdit?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedTaskDependency?: TaskWithTaskDependency;
}

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: 250,
      width: 580,
    },
  },
  anchorOrigin: {
    vertical: 44,
    horizontal: 120,
  },
  transformOrigin: {
    vertical: 1,
    horizontal: 120,
  },
};

const Placeholder = ({ children }: { children: string }) => {
  return <div style={{ color: '#222222', fontSize: '14px' }}>{children}</div>;
};

const ManageTaskDependency = ({
  options,
  description,
  taskTemplate,
  setHandle,
  setEdit,
  selectedTaskDependency,
}: TaskDropDownProps) => {
  const [addTaskDependency, { isLoading: loading }] =
    useAddTaskDependencyMutation();
  const [updateTaskDependency] = useUpdateTaskDependencyMutation();
  const [selectedTask, setSelectedTask] = useState(
    selectedTaskDependency?.id ?? 0
  );
  const [status, setStatus] = useState(
    selectedTaskDependency?.taskDependency.status ?? ''
  );

  const handleTaskChange = (event: any) => {
    setSelectedTask(event.target.value);
  };

  const handlePhaseChange = (e: any) => {
    setStatus(e.target.value);
  };

  const handleCancel = () => {
    if (selectedTaskDependency) {
      setSelectedTask(selectedTaskDependency.id);
      setStatus(selectedTaskDependency.taskDependency.status);
    } else {
      setSelectedTask(0);
      setStatus('');
    }
  };

  const handleSave = () => {
    if (
      status === ValidTaskDependencyStatus.inReview ||
      status === ValidTaskDependencyStatus.complete
    ) {
      if (selectedTaskDependency) {
        updateTaskDependency({
          id: selectedTaskDependency.taskDependency.id,
          body: {
            taskID: selectedTask,
            dependentTaskID:
              selectedTaskDependency.taskDependency.dependentTaskID,
            status: status,
          },
        });
        handleCancel();
        setEdit && setEdit(false);
      } else {
        addTaskDependency({
          taskID: selectedTask,
          dependentTaskID: taskTemplate.id,
          status: status,
        });
        handleCancel();
        setHandle && setHandle('');
      }
    }
  };

  return (
    <>
      <Box
        sx={{
          backgroundColor: '#F8F9FA',
          padding: '16px 40px 16px 45px',
        }}
      >
        <Stack
          display="flex"
          flexDirection="column"
          justifyContent="space-between"
        >
          <Typography>
            <b>{taskTemplate.shortName}</b>
          </Typography>
          <Typography>{taskTemplate.name}</Typography>
        </Stack>
      </Box>
      <Box mt={3} />
      <Box ml={4}>
        <InputLabel sx={{ color: '#222222' }}>Choose a Task</InputLabel>
      </Box>
      <FormControl sx={{ m: 4, mt: 1, minWidth: 600 }} size="small">
        <Select
          labelId="demo-select-small"
          id="demo-select-small"
          value={selectedTask}
          onChange={handleTaskChange}
          MenuProps={MenuProps}
        >
          <MenuItem disabled value={0}>
            {description}
          </MenuItem>
          <Divider />
          {options.map((TaskTemplate, index) => [
            <MenuItem style={{whiteSpace: 'normal'}} value={TaskTemplate.id} key={index}>
              {TaskTemplate.name}
            </MenuItem>,
            <Divider />,
          ])}
        </Select>
      </FormControl>
      <Box m={2} />
      <Box ml={4}>
        <InputLabel sx={{ color: '#222222' }}>Required Status</InputLabel>
      </Box>
      <FormControl sx={{ m: 4, mt: 1, minWidth: 600 }} size="small">
        <Select
          size="small"
          id="demo-simple-select-helper"
          name="Required Status"
          value={status}
          displayEmpty={true}
          onChange={(e) => handlePhaseChange(e)}
          sx={{ textTransform: 'capitalize' }}
        >
          <MenuItem disabled value="">
            <Placeholder>Choose a status from the dropdown</Placeholder>
          </MenuItem>
          {Object.values(ValidTaskDependencyStatus).map((e, i) => {
            return (
              <MenuItem key={i} value={e} sx={{ textTransform: 'capitalize' }}>
                {e === 'inReview' ? 'in Review' : e}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
      <Divider />
      <Box m={2} />
      <ActionButtons
        handleCancel={handleCancel}
        handleSave={handleSave}
        loading={loading}
        buttonValidation={selectedTask === 0 || status === ''}
      />
    </>
  );
};

export default ManageTaskDependency;
