import { sepAPI } from '../API';
import { DefaultStakeholderExtended } from '../../../shared/types/DefaultStakeholder';
import { setSnackbarForEndpoint } from '../../utils/snackbar';

export const DefaultStakeholderSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    getSepStakeholder: builder.query<DefaultStakeholderExtended[], number>({
      query: (sepID) => `default-stakeholders/${sepID}`,
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting the list of Stakeholders.',
        });
      },
      providesTags: ['DefaultStakeholdersForSEP'],
    }),
  }),
});

export const { useGetSepStakeholderQuery } = DefaultStakeholderSlice;
