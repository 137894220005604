import { sepAPI } from '../API';
import { setSnackbarForEndpoint } from '../../utils/snackbar';
import {
  CommentExtended,
  CreateCommentBody,
} from '../../../shared/types/Comment';

export const commentSlice = sepAPI.injectEndpoints({
  endpoints: (builder) => ({
    createComment: builder.mutation<CommentExtended, CreateCommentBody>({
      query: ({ ...body }) => {
        return {
          url: `comment`,
          method: 'POST',
          body,
        };
      },
      invalidatesTags: ['Comments', 'SEPs'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Comment created successfully!',
          errorMessage: 'There was a problem creating this comment.',
        });
      },
    }),
    deleteComment: builder.mutation<void, number>({
      query: (id) => {
        return {
          url: `comment/${id}`,
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Comments'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Comments deleted successfully!',
          errorMessage: 'There was a problem deleting this comment.',
        });
      },
    }),
    editComment: builder.mutation<
      CommentExtended,
      { id: number; comment: string }
    >({
      query: (arg) => {
        return {
          url: `comment/${arg.id}`,
          method: 'PATCH',
          body: { comment: arg.comment },
        };
      },
      invalidatesTags: ['Comments'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          successMessage: 'Comments edited successfully!',
          errorMessage: 'There was a problem edit the comments.',
        });
      },
    }),
    getComments: builder.query<CommentExtended[], number>({
      query: (id) => `sep/${id}/comments`,
      providesTags: (result, error, arg) =>
        result
          ? [
              ...result.map(({ id }) => ({ type: 'Comments' as const, id })),
              'Comments',
            ]
          : ['Comments'],
      onQueryStarted: (arg, api) => {
        setSnackbarForEndpoint(api, {
          errorMessage: 'There was a problem getting the comments.',
        });
      },
    }),
    updateUserReadComment: builder.mutation<void, number>({
      query: (IDs) => ({
        url: `user-comment-read`,
        method: 'POST',
        body: [IDs],
      }),
      invalidatesTags: (result, error, arg) => [{ type: 'Comments', id: arg }],
    }),
  }),
});

export const {
  useCreateCommentMutation,
  useGetCommentsQuery,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useUpdateUserReadCommentMutation,
} = commentSlice;
