import React, { useState } from 'react';
import { Box } from '@mui/material';
import AddButton from '../../../components/Admin/AddButton';
import KnockoutScreenCard from '../../../components/Admin/KnockoutScreens/KnockoutScreenCard';
import { useGetKnockoutScreenTemplateQuery } from '../../../services/knockoutSlice';
import { KnockoutScreenTemplate } from '../../../../shared/types/Knockout';
import ModalWidget from '../../../components/ModalWidget/ModalWidget';
import AddKnockoutScreen from '../../../components/Admin/KnockoutScreens/AddKnockoutScreen';
import AdminHeader from '../../../components/Admin/AdminHeader';

const initKnockoutScreen: KnockoutScreenTemplate = {
  id: 0,
  name: '',
  knockoutScreenDataFields: [],
  template: true,
  createdBy: new Date().toString(),
  sepID: 0,
  complete: false,
  createdAt: new Date().toDateString(),
  updatedAt: new Date().toDateString(),
};

const KnockoutScreens = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectedKnockoutScreen, setSelectedKnockoutScreen] =
    useState<KnockoutScreenTemplate>(initKnockoutScreen);
  const { data } = useGetKnockoutScreenTemplateQuery();
  // Get max Order number...
  const maxOrder = data
    ? Math.max(
        ...data.filter((entry) => entry.starter).map((e) => e.order || 0)
      )
    : 0;
  return (
    <Box sx={{ minHeight: '500px' }}>
      <AdminHeader title="Knockout Screens" />
      <ModalWidget
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        title={`${
          selectedKnockoutScreen.name === '' ? 'Add' : 'Edit'
        } Knockout Screen`}
        children={
          <AddKnockoutScreen
            onClose={() => setIsOpen(false)}
            knockoutScreen={selectedKnockoutScreen}
            newOrder={maxOrder + 1}
          />
        }
      />
      {data &&
        [
          ...data
            ?.filter((e) => e.starter)
            .sort((a, b) => {
              const aOrder: number = a.order || 0;
              const bOrder: number = b.order || 0;
              return aOrder - bOrder;
            }),
          ...data?.filter((e) => !e.starter),
        ].map((knockoutScreen: KnockoutScreenTemplate, key) => {
          return (
            <KnockoutScreenCard
              key={key}
              knockoutScreen={knockoutScreen}
              onEditTriggered={(e: KnockoutScreenTemplate) => {
                setSelectedKnockoutScreen(e);
                setIsOpen(true);
              }}
            />
          );
        })}
      <AddButton
        tab="Knockout Screen"
        onAdd={(tab: string) => {
          setSelectedKnockoutScreen({
            ...initKnockoutScreen,
            order: maxOrder + 1,
          });
          setIsOpen(true);
        }}
      />
    </Box>
  );
};

export default KnockoutScreens;
