import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { UserShort } from '../../../shared/types/User';
import { Department } from '../../../shared/types/Department';
import './styles.css';

export interface SepReviewTeamProp {
  reviewer: UserShort | null;
  department: Department;
}

export interface SepReviewTeamProps {
  reviewerTeams: SepReviewTeamProp[];
}

const SepReviewTeam = ({ reviewerTeams }: SepReviewTeamProps) => {
  return (
    <Box sx={{ padding: '30px 30px 10px 30px' }}>
      <Box sx={{ mb: 4 }}>
        <Typography
          sx={{
            font: ' normal normal 600 18px/24px Open Sans;',
          }}
        >
          Review Team
        </Typography>
      </Box>
      <Grid
        container
        item
        sx={{ display: 'flex', mb: 3 }}
        xs={12}
        md={12}
        lg={12}
        data-testid="reviewerTeam"
      >
        {reviewerTeams.map((team, index) => (
          <Grid key={`reviewerTeams${index}`} item md={3}>
            <p className="sep-label">{team.department.name}</p>
            <p className="sep-data">
              {team.reviewer?.givenName && team.reviewer?.surname
                ? `${team.reviewer?.givenName} ${team.reviewer?.surname}`
                : team.reviewer?.displayName}
            </p>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SepReviewTeam;
